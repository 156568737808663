import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormAdd from "./FormAdd";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function ModalAdd(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text: "This will also remove the number from any associated agents!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/number/owned/` + _id,
        })
          .then((response) => {
            props.deleteOwnedFromState(response.data._id);

            Swal.fire("Deleted!", "Your phone is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const label = props.buttonLabel;
  var addEditButton = null;
  if (props.buttonType === "add") {
    addEditButton = (
      <>
        <h2 style={{ paddingBottom: "0px", display: "inline-block" }}>
          Your Numbers
        </h2>
        <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
          {label}
        </Button>
      </>
    );
  } else if (props.buttonType === "actions") {
    addEditButton = (
      <Button
        color={"default"}
        onClick={() => {
          confirmDelete(props.item._id, props.item.name);
        }}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    );
  }

  return (
    <>
      {addEditButton}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>
          <FormAdd
            addOwnedToState={props.addOwnedToState}
            toggle={toggle}
            item={props.item}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalAdd;
