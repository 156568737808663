import { LOAD_PROFILE, GET_PROFILE, SET_PROFILE } from "../actions/types";

const initialState = {
  payload: false,
  loading: true,
  isProfileUpdating: false,
  error: {},
};

export default function (state = initialState, action) {
  // const { type, payload } = action;

  switch (action.type) {
    case LOAD_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE:
      return {
        payload: action.payload,
        loading: false,
      };

    case SET_PROFILE:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
