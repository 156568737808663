import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Row, Col, Container, ModalFooter } from "reactstrap";

// core components
import ConditionalWrapper from "../components/ConditionalWrapper.js";
import CardsHeader from "components/Headers/CardsHeader.js";
import ModalAgent from "./ModalAgent";
import TableAgent from "./TableAgent";
import axios from "axios";
import Loader from "layouts/Loader.js";
import { agentDropdown, getAndTeamByOwnerLead } from "./../../../actions/agentAction";
import { numberDropdown } from "./../../../actions/numberAction";
function Agents(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getItems = (token, abortController) => {
    setLoading(true);
    fetch("/api/agent/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        if (items) {
          setItems(items);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const addItemToState = (item) => {
    setItems([...items, item]);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["agent"] += 1;
      props.setStepList(stepList);
    }
    props.agentDropdown();
    props.getAndTeamByOwnerLead();
  };

  const updateState = (item) => {
    const itemIndex = items.findIndex((data) => data._id === item._id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
    props.agentDropdown();
    props.getAndTeamByOwnerLead();
  };

  const deleteItemFromState = (_id) => {
    const updatedItems = items.filter((item) => item._id !== _id);
    setItems(updatedItems);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["agent"] -= 1;
      props.setStepList(stepList);
    }
    props.agentDropdown();
    props.getAndTeamByOwnerLead();
  };

  const changeStatusDnd = (data) => {
    axios({
      method: "patch",
      responseType: "json",
      url: `/api/agent/` + data._id + "/dnd",
      data: { dnd: !data.dnd },
    })
      .then((response) => {
        const updatedItems = items.filter((item) => {
          if (item._id === data._id) {
            Object.assign(item, response.data);
          }
          return item;
        });
        setItems(updatedItems);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token]);

  return (
    <>
      {props.handleSkipButton ? "" : <CardsHeader name="Agents" />}
      <ConditionalWrapper
        condition={!props.handleSkipButton}
        wrapper={(children) => (
          <>
            {" "}
            <Container className="mt--6" fluid>
              <Row>
                <div className="col">
                  <div className="card-wrapper">{children}</div>
                </div>
              </Row>
            </Container>
          </>
        )}
      >
        <Card>
          <CardBody>
            {loading ? (
              <Loader />
            ) : (
              <Row>
                <Col lg="12">
                  {!loading ? (
                    <>
                      {props.auth.user.role !== "agent" ? (
                        <ModalAgent
                          buttonType="add"
                          buttonLabel="Add Agent"
                          addItemToState={addItemToState}
                          headerTitle="Add Agent"
                        />
                      ) : null}
                      {/*item.phone.status*/}
                      <TableAgent
                        items={items}
                        updateState={updateState}
                        auth={props.auth}
                        updateOwnedState = {
                          (data, item) => {
                            const itemIndex = items.findIndex((data) => data._id === item._id);
                            item['phone']['status'] = data.status
                            const newArray = [
                              ...items.slice(0, itemIndex),
                              item,
                              ...items.slice(itemIndex + 1),
                            ];
                            setItems(newArray);
                            props.numberDropdown();
                          }
                        }
                        deleteItemFromState={deleteItemFromState}
                        changeStatusDnd={changeStatusDnd}
                        hideColumn={props.handleSkipButton}
                        settings={[
                          { width: 1200, column: "all" },
                          { width: 570, column: 5 },
                          { width: 520, column: 4 },
                          { width: 400, column: 3 },
                          { width: 0, column: 2 },
                        ]}
                      />
                    </>
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </Col>
              </Row>
            )}
            <hr />
          </CardBody>
        </Card>{" "}
        <ModalFooter>
          {props.handleSkipButton ? props.handleSkipButton() : <></>}
        </ModalFooter>
      </ConditionalWrapper>{" "}
    </>
  );
}
Agents.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  agentDropdown,
  getAndTeamByOwnerLead,
  numberDropdown
};

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
