import React from "react";
import Ajax from "./spinner.gif";

const Loader = () => {
  return (
    <div>
      <img
        alt="loading..."
        src={Ajax}
        className = "loader_width"
        style={{
          //zIndex: "1200",
          //left: "40%",
          //right: "60%",
          //position: "absolute",
        }}
      />
    </div>
  );
};

export default Loader;
//left: "50%", right: "50%", position: "fixed",
// position: "fixed",
