import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import FormAgent from "./FormAgent";
import FormAgentView from "./FormAgentView";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormSchedule from "../Agents/FormSchedule";
const MySwal = withReactContent(Swal);

function MobileModalAgent(props) {
  const [modal, setModal] = useState(false);
  const [typeModal, setTypeModal] = useState("modal");
  const [screenChange, setScreenValue] = useState("desktop");

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text:
        "Removing an agent will also remove it from any teams. Make sure this doesnt leave you with empty teams.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/agent/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);

            Swal.fire("Deleted!", "Your agent is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: err.response.data.error.message,
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: err.response.data.error.message,
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const allowDelete = (account) => {
    if (typeof account !== "undefined") {
      if (
        account.role === "owner" ||
        account.email === props.auth.user.email ||
        props.auth.user.role === "agent"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (props.auth.user.role === "agent") {
      return true;
    } else {
      return false;
    }
  };
  const allowEdit = (item) => {
    const account = item.account;
    if (typeof account !== "undefined") {
      if (
        account.email !== props.auth.user.email &&
        (props.auth.user.role === "agent" || account.role === "owner")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return props.auth.user.role === "agent";
    }
  };
  const allowSchedule = (item) => {
    const account = item.account;
    var bool = false;
    if (typeof account !== "undefined") {
      if (
        account.email !== props.auth.user.email &&
        props.auth.user.role === "agent"
      ) {
        bool = true;
      }
    } else {
      bool = props.auth.user.role === "agent";
    }
    return bool;
  };
  const scheduleToggle = () => {
    setTypeModal("schedule");
    toggle();
  };
  const modalToggle = () => {
    setTypeModal("modal");
    toggle();
  };
  var addEditButton = "";
  if (props.buttonType === "actions") {
    addEditButton = (
      <UncontrolledDropdown className="mobilescreen_button" direction="left">
        <DropdownToggle
          caret
          className={"remove_background action_act"}
        ></DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={scheduleToggle}
            className="dropdown-item remove_background"
          >
            <i className="fas fa-calendar"></i>{" "}
            {props.type["schedule"].buttonLabel}
          </DropdownItem>
          {allowEdit(props.item) ? (
            <DropdownItem
              onClick={modalToggle}
              className="dropdown-item remove_background"
            >
              <i className="fas fa-eye"></i> View
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={modalToggle}
              className="dropdown-item remove_background"
            >
              <i className="fas fa-pen"></i> Edit
            </DropdownItem>
          )}
          <DropdownItem
            disabled={allowDelete(props.item.account)}
            onClick={() => {
              confirmDelete(
                props.item._id,
                props.item.first + " " + props.item.last
              );
            }}
            className="dropdown-item remove_background"
          >
            <i className="fa fa-trash"></i> Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
  const getFormModal = () => {
    switch (typeModal) {
      case "schedule":
        return (
          <FormSchedule
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            updateWhat="agent"
            toggle={modalToggle}
            item={props.item}
            allowEdit={!allowSchedule(props.item)}
            screenChange={screenChange}
            setScreenValue={setScreenValue}
          />
        );
      default:
        var formModal = null;
        if (modal) {
          formModal = !allowEdit(props.item) ? (
            <FormAgent
              addItemToState={props.addItemToState}
              updateState={props.updateState}
              toggle={scheduleToggle}
              item={props.item}
              auth={props.auth}
              hasLogin={props.hasLogin}
            />
          ) : (
            <FormAgentView
              toggle={toggle}
              item={props.item}
              auth={props.auth}
              hasLogin={props.hasLogin}
            />
          );
        }
        return formModal;
    }
  };
  return (
    <>
      {addEditButton}

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {allowEdit(props.item)
            ? props.type[typeModal].headerTitleView
            : props.type[typeModal].headerTitle}
        </ModalHeader>
        <ModalBody>{getFormModal()}</ModalBody>
      </Modal>
    </>
  );
}
MobileModalAgent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MobileModalAgent);
