import React from "react";
import DateFilter from "./DateFilter";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Bar } from "react-chartjs-2";
import Loader from "layouts/Loader";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

class ReportLeads extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const startValue = new Date(date).setMonth(date.getMonth() - 1).valueOf();
    date.setHours(23, 59, 59, 999);
    const endValue = new Date(date).valueOf();
    this.state = {
      defautValue: [startValue, endValue],
      byDay: false,
      byDayStatus: false,
      byDayResult: false,
      mainCount: false,
    };
  }
  async _leadByDay(_data) {
    const _label = [];
    const _avr = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _avr.push(_byday.byDay);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Leads",
          backgroundColor: "rgba(83, 51, 237, 0.2)",
          borderColor: "rgba(83, 51, 237, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(83, 51, 237, 0.4)",
          hoverBorderColor: "rgba(83, 51, 237, 1)",
          data: _avr,
        },
      ],
    };
    this.setState({ byDay: chart });
  }
  async _leadByDayStatus(_data) {
    const _label = [];
    const _waiting = [];
    const _called = [];
    const _not_called = [];
    const _skipped = [];
    const _error = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _waiting.push(_byday.waiting);
      _called.push(_byday.called);
      _not_called.push(_byday.not_called);
      _skipped.push(_byday.skipped);
      _error.push(_byday.error);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Waiting",
          backgroundColor: "rgba(230, 126, 34, 0.2)",
          borderColor: "rgba(230, 126, 34, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(230, 126, 34, 0.4)",
          hoverBorderColor: "rgba(230, 126, 34, 1)",
          data: _waiting,
        },
        {
          label: "Called",
          backgroundColor: "rgba(240, 255, 0, 0.2)",
          borderColor: "rgba(240, 255, 0, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(240, 255, 0, 0.4)",
          hoverBorderColor: "rgba(240, 255, 0, 1)",
          data: _called,
        },
        {
          label: "Not Called",
          backgroundColor: "rgba(246, 36, 89, 0.2)",
          borderColor: "rgba(246, 36, 89, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(246, 36, 89, 0.4)",
          hoverBorderColor: "rgba(246, 36, 89, 1)",
          data: _not_called,
        },
        {
          label: "Skipped",
          backgroundColor: "rgba(77, 175, 124, 0.2)",
          borderColor: "rgba(77, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(77, 175, 124, 0.4)",
          hoverBorderColor: "rgba(77, 175, 124, 1)",
          data: _skipped,
        },
        {
          label: "Error",
          backgroundColor: "rgba(177, 175, 124, 0.2)",
          borderColor: "rgba(177, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(177, 175, 124, 0.4)",
          hoverBorderColor: "rgba(177, 175, 124, 1)",
          data: _error,
        },
      ],
    };
    this.setState({ byDayStatus: chart });
  }
  async _leadByDayResult(_data) {
    const _label = [];
    const _unknown = [];
    const _answered = [];
    const _contacted = [];
    const _voicemail = [];
    const _missed = [];
    const _removed = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _unknown.push(_byday.unknown);
      _answered.push(_byday.answered);
      _contacted.push(_byday.contacted);
      _voicemail.push(_byday.voicemail);
      _missed.push(_byday.missed);
      _removed.push(_byday.removed);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Unknown",
          backgroundColor: "rgba(230, 126, 34, 0.2)",
          borderColor: "rgba(230, 126, 34, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(230, 126, 34, 0.4)",
          hoverBorderColor: "rgba(230, 126, 34, 1)",
          data: _unknown,
        },
        {
          label: "Answered",
          backgroundColor: "rgba(240, 255, 0, 0.2)",
          borderColor: "rgba(240, 255, 0, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(240, 255, 0, 0.4)",
          hoverBorderColor: "rgba(240, 255, 0, 1)",
          data: _answered,
        },
        {
          label: "Contacted",
          backgroundColor: "rgba(246, 36, 89, 0.2)",
          borderColor: "rgba(246, 36, 89, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(246, 36, 89, 0.4)",
          hoverBorderColor: "rgba(246, 36, 89, 1)",
          data: _contacted,
        },
        {
          label: "Voicemail",
          backgroundColor: "rgba(77, 175, 124, 0.2)",
          borderColor: "rgba(77, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(77, 175, 124, 0.4)",
          hoverBorderColor: "rgba(77, 175, 124, 1)",
          data: _voicemail,
        },
        {
          label: "Missed",
          backgroundColor: "rgba(177, 175, 124, 0.2)",
          borderColor: "rgba(177, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(177, 175, 124, 0.4)",
          hoverBorderColor: "rgba(177, 175, 124, 1)",
          data: _missed,
        },
        {
          label: "Removed",
          backgroundColor: "rgba(277, 175, 124, 0.2)",
          borderColor: "rgba(277, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(277, 175, 124, 0.4)",
          hoverBorderColor: "rgba(277, 175, 124, 1)",
          data: _removed,
        },
      ],
    };
    this.setState({ byDayResult: chart });
  }
  getByDay(value) {
    this.setState({
      byDay: false,
      byDayStatus: false,
      byDayResult: false,
    });
    var startVlaue =
      value && value[0] ? moment(value[0]).format("YYYY-MM-DD") : "";
    var endVlaue =
      value && value[1] ? moment(value[1]).format("YYYY-MM-DD") : "";
    var queryString = `start=` + startVlaue + `&end=` + endVlaue;
    if (startVlaue && endVlaue) {
      if (startVlaue > endVlaue) {
        queryString = `start=` + endVlaue + `&end=` + startVlaue;
      }
    }
    this.axiosCancelSource = axios.CancelToken.source();
    axios({
      method: "get",
      responseType: "json",
      url: `/api/lead/report/byday?` + queryString,
      cancelToken: this.axiosCancelSource.token
    })
      .then(async (response) => {
        if (response.data) {
          this._leadByDay(response.data[0]["leadByDayStatus"]);
          this._leadByDayStatus(response.data[0]["leadByDayStatus"]);
          this._leadByDayResult(response.data[0]["leadByDayStatus"]);
          this.setState({ mainCount: response.data[0]["main_count"][0] });
        }
      })
      .catch((err) => {
        if( err.message !== 'AXIOS_CANCEL') {
          Swal.fire({
            text: "Something went wrong. Try again.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  }
  componentDidMount() {
    this.getByDay(this.state.defautValue);
  }
  componentWillUnmount() {
    this.axiosCancelSource.cancel('AXIOS_CANCEL')
  }
  setFilterValue(value) {
    this.getByDay(value);
  }
  render() {
    return (
      <>
        <SimpleHeader name="" parentName="Reports" />
        <Container className="mt--6" fluid>
          <Row>
            <span className="col">
              <span className="card-wrapper">
                <Card>
                  <CardHeader>
                    <span className="date_filter_header">
                      <h3 className="mb-0">Leads</h3>
                      <span className="date_filter_div">
                        <DateFilter
                          defautValue={this.state.defautValue}
                          setFilterValue={this.setFilterValue.bind(this)}
                        />
                      </span>
                    </span>
                  </CardHeader>
                  <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <Col sm="6" md="4" xl="4" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Leads Added</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_count
                              : "loading..."}{" "}
                            <i
                              className="fa fas fa-users text-info"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="4" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Leads Called</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_calls
                              : "loading..."}{" "}
                            <i
                              className="fa fas fa-phone-volume  text-green"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="4" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Leads Contacted</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_contrated
                              : "loading..."}{" "}
                            <i
                              className="fas fa-check-circle  text-pink"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">Leads Added by day</CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byDay ? (
                            <Bar
                              data={this.state.byDay}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Number of Leads",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">Lead Status by day</CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byDayStatus ? (
                            <Bar
                              data={this.state.byDayStatus}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Number of Leads",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">Lead Results by day</CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byDayResult ? (
                            <Bar
                              data={this.state.byDayResult}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Number of Leads",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>
                </Card>
              </span>
            </span>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReportLeads;
