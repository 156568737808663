import axios from "axios";
import { toastr } from "react-redux-toastr";

import {
  GET_ERRORS,
  LOAD_PROFILE,
  SET_PROFILE,
  GET_PROFILE,
  UPDATE_CURRENT_USER,
} from "./types";

// Get Profile
export const getProfile = (id) => (dispatch) => {
  dispatch({
    type: LOAD_PROFILE,
  });

  axios
    .get(`/api/agent/byuser/${id}`)
    .then((res) => {
      dispatch({
        type: SET_PROFILE,
        payload: {
          id: id,
          first: res.data.first,
          last: res.data.last,
          email: res.data.account.email,
        },
      });

      // return res.data;
    })

    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      //  return err;
    });
};

// Update User
export const updateProfile = (id, data) => (dispatch) => {
  return axios
    .post(`/api/user/update/${id}`, data)
    .then((resp) => {
      if (resp) {
        if (resp.data) {
          if (resp.data.message === "Profile updated.") {
            const toastrType = "success";
            const toastrOptions = {
              icon: toastrType,
              status: toastrType,
              progressBar: false,
              // closeOnToastrClick: true,
              transitionOut: "bounceOut",
            };
            toastr.info("Success", "Profile Update", toastrOptions);
            dispatch({
              type: UPDATE_CURRENT_USER,
              payload: {
                id: id,
                first: data.first,
                last: data.last,
                email: data.email,
              },
            });

            dispatch({
              type: GET_PROFILE,
              payload: {
                id: id,
                first: data.first,
                last: data.last,
                email: data.email,
              },
            });

            dispatch({
              type: GET_ERRORS,
              payload: {},
            });
          } else {
            dispatch({
              type: GET_ERRORS,
              payload: resp.data,
            });
          }
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: "Not Updated",
          });
          dispatch({
            type: GET_ERRORS,
            payload: "Not Updated",
          });
        }
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: "Not Updated",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
