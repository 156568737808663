import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import { Link } from "react-router-dom";
class SetupHeader extends React.Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };
  render() {
    return (
      <>
        <div className="header bg-gradient-info py-5 py-lg-5 pt-lg-5">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  {this.props.title ? (
                    <h1 className="text-white">{this.props.title}</h1>
                  ) : null}
                  {this.props.lead ? (
                    <p className="text-lead text-white">{this.props.lead}</p>
                  ) : null}
                </Col>
              </Row>{" "}
              <Row className="">
                <Col md="11">
                  <Link
                    to="./logout"
                    style={{
                      cursor: "pointer",
                      float: "right",
                      color: "white",
                    }}
                    onClick={this.onLogoutClick}
                  >
                    <span>Logout</span>
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}

SetupHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  // profile: state.profile,
});

//export default SetupHeader;
export default connect(mapStateToProps, { logoutUser })(SetupHeader);
