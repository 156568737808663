import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ModalFooter,
  Button,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function ApiTokenForm(props) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      _id: 0,
      name: "",
    },
  });

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete this API Key?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/api_token/` + props.item._id + `/delete`,
        })
          .then((response) => {
            props.deleteItemFromState(props.item._id);
            props.toggle();
            const toastrType = "success";
            const toastrOptions = {
              timeOut: 2250,
              icon: toastrType,
              status: toastrType,
              progressBar: false,
              closeOnToastrClick: true,
              transitionOut: "bounceOut",
            };
            toastr.light(
              "Success",
              "Token has been deleted sucessfully",
              toastrOptions
            );
            Swal.fire("Deleted!", "Your Api Key is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  /*

  const deleteItemFromState = () => {
    axios({
      method: "delete",
      responseType: "json",
      url: `/api/api_token/`+props.item._id+`/delete`
    })
    .then((item) => {
        props.deleteItemFromState(props.item._id);
        props.toggle();
        const toastrType = "success";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Success",
            "Token has been deleted sucessfully",
            toastrOptions
          );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong adding your number",
          toastrOptions
        );
      });
  }

  */

  const submitFormEdit = (form) => {
    setLoading(true);
    axios({
      method: "patch",
      responseType: "json",
      url: `/api/api_token/` + form._id + `/update`,
      data: form,
    })
      .then((item) => {
        props.updateItemToState(item.data);
        props.toggle();
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Token has been updates sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong adding your number",
          toastrOptions
        );
      });
  };
  useEffect(() => {
    register({ name: "_id" });
    if (Object.keys(props.item).length > 0) {
      setValue("_id", props.item._id);
      setValue("name", props.item.name);
      setValue("token", props.item.token);
    }
  }, [register, props.item, setValue]);
  return (
    <form onSubmit={handleSubmit(submitFormEdit)}>
      <Row>
        <Col sm="12">
          <FormGroup>
            <Label
              for="name"
              style={{
                marginTop: "20px",
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Name
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              innerRef={register({ required: true })}
            />
            {errors.name && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Name is required
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              for="token"
              style={{
                marginTop: "20px",
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Token
            </Label>
            <Input
              type="text"
              name="token"
              id="token"
              disabled={true}
              innerRef={register({ required: true })}
            />
          </FormGroup>
        </Col>
      </Row>
      <ModalFooter>
        <div className="div_container">
          <Button type="button" onClick={confirmDelete} color="danger">
            Delete
          </Button>
          {loading ? (
            <Button
              color="primary"
              type="button"
              className="float_right_button"
              disabled={true}
            >
              Loading...
            </Button>
          ) : (
            <Button
              color="primary"
              className="float_right_button"
              type="submit"
            >
              Submit
            </Button>
          )}
        </div>
      </ModalFooter>
    </form>
  );
}
ApiTokenForm.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenForm);
