import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Stepper from "react-js-stepper";
import Loader from "layouts/Loader.js";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Badge,
  Button,
  Input,
  ModalFooter,
} from "reactstrap";
// core components
import SetupHeader from "components/Headers/SetupHeader.js";

import PhoneForm from "../Agents/FormAddNumber.js";

import RecordingPage from "../Recordings/Recordings.js";
import AgentPage from "../Agents/Agents.js";
import TeamPage from "../Teams/Teams.js";
import IntegrationPage from "../Integration/Integration.js";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function Setup(props) {
  const [agent, setAgent] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const [stepList, setStepList] = useState([]);
  const [skipCheckbox, setSkipCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [setupComplete, setSetupComplete] = useState(false);
  const [steps, setSteps] = useState();

  const setcurrentStep = useCallback((agent, data) => {
    if (data.stripePlanId.includes("max") || data.stripePlanId === "trial") {
      setSteps([
        { title: "Phone *" },
        { title: "Recording" },
        { title: "Agents" },
        { title: "Teams" },
        { title: "Integrate" },
      ]);

      if (
        data["setupComplete"] ||
        !agent.national ||
        agent.status === "Pending"
      ) {
        setActiveStep(1);
      } else if (data["recording"] === 0) {
        setActiveStep(2);
      } else if (data["agent"] < 2) {
        setActiveStep(3);
      } else if (data["team"] === 0) {
        setActiveStep(4);
      } else {
        setActiveStep(5);
      }
    } else {
      setSteps([
        { title: "Phone *" },
        { title: "Agents" },
        { title: "Teams" },
        { title: "Integrate" },
      ]);
    }

    if (
      data["setupComplete"] ||
      !agent.national ||
      agent.status === "Pending"
    ) {
      setActiveStep(1);
    } else if (data["agent"] < 2) {
      setActiveStep(2);
    } else if (data["team"] === 0) {
      setActiveStep(3);
    } else {
      setActiveStep(4);
    }
  }, []);
  const getCurrentStepCompleted = (data) => {
    if (data["recording"] >= 1 && activeStep === 2) {
      return true;
    } else if (data["agent"] >= 2 && activeStep === 3) {
      return true;
    } else if (data["team"] >= 1 && activeStep === 4) {
      return true;
    } else if (data["team"] >= 1 && activeStep === 5) {
      return true;
    }
    return false;
  };
  //Bool true mean step is abount to complete
  //isfinish mean we are in final step
  const updateAccountTutorailStatus = (bool, isfinish) => {
    if (bool) {
      if (!setupComplete) {
        const token = props.auth.user.token;
        const csrf = props.auth.csrf;
        fetch("/api/billing/account_status_complete", {
          method: "post",
          headers: {
            Authorization: token,
            "xsrf-token": csrf,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (isfinish) props.history.push("/account/dashboard");
          })
          .catch((err) => {
            const toastrType = "error";
            const toastrOptions = {
              timeOut: 2250,
              icon: toastrType,
              status: toastrType,
              progressBar: false,
              closeOnToastrClick: true,
              transitionOut: "bounceOut",
            };
            toastr.light("Error", "Something went wrong", toastrOptions);
          });
      }
    } else {
      props.history.push("/account/dashboard");
    }
  };
  const getAgent = async (setcurrentStep) => {
    try {
      setLoading(true);
      const response = await axios({
        method: "get",
        responseType: "json",
        url: `/api/agent/byowner`,
      });
      const data = await response;
      const responseStep = await axios({
        method: "get",
        responseType: "json",
        url: `/api/user/usersteps`,
      });
      const dataStep = await responseStep;

      setStepList(dataStep.data);
      setAgent(data.data);
      setSetupComplete(dataStep.data["setupComplete"]);
      setcurrentStep(data.data, dataStep.data);
    } catch (err) {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 2250,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light("Error", "Something went wrong", toastrOptions);
    }
    setLoading(false);
    return {};
  };

  useEffect(() => {
    getAgent(setcurrentStep);
  }, [setcurrentStep]);

  const handleOnClickStepper = (step) => {
    setSkipCheckbox(false);
    setActiveStep(step);
  };

  const gotoPage = (step) => {
    setSkipCheckbox(false);
    setActiveStep(step);
  };

  const handleFinishCondition = (agent, data, type) => {
    if (data["setupComplete"]) {
      return data["setupComplete"];
    }
    //for individual steps and place skip and next accordings
    if (type) {
      switch (type) {
        case "recording":
          return data["recording"] >= 1;
        case "agent":
          return data["agent"] >= 2;
        case "team":
          return data["team"] >= 1;
        case "integration":
          return data["integration"] >= 1;
        default:
          return false;
      }
    }
    return (
      agent.national &&
      data["recording"] >= 1 &&
      data["agent"] >= 2 &&
      data["team"] >= 1 &&
      data["integration"] >= 1
    );
  };
  const handleFinishButton = () => {
    return (
      <Button
        type="button"
        color="success"
        className="float-right"
        onClick={() => {
          handleFinishCondition(agent, stepList)
            ? updateAccountTutorailStatus(!stepList.setupComplete, true)
            : updateAccountTutorailStatus(false);
        }}
      >
        Finish
      </Button>
    );
  };
  const handleSkipButton = () => {
    return (
      <Button
        type="button"
        color="primary"
        className="float-right"
        style={{ marginBottom: "25px" }}
        onClick={() => handleOnClickStepper(activeStep + 1)}
      >
        Next
      </Button>
    );
  };
  const skipButton = () => {
    if (agent.national) {
      return (
        <ModalFooter style={{ paddingBottom: "0px" }}>
          <Row className="end_tutorial">
            <Col xs="12">
              <Button
                type="button"
                color="warning"
                onClick={() =>
                  skipCheckbox
                    ? MySwal.fire({
                        title:
                          "You are about to skip the getting started tutorial.",
                        text:
                          "You can always access this again from Getting Started link in the main menu.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Skip tutorial",
                      }).then((result) => {
                        if (result.value) {
                          updateAccountTutorailStatus(skipCheckbox, true);
                        }
                      })
                    : updateAccountTutorailStatus(skipCheckbox)
                }
              >
                End Tutorial
              </Button>
            </Col>
            {!handleFinishCondition(agent, stepList) ? (
              <Col xs="12">
                <Input
                  type="checkbox"
                  title="Skip Setup, I know what i'm doing"
                  id="dontshow"
                  checked={skipCheckbox}
                  onChange={() => setSkipCheckbox(!skipCheckbox)}
                  className="checkbox"
                />
                <label htmlFor="dontshow" className="dontshow">
                  Don't show getting started tutorial again &nbsp;
                </label>
              </Col>
            ) : null}
          </Row>
        </ModalFooter>
      );
    }
  };
  const updateStep = (data) => {
    if (handleFinishCondition(agent, data)) {
      data["setupComplete"] = true;
      setStepList(data);
      updateAccountTutorailStatus(true);
    } else {
      setStepList(data);
    }
    if (getCurrentStepCompleted(data)) {
      setActiveStep(activeStep + 1);
    }
  };
  const stepContent = () => {
    if (activeStep === 1) {
      const nextButton = !!agent.national;
      return (
        <PhoneForm
          gotoPage={gotoPage}
          agent={agent}
          setAgent={setAgent}
          nextButtonAvaiable={nextButton}
        />
      );
    } else if (activeStep === 2) {
      return (
        <RecordingPage
          stepList={stepList}
          setStepList={updateStep}
          handleSkipButton={handleSkipButton}
        />
      );
    } else if (activeStep === 3) {
      return (
        <AgentPage
          stepList={stepList}
          setStepList={updateStep}
          handleSkipButton={handleSkipButton}
        />
      );
    } else if (activeStep === 4) {
      return (
        <TeamPage
          stepList={stepList}
          setStepList={updateStep}
          handleSkipButton={handleSkipButton}
        />
      );
    } else if (activeStep === 5) {
      return (
        <IntegrationPage
          stepList={stepList}
          setStepList={updateStep}
          handleSkipButton={handleFinishButton}
        />
      );
    }
  };

  const stepInstructions = () => {
    if (activeStep === 1) {
      return (
        <>
          <h1>
            <Badge
              color="success"
              style={{ marginBottom: "15px", marginRight: "10px" }}
              pill
            >
              Step {activeStep}:
            </Badge>
            Add your Phone Number -{" "}
            <Badge color="danger" pill>
              Required
            </Badge>
          </h1>{" "}
          <Alert
            style={{
              backgroundColor: "#cce5ff",
              color: "#004085",
              borderColor: "#b8daff",
            }}
          >
            <b>
              Your Phone number is used as a Caller ID and for{" "}
              {process.env.REACT_APP_NAME} to Call you.
            </b>
            <br></br>
            <br></br>Phone Numbers must be verified before they can be used as a
            Caller ID.
          </Alert>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <h1>
            <Badge
              color="success"
              style={{ marginBottom: "15px", marginRight: "10px" }}
              pill
            >
              Step {activeStep}:
            </Badge>
            Record your Sales Message -{" "}
            <Badge color="warning" pill>
              Recommended
            </Badge>
          </h1>{" "}
          <Alert
            style={{
              backgroundColor: "#cce5ff",
              color: "#004085",
              borderColor: "#b8daff",
            }}
          >
            <b>
              Recorded sales messages are played when a lead answers a call.
            </b>
            <br></br>
            <br></br>
            <em>
              <b>Example</b>: You've tried calling a lead several times and
              don't have more time to invest. You want{" "}
              {process.env.REACT_APP_NAME} to follow-up for you automatically.{" "}
              {process.env.REACT_APP_NAME} calls your lead and the lead answers.
              Your recorded sales message is played to the lead and the
              interested lead dials 1 to be connected with your sales Team.
            </em>
            <br></br> <br></br>Your recorded sales message is a great way to
            re-engage leads and make an irresistible offer. It will help
            automatically generate more inbound sales calls, helping your sales
            team close more deals.
            <br></br> <br></br>
            <b>Add Text to Speech</b> to get started quickly. This uses Machine
            learning and AI technology to convert your message into a life-like
            professional voice recording. You will be suprised how great it
            sounds.
          </Alert>
        </>
      );
    } else if (activeStep === 3) {
      return (
        <>
          <h1>
            <Badge
              color="success"
              style={{ marginBottom: "15px", marginRight: "10px" }}
              pill
            >
              Step {activeStep}:
            </Badge>
            Add additional Agents / Users -{" "}
            <Badge color="success" pill>
              Optional
            </Badge>
          </h1>{" "}
          <Alert
            style={{
              backgroundColor: "#cce5ff",
              color: "#004085",
              borderColor: "#b8daff",
            }}
          >
            <b>Agents are members of your sales Team.</b>
            <br></br> <br></br>
            {process.env.REACT_APP_NAME} will call your Agents when:
            <br></br>
            <li>A new lead come into the system.</li>
            <li>
              A lead dials 1 after listening to your recorded sales message.
            </li>
            <br></br>
            <b>
              {" "}
              Add an Agent for each person within your organization that you
              would like {process.env.REACT_APP_NAME} to call. You have already
              been added.
            </b>
            <br></br>
            <br></br>
            <li>Agents are members of Teams.</li>
            <li>Agents must have a valid phone number.</li>
            <li>Agents can set their own schedule.</li>
            <li>
              {" "}
              Allow Agents to log into {process.env.REACT_APP_NAME} by also
              creating an account.
            </li>
          </Alert>
        </>
      );
    } else if (activeStep === 4) {
      return (
        <>
          <h1>
            <Badge
              color="success"
              style={{ marginBottom: "15px", marginRight: "10px" }}
              pill
            >
              Step {activeStep}:
            </Badge>
            Add Teams -{" "}
            <Badge color="success" pill>
              Optional
            </Badge>
          </h1>{" "}
          <Alert
            style={{
              backgroundColor: "#cce5ff",
              color: "#004085",
              borderColor: "#b8daff",
            }}
          >
            <b>
              Teams consist of 1 or more Agents and control the behaviour of new
              calls.{" "}
            </b>
            <br></br> <br></br>
            <li>
              Create Teams for each of your lead sources, integrations and use
              cases.
            </li>
            <li>
              Don't fuss with all the options. The default values are a great
              way to get started.
            </li>
          </Alert>
        </>
      );
    } else if (activeStep === 5) {
      return (
        <>
          <h1>
            <Badge
              color="success"
              style={{ marginBottom: "15px", marginRight: "10px" }}
              pill
            >
              Step {activeStep}:
            </Badge>
            Setup your first Integration -{" "}
            <Badge color="success" pill>
              Optional
            </Badge>
          </h1>{" "}
          <Alert
            style={{
              backgroundColor: "#cce5ff",
              color: "#004085",
              borderColor: "#b8daff",
            }}
          >
            <b>
              Webhooks allow you to integrate {process.env.REACT_APP_NAME} with
              just about any external system.
            </b>{" "}
            <br></br> <br></br>
            <li>
              Zapier integrates with 1000's of applications and is the easiest
              way to get started.
            </li>
            <li>
              Webhook are another great option for triggering calls. Send a POST
              request to the webhook endpoint URL when something happens and
              {process.env.REACT_APP_NAME} will make a call.
            </li>
          </Alert>
        </>
      );
    }
  };
  const nextButton = agent.national && agent.status === "Verified";
  return (
    <>
      <SetupHeader title="Let's get Started" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="10" md="10">
            <Card className="bg-secondary border-0">
              <CardHeader className="bg-transparent pb-5">
                <Stepper
                  steps={steps}
                  activeStep={activeStep}
                  onSelect={nextButton ? handleOnClickStepper : () => {}}
                  showNumber={true}
                />
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                {stepInstructions()}
                {!loading ? (
                  <>
                    {stepContent()}
                    {skipButton()}
                  </>
                ) : (
                  <div style={{ height: "80px", marginTop: "70px" }}>
                    <Loader />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Setup.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
