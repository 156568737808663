import React, { useRef, useEffect, useCallback } from "react";
import { Table, Label } from "reactstrap";

import ModalTeam from "./ModalTeam";
import ModalSchedule from "../Agents/ModalSchedule";
import MobileModalTeam from "./MobileModalTeam";

import { connect } from "react-redux";

function TableTeam(props) {
  if (props.billing.payload.stripePlanId) {
    var boolBillingBasic = props.billing.payload.stripePlanId.includes("basic");
  }

  //ahow/hide colum on resize
  const itemsRef = useRef([]);
  const tableRef = useRef([]);
  const trHeadRef = useRef([]);
  const tbodyRef = useRef([]);
  const colCount = props.hideColumn ? 4 : 4;
  const visibleCoumnCount = useRef(colCount);
  const defaultHeader = useRef(colCount);
  const oldVisibleCoumnCount = useRef(colCount);
  const widowWidth = useRef(window.innerWidth);
  const getInitialColum = useCallback(
    (_init_, tableRef, trHeadRef) => {
      if (tableRef.current && trHeadRef.current) {
        if (
          !_init_ &&
          ((window.innerWidth < widowWidth.current &&
            widowWidth - window.innerWidth < 10) ||
            (window.innerWidth > widowWidth.current &&
              window.innerWidth - widowWidth.current < 100))
        ) {
          return false;
        }
        const settings = props.settings;
        if (settings) {
          for (var i = 0; i < settings.length; i++) {
            if (window.innerWidth >= settings[i].width) {
              if (props.settings[i].column !== "all") {
                visibleCoumnCount.current = props.settings[i].column;
              } else {
                visibleCoumnCount.current = defaultHeader.current;
              }
              break;
            }
          }
          if (oldVisibleCoumnCount.current !== visibleCoumnCount.current) {
            trHeadRef.current.setAttribute(
              "datacolum",
              visibleCoumnCount.current
            );
            if (tbodyRef.current.querySelectorAll("tr").length > 0) {
              tbodyRef.current
                .querySelectorAll("tr")
                .forEach((x) =>
                  x.setAttribute("datacolum", visibleCoumnCount.current)
                );
            } else {
              for (var ii = 0; ii < itemsRef.length; i++) {
                itemsRef.current[ii].setAttribute(
                  "datacolum",
                  visibleCoumnCount.current
                );
              }
            }
            oldVisibleCoumnCount.current = visibleCoumnCount.current;
            widowWidth.current = window.innerWidth;
          }
        }
      }
    },
    [visibleCoumnCount, oldVisibleCoumnCount, widowWidth, props.settings]
  );
  const allowSchedule = (item) => {
    return true;
    /*if(props.auth.user.role === "owner" || props.auth.user.role === "admin") {
      return false;
    }
    const accounts = item.members;
    const index = accounts.findIndex(
      (data) => data._id === props.auth.user.agent
    );
    return index !== -1;*/
  };
  useEffect(() => {
    //itemsRef.current = itemsRef.current.slice(0, (paginate ? page : rows).length);
    if (tableRef.current && trHeadRef.current) {
      setTimeout(() => getInitialColum(true, tableRef, trHeadRef), 1000);
      window.addEventListener("resize", (e) => {
        e.preventDefault();
        getInitialColum(false, tableRef, trHeadRef);
      });
      // returned function will be called on component unmount
      return () => {
        window.removeEventListener("resize", (e) => {
          e.preventDefault();
          getInitialColum(false, tableRef, trHeadRef);
        });
      };
    }
  }, [tableRef, trHeadRef, getInitialColum]);
  //ahow/hide colum on resize
  const items = props.items.map((item, index) => {
    var schedule = "";
    if (item.hasSchedule) {
      schedule = (
        <ModalSchedule
          buttonLabel="Schedule"
          item={item}
          auth={props.auth}
          updateState={props.updateState}
          updateWhat="team"
          headerTitle="Set Team Schedule"
          headerTitleView="View Team Schedule"
          allowEdit={!allowSchedule(item)}
        />
      );
    } else {
      schedule = "";
    }

    return [
      <tr
        key={item._id}
        role="row"
        datacolum={defaultHeader.current}
        maindatacolum={defaultHeader.current}
        ref={(el) => (itemsRef.current[index] = el)}
      >
        <td
          className="font-weight-bold"
          onClick={() => {
            const current = itemsRef.current[index];
            if (
              current.getAttribute("datacolum") ===
              current.getAttribute("maindatacolum")
            ) {
              current.classList.remove("parent_tr");
            } else {
              current.classList.toggle("parent_tr");
            }
            return true;
          }}
        >
          <b> {item.name}</b>
          <br></br>
          {item.recording || boolBillingBasic ? (
            ""
          ) : (
            <>
              <i
                data-toggle="tooltip"
                title="Lead calling will be disabled for this team."
                className="fas fa-exclamation-triangle"
                style={{
                  color: "#fb6340",
                  fontSize: "18px",
                  align: "center",
                }}
              ></i>{" "}
              <Label>No Recording</Label>
            </>
          )}
          <br></br>
          {item.callerid || item.calleridType === "agent" ? (
            ""
          ) : (
            <>
              <i
                data-toggle="tooltip"
                title="Lead calling will be disabled for this team."
                className="fas fa-exclamation-triangle"
                style={{
                  color: "#fb6340",
                  fontSize: "18px",
                  align: "center",
                }}
              ></i>{" "}
              <Label>No Caller ID</Label>
            </>
          )}
        </td>
        <td className="font-weight-bold">
          {" "}
          <ol style={{ paddingInlineStart: "10px" }}>
            {item.members.map((member) => (
              <li key={member._id}>
                {member.first} {member.last}
              </li>
            ))}
            {item.members.length ? (
              ""
            ) : (
              <>
                <i
                  data-toggle="tooltip"
                  title="Calls targeting this team will fail."
                  className="fas fa-exclamation-triangle"
                  style={{
                    color: "#fb6340",
                    fontSize: "24px",
                    align: "center",
                  }}
                ></i>{" "}
                <Label>No Members</Label>
              </>
            )}
          </ol>
        </td>
        <td className="font-weight-bold">
          {" "}
          {item.settingSummary.map((setting) => (
            <li key={setting}>{setting}</li>
          ))}
        </td>
        {props.hideColumn || props.auth.user.role === "agent" ? null : (
          <>
            <td className="action_coumn">
              <MobileModalTeam
                buttonType="actions"
                item={item}
                updateState={props.updateState}
                deleteItemFromState={props.deleteItemFromState}
                type={{
                  modal: {
                    headerTitle: "Edit Team Settings",
                    buttonLabel: "Edit",
                  },
                  schedule: {
                    headerTitle: "Set Team Schedule",
                    headerTitleView: "View Team Schedule",
                    buttonLabel: "Schedule",
                  },
                }}
              />
              <div className="desktop_screen_button">
                <div style={{ float: "right" }}>
                  {schedule}
                  <ModalTeam
                    buttonType="actions"
                    buttonLabel="Edit"
                    item={item}
                    updateState={props.updateState}
                    deleteItemFromState={props.deleteItemFromState}
                    headerTitle="Edit Team Settings"
                  />{" "}
                </div>
              </div>
            </td>
          </>
        )}
      </tr>, //child
      <tr key={"key_" + item._id} className="child">
        <td colSpan={6}>
          <ul className="clone_ul">
            <li className="clone_li">
              <b>Agents</b>:<br />
              <div style={{ marginLeft: 20 }}>
                <ol style={{ paddingInlineStart: "10px" }}>
                  {item.members.map((member) => (
                    <li key={member._id}>
                      {member.first} {member.last}
                    </li>
                  ))}
                  {item.members.length ? (
                    ""
                  ) : (
                    <>
                      <i
                        data-toggle="tooltip"
                        title="Calls targeting this team will fail."
                        className="fas fa-exclamation-triangle"
                        style={{
                          color: "#fb6340",
                          fontSize: "24px",
                          align: "center",
                        }}
                      ></i>{" "}
                      <Label>No Members</Label>
                    </>
                  )}
                </ol>
              </div>
            </li>
            <li className="clone_li">
              <b>Settings</b>:<br />
              <div>
                <ul>
                  {item.settingSummary.map((setting) => (
                    <li key={setting}>{setting}</li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </td>
      </tr>,
    ];
  });
  return (
    <div
      ref={(el) => (tableRef.current = el)}
      className="table_data custom_table"
    >
      <Table
        className="table-striped"
        responsive
        hover
        style={{ marginTop: "10px" }}
      >
        <thead>
          <tr ref={(el) => (trHeadRef.current = el)}>
            <th>Name</th>
            <th>Agents</th>
            <th>Settings</th>
            {props.hideColumn || props.auth.user.role === "agent" ? null : (
              <th className="action_coumn">
                <label>Actions</label>
              </th>
            )}
          </tr>
        </thead>
        <tbody ref={(el) => (tbodyRef.current = el)}>
          {items}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state) => ({
  billing: state.billing,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableTeam);
