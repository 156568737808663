import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormSchedule from "./FormSchedule";

function ModalSchedule(props) {
  const [modal, setModal] = useState(false);
  const [screenChange, setScreenValue] = useState("desktop");

  const toggle = () => {
    setModal(!modal);
  };
  const allowSchedule = (item) => {
    if(props.allowEdit === undefined) {
      const account = item.account;
      var bool = false;
      if (typeof account !== "undefined") {
        if (
          account.email !== props.auth.user.email &&
          (props.auth.user.role === "agent")
        ) {
          bool =  true;
        } 
      } else {
        bool = props.auth.user.role === "agent";
      }
      return bool;
      /*if (typeof account !== "undefined") {
        return account.email !== props.auth.user.email
      } else {
        return true;
      }*/
    } else {
      return props.allowEdit;
    }
  }
  return (
    <>
      {props.mobile?<Button
        className = "remove_background"
        onClick={toggle}>
        <i className="fas fa-calendar">
          <span
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
              display: "inline-block",
              paddingLeft: "4px",
            }}
          >
            {props.buttonLabel}
          </span>
        </i>
      </Button>:<Button
        color={"success"}
        onClick={toggle}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <i className="fas fa-calendar">
          <span
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
              display: "inline-block",
              paddingLeft: "4px",
            }}
          >
            {props.buttonLabel}
          </span>
        </i>
      </Button>}

      <Modal isOpen={modal} toggle={toggle}>
       <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {(!allowSchedule(props.item))?props.headerTitle:props.headerTitleView}
        </ModalHeader>
        <ModalBody>
          {" "}
          {modal?<FormSchedule
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            updateWhat={props.updateWhat}
            toggle={toggle}
            item={props.item}
            allowEdit = {!allowSchedule(props.item)}
            screenChange={screenChange}
            setScreenValue={(value)=>setScreenValue(value)}
          />:null}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalSchedule;
