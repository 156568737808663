import React from "react";
import { Table } from "reactstrap";
import ModalAdd from "./ModalAdd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function TableApiToken(props) {
  const items = (props.items.length > 0? props.items.map((item, index) => {
    return (
      <tr key={index}>
        <td>
          {item.name}
        </td>
        <td>
        <label className="custom-toggle">
            <input
              type="checkbox"
              placeholder="Active"
              name="active"
              checked={item.is_active}
              onChange={() => {
                MySwal.fire({
                  title:
                    "Are you sure you want to " +
                    (item.is_active ? "De-activate" : "Activate") +
                    " this token!",
                  text: "",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    props.changeStatusActive(item, index);
                  }
                });
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="No"
              data-label-on="Yes"
            />
          </label>
        </td>
        <td>
          <ModalAdd
            buttonType="actions"
            buttonLabel="View"
            item={item}
            updateItemToState = {props.updateItemToState}
            deleteItemFromState = {props.deleteItemFromState}
            headerTitle="View Token"
          />
        </td>
      </tr>
    );
  }):<tr>
  <td
    colSpan={3}
    style={{
      textAlign: "center",
    }}
  >
    No Record Found
  </td>
</tr>);

  return (
    <Table responsive hover style={{ marginTop: "10px", maxWidth: "400px" }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Active</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

export default TableApiToken;
