import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";

import { useForm } from "react-hook-form";

import startsWith from "lodash.startswith";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { toastr } from "react-redux-toastr";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
function FormLead(props) {
  const [ loading, setLoading ] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    setError,
    clearError,
    getValues,
  } = useForm({
    defaultValues: {
      _id: 0,
      external_id: "",
      first: "",
      last: "",
      country: "",
      local: "",
      national: "",
      email: "",
      company: "",
      category: "",
      comments: "",
    },
  });

  // const options = [];
  useEffect(() => {
    register({ name: "country" }); // custom register
    register({ name: "national" });
    register({ name: "local" });
    register({ name: "_id" });
    register({ name: "owner" });
    register({ name: "team" });
    if (Object.keys(props.item).length > 0) {
      setValue("external_id", props.item.external_id);
      setValue("_id", props.item._id);
      setValue("first", props.item.first);
      setValue("last", props.item.last);
      setValue("country", props.item.country);
      setValue("local", props.item.local);
      setValue("national", props.item.national.toString());
      setValue("email", props.item.email);
      setValue("company", props.item.company);
      setValue("category", props.item.category);
      setValue("comments", props.item.comments);
    }
  }, [register, setValue, props]);

  const getPhone = (props) => {
    if (Object.keys(props.item).length > 0) {
      return props.item.national.toString();
    } else {
      return "";
    }
  };
  const _submitFormAdd = (form) => {
    setLoading(true);
    axios({
      method: "post",
      responseType: "json",
      url: `/api/lead/`,
      data: form,
    })
      .then((item) => {
        setLoading(false);
        props.addItemToState(item.data);
        props.toggle();
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Lead has been created sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Lead",
          toastrOptions
        );

        if (err.response && err.response.data) {
          if (err.response.data.error) {
            return setError(
              err.response.data.error.field,
              "duplicate",
              err.response.data.error.message
            );
          } else {
          }
        }
      });
  };
  const submitFormAdd = async (form) => {
    if (form.national === "" || form.local === "" || form.country === "") {
      return setError("national", "required", "Phone number is required");
    }
    if (
      props.auth.user.role === "agent" &&
      props.ownerRecord &&
      props.ownerRecord.agentPermission === "team" &&
      (form.team === "" || form.team === undefined)
    ) {
      await Swal.fire({
        icon: "warning",
        input: "select",
        inputOptions: _getTeam(),
        inputPlaceholder: "Select a team",
        title:
          "Warning: Assign a team to this lead before saving. You will loose access to the lead if you fail to assign a team.",
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve();
            } else {
              resolve("You need to select a team :)");
            }
          });
        },
      }).then((result) => {
        if (result.value) {
          form.team = result.value;
          _submitFormAdd(form);
        }
      });
    } else {
      _submitFormAdd(form);
    }
  };
  const _selectOwner = (selected) => {
    if (selected) {
      setValue("owner", selected);
      //setOwner(selected.first + " " + selected.last);
      clearError("owner");
    } else {
      setValue("owner", "");
      //setOwner("");
    }
  };
  const selectOwner = (selected) => {
    if (
      props.auth.user.role === "agent" &&
      props.ownerRecord &&
      props.ownerRecord.agentPermission === "own" &&
      (!selected || selected !== props.ownerRecord.agent)
    ) {
      Swal.fire(
        "",
        "Warning: You are about to reassign the lead to another agent. This will make the lead visible to this agent and you will lose access to the lead.",
        "warning"
      );
    }
    _selectOwner(selected);
  };
  const _selectTeam = (selected) => {
    if (selected) {
      setValue("team", selected);
      //setTeam(selected.name);
      clearError("team");
    } else {
      setValue("team", "");
      //setTeam("");
    }
  };
  const selectTeam = (selected) => {
    if (
      props.auth.user.role === "agent" &&
      props.ownerRecord &&
      props.ownerRecord.agentPermission === "team" &&
      (!selected ||
        props.ownerRecord.members.length === 0 ||
        !props.ownerRecord.members.includes(selected))
    ) {
      Swal.fire(
        "",
        "Warning: You are about to reassign the lead to a team that you are not part of. This will make the lead visible to members of that team and you will lose access to the lead.",
        "warning"
      );
    }
    _selectTeam(selected);
  };
  const getTeam = () => {
    const ownerValue = getValues("owner");
    var ownerR = props.auth.user.agent;
    if (ownerValue) {
      ownerR = ownerValue;
    }
    if (props.teams && !props.teams.seeallteams) {
      const yourTeam =
        props.teams.list[0].options.filter((item) => {
          return props.teams.seeallteams || item.members.includes(ownerR);
        }) || [];
      const otherTeam =
        props.teams.list[1].options.filter((item) => {
          return props.teams.seeallteams || item.members.includes(ownerR);
        }) || [];
      var returnData = [];
      if (yourTeam.length > 0) {
        returnData.push({
          label: props.teams.list[0].label,
          options: yourTeam,
        });
      }
      if (otherTeam.length > 0) {
        returnData.push({
          label: props.teams.list[1].label,
          options: otherTeam,
        });
      }
      return returnData;
    } else {
      return props.teams && props.teams.list;
    }
  };
  const _getTeam = () => {
    const team = getTeam();
    var options = [];
    if (team.length >= 1) {
      options = { [team[0].label]: {} };
      const _options = team[0].options;
      for (let i = 0; i < _options.length; i++) {
        options[team[0].label][_options[i]._id] = _options[i].name;
      }
    }
    if (team.length === 2) {
      options[team[1].label] = {};
      const _options = team[1].options;
      for (let i = 0; i < _options.length; i++) {
        options[team[1].label][_options[i]._id] = _options[i].name;
      }
    }
    return options;
  };
  const getDefaultValue = () => {
    const value = {
      _id: props.auth.user.agent,
      first: props.auth.user.first,
      last: props.auth.user.last,
    };
    return value;
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitFormAdd)}>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="first"
              >
                First
              </Label>
              <Input
                type="text"
                name="first"
                id="first"
                innerRef={register({ required: true })}
              />

              {errors.first && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  First name is required
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="last"
              >
                Last
              </Label>
              <Input
                type="text"
                name="last"
                id="last"
                innerRef={register({ required: true })}
              />{" "}
              {errors.last && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Last name is required
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
              for="national"
            >
              Phone
            </Label>
            <PhoneInput
              style={{
                marginBottom: "25px",
              }}
              enableSearch={true}
              disableInitialCountryGuess={false}
              jumpCursorToEnd={false}
              autoFormat={false}
              country={"us"}
              preferredCountries={["us", "ca", "uk"]}
              regions={["north-america", "europe"]}
              name="national"
              id="national"
              placeholder="Enter phone number"
              onChange={(phone, country) => {
                setValue("national", phone);
                setValue("country", country);
                setValue("local", phone.slice(country.dialCode.length));
              }}
              isValid={(value, country, countries) => {
                return countries.some((country) => {
                  return (
                    startsWith(value, country.dialCode) ||
                    startsWith(country.dialCode, value)
                  );
                });
              }}
              defaultErrorMessage={"Invalid Phone"}
              value={getPhone(props)}
              innerRef={register({ validate: (value) => value !== "" })}
            />
            {errors.national && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Phone number is required
              </div>
            )}
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="external_id"
              >
                External Id
              </Label>
              <Input
                type="text"
                name="external_id"
                id="external_id"
                innerRef={register()}
              />

              {errors.external_id && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  A lead already exists with this External Id
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
            for="email"
          >
            Email
          </Label>
          <Input type="text" name="email" id="email" innerRef={register()} />
        </FormGroup>

        <FormGroup>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
            for="company"
          >
            Company
          </Label>
          <Input
            type="text"
            name="company"
            id="company"
            innerRef={register()}
          />
        </FormGroup>

        <FormGroup>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
            for="category"
          >
            Category
          </Label>
          <Input
            type="text"
            name="category"
            id="category"
            innerRef={register()}
          />
        </FormGroup>

        <FormGroup>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
            for="comments"
          >
            Comments
          </Label>
          <Input
            type="textarea"
            name="comments"
            id="comments"
            innerRef={register()}
          />
        </FormGroup>
        <Row style={{ marginBottom: "10px" }}>
          <Col md="6">
            <Label
              for="owner"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Lead Owner
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              style={{ marginBottom: "10px" }}
              name="owner"
              id="owner"
              isSearchable
              isClearable
              onChange={(selected) => {
                selectOwner(selected);
              }}
              defaultValue={getDefaultValue()}
              options={props.agents || ""}
              getOptionLabel={(option) => `${option.first} ${option.last}`}
              getOptionValue={(option) => JSON.stringify(option)}
              innerRef={register()}
            />
          </Col>

          <Col md="6">
            <Label
              for="team"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Team
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              style={{ marginBottom: "10px" }}
              name="team"
              id="team"
              isSearchable
              isClearable
              onChange={(selected) => {
                selectTeam(selected);
              }}
              defaultValue={props.item.team ? props.item.team : ""}
              options={getTeam()}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => JSON.stringify(option)}
              innerRef={register()}
            />
          </Col>
        </Row>
        <ModalFooter>
        {loading?<Button 
          color="primary" 
          type="button" 
          disabled={true}>
            Saving...
          </Button>:<Button type="submit" color="primary">Save Lead</Button>}
        </ModalFooter>
      </form>
    </>
  );
}
FormLead.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  teams: state.teams.teamDSDropdown,
  agents: state.agents.agentDropdown,
  ownerRecord: state.agents.agentTODropdown
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormLead);
