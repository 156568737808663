import React from "react";
import { Row, Col } from "reactstrap";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
const formatString = "ddd, D-MMM-YYYY h:mm a";
function ViewCall(props) {
  const { item } = props;
  return (
    <Row>
      <Col xs="12">
        <div style={{ backgroundColor: "#F0F8FF" }}>
          <label className="font-weight-bold">Date: </label>{" "}
          {moment(item.startTime).format(formatString)}
        </div>
      </Col>
      <Col xs="12">
        <div>
          <label className="font-weight-bold">Final Status: </label>{" "}
          {item.status}
        </div>
      </Col>
      <Col xs="12">
        <div style={{ backgroundColor: "#F0F8FF" }}>
          <label className="font-weight-bold">Direction: </label>{" "}
          {item.direction}
        </div>
      </Col>
      <Col xs="12">
        <div>
          <label className="font-weight-bold">Initial Target: </label>{" "}
          {item.type}
        </div>
      </Col>

      {/*Duration */}
      <Col xs="12">
        <div style={{ backgroundColor: "#F0F8FF" }}>
          <label className="font-weight-bold">Duration: </label>{" "}
          {item.callDuration ? <>{item.callDuration}s</> : <>0s</>}
        </div>
      </Col>

      {/*Call Recording */}
      {item.recordingUrl ? (
        <Col xs="12">
          <audio
            src={item.recordingUrl}
            controls="controls"
            style={{ width: "300px" }}
          />
        </Col>
      ) : (
        <></>
      )}

      {/*Source */}
      <Col xs="12">
        <div>
          <label className="font-weight-bold">Source: </label> {item.source}
        </div>
      </Col>
      {/*Lead */}
      <Col xs="12">
        <div style={{ backgroundColor: "#F0F8FF" }}>
          <label className="font-weight-bold">Lead: </label>{" "}
          {item.lead
            ? item.lead.first + " " + item.lead.last
            : item.leadDetails.first + " " + item.leadDetails.first}
        </div>
      </Col>
      {/*Phone */}
      <Col xs="12">
        <div style={{ backgroundColor: "#F0F8FF" }}>
          <label className="font-weight-bold">Phone: </label>{" "}
          {item.lead ? item.lead.national : item.leadDetails.national}
        </div>
      </Col>

      {/*Team/Agent */}
      <Col xs="12" className="colum_div">
        <div>
          <label className="font-weight-bold">Team: </label>
          {item.team !== null ? item.team.name : " n/a"}
        </div>
        <div style={{ backgroundColor: "#F0F8FF" }}>
          <div>
            <label className="font-weight-bold">Agents: </label>
          </div>
          <div style={{ marginLeft: "10px" }}>
            {item.team !== null
              ? item.team.members &&
                item.team.members.map((member, index) => {
                  return (
                    <div className="subheading" key={index}>
                      {member.first} {member.last}
                    </div>
                  );
                })
              : item.segment02.outcome.map((m) => m.target).join(",")}
          </div>
        </div>
      </Col>
      <hr></hr>
      <Col>
        <div className="tooltiptext tooltip-bottom">
          {item.segment02.logs.length > 0 ? (
            <>
              {" "}
              <div>
                <label className="font-weight-bold">
                  Calling {item.segment02.target}:{" "}
                  {item.segment02.segmentStatus}
                </label>
              </div>
            </>
          ) : (
            <></>
          )}

          {item.segment02.logs.length > 0 ? (
            item.segment02.logs.reverse().map((log, index) => {
              return (
                <div className="subheading" key={index}>
                  {moment(log.date).format(formatString) + ": " + log.message}
                </div>
              );
            })
          ) : (
            <></>
          )}

          <div>
            <label className="font-weight-bold">
              Calling {item.segment01.target}: {item.segment01.segmentStatus}
            </label>
          </div>
          {item.segment01.logs.reverse().map((log, index) => {
            if (log) {
              return (
                <div className="subheading" key={index}>
                  {moment(log.date).format(formatString) + ": " + log.message}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Col>
    </Row>
  );
}

export default ViewCall;
