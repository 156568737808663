import React, { useEffect, useState } from "react";

import { 
  Button, 
  Label, 
  Row, 
  ModalFooter, 
  Card, 
  CardBody,
  Modal,
  ModalHeader,
  ModalBody
 } from "reactstrap";
import { useForm } from "react-hook-form";
import startsWith from "lodash.startswith";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { toastr } from "react-redux-toastr";
import axios from "axios";
import FormGetCode from "../Numbers/FormGetCode";
import { numberDropdown  } from "./../../../actions/numberAction";
function FormAddNumber(props) {
  const [ loading, setLoading ] = useState(false);
  const [modal, setModal] = useState(false);
  const [enablePhoneEdit, setEnablePhoneEdit] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };
  const { register, setValue, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      _id: 0,
      country: "",
      local: "",
      national: "",
    },
  });

  useEffect(() => {
    register({ name: "country" }); // custom register
    register({ name: "national" });
    register({ name: "local" });
    register({ name: "_id" });

    setValue("_id", props.agent._id);
    setValue("country", props.agent.country);
    setValue("local", props.agent.local);
    setValue("national", props.agent.national);
    if( props.agent.national ) {
      setEnablePhoneEdit(true)
    }
  }, [register, setValue, props]);

  const getPhone = (props) => {
    if (props.agent.national) {
      return props.agent.national;
    } else {
      return "";
    }
  };

  const submitFormEdit = (form) => {
    if (
      form.national === "" ||
      form.local === "" ||
      form.country === "" ||
      !form.country
    ) {
      return setError("national", "required", "Phone number is required");
    }
    setLoading(true);
    const record = {
      country: form.country,
      local: form.local,
      national: form.national,
    };
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/agent/setownerphone",
      data: record,
    })
      .then((item) => {
        setLoading(false);
        if (item.data._id) {
          const toastrType = "success";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Success",
            "Phone Number has been added to your account sucessfully.",
            toastrOptions
          );
          //props.gotoPage(2);
          props.setAgent(item.data);
          setEnablePhoneEdit(true);
        }
      })

      .catch((err) => {
        setLoading(false);
        const toastrType = "warning";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Phone Number",
          toastrOptions
        );
      });
  };
  const formeditdisabled = props.agent.national && enablePhoneEdit;
  return (
    <>
      <form onSubmit={handleSubmit(submitFormEdit)}>
        <Card>
          <CardBody>
            <Row>
              <div>
                <Label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                  for="national"
                >
                  Phone
                </Label>
                <PhoneInput
                  enableSearch={true}
                  country={"us"}
                  disableInitialCountryGuess={false}
                  jumpCursorToEnd={false}
                  autoFormat={false}
                  preferredCountries={["us", "ca", "uk"]}
                  regions={["north-america", "europe"]}
                  name="national"
                  id="national"
                  placeholder="Enter phone number"
                  disabled = {formeditdisabled}
                  onChange={(phone, country) => {
                    setValue("national", phone);
                    setValue("country", country);
                    setValue("local", phone.slice(country.dialCode.length));
                  }}
                  isValid={(value, country, countries) => {
                    return countries.some((country) => {
                      return (
                        startsWith(value, country.dialCode) ||
                        startsWith(country.dialCode, value)
                      );
                    });
                  }}
                  defaultErrorMessage={"Invalid Phone"}
                  value={getPhone(props)}
                  innerRef={register({ validate: (value) => value !== "" })}
                />
                {errors.national && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Phone number is required
                  </div>
                )}{" "}
              </div>
               <div className="telephone_input" style={{"lineHeight": "40px"}}>
               {loading?<Button 
                  color="success" 
                  type="button" 
                  disabled={true}>
                    Loading...
                  </Button>:<Button color="success" type="submit" style={{display: (enablePhoneEdit)?"none":""}}>Submit</Button>}
              <Button 
                type="button" 
                color="success" 
                onClick = {()=> setEnablePhoneEdit(false)} 
                style={{background: '#11cdef', border: '1px solid #11cdef', display: (!enablePhoneEdit)?"none":""}} 
                size="sm">
                  <i className="fas fa-pen"></i>
              </Button>
              { props.agent.national && props.agent.status !== 'Verified'?
              <Button  type="button" color="primary" disabled= {!enablePhoneEdit} onClick={()=>toggle()}>Verify</Button>
              :null }
              { props.agent.national && props.agent.status === 'Verified'?
                <span><span className="verified_span">Verified</span></span> : null}
              </div>
              { props.agent.national && props.agent.status !== 'Verified'?<div className="verify_phone_number"><p>You must Verify your phone to continue</p></div>:null}
            </Row>
            <br></br>
          </CardBody>
        </Card>
      </form>

      <ModalFooter>
        {props.nextButtonAvaiable  && props.agent.status === 'Verified' ? (
          <Button
            type="button"
            color="primary"
            onClick={() => props.gotoPage(2)}
          >
            Next
          </Button>
        ) : (
          <></>
        )}
      </ModalFooter>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle} {(props.agent)?props.agent.national:null}
        </ModalHeader>
        <ModalBody>
          <FormGetCode
            updateOwnedState={(data) => {
              props.setAgent({...props.agent, status:data.status});
              props.numberDropdown();
            }}
            toggle={toggle}
            item={{
              _id: props.agent.phone_id,
              country: props.agent.country,
              local: props.agent.local,
              national: props.agent.national,
              status: props.agent.status
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = { 
  numberDropdown
 };

export default connect(mapStateToProps, mapDispatchToProps)(FormAddNumber);