export const GET_ERRORS = "GET_ERRORS";

// USERS
export const LOAD_CURRENT_USER = "LOAD_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CSRF = "SET_CSRF";

// PROFILE
export const LOAD_PROFILE = "LOAD_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const GET_PROFILE = "GET_PROFILE";

// BILLING
export const LOAD_BILLING = "LOAD_BILLING";
export const SET_BILLING = "SET_BILLING";
export const BUTTON_LOAD_BILLING = "BUTTON_LOAD_BILLING";
export const BUTTON_LOAD_BILLING_OFF = "BUTTON_LOAD_BILLING_OFF";
export const UPDATE_BILLING = "UPDATE_BILLING";

//TAGS DATA
export const LOAD_TAG_LIST = "LOAD_TAG_LIST";
export const ACTION_LOAD_TAG_LIST = "ACTION_LOAD_TAG_LIST";
export const TAG_LIST = "TAG_LIST";
export const UPDATE_TAG_LIST = "UPDATE_TAG_LIST";

export const NOTIFICATION_USER = "NOTIFICATION_USER";

export const AGENT_USER_DROPDOWN = "AGENT_USER_DROPDOWN";
export const LOAD_AGENT_USER_DROPDOWN = "LOAD_AGENT_USER_DROPDOWN";
export const LOAD_AGENT_TEAM_OWNER_LEAD_DROPDOWN =
  "LOAD_AGENT_TEAM_OWNER_LEAD_DROPDOWN";
export const AGENT_TEAM_OWNER_LEAD_DROPDOWN = "AGENT_TEAM_OWNER_LEAD_DROPDOWN";

export const NUMBER_USER_DROPDOWN = "NUMBER_USER_DROPDOWN";
export const LOAD_NUMBER_USER_DROPDOWN = "LOAD_NUMBER_USER_DROPDOWN";

export const LOAD_INTEGRATION_LIST = "LOAD_INTEGRATION_LIST";
export const INTEGRATION_LIST = "INTEGRATION_LIST";

export const LOAD_USER_INTEGRATION_LIST = "LOAD_USER_INTEGRATION_LIST";
export const USER_INTEGRATION_LIST = "USER_INTEGRATION_LIST";

export const LOAD_RECORDING_BY_TYPE_DATA = "LOAD_RECORDING_BY_TYPE_DATA";
export const RECORDING_BY_TYPE_DATA = "RECORDING_BY_TYPE_DATA";

export const LOAD_TEAM_USER_DROPDOWN = "LOAD_TEAM_USER_DROPDOWN";
export const TEAM_USER_DROPDOWN = "TEAM_USER_DROPDOWN";
export const LOAD_TEAM_USER_DROPDOWN_SETTINGS =
  "LOAD_TEAM_USER_DROPDOWN_SETTINGS";
export const TEAM_USER_DROPDOWN_SETTINGS = "TEAM_USER_DROPDOWN_SETTINGS";
export const LOAD_TEAM_USER_LIST = "LOAD_TEAM_USER_LIST";
export const TEAM_USER_LIST = "TEAM_USER_LIST";

export const LOAD_LEAD_USER_LIST = "LOAD_LEAD_USER_LIST";
export const LEAD_USER_LIST = "LEAD_USER_LIST";
export const LOAD_LEAD_USER_DROPDOWN = "LOAD_LEAD_USER_DROPDOWN";
export const LEAD_USER_DROPDOWN = "LEAD_USER_DROPDOWN";

export const LOAD_CALL_LIST = "LOAD_CALL_LIST";
export const CALL_LIST = "CALL_LIST";
