import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  FormGroup,
  Label,
  Input,
  Table,
  ModalFooter,
} from "reactstrap";

import { toastr } from "react-redux-toastr";
import axios from "axios";

function FormSchedule(props) {
  const [ loading, setLoading ] = useState(false);
  const { register, setValue, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      sunday: true,
      sundayStart: "allday",
      sundayEnd: "23:59:00",
      monday: true,
      mondayEnd: "23:59:00",
      tuesday: true,
      tuesdayEnd: "23:59:00",
      wednesday: true,
      wednesdayEnd: "23:59:00",
      thursday: true,
      thursdayEnd: "23:59:00",
      friday: true,
      fridayEnd: "23:59:00",
      saturday: true,
      saturdayEnd: "23:59:00",
    },
  });

  const data = [
    { name: "monday", label: "Monday" },
    { name: "tuesday", label: "Tuesday" },
    { name: "wednesday", label: "Wednesday" },
    { name: "thursday", label: "Thursday" },
    { name: "friday", label: "Friday" },
    { name: "saturday", label: "Saturday" },
    { name: "sunday", label: "Sunday" },
  ];
  /*const changeScreen = (e) => {
    if (e) {
      e.preventDefault();
    }
    var currentScreen = null;
    if (window.innerWidth < 517) {
      currentScreen = "mobile";
    } else {
      currentScreen = "desktop";
    }
    if (currentScreen != props.screenChange) {
      props.setScreenValue(currentScreen);
    }
  };*/

  useEffect(() => {
    register({ name: "_id" });
    if (Object.keys(props.item).length > 0) {
      setValue("_id", props.item._id);
      setValue("timezone", props.item.timezone);
      setValue("sunday", props.item.schedule[0].is_available);
      setValue("sundayStart", props.item.schedule[0].times[0].start);
      setValue("sundayEnd", props.item.schedule[0].times[0].end);
      setValue("monday", props.item.schedule[1].is_available);
      setValue("mondayStart", props.item.schedule[1].times[0].start);
      setValue("mondayEnd", props.item.schedule[1].times[0].end);
      setValue("tuesday", props.item.schedule[2].is_available);
      setValue("tuesdayStart", props.item.schedule[2].times[0].start);
      setValue("tuesdayEnd", props.item.schedule[2].times[0].end);
      setValue("wednesday", props.item.schedule[3].is_available);
      setValue("wednesdayStart", props.item.schedule[3].times[0].start);
      setValue("wednesdayEnd", props.item.schedule[3].times[0].end);
      setValue("thursday", props.item.schedule[4].is_available);
      setValue("thursdayStart", props.item.schedule[4].times[0].start);
      setValue("thursdayEnd", props.item.schedule[4].times[0].end);
      setValue("friday", props.item.schedule[5].is_available);
      setValue("fridayStart", props.item.schedule[5].times[0].start);
      setValue("fridayEnd", props.item.schedule[5].times[0].end);
      setValue("saturday", props.item.schedule[6].is_available);
      setValue("saturdayStart", props.item.schedule[6].times[0].start);
      setValue("saturdayEnd", props.item.schedule[6].times[0].end);
    }
    //changeScreen();
    //window.addEventListener('resize', (e)=> changeScreen(e));
    return () => {
      //window.removeEventListener('resize', (e)=> changeScreen(e));
    };
  }, [register, setValue, props]);
  var checkWatch = {};
  checkWatch.sundayAvalShow = watch("sunday", true);
  checkWatch.sundayShow = watch("sundayStart", "allday");
  checkWatch.mondayAvalShow = watch("monday", true);
  checkWatch.mondayShow = watch("mondayStart", "allday");
  checkWatch.tuesdayAvalShow = watch("tuesday", true);
  checkWatch.tuesdayShow = watch("tuesdayStart", "allday");
  checkWatch.wednesdayAvalShow = watch("wednesday", true);
  checkWatch.wednesdayShow = watch("wednesdayStart", "allday");
  checkWatch.thursdayAvalShow = watch("thursday", true);
  checkWatch.thursdayShow = watch("thursdayStart", "allday");
  checkWatch.fridayAvalShow = watch("friday", true);
  checkWatch.fridayShow = watch("fridayStart", "allday");
  checkWatch.saturdayAvalShow = watch("saturday", true);
  checkWatch.saturdayShow = watch("saturdayStart", "allday");

  const submitFormEdit = (form) => {
    // Prevent browser refresh

    // Used when setting a schedule from Agents
    setLoading(true);
    const record = {
      timezone: form.timezone,
      schedule: [
        {
          label: "Sunday",
          day: 0,
          is_available: form.sunday,
          times: [{ start: form.sundayStart, end: form.sundayEnd }],
        },
        {
          label: "Monday",
          day: 1,
          is_available: form.monday,
          times: [{ start: form.mondayStart, end: form.mondayEnd }],
        },
        {
          label: "Tuesday",
          day: 2,
          is_available: form.tuesday,
          times: [{ start: form.tuesdayStart, end: form.tuesdayEnd }],
        },
        {
          label: "Wednesday",
          day: 3,
          is_available: form.wednesday,
          times: [{ start: form.wednesdayStart, end: form.wednesdayEnd }],
        },
        {
          label: "Thursday",
          day: 4,
          is_available: form.thursday,
          times: [{ start: form.thursdayStart, end: form.thursdayEnd }],
        },
        {
          label: "Friday",
          day: 5,
          is_available: form.friday,
          times: [{ start: form.fridayStart, end: form.fridayEnd }],
        },
        {
          label: "Saturday",
          day: 6,
          is_available: form.saturday,
          times: [{ start: form.saturdayStart, end: form.saturdayEnd }],
        },
      ],
    };

    axios({
      method: "patch",
      responseType: "json",
      url: "/api/" + props.updateWhat + "/" + form._id + "/schedule/",
      data: record,
    })
      .then((item) => {
        setLoading(false);
        props.updateState(item.data);
        props.toggle();
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Schedule has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "warning";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Schedule",
          toastrOptions
        );
      });
  };

  const [timezone] = React.useState([
    { value: "America/New_York", label: "America/Eastern" },
    { value: "America/Chicago", label: "America/Central" },
    { value: "America/Denver", label: "America/Mountain" },
    { value: "America/Los_Angeles", label: "America/Pacific" },
    { value: "America/Anchorage", label: "America/Alaska" },
    { value: "America/Adak", label: "America/Hawaii" },
    { value: "Africa/Abidjan", label: "Africa/Abidjan" },
    { value: "Africa/Accra", label: "Africa/Accra" },
    { value: "Africa/Addis_Ababa", label: "Africa/Addis Ababa" },
    { value: "Africa/Algiers", label: "Africa/Algiers" },
    { value: "Africa/Asmara", label: "Africa/Asmara" },
    { value: "Africa/Bamako", label: "Africa/Bamako" },
    { value: "Africa/Bangui", label: "Africa/Bangui" },
    { value: "Africa/Banjul", label: "Africa/Banjul" },
    { value: "Africa/Bissau", label: "Africa/Bissau" },
    { value: "Africa/Blantyre", label: "Africa/Blantyre" },
    { value: "Africa/Brazzaville", label: "Africa/Brazzaville" },
    { value: "Africa/Bujumbura", label: "Africa/Bujumbura" },
    { value: "Africa/Cairo", label: "Africa/Cairo" },
    { value: "Africa/Casablanca", label: "Africa/Casablanca" },
    { value: "Africa/Ceuta", label: "Africa/Ceuta" },
    { value: "Africa/Conakry", label: "Africa/Conakry" },
    { value: "Africa/Dakar", label: "Africa/Dakar" },
    { value: "Africa/Dar_es_Salaam", label: "Africa/Dar es Salaam" },
    { value: "Africa/Djibouti", label: "Africa/Djibouti" },
    { value: "Africa/Douala", label: "Africa/Douala" },
    { value: "Africa/El_Aaiun", label: "Africa/El Aaiun" },
    { value: "Africa/Freetown", label: "Africa/Freetown" },
    { value: "Africa/Gaborone", label: "Africa/Gaborone" },
    { value: "Africa/Harare", label: "Africa/Harare" },
    { value: "Africa/Johannesburg", label: "Africa/Johannesburg" },
    { value: "Africa/Juba", label: "Africa/Juba" },
    { value: "Africa/Kampala", label: "Africa/Kampala" },
    { value: "Africa/Khartoum", label: "Africa/Khartoum" },
    { value: "Africa/Kigali", label: "Africa/Kigali" },
    { value: "Africa/Kinshasa", label: "Africa/Kinshasa" },
    { value: "Africa/Lagos", label: "Africa/Lagos" },
    { value: "Africa/Libreville", label: "Africa/Libreville" },
    { value: "Africa/Lome", label: "Africa/Lome" },
    { value: "Africa/Luanda", label: "Africa/Luanda" },
    { value: "Africa/Lubumbashi", label: "Africa/Lubumbashi" },
    { value: "Africa/Lusaka", label: "Africa/Lusaka" },
    { value: "Africa/Malabo", label: "Africa/Malabo" },
    { value: "Africa/Maputo", label: "Africa/Maputo" },
    { value: "Africa/Maseru", label: "Africa/Maseru" },
    { value: "Africa/Mbabane", label: "Africa/Mbabane" },
    { value: "Africa/Mogadishu", label: "Africa/Mogadishu" },
    { value: "Africa/Monrovia", label: "Africa/Monrovia" },
    { value: "Africa/Nairobi", label: "Africa/Nairobi" },
    { value: "Africa/Ndjamena", label: "Africa/Ndjamena" },
    { value: "Africa/Niamey", label: "Africa/Niamey" },
    { value: "Africa/Nouakchott", label: "Africa/Nouakchott" },
    { value: "Africa/Ouagadougou", label: "Africa/Ouagadougou" },
    { value: "Africa/Porto-Novo", label: "Africa/Porto-Novo" },
    { value: "Africa/Sao_Tome", label: "Africa/Sao Tome" },
    { value: "Africa/Tripoli", label: "Africa/Tripoli" },
    { value: "Africa/Tunis", label: "Africa/Tunis" },
    { value: "Africa/Windhoek", label: "Africa/Windhoek" },
    { value: "America/Anguilla", label: "America/Anguilla" },
    { value: "America/Antigua", label: "America/Antigua" },
    { value: "America/Araguaina", label: "America/Araguaina" },
    {
      value: "America/Argentina/Buenos_Aires",
      label: "America/Argentina/Buenos Aires",
    },
    {
      value: "America/Argentina/Catamarca",
      label: "America/Argentina/Catamarca",
    },
    { value: "America/Argentina/Cordoba", label: "America/Argentina/Cordoba" },
    { value: "America/Argentina/Jujuy", label: "America/Argentina/Jujuy" },
    {
      value: "America/Argentina/La_Rioja",
      label: "America/Argentina/La Rioja",
    },
    { value: "America/Argentina/Mendoza", label: "America/Argentina/Mendoza" },
    {
      value: "America/Argentina/Rio_Gallegos",
      label: "America/Argentina/Rio Gallegos",
    },
    { value: "America/Argentina/Salta", label: "America/Argentina/Salta" },
    {
      value: "America/Argentina/San_Juan",
      label: "America/Argentina/San Juan",
    },
    {
      value: "America/Argentina/San_Luis",
      label: "America/Argentina/San Luis",
    },
    { value: "America/Argentina/Tucuman", label: "America/Argentina/Tucuman" },
    { value: "America/Argentina/Ushuaia", label: "America/Argentina/Ushuaia" },
    { value: "America/Aruba", label: "America/Aruba" },
    { value: "America/Asuncion", label: "America/Asuncion" },
    { value: "America/Atikokan", label: "America/Atikokan" },
    { value: "America/Bahia", label: "America/Bahia" },
    { value: "America/Bahia_Banderas", label: "America/Bahia Banderas" },
    { value: "America/Barbados", label: "America/Barbados" },
    { value: "America/Belem", label: "America/Belem" },
    { value: "America/Belize", label: "America/Belize" },
    { value: "America/Blanc-Sablon", label: "America/Blanc-Sablon" },
    { value: "America/Boa_Vista", label: "America/Boa Vista" },
    { value: "America/Bogota", label: "America/Bogota" },
    { value: "America/Boise", label: "America/Boise" },
    { value: "America/Cambridge_Bay", label: "America/Cambridge Bay" },
    { value: "America/Campo_Grande", label: "America/Campo Grande" },
    { value: "America/Cancun", label: "America/Cancun" },
    { value: "America/Caracas", label: "America/Caracas" },
    { value: "America/Cayenne", label: "America/Cayenne" },
    { value: "America/Cayman", label: "America/Cayman" },
    { value: "America/Chihuahua", label: "America/Chihuahua" },
    { value: "America/Costa_Rica", label: "America/Costa Rica" },
    { value: "America/Creston", label: "America/Creston" },
    { value: "America/Cuiaba", label: "America/Cuiaba" },
    { value: "America/Curacao", label: "America/Curacao" },
    { value: "America/Danmarkshavn", label: "America/Danmarkshavn" },
    { value: "America/Dawson", label: "America/Dawson" },
    { value: "America/Dawson_Creek", label: "America/Dawson Creek" },
    { value: "America/Detroit", label: "America/Detroit" },
    { value: "America/Dominica", label: "America/Dominica" },
    { value: "America/Edmonton", label: "America/Edmonton" },
    { value: "America/Eirunepe", label: "America/Eirunepe" },
    { value: "America/El_Salvador", label: "America/El Salvador" },
    { value: "America/Fort_Nelson", label: "America/Fort Nelson" },
    { value: "America/Fortaleza", label: "America/Fortaleza" },
    { value: "America/Glace_Bay", label: "America/Glace Bay" },
    { value: "America/Godthab", label: "America/Godthab" },
    { value: "America/Goose_Bay", label: "America/Goose Bay" },
    { value: "America/Grand_Turk", label: "America/Grand Turk" },
    { value: "America/Grenada", label: "America/Grenada" },
    { value: "America/Guadeloupe", label: "America/Guadeloupe" },
    { value: "America/Guatemala", label: "America/Guatemala" },
    { value: "America/Guayaquil", label: "America/Guayaquil" },
    { value: "America/Guyana", label: "America/Guyana" },
    { value: "America/Halifax", label: "America/Halifax" },
    { value: "America/Havana", label: "America/Havana" },
    { value: "America/Hermosillo", label: "America/Hermosillo" },
    {
      value: "America/Indiana/Indianapolis",
      label: "America/Indiana/Indianapolis",
    },
    { value: "America/Indiana/Knox", label: "America/Indiana/Knox" },
    { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo" },
    {
      value: "America/Indiana/Petersburg",
      label: "America/Indiana/Petersburg",
    },
    { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell City" },
    { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay" },
    { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes" },
    { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac" },
    { value: "America/Inuvik", label: "America/Inuvik" },
    { value: "America/Iqaluit", label: "America/Iqaluit" },
    { value: "America/Jamaica", label: "America/Jamaica" },
    { value: "America/Juneau", label: "America/Juneau" },
    {
      value: "America/Kentucky/Louisville",
      label: "America/Kentucky/Louisville",
    },
    {
      value: "America/Kentucky/Monticello",
      label: "America/Kentucky/Monticello",
    },
    { value: "America/Kralendijk", label: "America/Kralendijk" },
    { value: "America/La_Paz", label: "America/La Paz" },
    { value: "America/Lima", label: "America/Lima" },
    { value: "America/Lower_Princes", label: "America/Lower Princes" },
    { value: "America/Maceio", label: "America/Maceio" },
    { value: "America/Managua", label: "America/Managua" },
    { value: "America/Manaus", label: "America/Manaus" },
    { value: "America/Marigot", label: "America/Marigot" },
    { value: "America/Martinique", label: "America/Martinique" },
    { value: "America/Matamoros", label: "America/Matamoros" },
    { value: "America/Mazatlan", label: "America/Mazatlan" },
    { value: "America/Menominee", label: "America/Menominee" },
    { value: "America/Merida", label: "America/Merida" },
    { value: "America/Metlakatla", label: "America/Metlakatla" },
    { value: "America/Mexico_City", label: "America/Mexico City" },
    { value: "America/Miquelon", label: "America/Miquelon" },
    { value: "America/Moncton", label: "America/Moncton" },
    { value: "America/Monterrey", label: "America/Monterrey" },
    { value: "America/Montevideo", label: "America/Montevideo" },
    { value: "America/Montserrat", label: "America/Montserrat" },
    { value: "America/Nassau", label: "America/Nassau" },
    { value: "America/Nipigon", label: "America/Nipigon" },
    { value: "America/Nome", label: "America/Nome" },
    { value: "America/Noronha", label: "America/Noronha" },
    {
      value: "America/North_Dakota/Beulah",
      label: "America/North Dakota/Beulah",
    },
    {
      value: "America/North_Dakota/Center",
      label: "America/North Dakota/Center",
    },
    {
      value: "America/North_Dakota/New_Salem",
      label: "America/North Dakota/New Salem",
    },
    { value: "America/Ojinaga", label: "America/Ojinaga" },
    { value: "America/Panama", label: "America/Panama" },
    { value: "America/Pangnirtung", label: "America/Pangnirtung" },
    { value: "America/Paramaribo", label: "America/Paramaribo" },
    { value: "America/Phoenix", label: "America/Phoenix" },
    { value: "America/Port-au-Prince", label: "America/Port-au-Prince" },
    { value: "America/Port_of_Spain", label: "America/Port of Spain" },
    { value: "America/Porto_Velho", label: "America/Porto Velho" },
    { value: "America/Puerto_Rico", label: "America/Puerto Rico" },
    { value: "America/Punta_Arenas", label: "America/Punta Arenas" },
    { value: "America/Rainy_River", label: "America/Rainy River" },
    { value: "America/Rankin_Inlet", label: "America/Rankin Inlet" },
    { value: "America/Recife", label: "America/Recife" },
    { value: "America/Regina", label: "America/Regina" },
    { value: "America/Resolute", label: "America/Resolute" },
    { value: "America/Rio_Branco", label: "America/Rio Branco" },
    { value: "America/Santarem", label: "America/Santarem" },
    { value: "America/Santiago", label: "America/Santiago" },
    { value: "America/Santo_Domingo", label: "America/Santo Domingo" },
    { value: "America/Sao_Paulo", label: "America/Sao Paulo" },
    { value: "America/Scoresbysund", label: "America/Scoresbysund" },
    { value: "America/Sitka", label: "America/Sitka" },
    { value: "America/St_Barthelemy", label: "America/St Barthelemy" },
    { value: "America/St_Johns", label: "America/St Johns" },
    { value: "America/St_Kitts", label: "America/St Kitts" },
    { value: "America/St_Lucia", label: "America/St Lucia" },
    { value: "America/St_Thomas", label: "America/St Thomas" },
    { value: "America/St_Vincent", label: "America/St Vincent" },
    { value: "America/Swift_Current", label: "America/Swift Current" },
    { value: "America/Tegucigalpa", label: "America/Tegucigalpa" },
    { value: "America/Thule", label: "America/Thule" },
    { value: "America/Thunder_Bay", label: "America/Thunder Bay" },
    { value: "America/Tijuana", label: "America/Tijuana" },
    { value: "America/Toronto", label: "America/Toronto" },
    { value: "America/Tortola", label: "America/Tortola" },
    { value: "America/Vancouver", label: "America/Vancouver" },
    { value: "America/Whitehorse", label: "America/Whitehorse" },
    { value: "America/Winnipeg", label: "America/Winnipeg" },
    { value: "America/Yakutat", label: "America/Yakutat" },
    { value: "America/Yellowknife", label: "America/Yellowknife" },
    { value: "Antarctica/Casey", label: "Antarctica/Casey" },
    { value: "Antarctica/Davis", label: "Antarctica/Davis" },
    { value: "Antarctica/DumontDUrville", label: "Antarctica/DumontDUrville" },
    { value: "Antarctica/Macquarie", label: "Antarctica/Macquarie" },
    { value: "Antarctica/Mawson", label: "Antarctica/Mawson" },
    { value: "Antarctica/McMurdo", label: "Antarctica/McMurdo" },
    { value: "Antarctica/Palmer", label: "Antarctica/Palmer" },
    { value: "Antarctica/Rothera", label: "Antarctica/Rothera" },
    { value: "Antarctica/Syowa", label: "Antarctica/Syowa" },
    { value: "Antarctica/Troll", label: "Antarctica/Troll" },
    { value: "Antarctica/Vostok", label: "Antarctica/Vostok" },
    { value: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen" },
    { value: "Asia/Aden", label: "Asia/Aden" },
    { value: "Asia/Almaty", label: "Asia/Almaty" },
    { value: "Asia/Amman", label: "Asia/Amman" },
    { value: "Asia/Anadyr", label: "Asia/Anadyr" },
    { value: "Asia/Aqtau", label: "Asia/Aqtau" },
    { value: "Asia/Aqtobe", label: "Asia/Aqtobe" },
    { value: "Asia/Ashgabat", label: "Asia/Ashgabat" },
    { value: "Asia/Atyrau", label: "Asia/Atyrau" },
    { value: "Asia/Baghdad", label: "Asia/Baghdad" },
    { value: "Asia/Bahrain", label: "Asia/Bahrain" },
    { value: "Asia/Baku", label: "Asia/Baku" },
    { value: "Asia/Bangkok", label: "Asia/Bangkok" },
    { value: "Asia/Barnaul", label: "Asia/Barnaul" },
    { value: "Asia/Beirut", label: "Asia/Beirut" },
    { value: "Asia/Bishkek", label: "Asia/Bishkek" },
    { value: "Asia/Brunei", label: "Asia/Brunei" },
    { value: "Asia/Chita", label: "Asia/Chita" },
    { value: "Asia/Choibalsan", label: "Asia/Choibalsan" },
    { value: "Asia/Colombo", label: "Asia/Colombo" },
    { value: "Asia/Damascus", label: "Asia/Damascus" },
    { value: "Asia/Dhaka", label: "Asia/Dhaka" },
    { value: "Asia/Dili", label: "Asia/Dili" },
    { value: "Asia/Dubai", label: "Asia/Dubai" },
    { value: "Asia/Dushanbe", label: "Asia/Dushanbe" },
    { value: "Asia/Famagusta", label: "Asia/Famagusta" },
    { value: "Asia/Gaza", label: "Asia/Gaza" },
    { value: "Asia/Hebron", label: "Asia/Hebron" },
    { value: "Asia/Ho_Chi_Minh", label: "Asia/Ho Chi Minh" },
    { value: "Asia/Hong_Kong", label: "Asia/Hong Kong" },
    { value: "Asia/Hovd", label: "Asia/Hovd" },
    { value: "Asia/Irkutsk", label: "Asia/Irkutsk" },
    { value: "Asia/Jakarta", label: "Asia/Jakarta" },
    { value: "Asia/Jayapura", label: "Asia/Jayapura" },
    { value: "Asia/Jerusalem", label: "Asia/Jerusalem" },
    { value: "Asia/Kabul", label: "Asia/Kabul" },
    { value: "Asia/Kamchatka", label: "Asia/Kamchatka" },
    { value: "Asia/Karachi", label: "Asia/Karachi" },
    { value: "Asia/Kathmandu", label: "Asia/Kathmandu" },
    { value: "Asia/Khandyga", label: "Asia/Khandyga" },
    { value: "Asia/Kolkata", label: "Asia/Kolkata" },
    { value: "Asia/Krasnoyarsk", label: "Asia/Krasnoyarsk" },
    { value: "Asia/Kuala_Lumpur", label: "Asia/Kuala Lumpur" },
    { value: "Asia/Kuching", label: "Asia/Kuching" },
    { value: "Asia/Kuwait", label: "Asia/Kuwait" },
    { value: "Asia/Macau", label: "Asia/Macau" },
    { value: "Asia/Magadan", label: "Asia/Magadan" },
    { value: "Asia/Makassar", label: "Asia/Makassar" },
    { value: "Asia/Manila", label: "Asia/Manila" },
    { value: "Asia/Muscat", label: "Asia/Muscat" },
    { value: "Asia/Nicosia", label: "Asia/Nicosia" },
    { value: "Asia/Novokuznetsk", label: "Asia/Novokuznetsk" },
    { value: "Asia/Novosibirsk", label: "Asia/Novosibirsk" },
    { value: "Asia/Omsk", label: "Asia/Omsk" },
    { value: "Asia/Oral", label: "Asia/Oral" },
    { value: "Asia/Phnom_Penh", label: "Asia/Phnom Penh" },
    { value: "Asia/Pontianak", label: "Asia/Pontianak" },
    { value: "Asia/Pyongyang", label: "Asia/Pyongyang" },
    { value: "Asia/Qatar", label: "Asia/Qatar" },
    { value: "Asia/Qyzylorda", label: "Asia/Qyzylorda" },
    { value: "Asia/Riyadh", label: "Asia/Riyadh" },
    { value: "Asia/Sakhalin", label: "Asia/Sakhalin" },
    { value: "Asia/Samarkand", label: "Asia/Samarkand" },
    { value: "Asia/Seoul", label: "Asia/Seoul" },
    { value: "Asia/Shanghai", label: "Asia/Shanghai" },
    { value: "Asia/Singapore", label: "Asia/Singapore" },
    { value: "Asia/Srednekolymsk", label: "Asia/Srednekolymsk" },
    { value: "Asia/Taipei", label: "Asia/Taipei" },
    { value: "Asia/Tashkent", label: "Asia/Tashkent" },
    { value: "Asia/Tbilisi", label: "Asia/Tbilisi" },
    { value: "Asia/Tehran", label: "Asia/Tehran" },
    { value: "Asia/Thimphu", label: "Asia/Thimphu" },
    { value: "Asia/Tokyo", label: "Asia/Tokyo" },
    { value: "Asia/Tomsk", label: "Asia/Tomsk" },
    { value: "Asia/Ulaanbaatar", label: "Asia/Ulaanbaatar" },
    { value: "Asia/Urumqi", label: "Asia/Urumqi" },
    { value: "Asia/Ust-Nera", label: "Asia/Ust-Nera" },
    { value: "Asia/Vientiane", label: "Asia/Vientiane" },
    { value: "Asia/Vladivostok", label: "Asia/Vladivostok" },
    { value: "Asia/Yakutsk", label: "Asia/Yakutsk" },
    { value: "Asia/Yangon", label: "Asia/Yangon" },
    { value: "Asia/Yekaterinburg", label: "Asia/Yekaterinburg" },
    { value: "Asia/Yerevan", label: "Asia/Yerevan" },
    { value: "Atlantic/Azores", label: "Atlantic/Azores" },
    { value: "Atlantic/Bermuda", label: "Atlantic/Bermuda" },
    { value: "Atlantic/Canary", label: "Atlantic/Canary" },
    { value: "Atlantic/Cape_Verde", label: "Atlantic/Cape Verde" },
    { value: "Atlantic/Faroe", label: "Atlantic/Faroe" },
    { value: "Atlantic/Madeira", label: "Atlantic/Madeira" },
    { value: "Atlantic/Reykjavik", label: "Atlantic/Reykjavik" },
    { value: "Atlantic/South_Georgia", label: "Atlantic/South Georgia" },
    { value: "Atlantic/St_Helena", label: "Atlantic/St Helena" },
    { value: "Atlantic/Stanley", label: "Atlantic/Stanley" },
    { value: "Australia/Adelaide", label: "Australia/Adelaide" },
    { value: "Australia/Brisbane", label: "Australia/Brisbane" },
    { value: "Australia/Broken_Hill", label: "Australia/Broken Hill" },
    { value: "Australia/Currie", label: "Australia/Currie" },
    { value: "Australia/Darwin", label: "Australia/Darwin" },
    { value: "Australia/Eucla", label: "Australia/Eucla" },
    { value: "Australia/Hobart", label: "Australia/Hobart" },
    { value: "Australia/Lindeman", label: "Australia/Lindeman" },
    { value: "Australia/Lord_Howe", label: "Australia/Lord Howe" },
    { value: "Australia/Melbourne", label: "Australia/Melbourne" },
    { value: "Australia/Perth", label: "Australia/Perth" },
    { value: "Australia/Sydney", label: "Australia/Sydney" },
    { value: "Europe/Amsterdam", label: "Europe/Amsterdam" },
    { value: "Europe/Andorra", label: "Europe/Andorra" },
    { value: "Europe/Astrakhan", label: "Europe/Astrakhan" },
    { value: "Europe/Athens", label: "Europe/Athens" },
    { value: "Europe/Belgrade", label: "Europe/Belgrade" },
    { value: "Europe/Berlin", label: "Europe/Berlin" },
    { value: "Europe/Bratislava", label: "Europe/Bratislava" },
    { value: "Europe/Brussels", label: "Europe/Brussels" },
    { value: "Europe/Bucharest", label: "Europe/Bucharest" },
    { value: "Europe/Budapest", label: "Europe/Budapest" },
    { value: "Europe/Busingen", label: "Europe/Busingen" },
    { value: "Europe/Chisinau", label: "Europe/Chisinau" },
    { value: "Europe/Copenhagen", label: "Europe/Copenhagen" },
    { value: "Europe/Dublin", label: "Europe/Dublin" },
    { value: "Europe/Gibraltar", label: "Europe/Gibraltar" },
    { value: "Europe/Guernsey", label: "Europe/Guernsey" },
    { value: "Europe/Helsinki", label: "Europe/Helsinki" },
    { value: "Europe/Isle_of_Man", label: "Europe/Isle of Man" },
    { value: "Europe/Istanbul", label: "Europe/Istanbul" },
    { value: "Europe/Jersey", label: "Europe/Jersey" },
    { value: "Europe/Kaliningrad", label: "Europe/Kaliningrad" },
    { value: "Europe/Kiev", label: "Europe/Kiev" },
    { value: "Europe/Kirov", label: "Europe/Kirov" },
    { value: "Europe/Lisbon", label: "Europe/Lisbon" },
    { value: "Europe/Ljubljana", label: "Europe/Ljubljana" },
    { value: "Europe/London", label: "Europe/London" },
    { value: "Europe/Luxembourg", label: "Europe/Luxembourg" },
    { value: "Europe/Madrid", label: "Europe/Madrid" },
    { value: "Europe/Malta", label: "Europe/Malta" },
    { value: "Europe/Mariehamn", label: "Europe/Mariehamn" },
    { value: "Europe/Minsk", label: "Europe/Minsk" },
    { value: "Europe/Monaco", label: "Europe/Monaco" },
    { value: "Europe/Moscow", label: "Europe/Moscow" },
    { value: "Europe/Oslo", label: "Europe/Oslo" },
    { value: "Europe/Paris", label: "Europe/Paris" },
    { value: "Europe/Podgorica", label: "Europe/Podgorica" },
    { value: "Europe/Prague", label: "Europe/Prague" },
    { value: "Europe/Riga", label: "Europe/Riga" },
    { value: "Europe/Rome", label: "Europe/Rome" },
    { value: "Europe/Samara", label: "Europe/Samara" },
    { value: "Europe/San_Marino", label: "Europe/San Marino" },
    { value: "Europe/Sarajevo", label: "Europe/Sarajevo" },
    { value: "Europe/Saratov", label: "Europe/Saratov" },
    { value: "Europe/Simferopol", label: "Europe/Simferopol" },
    { value: "Europe/Skopje", label: "Europe/Skopje" },
    { value: "Europe/Sofia", label: "Europe/Sofia" },
    { value: "Europe/Stockholm", label: "Europe/Stockholm" },
    { value: "Europe/Tallinn", label: "Europe/Tallinn" },
    { value: "Europe/Tirane", label: "Europe/Tirane" },
    { value: "Europe/Ulyanovsk", label: "Europe/Ulyanovsk" },
    { value: "Europe/Uzhgorod", label: "Europe/Uzhgorod" },
    { value: "Europe/Vaduz", label: "Europe/Vaduz" },
    { value: "Europe/Vatican", label: "Europe/Vatican" },
    { value: "Europe/Vienna", label: "Europe/Vienna" },
    { value: "Europe/Vilnius", label: "Europe/Vilnius" },
    { value: "Europe/Volgograd", label: "Europe/Volgograd" },
    { value: "Europe/Warsaw", label: "Europe/Warsaw" },
    { value: "Europe/Zagreb", label: "Europe/Zagreb" },
    { value: "Europe/Zaporozhye", label: "Europe/Zaporozhye" },
    { value: "Europe/Zurich", label: "Europe/Zurich" },
    { value: "Indian/Antananarivo", label: "Indian/Antananarivo" },
    { value: "Indian/Chagos", label: "Indian/Chagos" },
    { value: "Indian/Christmas", label: "Indian/Christmas" },
    { value: "Indian/Cocos", label: "Indian/Cocos" },
    { value: "Indian/Comoro", label: "Indian/Comoro" },
    { value: "Indian/Kerguelen", label: "Indian/Kerguelen" },
    { value: "Indian/Mahe", label: "Indian/Mahe" },
    { value: "Indian/Maldives", label: "Indian/Maldives" },
    { value: "Indian/Mauritius", label: "Indian/Mauritius" },
    { value: "Indian/Mayotte", label: "Indian/Mayotte" },
    { value: "Indian/Reunion", label: "Indian/Reunion" },
    { value: "Pacific/Apia", label: "Pacific/Apia" },
    { value: "Pacific/Auckland", label: "Pacific/Auckland" },
    { value: "Pacific/Bougainville", label: "Pacific/Bougainville" },
    { value: "Pacific/Chatham", label: "Pacific/Chatham" },
    { value: "Pacific/Chuuk", label: "Pacific/Chuuk" },
    { value: "Pacific/Easter", label: "Pacific/Easter" },
    { value: "Pacific/Efate", label: "Pacific/Efate" },
    { value: "Pacific/Enderbury", label: "Pacific/Enderbury" },
    { value: "Pacific/Fakaofo", label: "Pacific/Fakaofo" },
    { value: "Pacific/Fiji", label: "Pacific/Fiji" },
    { value: "Pacific/Funafuti", label: "Pacific/Funafuti" },
    { value: "Pacific/Galapagos", label: "Pacific/Galapagos" },
    { value: "Pacific/Gambier", label: "Pacific/Gambier" },
    { value: "Pacific/Guadalcanal", label: "Pacific/Guadalcanal" },
    { value: "Pacific/Guam", label: "Pacific/Guam" },
    { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
    { value: "Pacific/Kiritimati", label: "Pacific/Kiritimati" },
    { value: "Pacific/Kosrae", label: "Pacific/Kosrae" },
    { value: "Pacific/Kwajalein", label: "Pacific/Kwajalein" },
    { value: "Pacific/Majuro", label: "Pacific/Majuro" },
    { value: "Pacific/Marquesas", label: "Pacific/Marquesas" },
    { value: "Pacific/Midway", label: "Pacific/Midway" },
    { value: "Pacific/Nauru", label: "Pacific/Nauru" },
    { value: "Pacific/Niue", label: "Pacific/Niue" },
    { value: "Pacific/Norfolk", label: "Pacific/Norfolk" },
    { value: "Pacific/Noumea", label: "Pacific/Noumea" },
    { value: "Pacific/Pago_Pago", label: "Pacific/Pago Pago" },
    { value: "Pacific/Palau", label: "Pacific/Palau" },
    { value: "Pacific/Pitcairn", label: "Pacific/Pitcairn" },
    { value: "Pacific/Pohnpei", label: "Pacific/Pohnpei" },
    { value: "Pacific/Port_Moresby", label: "Pacific/Port Moresby" },
    { value: "Pacific/Rarotonga", label: "Pacific/Rarotonga" },
    { value: "Pacific/Saipan", label: "Pacific/Saipan" },
    { value: "Pacific/Tahiti", label: "Pacific/Tahiti" },
    { value: "Pacific/Tarawa", label: "Pacific/Tarawa" },
    { value: "Pacific/Tongatapu", label: "Pacific/Tongatapu" },
    { value: "Pacific/Wake", label: "Pacific/Wake" },
    { value: "Pacific/Wallis", label: "Pacific/Wallis" },
    { value: "UTC", label: "UTC" },
  ]);

  const [times] = React.useState([
    { value: "allday", label: "All Day" },
    { value: "00:00:00", label: "12:00 am" },
    { value: "00:15:00", label: "12:15 am" },
    { value: "00:30:00", label: "12:30 am" },
    { value: "00:45:00", label: "12:45 am" },
    { value: "01:00:00", label: "1:00 am" },
    { value: "01:15:00", label: "1:15 am" },
    { value: "01:30:00", label: "1:30 am" },
    { value: "01:45:00", label: "1:45 am" },
    { value: "02:00:00", label: "2:00 am" },
    { value: "02:15:00", label: "2:15 am" },
    { value: "02:30:00", label: "2:30 am" },
    { value: "02:45:00", label: "2:45 am" },
    { value: "03:00:00", label: "3:00 am" },
    { value: "03:15:00", label: "3:15 am" },
    { value: "03:30:00", label: "3:30 am" },
    { value: "03:45:00", label: "3:45 am" },
    { value: "04:00:00", label: "4:00 am" },
    { value: "04:15:00", label: "4:15 am" },
    { value: "04:30:00", label: "4:30 am" },
    { value: "04:45:00", label: "4:45 am" },
    { value: "05:00:00", label: "5:00 am" },
    { value: "05:15:00", label: "5:15 am" },
    { value: "05:30:00", label: "5:30 am" },
    { value: "05:45:00", label: "5:45 am" },
    { value: "06:00:00", label: "6:00 am" },
    { value: "06:15:00", label: "6:15 am" },
    { value: "06:30:00", label: "6:30 am" },
    { value: "06:45:00", label: "6:45 am" },
    { value: "07:00:00", label: "7:00 am" },
    { value: "07:15:00", label: "7:15 am" },
    { value: "07:30:00", label: "7:30 am" },
    { value: "07:45:00", label: "7:45 am" },
    { value: "08:00:00", label: "8:00 am" },
    { value: "08:15:00", label: "8:15 am" },
    { value: "08:30:00", label: "8:30 am" },
    { value: "08:45:00", label: "8:45 am" },
    { value: "09:00:00", label: "9:00 am" },
    { value: "09:15:00", label: "9:15 am" },
    { value: "09:30:00", label: "9:30 am" },
    { value: "09:45:00", label: "9:45 am" },
    { value: "10:00:00", label: "10:00 am" },
    { value: "10:15:00", label: "10:15 am" },
    { value: "10:30:00", label: "10:30 am" },
    { value: "10:45:00", label: "10:45 am" },
    { value: "11:00:00", label: "11:00 am" },
    { value: "11:15:00", label: "11:15 am" },
    { value: "11:30:00", label: "11:30 am" },
    { value: "11:45:00", label: "11:45 am" },
    { value: "12:00:00", label: "12:00 pm" },
    { value: "12:15:00", label: "12:15 pm" },
    { value: "12:30:00", label: "12:30 pm" },
    { value: "12:45:00", label: "12:45 pm" },
    { value: "13:00:00", label: "1:00 pm" },
    { value: "13:15:00", label: "1:15 pm" },
    { value: "13:30:00", label: "1:30 pm" },
    { value: "13:45:00", label: "1:45 pm" },
    { value: "14:00:00", label: "2:00 pm" },
    { value: "14:15:00", label: "2:15 pm" },
    { value: "14:30:00", label: "2:30 pm" },
    { value: "14:45:00", label: "2:45 pm" },
    { value: "15:00:00", label: "3:00 pm" },
    { value: "15:15:00", label: "3:15 pm" },
    { value: "15:30:00", label: "3:30 pm" },
    { value: "15:45:00", label: "3:45 pm" },
    { value: "16:00:00", label: "4:00 pm" },
    { value: "16:15:00", label: "4:15 pm" },
    { value: "16:30:00", label: "4:30 pm" },
    { value: "16:45:00", label: "4:45 pm" },
    { value: "17:00:00", label: "5:00 pm" },
    { value: "17:15:00", label: "5:15 pm" },
    { value: "17:30:00", label: "5:30 pm" },
    { value: "17:45:00", label: "5:45 pm" },
    { value: "18:00:00", label: "6:00 pm" },
    { value: "18:15:00", label: "6:15 pm" },
    { value: "18:30:00", label: "6:30 pm" },
    { value: "18:45:00", label: "6:45 pm" },
    { value: "19:00:00", label: "7:00 pm" },
    { value: "19:15:00", label: "7:15 pm" },
    { value: "19:30:00", label: "7:30 pm" },
    { value: "19:45:00", label: "7:45 pm" },
    { value: "20:00:00", label: "8:00 pm" },
    { value: "20:15:00", label: "8:15 pm" },
    { value: "20:30:00", label: "8:30 pm" },
    { value: "20:45:00", label: "8:45 pm" },
    { value: "21:00:00", label: "9:00 pm" },
    { value: "21:15:00", label: "9:15 pm" },
    { value: "21:30:00", label: "9:30 pm" },
    { value: "21:45:00", label: "9:45 pm" },
    { value: "22:00:00", label: "10:00 pm" },
    { value: "22:15:00", label: "10:15 pm" },
    { value: "22:30:00", label: "10:30 pm" },
    { value: "22:45:00", label: "10:45 pm" },
    { value: "23:00:00", label: "11:00 pm" },
    { value: "23:15:00", label: "11:15 pm" },
    { value: "23:30:00", label: "11:30 pm" },
    { value: "23:45:00", label: "11:45 pm" },
    { value: "23:59:00", label: "11:59 pm" },
  ]);
  const mobileTable = () => {
    return data.map((_data, index) => {
      return (
        <div className="tablelayout" key={index}>
          <div className="tablecell ">
            <label
              className="tablelayout_header"
              style={{ backgroundColor: "#d4d4d4" }}
            >
              {_data.label}
            </label>
            <div className="tablelayout">
              <div className="tablerow">
                <p className="tablecell">OnCall</p>
                <p className="tablecell">
                  {" "}
                  <label className="custom-toggle mr-1">
                    <input
                      type="checkbox"
                      disabled={!props.allowEdit}
                      placeholder={_data.label}
                      name={_data.name}
                      ref={register()}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>{" "}
                </p>
              </div>
              <div className="tablerow">
                <p className="tablecell"> Start Time </p>
                <p className="tablecell start_time_width">
                  {checkWatch[_data.name + "AvalShow"] !== false && (
                    <select
                      name={_data.name + "Start"}
                      ref={register()}
                      disabled={!props.allowEdit}
                    >
                      {times.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  )}
                </p>
              </div>
              <div className="tablerow">
                <p className="tablecell"> End Time </p>
                <p className="tablecell start_time_width">
                  {checkWatch[_data.name + "Show"] !== "allday" &&
                    checkWatch[_data.name + "AvalShow"] !== false && (
                      <select
                        name={_data.name + "End"}
                        ref={register()}
                        disabled={!props.allowEdit}
                      >
                        {times.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  const DeskTopTable = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Day</th>
            <th>OnCall</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Sunday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Sunday"
                  name="sunday"
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>

            <td>
              {checkWatch.sundayAvalShow !== false && (
                <select name="sundayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.sundayShow !== "allday" &&
                checkWatch.sundayAvalShow !== false && (
                  <select name="sundayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">Monday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Monday"
                  name="monday"
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
            <td>
              {checkWatch.mondayAvalShow !== false && (
                <select name="mondayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.mondayShow !== "allday" &&
                checkWatch.mondayAvalShow !== false && (
                  <select name="mondayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">Tuesday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Tuesday"
                  name="tuesday"
                  ref={register()}
                />

                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
            <td>
              {checkWatch.tuesdayAvalShow !== false && (
                <select name="tuesdayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.tuesdayShow !== "allday" &&
                checkWatch.tuesdayAvalShow !== false && (
                  <select name="tuesdayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">Wednesday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Wednesday"
                  name="wednesday"
                  ref={register()}
                />

                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
            <td>
              {checkWatch.wednesdayAvalShow !== false && (
                <select name="wednesdayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.wednesdayShow !== "allday" &&
                checkWatch.wednesdayAvalShow !== false && (
                  <select name="wednesdayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">Thursday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Thursday"
                  name="thursday"
                  ref={register()}
                />

                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
            <td>
              {checkWatch.thursdayAvalShow !== false && (
                <select name="thursdayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.thursdayShow !== "allday" &&
                checkWatch.thursdayAvalShow !== false && (
                  <select name="thursdayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">Friday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Friday"
                  name="friday"
                  ref={register()}
                />

                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
            <td>
              {checkWatch.fridayAvalShow !== false && (
                <select name="fridayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.fridayShow !== "allday" &&
                checkWatch.fridayAvalShow !== false && (
                  <select name="fridayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
          <tr>
            <th scope="row">Saturday</th>
            <td>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  placeholder="Saturday"
                  name="saturday"
                  ref={register()}
                />

                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
            <td>
              {checkWatch.saturdayAvalShow !== false && (
                <select name="saturdayStart" ref={register()}>
                  {times.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              {checkWatch.saturdayShow !== "allday" &&
                checkWatch.saturdayAvalShow !== false && (
                  <select name="saturdayEnd" ref={register()}>
                    {times.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };
  if (!props.allowEdit) {
    return (
      <>
        {props.screenChange === "desktop" && false ? (
          <div className="desktop_scedule_form">{DeskTopTable()}</div>
        ) : (
          <div className="mobile_scedule_form2">{mobileTable()}</div>
        )}
        <FormGroup style={{ marginTop: "20px" }}>
          <Label for="timezone">Timezone</Label>
          <Input
            type="select"
            name="timezone"
            id="timezone"
            disabled={!props.allowEdit}
            innerRef={register({ required: true })}
          >
            {timezone.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      </>
    );
  } else {
    return (
      <>
        <form onSubmit={handleSubmit(submitFormEdit)}>
          {props.screenChange === "desktop" && false ? (
            <div className="desktop_scedule_form">{DeskTopTable()}</div>
          ) : (
            <div className="mobile_scedule_form2">{mobileTable()}</div>
          )}
          <FormGroup style={{ marginTop: "20px" }}>
            <Label for="timezone">Timezone</Label>
            <Input
              type="select"
              name="timezone"
              id="timezone"
              innerRef={register({ required: true })}
            >
              {timezone.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Input>
            {errors.timezone && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Timezone is required
              </div>
            )}
          </FormGroup>

          <ModalFooter>
          {loading?<Button 
            color="primary" 
            type="button" 
            disabled={true}>
            Saving...
          </Button>:<Button color="primary">Save Schedule</Button>}
          </ModalFooter>
        </form>
      </>
    );
  }
}

export default FormSchedule;
