import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormPurchase from "./FormPurchase";
import TableSearch from "./TableSearch";

function ModalPurchase(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <h2 style={{ paddingBottom: "0px", display: "inline-block" }}>
        Purchased Numbers
      </h2>
      <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
        Purchase Phone
      </Button>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>
          <FormPurchase
            toggle={toggle}
            item={props.item}
            updateSearchState={props.updateSearchState}
          />

          <TableSearch
            items={props.searched}
            updatePurchasedState={props.updatePurchasedState}
            toggle={toggle}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalPurchase;
