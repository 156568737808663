import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormEdit from "./FormEdit";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function ModalAdd(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/number/purchased/` + _id,
        })
          .then((response) => {
            props.deletePurchasedFromState(response.data._id);

            Swal.fire("Deleted!", "Your phone is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  return (
    <>
      <Button
        color={"info"}
        onClick={toggle}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <i className="fas fa-pen"></i>
      </Button>

      <Button
        color={"default"}
        onClick={() => {
          confirmDelete(props.item._id, props.item.name);
        }}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle} {props.item.national}
        </ModalHeader>
        <ModalBody>
          <FormEdit
            updatePurchasedState={props.updatePurchasedState}
            toggle={toggle}
            item={props.item}
            agents={props.agents}
            teams={props.teams}
            recordings={props.recordings}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalAdd;
