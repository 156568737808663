// What i used as base for project
// https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82
// https://github.com/rishipr/mern-auth/blob/master/client/src/actions/authActions.js
//
// Has very clean configuration
// https://github.com/flaviuse/mern-authentication

// May help with social login
// https://github.com/thechutrain/mern-passport

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { push } from "connected-react-router";
import { toastr } from "react-redux-toastr";

import {
  LOAD_CURRENT_USER,
  SET_CURRENT_USER,
  GET_ERRORS,
  NOTIFICATION_USER,
  SET_CSRF,
} from "./types";
import io from "socket.io-client";
import { teamDropdown, teamDropdownSettings } from "./teamAction";
import { agentDropdown, getAndTeamByOwnerLead } from "./agentAction";
import { numberDropdown } from "./numberAction";
import { recordingByTypeDropdown } from "./recordingAction";
import { getTags } from "./tagActions";

const socketUrl = process.env.BASE_URL;
let socket = null;
let timeOut = null;

//get csrf token
const callMethod = () => (dispatch) => {
  axios
    .get(`/api/csrf`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      axios.defaults.headers.common["xsrf-token"] = res.data; // Set it in header for the rest of the axios requests.
      dispatch({
        type: SET_CSRF,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const handleCsrfToken = () => (dispatch) => {
  dispatch(callMethod());
  setInterval(() => {
    dispatch(callMethod());
  }, 1000 * 60 * 60);
};

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  return axios
    .post("/api/user/register", userData)
    .then((res) => {
      const toastrType = "success";
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
      };
      toastr.light("Success", "Account Created", toastrOptions);

      history.push("/auth/login");

      //GA Datalayer for Registration
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
        userId: userData.email,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Login - get user token
export const loginUser = (userData, samePageRedirect = false, bool = true) => (
  dispatch
) => {
  return axios
    .post("/api/user/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      decoded.token = token;

      // Set current user
      dispatch(setCurrentUser(decoded, bool));
      if (samePageRedirect) {
      } else {
        axios
          .get("/api/billing/account_status")
          .then((res) => {
            if (res.data && !res.data.setupComplete) {
              dispatch(push("/getstarted/setup"));
            } else {
              dispatch(push("/account/dashboard"));
            }

            //GA Datalayer for Login
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "login",
              userId: userData.email,
              plan: res.data.stripePlanId,
              setupComplete: res.data.setupComplete,
              accountStatus: res.data.accountStatus,
              organization: res.data.organization,
            });
          })
          .catch((err) => {
            var error = err.response.data;
            if (error.message && error.message.message) {
              error.message = error.message.message;
            }
            dispatch({
              type: GET_ERRORS,
              payload: error,
            });
          });
      }
    })
    .catch((err) => {
      var error = err.response.data;
      if (error.message && error.message.message) {
        error.message = error.message.message;
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const cleartNotification = () => (dispatch) => {
  return dispatch({
    type: NOTIFICATION_USER,
    payload: null,
  });
};
// Set logged in user
export const setCurrentUser = (decoded, bool = true) => (dispatch) => {
  if (decoded && Object.keys(decoded).length > 0 && !socket) {
    const token = decoded.token;
    socket = io.connect(socketUrl, {
      path: "/websoccket",
      query: { token },
    });

    socket.on("call_status_update_" + decoded.organization, (message) => {
      //  socket.on("call_status_alert_" + decoded.organization, (message) => {
      dispatch({
        type: NOTIFICATION_USER,
        payload: message.data,
      });
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(() => {
        dispatch({
          type: NOTIFICATION_USER,
          payload: null,
        });
      }, 45000);
    });

    decoded.socket = socket;
    //Call Dropdown api's for all application here
    if (bool) {
      dispatch(teamDropdownSettings());
      dispatch(teamDropdown());
      dispatch(agentDropdown());
      dispatch(getAndTeamByOwnerLead());
      dispatch(numberDropdown());
      dispatch(recordingByTypeDropdown());
      dispatch(getTags());
    }
    //call dropdoen api's for all application here
  } else {
    if (socket) {
      socket.close();
      socket = null;
    }
  }
  dispatch({
    type: SET_CURRENT_USER,
    payload: decoded,
  });
};

// User loading
export const setUserLoading = () => {
  return {
    type: LOAD_CURRENT_USER,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(push("/auth/login"));
};

export const attemptSendResetPasswordLink = (email, history) => async (
  dispatch
) => {
  return axios
    .post("/api/user/forgot", email)
    .then((res) => {
      const toastrType = "success";
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
      };
      toastr.light(
        "Success",
        "Password Reset Instructions send to email.",
        toastrOptions
      );

      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const attemptResetPassword = (password, token, history) => async (
  dispatch
) => {
  return axios
    .post(`/api/user/reset/${token}`, password)
    .then((res) => {
      const toastrType = "success";
      const toastrOptions = {
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
      };
      toastr.light(
        "Success",
        "Password Changed, try logging in.",
        toastrOptions
      );

      dispatch(push("/auth/login"));
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
