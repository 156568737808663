import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";

function FormPurchase(props) {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setError, watch } = useForm({
    defaultValues: {
      name: "",
      country: "US",
      searchBy: "number",
      search: "",
      match: "anywhere",
    },
  });

  useEffect(() => {}, [register]);

  const submitFormSearch = (form) => {
    setLoading(true);
    axios({
      method: "post",
      responseType: "json",
      url: `/api/number/search`,
      data: form,
    })
      .then((item) => {
        setLoading(false);
        if (!item.data.error) {
          props.updateSearchState(item.data);

          const toastrType = "success";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light("Success", "Search Successful", toastrOptions);
        } else {
          const toastrType = "error";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Error",
            "Something went wrong with your search. Try again.",
            toastrOptions
          );

          //  if (err.data.error.field === "duplicate") {
          return setError(
            item.data.error.field,
            "duplicate",
            item.data.error.message
          );
          //    }
        }
      })
      .catch((err) => {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong with your search. Try again.",
          toastrOptions
        );
      });
  };

  const showMatchby = watch("searchBy", "number");

  return (
    <>
      <form onSubmit={handleSubmit(submitFormSearch)}>
        {" "}
        <Row>
          <Col sm="12">
            {" "}
            <h3 style={{ paddingBottom: "25px", paddingTop: "0px" }}>
              Customers will see this number on their call display.
            </h3>
            <FormGroup row>
              <Label for="name" sm={2}>
                Country
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="country"
                  id="country"
                  innerRef={register({ required: true })}
                >
                  <option key="US" value="US">
                    United States
                  </option>
                  <option key="CA" value="CA">
                    Canada
                  </option>
                  <option key="GB" value="GB">
                    United Kingdom
                  </option>
                  <option key="AU" value="AU">
                    Australia
                  </option>
                </Input>
              </Col>
            </FormGroup>{" "}
            <Row>
              <Col sm={4} style={{ paddingRight: "5px" }}>
                <FormGroup>
                  <Input
                    type="select"
                    name="searchBy"
                    id="searchBy"
                    innerRef={register()}
                  >
                    <option key="number" value="number">
                      Number
                    </option>
                    <option key="code" value="code">
                      Area Code
                    </option>
                    <option key="state" value="state">
                      State/Province
                    </option>
                    <option key="city" value="city">
                      City
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col style={{ paddingLeft: "5px" }}>
                <FormGroup>
                  <Input
                    type="text"
                    name="search"
                    id="search"
                    innerRef={register()}
                  />
                </FormGroup>
              </Col>
            </Row>
            {showMatchby === "number" && (
              <FormGroup row>
                <Label for="name" sm={2}>
                  Match
                </Label>
                <Col sm={10}>
                  <Input
                    type="select"
                    name="match"
                    id="match"
                    innerRef={register()}
                  >
                    <option key="anywhere" value="anywhere">
                      Anywhere in number
                    </option>
                    <option key="first" value="first">
                      First part of number
                    </option>
                    <option key="last" value="last">
                      Last part of number
                    </option>
                  </Input>
                </Col>
              </FormGroup>
            )}
          </Col>
        </Row>
        {loading ? (
          <Button color="primary" type="button" disabled={true}>
            Searching...
          </Button>
        ) : (
          <Button color="default">Search</Button>
        )}
      </form>
    </>
  );
}
FormPurchase.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormPurchase);
