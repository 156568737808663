import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import AsyncPaginate from "react-select-async-paginate";
import classnames from "classnames";

import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Badge,
  Alert,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  FormText,
  Collapse,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

import startsWith from "lodash.startswith";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { toastr } from "react-redux-toastr";
import axios from "axios";

import Datetime from "react-datetime";
//import { Loader } from "semantic-ui-react";
import Loader from "layouts/Loader.js";
var classNames = require("classnames");
function FormCall(props) {
  //const [agent, setAgent] = useState({});
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const [agents, setAgents] = useState([]);
  const [teamAgent, setTeamAgent] = useState();
  //const [lead, setLead] = useState();
  //const [when, setWhen] = useState(false);
  const [filterTeam, setFilterTeam] = useState(false);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [dateTime, setDateTime] = useState("");
  const [recording, setRecording] = useState("");
  const [voicemail, setVoicemail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isClickCheckbox, setClickCheckbox] = useState(false);
  const [isSetDefaultValue, setDefaultValue] = useState(false);
  const [isResetDefaultValueData, resetDefaultValueData] = useState(false);
  //  const [type, setType] = useState(props.type ? props.type : "");
  var settings = null;
  if (props.team) {
    const itemIndex = props.teamDSDropdown.list[0].options.findIndex(
      (data) => props.team._id === data._id
    );
    settings = props.team.settings;
    if (itemIndex !== -1) {
      settings = props.teamDSDropdown.list[0].options[itemIndex].settings;
    } else {
      const itemIndex2 = props.teamDSDropdown.list[1].options.findIndex(
        (data) => props.team._id === data._id
      );
      if (itemIndex2 !== -1) {
        settings = props.teamDSDropdown.list[1].options[itemIndex2].settings;
      }
    }
  }
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    setError,
    getValues,
    clearError,
    watch,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      direction: "",
      startTime: "",
      endTime: "",
      status: "",
      type: "",
      team: "",
      lead: "",
      target: "",
      recording:
        props.team && settings && settings.recording
          ? settings.recording
          : null,
      voicemail:
        props.team && settings && settings.voicemail
          ? settings.voicemail
          : null,
      optOut: false,
      whisperText: "",
      whisperTextLead: "",
    },
  });

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );
  /*
  const getPhone = (props) => {
    if (Object.keys(props.item).length > 0) {
      return props.item.national.toString();
    } else {
      return "";
    }
  };
*/

  const getTeam = () => {
    if (props.teamDSDropdown && props.teamDSDropdown.seeallteams) {
      return props.teamDSDropdown.list || "";
    } else {
      return props.teamDSDropdown ? props.teamDSDropdown.list[0].options : "";
    }
  };

  const loadOptions = async (inputValue, loadedOptions) => {
    try {
      const token = props.auth.user.token;
      const response = await fetch(
        `/api/lead/search/` +
          inputValue +
          `?limit=10&offset=${loadedOptions.length}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const responseJSON = await response.json();
      return { options: responseJSON.result, hasMore: responseJSON.has_more };
    } catch (err) {}
  };

  const getTeamAgent = useCallback(async () => {
    try {
      setLoadingAgent(true);
      const token = props.auth.user.token;
      var teamValue = getValues("team");
      if (teamValue && teamValue._id) {
        teamValue = teamValue._id;
      }
      const response = await fetch(`/api/team/teamAgent/` + teamValue, {
        headers: {
          Authorization: token,
        },
      });
      const responseJSON = await response.json();
      if (responseJSON.members) {
        setAgents(responseJSON.members);
        const agentData = responseJSON.members.map((agent) => {
          return agent["_id"];
        });
        setTeamAgent(agentData);
        setValue("team_agent", agentData);
      }
    } catch (err) {}
    setLoadingAgent(false);
  }, [getValues, props.auth.user.token, setValue]);

  const getAgent = () => {
    if (loadingAgent) {
      return (
        <div className="loader_class">
          <Loader />
        </div>
      );
    }
    return (
      <Col xs="9">
        <Row style={{ marginBottom: "15px" }}>
          {agents.length > 0 ? (
            agents.map((agent, index) => {
              const item = agent;
              return (
                <Col xs="4" key={index}>
                  <div className="agent_filter">
                    <label className="container_checkbox">
                      {item.first + " " + item.last}
                      <Input
                        type="checkbox"
                        checked={teamAgent.includes(item._id)}
                        onChange={(value) => {
                          if (teamAgent.includes(item._id)) {
                            const data = teamAgent.filter(
                              (value) => value !== item._id
                            );
                            setValue("team_agent", data);
                            setTeamAgent(data);
                          } else {
                            const data = [...teamAgent, item._id];
                            setValue("team_agent", data);
                            setTeamAgent(data);
                          }
                        }}
                      />{" "}
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </Col>
              );
            })
          ) : (
            <div className="agent_filter" style={{ height: "50px" }}>
              No Agent Found
            </div>
          )}
        </Row>
      </Col>
    );
  };
  const defaultSetValue = useCallback(() => {
    //setWhen(false);
    setValue("watch", false);
    // Set settings tab
    props.leadCall === "team" ? toggle("2") : toggle("3");
    setFilterTeam(false);
    setValue("team_agent", []);
    setValue("filter_team_agent", false);
    setTeamAgent([]);

    clearError("message");

    if (props.team) {
      //team
      if (filterTeam) {
        getTeamAgent();
      }
      // Set Team settings
      setValue("callMode", settings.callMode);
      setValue("recordCall", settings.recordCall);
      if (settings.callerid) {
        setValue("callerid", settings.callerid);
      }
      setValue("whisperText", settings.whisperText);
      clearError("whisperText");
      setValue("whisperLanguage", settings.whisperLanguage);
      setValue("whisperTextLead", settings.whisperText);
      clearError("whisperTextLead");
      setValue("whisperLanguageLead", settings.whisperLanguage);
      setValue("reschedule", settings.reschedule);
      setValue("callback_url", settings.callback_url);
      setValue("optOut", settings.optOut);

      if (settings.recording) {
        setValue("recording", settings.recording);
        setRecording(settings.recording);
        clearError("recording");
      }
      if (settings.voicemail) {
        setValue("voicemail", settings.voicemail._id);
        setVoicemail(settings.voicemail);
      }
    }
  }, [
    props.team,
    props.leadCall,
    clearError,
    filterTeam,
    getTeamAgent,
    setValue,
    toggle,
    settings,
  ]);

  // const options = [];
  const setInitial = useCallback(() => {
    if (isSetDefaultValue) {
      return null;
    }
    setDefaultValue(true);
    register({ name: "country" });
    register({ name: "local" });
    register({ name: "team_agent" });
    register({ name: "filter_team_agent" });
    register({ name: "voicemail" });
    if (props.leadCall === "lead" && props.lead) {
      setValue("type", "Lead");
      //setLead(props.lead.label);
      setValue("lead", props.lead);
      if (props.team) {
        setValue("team", props.team);
        setTeam(props.team);
      }
      defaultSetValue();
    } else if (props.leadCall === "team" && props.lead) {
      setValue("type", "Team");
      setValue("lead", props.lead);
      //setLead(props.lead.label);
      if (props.team) {
        setValue("team", props.team);
        setTeam(props.team);
      }
      defaultSetValue();
    }
  }, [
    register,
    props.lead,
    props.leadCall,
    props.team,
    setValue,
    defaultSetValue,
    isSetDefaultValue,
    setDefaultValue,
  ]);
  useEffect(() => {
    setInitial();
  }, [setInitial]);
  const showSegment01 = watch("type", "");
  const showPhone = watch("national", "");
  const showTeam = watch("team", "");
  const showLead = watch("lead", "");
  const showWhen = watch("when", false);
  const getTimeZone = () => {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  };
  const submitFormAdd = (form) => {
    setLoading(true);
    if (form.type === "New" || form.type === "Lead") {
      form.whisperText = form.whisperTextLead;
      form.whisperLanguage = form.whisperLanguageLead;
    }

    form.source = "Dashboard";
    if (form.team && form.team._id) {
      form.team = form.team._id;
    }
    if (form.lead && form.lead.value) {
      form.lead = form.lead.value;
    }
    if (form.recording && form.recording._id) {
      form.recording = form.recording._id;
    }

    if (form.callerid === "agent") {
      form.calleridType = "agent";
      form.callerid = null;
    } else {
      form.calleridType = "static";
    }
    if (form.startTime) {
      form.startTime = new Date(
        form.startTime + " " + getTimeZone()
      ).toUTCString(); /*Datetime.moment().format("Z")*/
    }
    axios({
      method: "post",
      responseType: "json",
      url: `/api/call/`,
      data: form,
    })
      .then((item) => {
        setLoading(false);
        const boolTeam =
          props.ownerRecord &&
          props.ownerRecord.members &&
          (props.ownerRecord.members.length === 0 ||
            !item.data.team ||
            !props.ownerRecord.members.includes(item.data.team._id));
        if (
          !(
            props.auth.user.role === "agent" &&
            props.ownerRecord &&
            props.ownerRecord.agentPermission === "team" &&
            boolTeam
          )
        ) {
          // Only run this when on the Calls Page otherwise skip
          if (props.addItemToState) {
            props.addItemToState(item.data);
          }
        }
        props.toggle();
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Call has been created sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        //console.log(err, "err=======================");
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Call",
          toastrOptions
        );

        if (err.response && err.response.data) {
          if (err.response.data.error) {
            return setError(
              err.response.data.error.field,
              "duplicate",
              err.response.data.error.message
            );
          } else {
          }
        }
      });
  };

  const getLabel = () => {
    if (
      (getValues("team") !== "" || props.leadCall === "team") &&
      getValues("type") === "Team"
    ) {
      return (
        <>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
          >
            What{" "}
            <Badge color="success" pill>
              Lead
            </Badge>{" "}
            should we call after a{" "}
            <Badge color="danger" pill>
              Team
            </Badge>{" "}
            member answers?
          </Label>
        </>
      );
    } else if (getValues("type") === "Lead") {
      return (
        <>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
          >
            Which{" "}
            <Badge color="success" pill>
              Lead
            </Badge>{" "}
            should we call?
          </Label>
        </>
      );
    }
  };

  const getTeamField = () => {
    return (
      <>
        {" "}
        <FormGroup>
          <Label
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
            for="team"
          >
            {getValues("type") === "Team" ? (
              <>
                What{" "}
                <Badge color="danger" pill>
                  Team
                </Badge>{" "}
                should we call?{" "}
              </>
            ) : (
              <>
                What{" "}
                <Badge color="danger" pill>
                  Team
                </Badge>{" "}
                should we call after the{" "}
                <Badge color="success" pill>
                  Lead
                </Badge>{" "}
                answers and dials 1?
              </>
            )}
          </Label>
          <Row>
            <Col xs="8">
              <Controller
                control={control}
                as={(props) => {
                  return (
                    <Select
                      //    className="basic-single"
                      className={classNames({
                        error: errors.team,
                        "basic-single": true,
                      })}
                      classNamePrefix="select"
                      isSearchable
                      isClearable
                      options={getTeam()}
                      {...props}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option._id}`}
                    />
                  );
                }}
                onChange={(optionSelected) => {
                  const selected =
                    optionSelected && optionSelected[0]
                      ? optionSelected[0]
                      : null;
                  if (selected) {
                    /*if (
                      props.auth.user.role === "agent" &&
                      props.ownerRecord &&
                      props.ownerRecord.agentPermission === "team" &&
                      (!selected ||
                        props.ownerRecord.members.length === 0 ||
                        !props.ownerRecord.members.includes(selected._id))
                    ) {
                      Swal.fire(
                        "",
                        "Warning: You are about to reassign the lead to a team that you are not part of. This will make the lead visible to members of that team and you will loose access to the lead."
                      );
                    }*/
                    setTeam(selected);
                    setValue("team", selected);
                    setValue("filter_team_agent", false);

                    if (filterTeam) {
                      getTeamAgent();
                    }

                    // Set Team settings
                    setValue("callMode", selected.settings.callMode);
                    setValue("recordCall", selected.settings.recordCall);
                    if (selected.settings.callerid) {
                      setValue("callerid", selected.settings.callerid);
                    }
                    setValue("whisperText", selected.settings.whisperText);
                    setValue(
                      "whisperLanguage",
                      selected.settings.whisperLanguage
                    );
                    setValue("whisperTextLead", selected.settings.whisperText);
                    clearError("whisperTextLead");
                    setValue(
                      "whisperLanguageLead",
                      selected.settings.whisperLanguage
                    );
                    setValue("reschedule", selected.settings.reschedule);
                    setValue("callback_url", selected.settings.callback_url);
                    setValue("optOut", selected.settings.optOut);

                    if (selected.settings.recording) {
                      setValue("recording", selected.settings.recording);
                      setRecording(selected.settings.recording);
                      clearError("recording");
                    }
                    if (selected.settings.voicemail) {
                      setValue("voicemail", selected.settings.voicemail._id);
                      setVoicemail(selected.settings.voicemail);
                    } else {
                      setValue("voicemail", "");
                      setVoicemail("");
                    }
                    clearError("message");

                    return selected;
                  } else {
                    setTeam("");
                    setValue("filter_team_agent", false);
                    setFilterTeam(false);
                    return "";
                  }
                }}
                name="team"
                rules={{ required: true }}
              />
            </Col>

            {team ? (
              <Col xs="4">
                <label className="container_checkbox">
                  Filter Team Member
                  <Input
                    type="checkbox"
                    checked={filterTeam}
                    onChange={() => {
                      setValue("filter_team_agent", !filterTeam);
                      setValue("team_agent", []);
                      setFilterTeam(!filterTeam);
                      setTeamAgent([]);
                      if (!filterTeam) {
                        getTeamAgent();
                      }
                    }}
                  />{" "}
                  <span className="checkmark"></span>
                </label>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          {errors.team && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              A Team is required
            </div>
          )}
        </FormGroup>
        {filterTeam ? getAgent() : <></>}
      </>
    );
  };

  const getCallType = (calltype) => {
    if (calltype === "Lead")
      return (
        <Alert
          style={{
            color: "#0c5460",
            backgroundColor: "#d1ecf1",
            borderColor: "#bee5eb",
          }}
        >
          This will call a{" "}
          <Badge color="success" pill>
            Lead
          </Badge>{" "}
          currently in the system and play your recorded message. The{" "}
          <Badge color="success" pill>
            Lead
          </Badge>{" "}
          can dial 1 to be connected with a{" "}
          <Badge color="danger" pill>
            Team
          </Badge>{" "}
          of your choice.
        </Alert>
      );
    if (calltype === "New")
      return (
        <Alert
          style={{
            color: "#0c5460",
            backgroundColor: "#d1ecf1",
            borderColor: "#bee5eb",
          }}
        >
          This will call a{" "}
          <Badge color="success" pill>
            Lead
          </Badge>{" "}
          not in the system and play your recorded message. The{" "}
          <Badge color="success" pill>
            Lead
          </Badge>{" "}
          can dial 1 to be connected with a{" "}
          <Badge color="danger" pill>
            Team
          </Badge>{" "}
          or agent of your choice.
        </Alert>
      );

    if (calltype === "Team")
      return (
        <Alert
          style={{
            color: "#0c5460",
            backgroundColor: "#d1ecf1",
            borderColor: "#bee5eb",
          }}
        >
          This will call a{" "}
          <Badge color="danger" pill>
            Team
          </Badge>{" "}
          , read whisper text and allow the{" "}
          <Badge color="danger" pill>
            Team
          </Badge>{" "}
          member who answers to connect with the{" "}
          <Badge color="success" pill>
            Lead
          </Badge>{" "}
          when they are ready.
        </Alert>
      );

    return <></>;
  };
  const renderInput = (props, openCalendar, closeCalendar) => {
    return (
      <div className="input-group date" id="datetimepicker1">
        <Input
          type="text"
          {...props}
          name="startTime"
          className={classnames({ error: errors.startTime })}
          innerRef={register({ required: true })}
        />
        <span className="input-group-addon input-group-append">
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={openCalendar}
          >
            <span className="fa fa-calendar"></span>
          </button>
        </span>
      </div>
    );
  };
  const checkMinutes = (currentDate, timeStamp) => {
    return timeStamp.format("HH:ss") > currentDate.format("HH:ss");
  };
  const getTimeStampData = (timeStamp) => {
    const currentDate = Datetime.moment();
    if (
      currentDate.format("YYYY-MM-DD") === timeStamp.format("YYYY-MM-DD") &&
      timeStamp
    ) {
      if (checkMinutes(currentDate, timeStamp)) {
        return timeStamp;
      } else if (currentDate.minutes() !== 0) {
        return currentDate.minutes() < 30
          ? currentDate.add(30 - currentDate.minutes(), "minutes")
          : currentDate.add(60 - currentDate.minutes(), "minutes");
      }
      return currentDate;
    } else {
      return timeStamp;
    }
  };
  const getWhenField = () => {
    return (
      <>
        <FormGroup>
          <Row>
            <Col md="4">
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",

                  fontWeight: "600",
                }}
              >
                When should the call Start?
              </Label>
              <span
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  marginTop: "15px",
                }}
              >
                Now
              </span>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  placeholder="when"
                  name="when"
                  id="when"
                  onChange={() => {
                    if (getValues("when")) {
                      const currenttime = getTimeStampData(Datetime.moment());
                      setDateTime(currenttime);
                      setValue("startTime", currenttime);
                    } else {
                      setDateTime("");
                      setValue("startTime", "");
                    }
                    return true;
                  }}
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>{" "}
              <span style={{ paddingLeft: "10px" }}>Later</span>
            </Col>
            <Col
              md=""
              style={{
                paddingTop: "10px",
              }}
            >
              {showWhen === true ? (
                <>
                  <Datetime
                    isValidDate={(current) => {
                      return current.isAfter(
                        Datetime.moment().startOf("day") - 1
                      );
                    }}
                    timeFormat
                    value={dateTime}
                    name="startTime"
                    id="startTime"
                    style={{
                      display: "inline-block",
                      width: "250px !important",
                    }}
                    onChange={(date) => {
                      const dateValue = getTimeStampData(date);
                      setDateTime(dateValue);
                      setValue("startTime", dateValue);
                      clearError("startTime");
                    }}
                    innerRef={register({ required: true })}
                    timeConstraints={{ minutes: { step: 30 } }}
                    renderInput={renderInput}
                  />
                  {errors.startTime && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Date is required
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </FormGroup>

        {errors.message && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {errors.message.message}
          </div>
        )}
      </>
    );
  };

  const getLeadField = () => {
    return (
      <>
        {" "}
        {getLabel()}
        <FormGroup>
          <Controller
            as={(props) => {
              return (
                <AsyncPaginate
                  className={classnames({
                    error: errors.lead,
                    "basic-single": true,
                  })}
                  classNamePrefix="select"
                  isSearchable
                  isClearable
                  cacheOptions
                  {...props}
                  loadOptions={loadOptions}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                />
              );
            }}
            onChange={(optionSelected) => {
              clearError("message");

              const selected =
                optionSelected && optionSelected[0] ? optionSelected[0] : "";
              if (selected) {
                //setLead(selected.label);
                setValue("lead", selected);
              } else {
                //setLead("");
                setValue("lead", "");
              }
              return selected;
            }}
            name="lead"
            id="lead"
            control={control}
            rules={{ required: true }}
          />
          {errors.lead && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              A Lead is required
            </div>
          )}
        </FormGroup>
      </>
    );
  };
  const checkDefaultOpen = () => {
    const when = getValues("when");
    var bool = false;
    switch (getValues("type")) {
      case "Lead":
        bool =
          getValues("lead") &&
          getValues("team") &&
          (!when || (when && getValues("startTime"))) &&
          (errors.recording || errors.whisperTextLead || errors.callerid);
        break;
      case "New":
        bool =
          getValues("national") &&
          getValues("team") &&
          (!when || (when && getValues("startTime"))) &&
          (errors.recording || errors.whisperTextLead || errors.callerid);
        break;
      case "Team":
        bool =
          getValues("lead") &&
          getValues("team") &&
          (!when || (when && getValues("startTime"))) &&
          (errors.whisperText || errors.callerid);
        break;
      default:
        bool = false;
    }
    return Boolean(bool);
  };
  const getWhenFieldVisible = () => {
    return (
      (props.leadCall === "team" || props.leadCall === "lead") &&
      props.lead &&
      props.team &&
      !isResetDefaultValueData
    );
  };
  const getTeamFieldVisible = () => {
    return props.leadCall === "team" && props.lead && !isResetDefaultValueData;
  };
  const getLeadFieldVisible = () => {
    return props.leadCall === "lead" && props.lead && !isResetDefaultValueData;
  };
  const getSettings = (props) => {
    if (!isOpen && isClickCheckbox && checkDefaultOpen()) {
      setClickCheckbox(false);
      setIsOpen(true);
    }
    return (
      <>
        <div style={{ fontSize: "10px" }}>
          Call Settings are besed on the selected Team. Override these settings
          below.
        </div>{" "}
        <div
          onClick={() => {
            setIsOpen(!isOpen);
            setClickCheckbox(false);
            return true;
          }}
          style={{
            marginBottom: "1rem",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            display: "inline-block",
          }}
        >
          Change Call Settings
          <i
            className={"fas fa-caret-" + (isOpen ? "down" : "right")}
            style={{
              display: "inline-block",
              color: "black",
              textDecorationLine: "none",
              marginLeft: "5px",
            }}
          ></i>
        </div>
        <Collapse isOpen={isOpen}>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Common
              </NavLink>
            </NavItem>

            {getValues("type") === "Team" ? (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Teams
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {getValues("type") === "Lead" || getValues("type") === "New" ? (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Leads
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <h3 style={{ paddingBottom: "25px", paddingTop: "15px" }}>
                    Settings for All Calls.
                  </h3>
                  {Object.keys(errors).length > 0 &&
                    !(Object.keys(errors).length === 1 && errors.message) && (
                      <div
                        style={{
                          display: "block",
                          color: "#fb6340",
                          marginTop: "1px !important",
                        }}
                      >
                        <Alert color="danger">
                          Required fields may be missing on another tab.
                        </Alert>
                      </div>
                    )}

                  <FormGroup>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "0rem",
                        fontWeight: "600",
                      }}
                    >
                      Call Routing
                    </label>
                    <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      Round Robin
                    </span>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        placeholder="callMode"
                        name="callMode"
                        ref={register()}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>{" "}
                    <span style={{ paddingLeft: "10px" }}>Simultaneous</span>
                  </FormGroup>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Call Recording
                  </label>
                  <FormGroup>
                    <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      Off
                    </span>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        placeholder="recordCall"
                        name="recordCall"
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        ref={register()}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                    <span style={{ paddingLeft: "10px" }}>On</span>
                  </FormGroup>

                  <FormGroup style={{ marginTop: "20px" }}>
                    <Label
                      for="callerid"
                      style={{
                        display: "block",
                        marginBottom: "0rem",
                        fontWeight: "600",
                      }}
                    >
                      Lead-facing Caller ID
                    </Label>
                    <FormText>
                      The Caller ID a Lead will see when recieving a call.
                    </FormText>
                    <Input
                      type="select"
                      name="callerid"
                      id="callerid"
                      innerRef={register({ required: true })}
                    >
                      <option key="agent" value="agent">
                        The Agent's Phone Number
                      </option>
                      {props.callerids.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}: {item.national}
                        </option>
                      ))}
                    </Input>
                    {errors.callerid && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Caller ID is required
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="callback_url"
                      style={{
                        display: "inline-block",
                        marginBottom: "0rem",
                        fontWeight: "600",
                      }}
                    >
                      Callback URL
                    </Label>

                    <FormText>
                      A webhook URL to send call completed events after the call
                    </FormText>

                    <Input
                      type="text"
                      name="callback_url"
                      id="callback_url"
                      innerRef={register()}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>

            {getValues("type") === "Team" ||
            (props.leadCall === "team" && getValues("type") === "") ? (
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <h3 style={{ paddingTop: "15px" }}>
                      Settings for calls targeting a Team first.
                    </h3>

                    {Object.keys(errors).length > 0 && (
                      <div
                        style={{
                          display: "block",
                          color: "#fb6340",
                          marginTop: "1px !important",
                        }}
                      >
                        <Alert color="danger">
                          Required fields may be missing on another tab.
                        </Alert>
                      </div>
                    )}

                    <FormGroup>
                      <Label
                        for="whisperText"
                        style={{
                          paddingTop: "25px",
                          display: "inline-block",
                          marginBottom: "0rem",
                          fontWeight: "600",
                        }}
                      >
                        Whisper Text
                      </Label>

                      <FormText>
                        <b>Spoken to Agents before connecting to a lead.</b>
                        <br></br>
                        You can include the following values from the lead
                        record:<br></br> {"{first}"} {"{last}"} {"{company}"}{" "}
                        {"{category}"} {"{comments}"}
                      </FormText>
                      <Input
                        type="textarea"
                        name="whisperText"
                        id="whisperText"
                        innerRef={register({ required: true })}
                      />
                      {errors.whisperText && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Whisper Text is required
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup style={{ marginTop: "20px" }}>
                      <Label
                        for="whisperLanguage"
                        style={{
                          display: "block",
                          marginBottom: "0rem",
                          fontWeight: "600",
                        }}
                      >
                        Whispertext Language
                      </Label>

                      <Input
                        type="select"
                        name="whisperLanguage"
                        id="whisperLanguage"
                        innerRef={register({ required: true })}
                      >
                        <option value="en-US">English</option>
                        <option value="es-ES">Spanish</option>
                        <option value="fr-FR">French</option>
                        <option value="it-IT">Italian</option>
                        <option value="de-DE">German</option>
                        <option value="nl-NL">Dutch</option>
                        <option value="pt-BR">Portuguese</option>
                      </Input>
                      {errors.whisperLanguage && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Whispertext Language is required
                        </div>
                      )}
                    </FormGroup>

                    <label
                      style={{
                        display: "block",
                        marginBottom: "0rem",
                        fontWeight: "600",
                      }}
                    >
                      Reschedule Call If Team Offline
                    </label>

                    <FormText>
                      Reschedule the call for the first available time slot
                    </FormText>

                    <FormGroup>
                      <span
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      >
                        Off
                      </span>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          placeholder="reschedule"
                          name="reschedule"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          ref={register()}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                      <span
                        style={{ paddingLeft: "10px", paddingRight: "35px" }}
                      >
                        On
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
            ) : null}

            {getValues("type") !== "Team" ? (
              <TabPane tabId="3">
                {" "}
                <Row>
                  <Col sm="12">
                    <h3 style={{ paddingBottom: "25px", paddingTop: "15px" }}>
                      Settings for calls targeting a Lead first.
                    </h3>
                    <FormGroup>
                      {getValues("type") !== "" ? (
                        <Row>
                          <Col xs="12" lg="7">
                            <label
                              style={{
                                display: "block",
                                marginBottom: "0rem",
                                fontWeight: "600",
                              }}
                            >
                              Recorded Message
                            </label>
                            <Controller
                              as={
                                <Select
                                  isClearable
                                  options={props.recordings.message}
                                  closeMenuOnSelect={true}
                                  value={recording ? recording : null}
                                  getOptionLabel={(option) => `${option.name}`}
                                  getOptionValue={(option) => `${option._id}`}
                                  //      innerRef={register({ required: true })}
                                />
                              }
                              onChange={(selectedOptions) => {
                                if (selectedOptions && selectedOptions[0]) {
                                  setRecording(selectedOptions[0]);
                                  return selectedOptions[0];
                                } else {
                                  setRecording("");
                                  return null;
                                }
                              }}
                              name="recording"
                              id="recording"
                              control={control}
                              rules={{ required: true }}
                            />
                            {errors.recording && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                An Message Recording is required
                              </div>
                            )}
                          </Col>
                          <Col xs="12" lg="5" className="audio_col">
                            {recording._id ? (
                              <audio
                                src={recording.fileName}
                                controls="controls"
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      {getValues("type") !== "" ? (
                        <Row>
                          <Col xs="12" lg="7">
                            <label
                              style={{
                                display: "block",
                                marginBottom: "0rem",
                                fontWeight: "600",
                              }}
                            >
                              Voicemail
                            </label>
                            <Select
                              name="voicemail"
                              id="voicemail"
                              isClearable
                              options={props.recordings.voicemail}
                              closeMenuOnSelect={true}
                              onChange={(selectedOptions) => {
                                if (selectedOptions) {
                                  setValue("voicemail", selectedOptions._id);
                                  setVoicemail(selectedOptions);
                                } else {
                                  setValue("voicemail", "");
                                  setVoicemail("");
                                }
                              }}
                              value={voicemail ? voicemail : null}
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => `${option._id}`}
                              innerRef={register({ required: false })}
                            />
                          </Col>
                          <Col xs="12" lg="5" className="audio_col">
                            {voicemail._id ? (
                              <audio
                                src={voicemail.fileName}
                                controls="controls"
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="whisperTextLead"
                        style={{
                          display: "inline-block",
                          marginBottom: "0rem",
                          fontWeight: "600",
                        }}
                      >
                        Whisper Text
                      </Label>

                      <FormText>
                        <b>Spoken to Agents before connecting to a lead.</b>
                        <br></br>
                        You can include the following values from the lead
                        record:<br></br> {"{first}"} {"{last}"} {"{company}"}{" "}
                        {"{category}"} {"{comments}"}
                      </FormText>
                      <Input
                        type="textarea"
                        name="whisperTextLead"
                        id="whisperTextLead"
                        innerRef={register({ required: true })}
                      />
                      {errors.whisperTextLead && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Whisper Text is required
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup style={{ marginTop: "20px" }}>
                      <Label
                        for="whisperLanguageLead"
                        style={{
                          display: "block",
                          marginBottom: "0rem",
                          fontWeight: "600",
                        }}
                      >
                        Whispertext Language
                      </Label>

                      <Input
                        type="select"
                        name="whisperLanguageLead"
                        id="whisperLanguageLead"
                        innerRef={register({ required: true })}
                      >
                        <option value="en-US">English</option>
                        <option value="es-ES">Spanish</option>
                        <option value="fr-FR">French</option>
                        <option value="it-IT">Italian</option>
                        <option value="de-DE">German</option>
                        <option value="nl-NL">Dutch</option>
                        <option value="pt-BR">Portuguese</option>
                      </Input>
                      {errors.whisperLanguageLead && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Whispertext Language is required
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <div className="agent_filter">
                        <label className="container_checkbox">
                          Include an otp out message
                          <Input
                            type="checkbox"
                            name="optOut"
                            id="optOut"
                            innerRef={register({ required: false })}
                          />{" "}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
            ) : null}
          </TabContent>
        </Collapse>
      </>
    );
  };
  const isValueAvailable = () => {
    return showPhone.length > 0;
  };
  const firstCall = () => {
    setTeam("");
    setValue("team", "");

    //setLead("");
    setValue("lead", "");

    //setWhen(false);
    setValue("watch", false);

    // Set settings tab
    getValues("type") === "Team" ? toggle("2") : toggle("3");

    setFilterTeam(false);
    setValue("team_agent", []);
    setValue("filter_team_agent", false);
    setTeamAgent([]);
    resetDefaultValueData(true);
  };

  if (props.billing.payload.stripePlanId) {
    var boolBillingBasic = props.billing.payload.stripePlanId.includes("basic");
  }

  var disableProps = {};
  if (boolBillingBasic) {
    disableProps = {
      title: "Upgrade to Assist MAX to activate Lead Calling",
    };
  }
  return (
    <>
      <form onSubmit={handleSubmit(submitFormAdd)}>
        {" "}
        <Row>
          {" "}
          <Col md="4">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="type"
              >
                Who should we call first?
              </Label>
              <Input
                type="select"
                name="type"
                id="type"
                className={classnames({ error: errors.type })}
                style={{ display: "inline-block", width: "190px" }}
                onChange={firstCall}
                defaultValue={"Team"}
                innerRef={register({ required: true })}
              >
                <option value="Team">Team</option>
                <option
                  value="Lead"
                  {...disableProps}
                  disabled={boolBillingBasic}
                >
                  {boolBillingBasic
                    ? "Existing Lead (upgrade for this feature)"
                    : "Existing Lead"}
                </option>
                <option
                  value="New"
                  {...disableProps}
                  disabled={boolBillingBasic}
                >
                  {boolBillingBasic
                    ? "New Lead (upgrade for this feature)"
                    : "New Lead"}
                </option>
              </Input>
              {errors.type && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  You must select who to call first
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="8">
            {getCallType(getValues("type"))}
            <div></div>
          </Col>
        </Row>
        {(showSegment01 === "Team" || getTeamFieldVisible()) && (
          <>{getTeamField()}</>
        )}
        {((showTeam !== "" && showSegment01 === "Team") ||
          getTeamFieldVisible()) && <>{getLeadField()}</>}
        {(showSegment01 === "Lead" || getLeadFieldVisible()) && (
          <>{getLeadField()}</>
        )}
        {((showSegment01 === "Lead" && showLead) || getLeadFieldVisible()) && (
          <>{getTeamField()} </>
        )}
        {showSegment01 === "New" && (
          <>
            <Row>
              {" "}
              <Col md="12">
                <Label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Who should we call?
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    name="first"
                    id="first"
                    placeholder="First Name"
                    className={classnames({ error: errors.first })}
                    innerRef={register({ required: false })}
                    onChange={() => {}}
                  />

                  {errors.first && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      First name is required
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    name="last"
                    id="last"
                    placeholder="Last Name"
                    className={classnames({ error: errors.last })}
                    innerRef={register({ required: false })}
                    onChange={() => {}}
                  />{" "}
                  {errors.last && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Last name is required
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
              for="national"
            >
              Phone
            </Label>
            <Controller
              as={
                <PhoneInput
                  style={{}}
                  enableSearch={true}
                  disableInitialCountryGuess={false}
                  jumpCursorToEnd={true}
                  autoFormat={false}
                  country={"us"}
                  preferredCountries={["us", "ca", "uk"]}
                  regions={["north-america", "europe"]}
                  placeholder="Enter phone number"
                  isValid={(value, country, countries) => {
                    return countries.some((country) => {
                      return (
                        startsWith(value, country.dialCode) ||
                        startsWith(country.dialCode, value)
                      );
                    });
                  }}
                  defaultErrorMessage={""}
                  //     value={getPhone(props)}
                />
              }
              onChange={(data) => {
                if (data.length >= 2) {
                  const phone = data[0];
                  const country = data[1];
                  if (country) {
                    setValue("national", phone);
                    setValue("country", country);
                    setValue("local", phone.slice(country.dialCode.length));
                  }
                  return phone;
                }
              }}
              name="national"
              id="national"
              control={control}
              rules={{ required: true }}
            />
            <div style={{ paddingBottom: "15px" }}>
              {errors.national && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.national.message.includes("A lead name")
                    ? errors.national.message
                    : "Phone number is Required"}
                </div>
              )}
            </div>
          </>
        )}
        {showSegment01 === "New" && isValueAvailable() && <>{getTeamField()}</>}
        {/*showLead !== "" && showSegment01 === "New" && <>{getTeamField()}</>*/}
        {(showSegment01 === "Team" && getValues("lead") && getValues("team")) ||
        (((showSegment01 === "Lead" && getValues("lead")) ||
          showSegment01 === "New") &&
          getValues("team")) ||
        getWhenFieldVisible() ? (
          getWhenField()
        ) : (
          <></>
        )}
        <hr></hr>
        <Row>
          <Col md="9">
            <div>
              {getValues("type") !== "" ||
              props.leadCall === "lead" ||
              props.leadCall === "team"
                ? getSettings(props)
                : ""}
            </div>{" "}
          </Col>
          <Col md="3">
            {loading ? (
              <Button
                color="primary"
                type="button"
                style={{ float: "right" }}
                disabled={true}
              >
                Calling...
              </Button>
            ) : (
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={() => {
                  setClickCheckbox(true);
                  if (checkDefaultOpen()) {
                    if (errors.callerid) {
                      toggle("1");
                    } else {
                      getValues("type") === "Team" ? toggle("2") : toggle("3");
                    }
                  }
                  return true;
                }}
                //     onClick={() => triggerValidation()}
              >
                Make Call
              </Button>
            )}{" "}
          </Col>
        </Row>
      </form>
    </>
  );
}
FormCall.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  teamDSDropdown: state.teams.teamDSDropdown,
  recordings: state.recordings.recordingByType,
  callerids: state.numbers.numberDropdown,
  billing: state.billing,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormCall);
