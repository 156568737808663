import {
  LOAD_BILLING,
  SET_BILLING,
  BUTTON_LOAD_BILLING,
  BUTTON_LOAD_BILLING_OFF,
  UPDATE_BILLING,
} from "../actions/types";

const initialState = {
  payload: false,
  loading: true,
  error: {},
  loadingButton: false,
};

export default function (state = initialState, action) {
  //  const { type, payload } = action;

  switch (action.type) {
    case LOAD_BILLING:
      return {
        ...state,
        loading: true,
      };
    case SET_BILLING:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case UPDATE_BILLING:
      return {
        payload: {
          // Copy the whole state
          ...state.payload,
          ...action.payload,
        },
        loading: false,
      };

    case BUTTON_LOAD_BILLING:
      return {
        ...state,
        loadingButton: true,
      };
    case BUTTON_LOAD_BILLING_OFF:
      return {
        ...state,
        loadingButton: false,
      };
    default:
      return state;
  }
}
