import React from "react";
import {} from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { attemptSendResetPasswordLink } from "../../../actions/authActions";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

// core components
import AuthHeader from "../../../components/Headers/AuthHeader.js";

class Forgot extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
      submited: false,
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        submited: nextProps.submited,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    try {
      var res = await this.props.attemptSendResetPasswordLink(
        data,
        this.props.history
      );
      this.setState({ loading: false });
      if (res.status === 200) {
        this.setState({ submited: true });
      }
    } catch (error) {
      this.setState({ error: "Sorry, something went wrong." });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <React.Fragment>
        {" "}
        <AuthHeader
          title="Recover Password"
          lead="Use this form if you have forgot your password. We will send you a password reset email to help."
        />
        {!this.state.submited && (
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form noValidate onSubmit={this.onSubmit}>
                      <FormGroup>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            placeholder="Email"
                            type="email"
                            id="email"
                            className={classnames("", {
                              invalid: errors.email,
                            })}
                          />
                        </InputGroup>
                        <span
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.email}
                        </span>
                        <span
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.message}
                        </span>
                      </FormGroup>
                      <div className="text-center">
                        {this.state.loading ? (
                          <Button
                            className="my-4"
                            color="info"
                            type="button"
                            disabled={true}
                          >
                            Sending...
                          </Button>
                        ) : (
                          <Button className="my-4" color="info" type="submit">
                            Recover Password
                          </Button>
                        )}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link to="./login" className="text-light">
                      <small>Login</small>
                    </Link>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link to="./register" className="text-light">
                      <small>Create new account</small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
        {this.state.submited && (
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="container">
                      <strong>A reset link has been sent to: </strong>
                      {this.state.email}. You have 12 hours to reset your
                      password. It may take up to 15 min to receive our email.
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

Forgot.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  submited: state.submited,
});

export default connect(mapStateToProps, { attemptSendResetPasswordLink })(
  Forgot
);
