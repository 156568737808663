import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Row, Col, Container } from "reactstrap";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import ModalAdmin from "./ModalAdmin";

import moment from "moment";
import { Table, DefaultColumnFilter, DateFilter } from "../components/Table";
import Loader from "layouts/Loader.js";

function Admin(props) {
  //const [curentAction, setCurentAction] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getItems = React.useCallback((token, abortController) => {
    setLoading(true);
    fetch("/api/admin/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        setLoading(false);
        if (items) {
          setItems(items);
        }
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token, getItems]);
  /*const addItemToState = (item) => {
    setItems([item, ...items]);
  };*/
  const updateState = useCallback(
    (item) => {
      const itemIndex = items.findIndex((data) => data._id === item._id);
      const newArray = [
        ...items.slice(0, itemIndex),
        item,
        ...items.slice(itemIndex + 1),
      ];
      setItems(newArray);
    },
    [items]
  );

  const deleteItemFromState = useCallback(
    (_id) => {
      const updatedItems = items.filter((item) => item._id !== _id);
      setItems(updatedItems);
    },
    [items]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Owner",
        accessor: (row) => {
          return [row.name, row._id];
        },
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          return (
            <>
              {item.name}
              <br></br>
              {item._id}
            </>
          );
        },
        responsiveClone: true,
        Filter: DefaultColumnFilter,
        disableSortBy: true,
        minWidth: 75,
      },
      {
        Header: "Created",
        responsiveClone: true,
        accessor: (r) => moment(r.created_at).valueOf(),
        Cell: ({ cell: { value } }) =>
          moment(value).format("ddd, D-MMM-YYYY, h:mm a"),
        Filter: DateFilter,
        filter: "between",
        minWidth: 75,
      },
      {
        Header: "Usage",
        responsiveClone: true,
        accessor: (row) => {
          return [
            "Agents " + row.agent,
            "Teams " + row.team,
            "Leads " + row.lead,
            "Calls " + row.call,
            "Recordings " + row.recording,
            "Numbers " + row.number,
            "Purchased " + row.billing.phoneQuantity,
          ];
        },
        Cell: ({ column, cell, row }) => {
          const item = row.original;

          return (
            <>
              <b>Agents</b>: {item.agent} <br></br> <b>Teams</b>: {item.team}{" "}
              <br></br> <b>Leads</b>: {item.lead} <br></br> <b>Calls</b>:{" "}
              {item.call} <br></br> <b>Recordings</b>: {item.recording}{" "}
              <br></br> <b>Numbers</b>: {item.number}
              <br></br> <b>Purchased</b>: {item.billing.phoneQuantity}
            </>
          );
        },
        Filter: DefaultColumnFilter,
        minWidth: 75,
      },
      {
        Header: "Billing",
        responsiveClone: true,
        accessor: (row) => {
          if (row.billing && row.setting) {
            return [
              "setupComplete " + row.billing.setupComplete,
              "accountStatus " + row.billing.accountStatus,
              "usageStatus " + row.billing.usageStatus,
              "methodStatus " + row.billing.methodStatus,
              "stripePlanId " + row.billing.stripePlanId,
              "billingDate " + row.billing.billingDate,
              "billingDate " + row.billing.billingDate,
              "trialEnd " + row.billing.trialEnd,
              "grandTotal " + row.billing.grandTotal,
              "stripeId " + row.billing.stripeId,
              "twilioSid " + row.setting.accountSid,
            ];
          } else {
            return [];
          }
        },
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          if (item.billing && item.setting) {
            return (
              <>
                <b>setupComplete</b>:{" "}
                {item.billing.setupComplete ? "Yes" : "No"}
                <br></br> <b>accountStatus</b>: {item.billing.accountStatus}
                <br></br> <b>usageStatus</b>: {item.billing.usageStatus}{" "}
                <br></br> <b>methodStatus</b>: {item.billing.methodStatus}{" "}
                <br></br> <b>planType</b>: {item.billing.stripePlanId} <br></br>{" "}
                <b>billingDate</b>: {item.billing.billingDate} <br></br>{" "}
                <b>trialEnd</b>: {item.billing.trialEnd} <br></br>{" "}
                <b>grandTotal</b>: {item.billing.grandTotal / 100} <br></br>{" "}
                <b>stripeId:</b> {item.billing.stripeId} <br></br>{" "}
                <b>accountSid:</b> {item.setting.accountSid}
              </>
            );
          } else {
            return [];
          }
        },
        Filter: DefaultColumnFilter,
        minWidth: 75,
      },
      {
        Header: " ",
        disableFilter: true,
        responsiveClone: false,
        actionCoumn: true,
        Cell: (props) => {
          return (
            <div style={{ float: "right" }}>
              <ModalAdmin
                buttonType="actions"
                buttonLabel="Edit"
                item={props.row.original}
                updateState={updateState}
                deleteItemFromState={deleteItemFromState}
                headerTitle="Edit Organization"
              />{" "}
            </div>
          );
        },
      },
    ],
    [deleteItemFromState, updateState]
  );
  return (
    <>
      <CardsHeader name="Admin" />{" "}
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12" className="lead_container">
                      {!loading ? (
                        <Table
                          name="Admin"
                          columns={columns}
                          data={items}
                          globalFilter={true}
                          filter={true}
                          paginate={true}
                          sort={true}
                          settings={[
                            { width: 882, column: "all" },
                            { width: 650, column: 5 },
                            { width: 580, column: 4 },
                            { width: 0, column: 3 },
                          ]}
                        />
                      ) : (
                        <>
                          <Loader />
                        </>
                      )}
                    </Col>
                  </Row>
                  <hr />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
Admin.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
