import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import Calls  from "./Calls"

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <CardsHeader name="Dashboards" />

        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Calls</h3>
                    </div>
                    <div className="col text-right">
                    <Link to="/account/calls" className="btn btn-primary btn-sm">
                        See All
                    </Link>
                    </div>
                  </Row>
                </CardHeader>
                <Calls settings = {
                    [ {"width": 882, "column" : "all" },
                    {"width": 570, "column" : 5 },
                    {"width": 448, "column" : 4 },
                    {"width": 0, "column" : 3 }]
                  }/>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
