import axios from "axios";

import {
  GET_ERRORS,
  LOAD_BILLING,
  SET_BILLING,
  BUTTON_LOAD_BILLING,
  BUTTON_LOAD_BILLING_OFF,
  UPDATE_BILLING,
} from "./types";

// Get Billing
export const getBilling = () => (dispatch) => {
  dispatch({
    type: LOAD_BILLING,
  });

  axios
    .get(`/api/billing/view/`)
    .then((res) => {
      dispatch({
        type: SET_BILLING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      //   return err;
    });
};

// Update User
export const addupdatePlan = (plan, type) => (dispatch) => {
  dispatch({
    type: BUTTON_LOAD_BILLING,
  });
  return axios
    .get("/api/billing/" + type + "/" + plan)
    .then((res) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });

      if (res.status === 200) {
        dispatch({
          type: UPDATE_BILLING,
          payload: res.data,
        });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });

      return error;
    });
};

// Change Plan
export const changePlan = (plan, type) => (dispatch) => {
  dispatch({
    type: BUTTON_LOAD_BILLING,
  });
  return axios
    .get("/api/billing/" + type + "/" + plan)
    .then((res) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });

      if (res.status === 200) {
        dispatch({
          type: UPDATE_BILLING,
          payload: res.data,
        });
        return res;
      }
    })
    .catch((error) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });

      return error;
    });
};

// Add Usage  User
export const addUsage = () => (dispatch) => {
  dispatch({
    type: BUTTON_LOAD_BILLING,
  });
  return axios
    .get("/api/billing/addusage/manual")
    .then((res) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });

      if (res.status === 200 && !res.data.error) {
        dispatch({
          type: UPDATE_BILLING,
          payload: res.data,
        });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      return error;
    });
};

// Cancel Plan
export const cancelPlan = () => (dispatch) => {
  dispatch({
    type: BUTTON_LOAD_BILLING,
  });
  return axios
    .get(`/api/billing/canres/cancel`)
    .then((res) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      if (res.status === 200) {
        var endDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
        endDate.setUTCSeconds(res.data.endDate);

        var canceledDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
        canceledDate.setUTCSeconds(res.data.canceledDate);

        dispatch({
          type: UPDATE_BILLING,
          payload: res.data,
        });

        return { cancel_at: endDate, canceled_at: canceledDate };
      } else {
        return { error: "Something went wrong" };
      }
    })
    .catch((error) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      return error;
    });
};

// Resume Plan
export const resumePlan = () => (dispatch) => {
  dispatch({
    type: BUTTON_LOAD_BILLING,
  });
  return axios
    .get("/api/billing/canres/resume")
    .then((res) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      if (res.status === 200) {
        dispatch({
          type: UPDATE_BILLING,
          payload: res.data,
        });

        return { accountStatus: "Active" };
      } else {
        return { error: "Something went wrong" };
      }
    })
    .catch((error) => {
      dispatch({
        type: BUTTON_LOAD_BILLING_OFF,
      });
      return error;
    });
};
