import React from "react";
import DateFilter from "./DateFilter";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Loader from "layouts/Loader";

class ReportInbound extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const startValue = new Date(date).setMonth(date.getMonth() - 1).valueOf();
    date.setHours(23, 59, 59, 999);
    const endValue = new Date(date).valueOf();
    this.state = {
      defautValue: [startValue, endValue],
      byDay: false,
      mainCount: false,
    };
  }
  async _callByAv(_data) {
    const _label = [];
    const _missed = [];
    const _completed = [];
    const _findingAgent = [];
    const _hungUp = [];
    const _inProgress = [];
    const _answered = [];
    const _agentFound = [];
    const _optOut = [];
    const _initiated = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _missed.push(_byday.missed);
      _completed.push(_byday.completed);
      _findingAgent.push(_byday.findingAgent);
      _hungUp.push(_byday.hungUp);
      _inProgress.push(_byday.inProgress);
      _answered.push(_byday.answered);
      _agentFound.push(_byday.agentFound);
      _optOut.push(_byday.optOut);
      _initiated.push(_byday.initiated);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Missed",
          backgroundColor: "rgba(230, 126, 34, 0.2)",
          borderColor: "rgba(230, 126, 34, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(230, 126, 34, 0.4)",
          hoverBorderColor: "rgba(230, 126, 34, 1)",
          data: _missed,
        },
        {
          label: "Completed",
          backgroundColor: "rgba(240, 255, 0, 0.2)",
          borderColor: "rgba(240, 255, 0, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(240, 255, 0, 0.4)",
          hoverBorderColor: "rgba(240, 255, 0, 1)",
          data: _completed,
        },
        {
          label: "Finding Agent",
          backgroundColor: "rgba(246, 36, 89, 0.2)",
          borderColor: "rgba(246, 36, 89, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(246, 36, 89, 0.4)",
          hoverBorderColor: "rgba(246, 36, 89, 1)",
          data: _findingAgent,
        },
        {
          label: "Hung Up",
          backgroundColor: "rgba(77, 175, 124, 0.2)",
          borderColor: "rgba(77, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(77, 175, 124, 0.4)",
          hoverBorderColor: "rgba(77, 175, 124, 1)",
          data: _hungUp,
        },
        {
          label: "In-Progress",
          backgroundColor: "rgba(177, 175, 124, 0.2)",
          borderColor: "rgba(177, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(177, 175, 124, 0.4)",
          hoverBorderColor: "rgba(177, 175, 124, 1)",
          data: _inProgress,
        },
        {
          label: "Answered",
          backgroundColor: "rgba(277, 175, 124, 0.2)",
          borderColor: "rgba(277, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(277, 175, 124, 0.4)",
          hoverBorderColor: "rgba(277, 175, 124, 1)",
          data: _answered,
        },
        {
          label: "Agent Found",
          backgroundColor: "rgba(146, 36, 89, 0.2)",
          borderColor: "rgba(146, 36, 89, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(146, 36, 89, 0.4)",
          hoverBorderColor: "rgba(146, 36, 89, 1)",
          data: _agentFound,
        },
        {
          label: "Opt Out",
          backgroundColor: "rgba(146, 36, 89, 0.2)",
          borderColor: "rgba(146, 36, 89, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(146, 36, 89, 0.4)",
          hoverBorderColor: "rgba(146, 36, 89, 1)",
          data: _optOut,
        },
        {
          label: "Initiated",
          backgroundColor: "rgba(140, 255, 0, 0.2)",
          borderColor: "rgba(140, 255, 0, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(140, 255, 0, 0.4)",
          hoverBorderColor: "rgba(140, 255, 0, 1)",
          data: _initiated,
        },
      ],
    };
    this.setState({ byDay: chart });
  }
  getByDay(value) {
    this.setState({
      byDay: false,
    });
    var startVlaue =
      value && value[0] ? moment(value[0]).format("YYYY-MM-DD") : "";
    var endVlaue =
      value && value[1] ? moment(value[1]).format("YYYY-MM-DD") : "";
    var queryString = `start=` + startVlaue + `&end=` + endVlaue;
    if (startVlaue && endVlaue) {
      if (startVlaue > endVlaue) {
        queryString = `start=` + endVlaue + `&end=` + startVlaue;
      }
    }
    this.axiosCancelSource = axios.CancelToken.source();
    axios({
      method: "get",
      responseType: "json",
      url: `/api/call/report/inboundbyday?` + queryString,
      cancelToken: this.axiosCancelSource.token
    })
      .then(async (response) => {
        if (response.data) {
          this._callByAv(response.data[0]["callByDay"]);
          this.setState({ mainCount: response.data[0]["mainCount"][0] });
        }
      })
      .catch((err) => {
        if( err.message !== 'AXIOS_CANCEL') {
          Swal.fire({
            text: "Something went wrong. Try again.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  }
  componentDidMount() {
    this.getByDay(this.state.defautValue);
  }
  componentWillUnmount() {
    this.axiosCancelSource.cancel('AXIOS_CANCEL')
  }
  setFilterValue(value) {
    this.getByDay(value);
  }
  render() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const defautValue = [
      new Date(date).setMonth(date.getMonth() - 1).valueOf(),
      new Date(date).valueOf(),
    ];
    return (
      <>
        <SimpleHeader name="" parentName="Reports" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <div className="date_filter_header">
                      <h3 className="mb-0">Inbound</h3>
                      <div className="date_filter_div">
                        <DateFilter
                          defautValue={defautValue}
                          setFilterValue={this.setFilterValue.bind(this)}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <Col sm="6" md="4" xl="4" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Inbound Calls</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_calls
                              : "loading..."}{" "}
                            <i
                              className="fas fa-sign-in-alt text-green"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="4" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Leads Called In</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_lead_calls
                              : "loading..."}{" "}
                            <i
                              className="fa fas fa-users  text-info"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="4" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">AVG Call Length</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_avg_calls + " min"
                              : "loading..."}{" "}
                            <i
                              className="fas fa-hourglass-start text-purple"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">Inbound Calls by Day</CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byDay ? (
                            <Bar
                              data={this.state.byDay}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Number of Calls",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReportInbound;
