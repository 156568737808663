import axios from "axios";
import {
    LOAD_RECORDING_BY_TYPE_DATA,
    RECORDING_BY_TYPE_DATA,
    GET_ERRORS
  } from "./types";
export const recordingByTypeDropdown = () => async ( dispatch ) => {
  dispatch({
    type: LOAD_RECORDING_BY_TYPE_DATA
  });
  axios
    .get(`/api/recording/recording_by_type`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      dispatch({
        type: RECORDING_BY_TYPE_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
  });
}