import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
//import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded

import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

// core styles
import "assets/scss/dashboard-react.scss?v1.1.0";

//Date picker
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import App from "./app";
import configureStore from "./store";
import * as serviceWorker from "./serviceWorker";

require("react-datetime");
require("dotenv").config();

const history = createBrowserHistory();
const store = configureStore(history);
//   <Route path="/agent" render={(props) => <AgentLayout {...props} />} />
//
ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
