import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Row, Col, Container, ModalFooter } from "reactstrap";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import ConditionalWrapper from "../components/ConditionalWrapper.js";
import ModalRecording from "./ModalRecording";
import ModalTexttospeech from "./ModalTexttospeech";
import TableRecording from "./TableRecording";
import { recordingByTypeDropdown } from "./../../../actions/recordingAction";
import { teamDropdownSettings } from "./../../../actions/teamAction";
import Loader from "layouts/Loader.js";

function Recordings(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getItems = (token, abortController) => {
    setLoading(true);
    fetch("/api/recording/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        if (items) {
          setItems(items);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const addItemToState = (item) => {
    setItems([...items, item]);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["recording"] += 1;
      props.setStepList(stepList);
    }
    props.recordingByTypeDropdown();
    props.teamDropdownSettings();
  };

  const updateState = (item) => {
    const itemIndex = items.findIndex((data) => data._id === item._id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
    props.recordingByTypeDropdown();
  };

  const deleteItemFromState = (_id) => {
    const updatedItems = items.filter((item) => item._id !== _id);
    setItems(updatedItems);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["recording"] -= 1;
      props.setStepList(stepList);
    }
    props.recordingByTypeDropdown();
    props.teamDropdownSettings();
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token]);

  return (
    <>
      {props.handleSkipButton ? "" : <CardsHeader name="Recordings" />}
      <ConditionalWrapper
        condition={!props.handleSkipButton}
        wrapper={(children) => (
          <>
            {" "}
            <Container className="mt--6" fluid>
              <Row>
                <div className="col">
                  <div className="card-wrapper">{children}</div>
                </div>
              </Row>
            </Container>
          </>
        )}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg="12">
                {!loading ? (
                  <>
                    <ModalRecording
                      buttonType="add"
                      buttonLabel="Add Recording"
                      addItemToState={addItemToState}
                      item={{}}
                      headerTitle="Add Recording"
                    />
                    <ModalTexttospeech
                      buttonType="add"
                      buttonLabel="Add Text to Speech"
                      addItemToState={addItemToState}
                      item={{}}
                      headerTitle="Add Text to Speech"
                    />
                    <TableRecording
                      items={items}
                      updateState={updateState}
                      deleteItemFromState={deleteItemFromState}
                      hideColumn={props.handleSkipButton}
                    />
                  </>
                ) : (
                  <>
                    <Loader />
                  </>
                )}
              </Col>
            </Row>
            <hr />
          </CardBody>
        </Card>

        <ModalFooter>
          {props.handleSkipButton ? props.handleSkipButton() : <></>}{" "}
        </ModalFooter>
      </ConditionalWrapper>{" "}
    </>
  );
}
Recordings.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  recordingByTypeDropdown,
  teamDropdownSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recordings);
