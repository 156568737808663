import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormText,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
var classNames = require("classnames");
const MySwal = withReactContent(Swal);

function FormIntegration(props) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    setError,
    clearError,
  } = useForm({
    defaultValues: {
      _id: 0,
      name: "",
      team: "",
      first: "fname",
      last: "lname",
      phone: "phone",
      company: "company",
      email: "email",
      category: "category",
    },
  });

  useEffect(() => {
    register({ name: "_id" });
    register({ name: "team" });
    setValue("type", "webhook");
    if (props.item) {
      setValue("_id", props.item._id);
      setValue("name", props.item.name);
      setValue("webhook_url", props.item.webhook_url);
      setValue("phone", props.item.phone);
      setValue("first", props.item.first);
      setValue("last", props.item.last);
      setValue("email", props.item.email);
      setValue("category", props.item.category);
      setValue("company", props.item.company);

      if (props.item.team) {
        setValue("team", props.item.team._id);
      }
    }
  }, [register, setValue, props]);

  const submitFormAdd = (form) => {
    if (!form.team) {
      return setError("team", "required");
    }
    setLoading(true);
    const record = {
      name: form.name,
      team: form.team,
      first: form.first,
      last: form.last,
      phone: form.phone,
      company: form.company,
      email: form.email,
      category: form.category,
      type: "webhook",
    };
    axios({
      method: "post",
      responseType: "json",
      url: `/api/user_integration/`,
      data: record,
    })
      .then((item) => {
        setLoading(false);
        props.addItemToState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Webhook has been created sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Integration",
          toastrOptions
        );
        if (err.response && err.response.data) {
        }
      });
  };
  const confirmDelete = (_id) => {
    MySwal.fire({
      title: "Are you sure you want to perform this action",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/user_integration/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);
            Swal.fire(
              "Deleted!",
              "Your integration is gone for good.",
              "success"
            );
          })
          .catch((err) => {
            Swal.fire({
              text: "Something went wrong. Try again.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };
  const submitFormEdit = (form) => {
    if (!form.team) {
      return setError("team", "required");
    }
    setLoading(true);
    const record = {
      name: form.name,
      team: form.team,
      first: form.first,
      last: form.last,
      phone: form.phone,
      company: form.company,
      email: form.email,
      category: form.category,
    };
    record.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios({
      method: "patch",
      responseType: "json",
      url: `/api/user_integration/` + form._id + `/update/`,
      data: record,
    })
      .then((item) => {
        setLoading(false);
        props.updateState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Webhook has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Integration",
          toastrOptions
        );
        if (err.response && err.response.data) {
        }
      });
  };

  var classError = "";
  if (errors.team) {
    classError = "error";
  }
  return (
    <>
      <form
        noValidate
        onSubmit={
          props.item
            ? handleSubmit(submitFormEdit)
            : handleSubmit(submitFormAdd)
        }
      >
        <FormGroup>
          <Label
            for="whisperText"
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
          >
            Name
          </Label>

          <Input
            type="text"
            name="name"
            className={classNames({ error: errors.name })}
            id="name"
            innerRef={register({ required: true })}
          />
          {errors.name && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              Name is required
            </div>
          )}
        </FormGroup>
        {props.item ? (
          <FormGroup>
            <Label
              for="whisperText"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Webhook Url
            </Label>

            <Input
              type="text"
              name="webhook_url"
              id="webhook_url"
              defaultValue={
                props.item.webhook_url
                  ? process.env.REACT_APP_FRONTEND + props.item.webhook_url
                  : ""
              }
              disabled={true}
            />
          </FormGroup>
        ) : (
          <></>
        )}
        <FormGroup>
          <Label
            for="team"
            style={{
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
          >
            Team
          </Label>
          <FormText style={{ paddingBottom: "10px" }}>
            {" "}
            Leads sent to this Webhook will be routed to this team.
          </FormText>{" "}
          <Select
            className={"basic-single " + classError}
            classNamePrefix="select"
            name="team"
            id="team"
            isSearchable
            onChange={(selected) => {
              if (selected) {
                setValue("team", selected._id);
                clearError("team");
              } else {
                setValue("team", "");
              }
            }}
            defaultValue={props.item ? props.item.team : ""}
            options={props.teams || ""}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option._id}`}
            innerRef={register({ required: true })}
          />
          {errors.team && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              A Team is required
            </div>
          )}
        </FormGroup>
        <Label
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
          for="national"
        >
          {" "}
          Webhook Fields
        </Label>
        <FormText style={{ paddingBottom: "10px" }}>
          Customize the fields that the Webhook receives.
        </FormText>
        <div className="webhook_div">
          <Col>
            <FormGroup row className="form_div_col">
              <Label for="first" lg={2}>
                First Name
              </Label>
              <Col lg={6}>
                <Input
                  type="text"
                  name="first"
                  id="first"
                  className={classNames({ error: errors.first })}
                  innerRef={register({ required: true })}
                />
              </Col>
              {errors.first && (
                <Col
                  lg={4}
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  First name is required
                </Col>
              )}
            </FormGroup>
            <FormGroup row className="form_div_col">
              <Label for="first" lg={2}>
                Last Name
              </Label>
              <Col lg={6}>
                <Input
                  type="text"
                  name="last"
                  id="last"
                  className={classNames({ error: errors.last })}
                  innerRef={register({ required: true })}
                />
              </Col>
              {errors.last && (
                <Col
                  lg={4}
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  Last name is required
                </Col>
              )}
            </FormGroup>
            <FormGroup row className="form_div_col">
              <Label for="last" lg={2}>
                Phone
              </Label>
              <Col lg={6}>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  className={classNames({ error: errors.phone })}
                  innerRef={register({ required: true })}
                />
              </Col>
              {errors.phone && (
                <Col
                  lg={4}
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  Phone Number is required
                </Col>
              )}
            </FormGroup>
            <FormGroup row className="form_div_col">
              <Label for="first" lg={2}>
                Company
              </Label>
              <Col lg={6}>
                <Input
                  type="text"
                  name="company"
                  id="company"
                  className={classNames({ error: errors.company })}
                  innerRef={register({ required: true })}
                />
              </Col>
              {errors.company && (
                <Col
                  lg={4}
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  Company is required
                </Col>
              )}
            </FormGroup>
            <FormGroup row className="form_div_col">
              <Label for="first" lg={2}>
                Email
              </Label>
              <Col lg={6}>
                <Input
                  type="email"
                  name="email"
                  className={classNames({ error: errors.email })}
                  id="email"
                  innerRef={register({
                    required: "Email is required to create a login.",
                  })}
                />
              </Col>
              {errors.email && (
                <Col
                  lg={4}
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  Email is required
                </Col>
              )}
            </FormGroup>
            <FormGroup row className="form_div_col">
              <Label for="first" lg={2}>
                Category
              </Label>
              <Col lg={6}>
                <Input
                  type="text"
                  name="category"
                  id="category"
                  className={classNames({ error: errors.category })}
                  innerRef={register({ required: true })}
                />
              </Col>
              {errors.category && (
                <Col
                  lg={4}
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  Category is required
                </Col>
              )}
            </FormGroup>
          </Col>
        </div>
        <ModalFooter>
          {props.item ? (
            <Row className="form_modal_footer">
              <Col lg="6" xs="6">
                <Button
                  type="button"
                  onClick={() => {
                    confirmDelete(props.item._id);
                  }}
                  color="danger"
                >
                  Delete
                </Button>
              </Col>
              <Col lg="6" xs="6">
                {loading ? (
                  <Button
                    className="float-right"
                    color="primary"
                    type="button"
                    disabled={true}
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button className="float-right" type="submit" color="primary">
                    Submit
                  </Button>
                )}
              </Col>
            </Row>
          ) : loading ? (
            <Button
              className="float-right"
              color="primary"
              type="button"
              disabled={true}
            >
              Saving...
            </Button>
          ) : (
            <Button className="float-right" type="submit" color="primary">
              Submit
            </Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}
FormIntegration.propTypes = {
  teams: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  teams: state.teams.teamDropdown,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormIntegration);
