import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormAgent from "./FormAgent";
import FormAgentView from "./FormAgentView";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function ModalAgent(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text:
        "Removing an agent will also remove it from any teams. Make sure this doesnt leave you with any empty teams.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/agent/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);

            Swal.fire("Deleted!", "Your agent is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const allowDelete = (account) => {
    if (typeof account !== "undefined") {
      if (
        account.role === "owner" ||
        account.email === props.auth.user.email ||
        props.auth.user.role === "agent"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (props.auth.user.role === "agent") {
      return true;
    } else {
      return false;
    }
  };
  const allowEdit = (item) => {
    if(props.buttonType !== "actions" ) {
      return false;
    }
    const account = (item)?item.account:false; 
    var bool = false;
    if (typeof account !== "undefined") {
      if (
        account.email !== props.auth.user.email &&
        (props.auth.user.role === "agent" || account.role === "owner")
      ) {
        bool =  true;
      } 
    } else {
      bool = props.auth.user.role === "agent";
    }
    return bool;
  };

  const label = props.buttonLabel;
  var addEditButton = null;
  if (props.buttonType === "add") {
    addEditButton = (
      <Button
        disabled={props.auth.user.role === "agent"}
        color={"success"}
        onClick={toggle}
        style={{ float: "right" }}
      >
        {label}
      </Button>
    );
  } else if (props.buttonType === "createAccount") {
    addEditButton = (
      <Button
        disabled={props.auth.user.role === "agent"}
        color={"default"}
        onClick={toggle}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        {props.buttonLabel}
      </Button>
    );
  } else if (props.buttonType === "actions") {
    if (props.mobile) {
      addEditButton = (
        <>
          {!allowEdit(props.item) ? <button
            onClick={toggle}
            className="dropdown-item remove_background"
          >
            <i className="fas fa-pen"></i> Edit
          </button>:<button
            onClick={toggle}
            className="dropdown-item remove_background"
          >
            <i className="fas fa-eye"></i> View
          </button>}
          <button
            disabled={allowDelete(props.item.account)}
            onClick={() => {
              confirmDelete(
                props.item._id,
                props.item.first + " " + props.item.last
              );
            }}
            className="dropdown-item remove_background"
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </>
      );
    } else {
      addEditButton = (
        <>
          {!allowEdit(props.item)? <Button
            color={"info"}
            onClick={toggle}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>:<Button
            color={"info"}
            onClick={toggle}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>}

          <Button
            disabled={allowDelete(props.item.account)}
            color={"default"}
            onClick={() => {
              confirmDelete(
                props.item._id,
                props.item.first + " " + props.item.last
              );
            }}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </>
      );
    }
  }

  return (
    <>
      {addEditButton}

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          className={"agentForm"}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }>
          {!allowEdit(props.item)?props.headerTitle:props.headerTitleView}
          {modal && props.item?<span className="float-right unique_id_class">id: {props.item.unique_id}</span>:null}
        </ModalHeader>
        <ModalBody>
          {modal? !allowEdit(props.item)?<FormAgent
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            item={props.item}
            auth={props.auth}
            hasLogin={props.hasLogin}
          />:<FormAgentView
          toggle={toggle}
          item={props.item}
          auth={props.auth}
          hasLogin={props.hasLogin}
        />:null}
        </ModalBody>
      </Modal>
    </>
  );
}
ModalAgent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAgent);
