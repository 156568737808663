import React from "react";
import classnames from "classnames";

function HookIntegration(props) {
  const appendClass =
    props.data._type === "created" ? "content-width" : "main-content-width";
  return (
    <div
      className={classnames({
        "hook_card_integration ": true,
        active: props.active,
      })}
    >
      <div className="hook_card_integration_left">{props.data.icon}</div>
      <div className="hook_card_integration_right_row row_col">
        <div className={"offet-margin-left " + appendClass}>
          <div className="hook_card_integration_right_row_title">
            {props.data.title}
          </div>
          {props.data._type === "created" ? (
            <div className="hook_card_integration_right_row_event">
              {props.userIntegrationsCount} events in the last 7 days
            </div>
          ) : (
            <></>
          )}
        </div>
        {props.data.action ? (
          <div className="margin-right-set action_div">{props.data.action}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default HookIntegration;
