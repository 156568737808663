import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AccountNavbar from "components/Navbars/AccountNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Banner from "components/Banner/Banner";
import routes from "routes.js";
import { getBilling } from "./../actions/billingActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const sideMenu = routes.sideMenu;

const accountMenu = routes.accountMenu;

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidenavOpen: true,
      sideMenu: this.getRoutes(sideMenu),
      accountMenu: this.getRoutes(accountMenu)
    };
  }
  componentDidMount() {
    if (
      window.innerWidth < 1200 &&
      document.body.classList.contains("g-sidenav-pinned")
    ) {
      this.toggleSidenav();
    }
    this.props.getBilling();
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1200) {
        if (document.body.classList.contains("g-sidenav-pinned")) {
          //this.toggleSidenav();
        }
      } else {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
          //this.toggleSidenav();
        }
      }
    });
    //this.setState({"sideMenu": this.getRoutes(sideMenu)})
    //this.setState({"accountMenu": this.getRoutes(accountMenu)})
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (sideMenu) => {
    const role = this.props.auth.user.role;
    return sideMenu.map((prop, key) => {
      if (prop.noAllow && prop.noAllow.includes(role)) {
        return null;
      }
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/account") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getAccountRoutes = (accountMenu) => {
    return accountMenu.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  collapseControll = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      window.innerWidth >= 768 &&
      window.innerWidth < 1200 &&
      this.is_touch_device2() &&
      !document.body.classList.contains("g-sidenav-show_div")
    ) {
      document.body.classList.add("g-sidenav-show_div");
    }
  };
  is_touch_device2 = () => {
    return (
      "ontouchstart" in window || // works on most browsers
      "onmsgesturechange" in window
    ); // works on IE10 with some false positives
  };
  toggleDivContainer = (toggle) => {
    if (toggle === "toggle") {
      document.body.classList.remove("g-sidenav-show_div");
    } else if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-show_div"); //for touch screen
      document.body.classList.add("g-sidenav-hidden");
      this.setState({
        sidenavOpen: false,
      });
    } else if (
      document.body.classList.contains("g-sidenav-hidden") &&
      !document.body.classList.contains("g-sidenav-show_div")
    ) {
      document.body.classList.add("g-sidenav-show_div");
    } else if (document.body.classList.contains("g-sidenav-show_div")) {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      document.body.classList.remove("g-sidenav-show_div"); //for touch screen
      this.setState({
        sidenavOpen: true,
      });
    }
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e, toggle) => {
    if (e) {
      e.preventDefault();
    }
    if (
      window.innerWidth >= 768 &&
      window.innerWidth < 1200 &&
      this.is_touch_device2()
    ) {
      this.toggleDivContainer(toggle);
    } else if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.setState({
        sidenavOpen: false,
      });
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.setState({
        sidenavOpen: true,
      });
    }
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "account/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  render() {
    return (
      <>
        <Banner billing={this.props.billing} />
        <Sidebar
          {...this.props}
          routes={sideMenu}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          mainContent={this.refs.mainContent}
          collapseControll={this.collapseControll}
          logo={{
            //innerLink: "/",
            innerLink: "#",
            imgSrc:
              process.env.REACT_APP_HOMEPAGE +
              "/wp-content/uploads/2020/10/logo-wide.png",
            imgAlt: "...",
          }}
          trialClass={
            this.props.billing.payload.accountStatus !== "Canceled" &&
            this.props.billing.payload.planType === "Trial"
          }
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AccountNavbar
            {...this.props}
            routes={accountMenu}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
          />

          <Switch>
            {this.state.sideMenu}
            {this.state.accountMenu}
            <Redirect from="*" to="/account/dashboard" />
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}
Account.propTypes = {
  getBilling: PropTypes.func.isRequired,
  billing: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  billing: state.billing,
  auth: state.auth,
});
const mapDispatchToProps = {
  getBilling,
};
export default connect(mapStateToProps, mapDispatchToProps)(Account);
