import React from "react";
import { FormGroup, Label } from "reactstrap";

import "react-phone-input-2/lib/style.css";

function InstructionsIntegration(props) {
  const print_r = (o) => {
    var array = {};
    array[props.item.phone] = "";
    array["type"] = "";
    array["source"] = "";
    array[props.item.first] = "";
    array[props.item.last] = "";
    array[props.item.company] = "";
    array[props.item.email] = "";
    array[props.item.category] = "";
    return JSON.stringify(array, null, "\t");
  };

  var print_query = (o) => {
    var parsedWebhook = JSON.parse(o);
    return Object.keys(parsedWebhook)
      .map(function (k) {
        return encodeURIComponent(k) + `=${encodeURIComponent(k)}`;
      })
      .join("&");
  };
  const arrayWebhook = { test: "test" };
  return (
    <>
      <FormGroup>
        <Label
          for="whisperText"
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
        >
          Name
        </Label>
        <div className="formfiled">{props.item.name}</div>
      </FormGroup>

      <FormGroup>
        <Label
          for="whisperText"
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
        >
          Webhook Url
        </Label>
        <div className="formfiled">
          {process.env.REACT_APP_FRONTEND}
          {props.item.webhook_url}
        </div>
      </FormGroup>

      <Label
        style={{
          display: "block",
          marginBottom: "0rem",
          fontWeight: "600",
        }}
        for="json"
      >
        {" "}
        Payload (JSON)
      </Label>
      <div className="webhook_div_payload">
        <pre>{print_r(arrayWebhook)}</pre>
      </div>
      <br></br>
      <Label
        style={{
          display: "block",
          marginBottom: "0rem",
          fontWeight: "600",
        }}
        for="query"
      >
        {" "}
        URL Query Parameters (Append to end of Wehbook URL)
      </Label>
      <div className="webhook_div_payload">
        <pre>?{print_query(print_r(arrayWebhook))}</pre>
      </div>
    </>
  );
}
export default InstructionsIntegration;
