import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableCall from "./TableCall";
import moment from "moment";
import ModalLead from "../Leads/ModalLead";
import Loader from "layouts/Loader.js";
const formatString = "ddd, D-MMM h:mm a";

function Calls(props) {
  //ahow/hide colum on resize
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(-1);
  const appendData = useCallback((item) => {
    if (item.segment01.logs.length > 0) {
      const segmentLogs = item.segment01.logs.sort(function (log1, log2) {
        const date1 = moment(log1.date).valueOf();
        const date2 = moment(log2.date).valueOf();
        if (date1 > date2)
          //sort string ascending
          return -1;
        if (date1 < date2) return 1;
        return 0;
      });
      item.segment01.logs = segmentLogs;
    }
    if (item.segment02.logs.length > 0) {
      const segmentLogs2 = item.segment02.logs.sort(function (log1, log2) {
        const date1 = moment(log1.date).valueOf();
        const date2 = moment(log2.date).valueOf();
        if (date1 > date2)
          //sort string ascending
          return -1;
        if (date1 < date2) return 1;
        return 0;
      });
      item.segment02.logs = segmentLogs2;
    }
    item.dir =
      item.direction === "Outgoing" ? (
        <>
          <i
            className="fas fa-sign-out-alt"
            title="Outgoing Call"
            style={{ color: "#2dce89" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-sign-in-alt"
            title="Incoming Call"
            style={{ color: "#5e72e4" }}
          ></i>
        </>
      );
    item.StatusHtml = (
      <>
        {item.status}{" "}
        <div className="calltooltip">
          {" "}
          <i
            className="fa fa-info-circle"
            id={"UncontrolledTooltip_" + item._id}
          ></i>
          <div className="tooltiptext tooltip-bottom">
            {item.segment02.logs.length > 0 ? (
              <>
                {" "}
                <div>
                  <label className="font-weight-bold">
                    Calling {item.segment02.target}:{" "}
                    {item.segment02.segmentStatus}
                  </label>
                </div>
              </>
            ) : (
              <></>
            )}

            {item.segment02.logs.length > 0 ? (
              item.segment02.logs.map((log, index) => {
                return (
                  <div className="subheading" key={index}>
                    {moment(log.date).format(formatString) + ": " + log.message}
                  </div>
                );
              })
            ) : (
              <></>
            )}

            <div>
              <label className="font-weight-bold">
                Calling {item.segment01.target}: {item.segment01.segmentStatus}
              </label>
            </div>
            {item.segment01.logs.map((log, index) => {
              if (log) {
                return (
                  <div className="subheading" key={index}>
                    {moment(log.date).format(formatString) + ": " + log.message}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <br></br>
        {item.segment01.segmentStatus}{" "}
        {typeof item.segment01.currentTarget === "object"
          ? item.segment01.currentTarget.name
          : item.segment01.currentTarget}
        <br></br>
        {item.segment02.segmentStatus}{" "}
        {typeof item.segment02.currentTarget === "object"
          ? item.segment02.currentTarget.name
          : item.segment02.currentTarget}
        {item.callDuration ? <>: {item.callDuration}s</> : <></>}
        {item.recordingUrl ? (
          <>
            {" "}
            <a href={item.recordingUrl + ".mp3?Download=true"}>
              <i
                className="fa fa-microphone"
                style={{ fontSize: "12pt", color: "#f5365c" }}
              ></i>
            </a>
          </>
        ) : (
          <></>
        )}{" "}
      </>
    );
    item.targetColor =
      item.type === "Lead" ? (
        <>
          <i className="fa fa-bullseye  text-info" title="Target"></i>{" "}
          {item.type}
        </>
      ) : (
        <>
          <i className="fa fa-bullseye  text-purple" title="Target"></i>{" "}
          {item.type}
        </>
      );
    const member =
      item.team !== null
        ? item.team.members.map((m) => m.first).join(",")
        : item.segment02.outcome.map((m) => m.target).join(",");
    item.leadTeamAgent = (
      <>
        <ModalLead
          buttonType="view"
          buttonLabel="Edit"
          leadId={item.lead ? item.lead._id : ""}
          item={item.lead ? item.lead : item.leadDetails}
          headerTitle="Edit Lead"
        />
        {item.team !== null ? (
          <>
            <br></br>
            <i className="fa fa-sitemap text-purple" title="Team"></i>{" "}
            {item.team.name}{" "}
          </>
        ) : (
          ""
        )}
        <br></br>
        <i
          className="ni ni-badge"
          title="Team Members"
          style={{ paddingLeft: "2px", paddingRight: "2px" }}
        ></i>{" "}
        {member}
      </>
    );
    return item;
  }, []);
  const getDropDowns = useCallback(async () => {
    if (loadingStage === -1) {
      setLoadingStage(0);
      setLoadingStage(1);
    }
  }, [loadingStage, setLoadingStage]);
  const getItems = useCallback(async (abortController) => {
    if (loadingStage === 1) {
      const token = props.auth.user.token;
      fetch("/api/call/?limit=10&type=Dashboard", {
        signal: abortController.signal,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          setLoading(false);
          if (responseJSON) {
            responseJSON.map((item, index) => {
              item = appendData(item);
              return item;
            });
            setItems(responseJSON);
          }
        })
        .catch((err) => {});
    }
  }, [props.auth.user.token, appendData, loadingStage]);
  useEffect(() => {
    const abortController = new window.AbortController();
    getDropDowns();
    getItems(abortController);
    return () => {
      abortController.abort();
    };
  }, [getItems, getDropDowns]);
  return loading ? (
    <Loader />
  ) : (
    <TableCall
      appendData={appendData}
      setItems={setItems}
      items={items}
      loading={loading}
      settings={props.settings}
    />
  );
}
Calls.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Calls);
