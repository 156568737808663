import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getBilling,
  addupdatePlan,
  changePlan,
  cancelPlan,
  resumePlan,
  addUsage,
} from "../../../actions/billingActions";
import { toastr } from "react-redux-toastr";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "layouts/Loader";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  CardText,
  Button,
  Label,
  FormGroup,
  Input,
  CardTitle,
  Alert,
  Badge,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CreditCard from "components/Sections/CreditCard.js";
import { loadStripe } from "@stripe/stripe-js";

const moment = require("moment");

const MySwal = withReactContent(Swal);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

class Billing extends React.Component {
  // Set state initial values
  constructor(props, context) {
    super(props, context);
    this.state = {
      plan: "",
      planTitle: "",
      planDescription: "",
      planIcon: "",
      planColor: "",
      currentPlan: "",
      minutes: "",
      errors: {},
    };
  }

  componentDidMount() {
    //this.props.auth.user.organization
    this.props.getBilling();
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (
      Object.keys(nextProps.billing.payload).length > 0 &&
      Object.keys(nextProps.errors).length === 0
    ) {
      this.setState(
        {
          plan: nextProps.billing.payload.stripePlanId,
          setupComplete: nextProps.billing.payload.setupComplete,
          canceledDate: nextProps.billing.payload.canceledDate,
          endDate: nextProps.billing.payload.endDate,
          startDate: nextProps.billing.payload.startDate,
          trialEnd: nextProps.billing.payload.trialEnd,
          accountStatus: nextProps.billing.payload.accountStatus,
          planType: nextProps.billing.payload.planType,
          stripePlanId: nextProps.billing.payload.stripePlanId,
          methodStatus: nextProps.billing.payload.methodStatus,
          cardType: nextProps.billing.payload.cardType,
          last4: nextProps.billing.payload.last4,
          expMonth: nextProps.billing.payload.expMonth,
          expYear: nextProps.billing.payload.expYear,
          cardName: nextProps.billing.payload.cardName,
          billingDate: nextProps.billing.payload.billingDate,
          phoneAmount: nextProps.billing.payload.phoneAmount,
          phoneQuantity: nextProps.billing.payload.phoneQuantity,
          phoneTotal: nextProps.billing.payload.phoneTotal,
          usageStatus: nextProps.billing.payload.usageStatus,
          amount: nextProps.billing.payload.amount,
          quantity: nextProps.billing.payload.quantity,
          minutes: nextProps.billing.payload.additionalMinutes,
          additionalMinutes: nextProps.billing.payload.additionalMinutes,

          total: nextProps.billing.payload.total,
          loading: true,
          errors: {},
        },
        () => this.getPlanInfo(nextProps.billing.payload.stripePlanId)
      );
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  changePlan = async (type) => {
    if (this.state.plan === this.state.stripePlanId && type === "change") {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 4500,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "You're already on this plan. Try selecting another.",
        toastrOptions
      );
      return;
    }
    var previousPlan = this.state.stripePlanId;
    try {
      const changeStatus = await this.props.changePlan(this.state.plan, type);

      if (changeStatus.status === 200) {
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 4500,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Subscription Plan Changed to " +
            changeStatus.data.planType +
            " successfully.",
          toastrOptions
        );

        this.setState(changeStatus.data);
        this.getPlanInfo(changeStatus.data.planId);

        //GA Datalayer for Change Plan
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "change_plan",
          userId: this.props.auth.user.email,
          previousPlan: previousPlan,
          plan: this.state.stripePlanId,
          setupComplete: this.state.setupComplete,
          accountStatus: this.state.accountStatus,
          organization: this.props.auth.user.organization,
          value: parseInt(
            (
              (this.state.amount / 100) * this.state.quantity +
              (this.state.phoneAmount * this.state.phoneQuantity) / 100
            ).toFixed(2)
          ),
        });
      } else {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 4500,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "There was a problem processing your payment request. Try again.",
          toastrOptions
        );
      }
    } catch (error) {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 4500,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "There was a problem processing your payment request. Try again.",
        toastrOptions
      );
    }
    return;
  };

  /* not used. This is used with change additional usage feature not implemented */
  changeMinutes = async (type) => {
    if (this.state.minutes === this.state.additionalMinutes) {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 4500,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "Your plan already has " +
          this.state.additionalMinutes +
          " additional minutes.",
        toastrOptions
      );
      return;
    }

    try {
      const changeStatus = await this.props.changePlan(this.state.plan, type);

      if (changeStatus.status === 200) {
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 4500,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Subscription Plan Changed to " +
            changeStatus.data.planType +
            " successfully.",
          toastrOptions
        );

        this.setState(changeStatus.data);
        this.getPlanInfo(changeStatus.data.planId);
      } else {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 4500,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "There was a problem processing your payment request. Try again.",
          toastrOptions
        );
      }
    } catch (error) {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 4500,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "There was a problem processing your payment request. Try again.",
        toastrOptions
      );
    }
    return;
  };

  addUsage = async (type) => {
    try {
      const addUsage = await this.props.addUsage();

      if (addUsage.status === 200) {
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 4500,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Additional Calling Credits Added Successfully",
          toastrOptions
        );

        this.setState(addUsage.data);
        this.getPlanInfo(addUsage.data.planId);
      } else {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 4500,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Calling Credits not added. Something went wrong. Try updating your credit card or contact Support.",
          toastrOptions
        );
      }
    } catch (error) {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 4500,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "There was a problem processing your payment request. Try again.",
        toastrOptions
      );
    }
    return;
  };

  // Add Subscription or Update card
  addupdatePlan = async (type) => {
    if (this.state.plan === this.state.stripePlanId && type === "add") {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 4500,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "You're already on this plan. Try selecting another.",
        toastrOptions
      );
      return;
    }

    try {
      const changeStatus = await this.props.addupdatePlan(
        this.state.plan,
        type
      );

      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        sessionId: changeStatus.data.id,
      });

      if (error) {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "There was a problem processing your payment request. Try again.",
          toastrOptions
        );
      } else {
        var message = "";

        this.setState(changeStatus.data);
        this.getPlanInfo(changeStatus.data.planId);

        if (type === "add") {
          message = "Subscription Plan Added Successfully.";
        } else if (type === "update") {
          message = "Credit card Updated Successfully.";
        }

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light("Success", message, toastrOptions);
      }
    } catch (error) {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 2250,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "There was a problem processing your payment request. Try again.2",
        toastrOptions
      );
    }

    return;
  };

  cancelPlan = async (event) => {
    event.preventDefault();

    MySwal.fire({
      title: "Are you sure about this?",
      text:
        "You are about to cancel your account. Your account will remain active until the end of the billing period at which point your account will be locked.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel my subscription :(",
    }).then(async (result) => {
      if (result.value) {
        try {
          const cancel = await this.props.cancelPlan();

          Swal.fire(
            "Canceled!",
            "Your account will remain active until " + cancel.cancel_at,
            "success"
          );

          //     this.updateState(item.data);
          this.setState(cancel.data);
          this.getCurrentPlan();
          this.getPlanButton();
        } catch (error) {
          Swal.fire({
            text: "Something went wrong. Try again.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    });
    return;
  };

  resumePlan = async (event) => {
    event.preventDefault();

    MySwal.fire({
      title: "We're happy to see you back!",
      text: "Please confirm reactivation of your subscription.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, resume my subscription!",
    }).then(async (result) => {
      if (result.value) {
        try {
          const resume = await this.props.resumePlan();

          Swal.fire("Activated!", "Your subscription has resumed!", "success");

          // this.setState(resume.data);
          this.setState(resume.data);
          this.getCurrentPlan();
          this.getPlanButton();
        } catch (error) {
          Swal.fire({
            text: "Something went wrong. Try again.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    });
    return;
  };

  updatePaymentButton() {
    return this.props.billing.loadingButton ? (
      <Button color="info" disabled={true}>
        Loading...
      </Button>
    ) : (
      <Button
        id="updateCard"
        color="info"
        href="#pablo"
        onClick={() => {
          this.addupdatePlan("update");
        }}
        style={{ marginBottom: "15px" }}
      >
        Update Card
      </Button>
    );
  }

  showPaymentMethod() {
    if (
      this.state.cardType !== "" &&
      this.state.cardType !== undefined &&
      this.state.accountStatus !== "Canceled"
    ) {
      return (
        <>
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">Credit Card Details</h5>
            </CardHeader>

            <CardBody>
              {this.updatePaymentButton()}
              <CreditCard
                cardType={this.state.cardType}
                methodStatus={this.state.methodStatus}
                last4={this.state.last4}
                cardName={this.state.cardName}
                expMonth={this.state.expMonth}
                expyear={this.state.expYear}
              />
            </CardBody>
          </Card>

          {/*}
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">Invoice</h5>
            </CardHeader>
          </Card>
              */}
        </>
      );
    } else {
      return "";
    }
  }

  getMinutesDropdown() {
    return (
      <>
        <FormGroup style={{ marginTop: "20px", width: "95%" }}>
          <Input
            type="select"
            name="minutes"
            id="minutes"
            value={this.state.minutes}
            onChange={this.handleChange}
          >
            <option key="0" value="0" style={{ fontSize: "14pt" }}>
              - none -
            </option>
            <option key="500" value="500" style={{ fontSize: "14pt" }}>
              +500
            </option>
            <option key="1000" value="1000" style={{ fontSize: "14pt" }}>
              +1,000
            </option>

            <option key="1500" value="1500" style={{ fontSize: "14pt" }}>
              +1,500
            </option>
            <option key="2000" value="2000" style={{ fontSize: "14pt" }}>
              +2,000
            </option>
            <option key="2500" value="2500" style={{ fontSize: "14pt" }}>
              +2,500
            </option>
            <option key="3000" value="3000" style={{ fontSize: "14pt" }}>
              +3,000
            </option>
            <option key="3500" value="3500" style={{ fontSize: "14pt" }}>
              +3,500
            </option>
            <option key="4500" value="4500" style={{ fontSize: "14pt" }}>
              +4,500
            </option>
            <option key="6000" value="6000" style={{ fontSize: "14pt" }}>
              +6,000
            </option>
            <option key="7000" value="7000" style={{ fontSize: "14pt" }}>
              +7,000
            </option>
            <option key="8000" value="8000" style={{ fontSize: "14pt" }}>
              +8,000
            </option>
            <option key="9000" value="9000" style={{ fontSize: "14pt" }}>
              +9,000
            </option>
            <option key="10000" value="10000" style={{ fontSize: "14pt" }}>
              +10,000
            </option>
            <option key="12500" value="12500" style={{ fontSize: "14pt" }}>
              +12,500
            </option>
            <option key="15000" value="15000" style={{ fontSize: "14pt" }}>
              +15,000
            </option>
            <option key="20000" value="20000" style={{ fontSize: "14pt" }}>
              +20,000
            </option>
          </Input>
        </FormGroup>
      </>
    );
  }

  getPlanButton() {
    var dropDown = (
      <FormGroup style={{ marginTop: "20px", width: "95%" }}>
        <Input
          type="select"
          name="plan"
          id="plan"
          value={this.state.plan}
          onChange={(event) => this.getPlanInfo(event.target.value)}
        >
          {this.isTrial()}
          <option
            key="assistbasic2"
            value="assistbasic2"
            style={{ fontSize: "14pt" }}
          >
            Assist Basic
          </option>
          <option
            key="assistmax2"
            value="assistmax2"
            style={{ fontSize: "14pt" }}
          >
            Assist Max
          </option>
          {/*
          <option
            key="agencybasic"
            value="agencybasic"
            style={{ fontSize: "14pt" }}
          >
            Agency Basic
          </option>
          <option
            key="agencymax"
            value="agencymax"
            style={{ fontSize: "14pt" }}
          >
            Agency Max
          </option>
*/}
        </Input>
      </FormGroup>
    );

    if (this.state.planType === "Trial") {
      return (
        <>
          {dropDown}
          {this.props.billing.loadingButton ? (
            <Button color="success" disabled={true}>
              Loading...
            </Button>
          ) : (
            <Button
              id="upgradePlan"
              color="success"
              onClick={() => {
                this.addupdatePlan("add");
              }}
            >
              Upgrade Plan
            </Button>
          )}
        </>
      );
    } else if (this.state.accountStatus === "Canceled") {
      return this.props.billing.loadingButton ? (
        <Button color="primary" disabled={true}>
          Loading...
        </Button>
      ) : (
        <Button
          id="reactivatePlan"
          color="primary"
          style={{ fontSize: "20pt" }}
          onClick={(event) => {
            this.resumePlan(event);
          }}
        >
          Reactivate Plan
        </Button>
      );
    } else {
      return (
        <>
          {dropDown}
          {this.props.billing.loadingButton ? (
            <Button color="success" disabled={true}>
              Loading...
            </Button>
          ) : (
            <Button
              id="changePlan"
              color="success"
              style={{ marginBottom: "10px" }}
              onClick={() => {
                this.changePlan("change");
              }}
            >
              Change Plan
            </Button>
          )}
          {this.props.billing.loadingButton ? (
            <Button color="light" disabled={true}>
              Loading...
            </Button>
          ) : (
            <Button
              id="cancelPlan"
              style={{
                marginBottom: "10px",
                width: "102px",
                marginLeft: "0px",
              }}
              color="light"
              onClick={(event) => {
                this.cancelPlan(event);
              }}
            >
              Cancel
            </Button>
          )}
        </>
      );
    }
  }

  getCurrentPlan() {
    if (this.state.accountStatus !== "Canceled") {
      if (this.state.planType === "Trial") {
        var trialEnd = new Date(this.state.trialEnd);
        var today = new Date();
        var endIn = Math.round(
          (trialEnd.getTime() - today.getTime()) / (1000 * 3600 * 24)
        );

        var m = trialEnd.getMonth();
        var d = trialEnd.getDate();
        var y = trialEnd.getFullYear();

        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var mlong = months[m];
        var fulldate = mlong + " " + d + ", " + y;

        return (
          <Label>
            {endIn > 0 ? (
              <strong>
                Your trial ends in{" "}
                <span style={{ color: "#fb6340" }}>{endIn} </span>
                days on <span style={{ color: "#fb6340" }}>{fulldate}</span>.
              </strong>
            ) : (
              <Alert
                style={{
                  color: "#856404",
                  backgroundColor: "#fff3cd",
                  borderColor: "#ffeeba",
                }}
              >
                <strong>
                  Your trial ended on{" "}
                  <span style={{ color: "#fb6340" }}>{fulldate} </span> but your
                  leads are still waiting. Upgrade your plan below.
                </strong>
              </Alert>
            )}
          </Label>
        );
      } else {
        return (
          <>
            <Label>
              <strong>Billing Date:</strong>{" "}
              {moment(this.state.billingDate).format("Do")} day of each month
            </Label>
            <br></br>
            <Label>
              <strong>Cost of Plan:</strong> <br></br>${this.state.amount / 100}{" "}
              x {this.state.quantity} Agent(s)
              {this.state.phoneQuantity > 0 ? <br></br> : ""}
              {this.state.phoneQuantity > 0
                ? "$" +
                  this.state.phoneAmount / 100 +
                  ".00 x " +
                  this.state.phoneQuantity +
                  " Number(s) "
                : ""}
              <br></br>
              {""}
              <div style={{ textDecoration: "overline" }}>
                {" "}
                <strong>
                  {" "}
                  $
                  {(
                    (this.state.amount / 100) * this.state.quantity +
                    (this.state.phoneAmount * this.state.phoneQuantity) / 100
                  ).toFixed(2)}
                </strong>{" "}
                / month{" "}
              </div>
              * Price does not reflect any adjustments made during the billing
              period.
            </Label>
          </>
        );
      }
    } else if (this.state.accountStatus === "Canceled") {
      var canceledDate = new Date(this.state.canceledDate);
      var endDate = new Date(this.state.endDate);

      return (
        <>
          <CardText style={{ fontSize: "18pt" }}>
            {this.state.planType}
          </CardText>
          <Alert
            style={{
              color: "#856404",
              backgroundColor: "#fff3cd",
              borderColor: "#ffeeba",
            }}
          >
            Your account was canceled on{" "}
            <strong>{canceledDate.toString()}</strong> and will be deactivate on{" "}
            <strong>{endDate.toString()}</strong>.
          </Alert>
        </>
      );
    }
  }

  addusageButton() {
    return (
      <>
        {this.props.billing.loadingButton ? (
          <Button color="success" disabled={true}>
            Loading...
          </Button>
        ) : (
          <Button
            color="success"
            style={{ marginBottom: "10px" }}
            onClick={() => {
              //   this.changeMinutes("minutes");
              this.addUsage();
            }}
          >
            Add Usage Credits
          </Button>
        )}
      </>
    );
  }

  isTrial() {
    if (this.state.planType === "Trial") {
      return (
        <option key="trial" value="trial" style={{ fontSize: "14pt" }}>
          Trial
        </option>
      );
    } else {
      return;
    }
  }

  getPlanInfo(selectedPlan) {
    this.setState({ plan: selectedPlan });

    //   Assist Basic
    var description = "";
    var title = "";
    var icon = "";
    var color = "";

    if (selectedPlan === "trial") {
      title = "Trial of Assist Max";
      icon = "ni ni-air-baloon";
      color = "bg-gradient-indigo";
      description = (
        <ul>
          <li>Agent Calling</li>
          <li>Direct to Lead Calling</li>
          <li>14 Days</li>
          <li>50 Calls</li>
        </ul>
      );
      this.setState({ planTitle: title });
      this.setState({ planIcon: icon });
      this.setState({ planColor: color });
      this.setState({ planDescription: description });
    } else if (selectedPlan === "assistbasic2") {
      title = "Assist Basic";
      icon = "ni ni-send";
      color = "bg-gradient-success";
      description = (
        <ul>
          <li>Agent Calling</li>
          <li>Per User Billing</li>
        </ul>
      );
      this.setState({ planTitle: title });
      this.setState({ planIcon: icon });
      this.setState({ planColor: color });
      this.setState({ planDescription: description });
    }
    // Assist Max
    else if (selectedPlan === "assistmax2") {
      title = "Assist Max";
      icon = "ni ni-spaceship";
      color = "bg-gradient-info";
      description = (
        <ul>
          <li>Agent Calling</li>
          <li>Direct to Lead Calling</li>
          <li>Per User Billing</li>
        </ul>
      );
      this.setState({ planTitle: title });
      this.setState({ planIcon: icon });
      this.setState({ planColor: color });
      this.setState({ planDescription: description });
    }

    // Agency Basic
    else if (selectedPlan === "agencybasic") {
      title = "Agency Basic";
      icon = "ni ni-shop";
      color = "bg-gradient-blue";
      description = (
        <ul>
          <li>Agent Calling</li>
          <li>Discounted Per User Billing (12+)</li>
        </ul>
      );
      this.setState({ planTitle: title });
      this.setState({ planIcon: icon });
      this.setState({ planColor: color });
      this.setState({ planDescription: description });
    }

    // Agency Max
    else if (selectedPlan === "agencymax") {
      title = "Agency Max";
      icon = "ni ni-istanbul";
      color = "bg-gradient-purple";
      description = (
        <ul>
          <li>Agent Calling</li>
          <li>Direct to Lead Calling</li>
          <li>Discounted Per User Billing (12+)</li>
        </ul>
      );
      this.setState({ planTitle: title });
      this.setState({ planIcon: icon });
      this.setState({ planColor: color });
      this.setState({ planDescription: description });
    }

    if (selectedPlan === this.state.stripePlanId) {
      this.setState({ currentPlan: "Your Plan" });
    } else if (selectedPlan === "trial") {
      this.setState({ currentPlan: "Your Plan" });
    } else {
      this.setState({ currentPlan: "" });
    }
  }

  render() {
    const { loading } = this.props;
    if (loading || this.props.billing.loading) {
      return <Loader />;
    }

    return (
      <>
        <SimpleHeader name="Billing" parentName="Settings" />

        <Container className="mt--6" fluid>
          {this.state.planType !== "Trial" &&
            this.state.accountStatus !== "Canceled" &&
            this.state.usageStatus === "suspended" && (
              <Card>
                <CardHeader className="bg-danger">
                  <h3 className="h3 mb-0" style={{ color: "white" }}>
                    Account Temporarily Suspended: Add Usage Credits
                  </h3>
                </CardHeader>
                <CardBody color="primary">
                  <Container
                    color="primary"
                    style={{
                      marginLeft: "0px",
                      paddingLeft: "0px",
                      paddingTop: "0px",
                    }}
                  >
                    <Row>
                      <Col xs="12" sm="12" md="12">
                        <b>No calling credits avaliable.</b> <br></br> <br></br>
                        <Alert color="dark ">
                          Additional usage credits are added automatically after
                          you consume 1,000 minutes (or their equivalent) during
                          any billing period.
                          <br></br>Your credit card was likely declined and this
                          has caused your account to become suspended for the
                          remainder of the billing period.
                        </Alert>
                        <br></br>
                        <strong>
                          To continue making calls, you must do the following:
                        </strong>{" "}
                        <br></br>
                        <br></br>
                        <blockquote>
                          1. Update your credit card. <br></br>
                          {this.updatePaymentButton()}
                          <br></br>
                          <br></br>
                          2. Add usage credits with the button below.
                          <br></br>
                          {this.addusageButton()}
                          <br></br> <br></br>
                          <em>
                            {" "}
                            **$29.99 adds 1,000 minutes (or thier equivalent)
                            for the billing period
                            <br></br>
                            (1,000 minutes assumes calling to continental US and
                            Canada).
                          </em>
                          <br></br>
                        </blockquote>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            )}

          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3
                      className="h3 mb-0"
                      style={{
                        fontSize: "18pt",
                        fontWeight: "800",
                        color: "primary",
                      }}
                    >
                      {this.state.planType}
                      {"    "} - {"    "}
                      {this.state.accountStatus === "Canceled" ||
                        (this.state.accountStatus === "suspended" && (
                          <Badge
                            color="danger"
                            style={{ verticalAlign: "middle" }}
                          >
                            {this.state.accountStatus}
                          </Badge>
                        ))}
                      {this.state.accountStatus !== "Canceled" &&
                        this.state.accountStatus !== "suspended" && (
                          <Badge
                            color="success"
                            style={{ verticalAlign: "middle" }}
                          >
                            {this.state.accountStatus}
                          </Badge>
                        )}
                    </h3>
                  </CardHeader>

                  <CardBody>{this.getCurrentPlan()}</CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h3 className="h3 mb-0">Change Plans</h3>
                  </CardHeader>
                  <CardBody>
                    <Container
                      style={{
                        marginLeft: "0px",
                        paddingLeft: "0px",
                        paddingTop: "0px",
                      }}
                    >
                      <Row>
                        <Col xs="12" sm="12" md="4">
                          {this.getPlanButton()}
                        </Col>
                        <Col xs="12" sm="12" md="8">
                          <Label style={{ fontSize: "14pt" }}>
                            <strong>{this.state.currentPlan}</strong>
                          </Label>
                          <Card className={this.state.planColor}>
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    className="text-uppercase text-muted mb-0 text-white"
                                    style={{ fontSize: "large" }}
                                  >
                                    {this.state.planTitle}
                                  </CardTitle>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                    <i className={this.state.planIcon} />
                                  </div>
                                </Col>
                              </Row>
                              <span className="mt-3 mb-0 text-md">
                                <span className="text-wrap text-white">
                                  {this.state.planDescription}
                                </span>
                              </span>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>

                {/*
                {this.state.planType !== "Trial" &&
                  this.state.accountStatus !== "Canceled" && (
                    <Card>
                      <CardHeader>
                        <h3 className="h3 mb-0">Additional Calling Minutes</h3>
                      </CardHeader>
                      <CardBody>
                        <Container
                          style={{
                            marginLeft: "0px",
                            paddingLeft: "0px",
                            paddingTop: "0px",
                          }}
                        >
                          <Row>
                            <Col xs="12" sm="12" md="4">
                                {this.getMinutesDropdown()}
                              {this.addusageButton()}
                            </Col>
                            <Col xs="12" sm="12" md="8">
                              <Label style={{ fontSize: "14pt" }}>
                                Your Current Plan has 1,000 minutes<br></br>
                                Plus{" "}
                                <strong>
                                  {this.state.additionalMinutes}
                                </strong>{" "}
                                Additional Minutes
                                <br></br>
                                You have consumed an estimated{" "}
                                <strong>xxx</strong> minutes this billing cycle.
                                <br></br>
                              </Label>
                            </Col>
                          </Row>
                        </Container>
                      </CardBody>
                    </Card>
                  )}
                        */}

                {this.showPaymentMethod()}
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

Billing.propTypes = {
  getBilling: PropTypes.func.isRequired,
  addupdatePlan: PropTypes.func.isRequired,
  changePlan: PropTypes.func.isRequired,
  cancelPlan: PropTypes.func.isRequired,
  resumePlan: PropTypes.func.isRequired,
  addUsage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  billing: state.billing,
});

const mapDispatchToProps = {
  getBilling,
  addupdatePlan,
  changePlan,
  cancelPlan,
  resumePlan,
  addUsage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
