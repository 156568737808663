import React, { useEffect, useState } from "react";
import { Button, Input, ModalFooter, FormGroup, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import Datetime from "react-datetime";
import classnames from "classnames";
import moment from "moment";

function ReSchedule(props) {
  const [loading, setLoading] = useState(false);
  const [dateTime, setDateTime] = useState("");
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    clearError,
    setError,
  } = useForm({
    defaultValues: {
      external_id: "",
      _id: 0,
      startTime: "",
    },
  });
  useEffect(() => {
    register({ name: "_id" });
    if (Object.keys(props.item).length > 0) {
      setValue("_id", props.item._id);
      const dateTime = moment(props.item.startTime).format("MM/DD/YYYY h:mm a");
      setValue("startTime", dateTime);
      setDateTime(dateTime);
    }
  }, [register, setValue, props]);
  const checkMinutes = (currentDate, timeStamp) => {
    return timeStamp.format("HH:ss") > currentDate.format("HH:ss");
  };
  const getTimeStampData = (timeStamp) => {
    const currentDate = Datetime.moment();
    if (
      currentDate.format("YYYY-MM-DD") === timeStamp.format("YYYY-MM-DD") &&
      timeStamp
    ) {
      if (checkMinutes(currentDate, timeStamp)) {
        return timeStamp;
      } else if (currentDate.minutes() !== 0) {
        return currentDate.minutes() < 30
          ? currentDate.add(30 - currentDate.minutes(), "minutes")
          : currentDate.add(60 - currentDate.minutes(), "minutes");
      }
      return currentDate;
    } else {
      return timeStamp;
    }
  };
  const submitFormEdit = (form) => {
    setLoading(true);
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/call/" + form._id + "/reschedule/",
      data: form,
    })
      .then((item) => {
        setLoading(false);
        props.updateState(item.data);
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Call Schedule has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Schedule",
          toastrOptions
        );

        if (err.response && err.response.data) {
          if (err.response.data.error) {
            return setError(
              err.response.data.error.field,
              "duplicate",
              err.response.data.error.message
            );
          } else {
          }
        }
      });
  };
  const renderInput = (props, openCalendar, closeCalendar) => {
    return (
      <div className="input-group date" id="datetimepicker1">
        <Input
          type="text"
          {...props}
          name="startTime"
          className={classnames({ error: errors.startTime })}
          innerRef={register({ required: true })}
        />
        <span className="input-group-addon input-group-append">
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={openCalendar}
          >
            <span className="fa fa-calendar"></span>
          </button>
        </span>
      </div>
    );
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitFormEdit)}>
        <FormGroup>
          <Row>
            <Col md="5">
              <Datetime
                isValidDate={(current) => {
                  return current.isAfter(Datetime.moment().startOf("day") - 1);
                }}
                timeFormat
                value={dateTime}
                name="startTime"
                id="startTime"
                style={{
                  display: "inline-block",
                  width: "250px !important",
                }}
                onChange={(date) => {
                  const dateValue = getTimeStampData(date);
                  setDateTime(dateValue);
                  setValue("startTime", dateValue);
                  clearError("startTime");
                }}
                innerRef={register({ required: true })}
                timeConstraints={{ minutes: { step: 30 } }}
                renderInput={renderInput}
              />
              {errors.startTime && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Date is required
                </div>
              )}
            </Col>
          </Row>
        </FormGroup>
        <ModalFooter>
          {loading ? (
            <Button color="primary" type="button" disabled={true}>
              Loading...
            </Button>
          ) : (
            <Button color="primary">Reschedule</Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}

export default ReSchedule;
