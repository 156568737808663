import React from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";

function DateFilter(props) {
    const [filterValue, setFilterValue] = React.useState(props.defautValue)
    const containerRef = React.createRef();
    const setFilter = (value) => {
        setFilterValue(value);
        props.setFilterValue(value);
    }
    const selectionRange = [
        {
        startDate: Number.isInteger(filterValue[0])
            ? new Date(filterValue[0])
            : filterValue[0],
        endDate: Number.isInteger(filterValue[1])
            ? new Date(filterValue[1])
            : filterValue[1],
        key: "selection",
        },
    ];

    var dateValue = "";
    if (filterValue[0] && filterValue[1]) {
        const date1 = moment(filterValue[0]).format("MM/DD/YYYY");
        const date2 = moment(filterValue[1]).format("MM/DD/YYYY");
        if (date1 === date2) {
        dateValue = date1;
        } else {
        dateValue = date1 + " - " + date2;
        }
    }

    const [open, setOpen] = React.useState(false);
    const onClickOnPage = (e) => {
        if (!containerRef || !containerRef.current) {
        return;
        }

        let didClickInside = false;

        let parentElement = e.target;
        while (parentElement) {
        if (parentElement === containerRef.current) {
            didClickInside = true;
            break;
        }

        parentElement = parentElement.parentElement;
        }

        if (didClickInside) {
        return;
        }

        // Clicked outside of the date range picker - cancel selection
        setOpenCalander(false);
    };
    const setOpenCalander = (bool) => {
        if (!bool) {
        document.body.removeEventListener("click", onClickOnPage, true);
        }
        setOpen(bool);
    };
    document.body.addEventListener("click", onClickOnPage, true);
        return <div className="filter_range">
            <>
                <label>Date Range</label>
                <input
                className={"form-control"}
                value={dateValue}
                onChange={(e) => {}}
                onFocus={() => {
                    //setOpen(!open);
                }}
                onClick={() => {
                    setOpenCalander(!open);
                }}
                />
                {dateValue ? (
                <div
                    className="close_calander_div"
                    onClick={() => setFilter([])}
                >
                    <span className="close_calander_span">X</span>
                </div>
                ) : (
                <></>
                )}

                {open ? (
                <div
                    ref={containerRef}
                    style={{
                    position: "absolute",
                    zIndex: "2000",
                    marginTop: "48px",
                    marginLeft: "-349px",
                    border: "1px solid grey",
                    paddingRight: "48px",
                    backgroundColor: "white",
                    paddingBottom: "30px",
                    }}
                >
                    <DateRangePicker
                    style={{ position: "absolute" }}
                    onChange={(ranges) => {
                        let endDate = ranges.selection.endDate.valueOf();
                        if (ranges.selection.endDate.getSeconds() === 0) {
                        endDate = endDate + 86400000 - 1;
                        }

                        // This check deals with clicking Continuos and setting a value where startDate is not defined. We just set a date way in the past so it is defined.
                        if (ranges.selection.startDate) {
                            //if( endDate <= moment(ranges.selection.startDate).add(3, 'months').valueOf() ) {
                                setFilter([
                                    ranges.selection.startDate.valueOf(),
                                    endDate,
                                ]);
                            //}
                            
                        } else {
                        ranges.selection.startDate = new Date(
                            "1980-01-01T07:00:00.000Z"
                        );
                        setFilter([
                            ranges.selection.startDate.valueOf(),
                            endDate,
                        ]);
                        }
                    }}
                    ranges={selectionRange}
                    direction="vertical"
                    />
                </div>
                ) : (
                ""
                )}
            </>
            </div>
}
export default DateFilter;