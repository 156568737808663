import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
  } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Loader from "layouts/Loader";
import TableApiToken from "./TableApiToken";
import ModalAdd from "./ModalAdd";
import axios from "axios";

function ApiToken(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    function getItems(token, abortController) {
      setLoading(true);
      fetch("/api/api_token/", {
        signal: abortController.signal,
        headers: {
          Authorization: token,
        },
      })
      .then((response) => response.json())
      .then((items) => {
        if (items) {
          setItems(items);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    }
    useEffect(() => {
      const abortController = new window.AbortController();
      getItems(props.auth.user.token, abortController);
      return () => {
        abortController.abort();
      };
    }, [props.auth.user.token]);
    const deleteItemFromState = (_id) => {
      const updatedItems = items.filter((item) => item._id !== _id);
      setItems(updatedItems);
    };
    const addItemToState = (item) =>{
      setItems([item, ...items]);
    };
    const updateItemToState = (item) =>{
      const itemIndex = items.findIndex((data) => data._id === item._id);
      const newArray = [
        ...items.slice(0, itemIndex),
        item,
        ...items.slice(itemIndex + 1),
      ];
      setItems(newArray);
    }
    const changeStatusActive = (data) => {
      axios({
        method: "patch",
        responseType: "json",
        url: `/api/api_token/` + data._id + "/active_toggle",
        data: { active: !data.is_active },
      })
      .then((response) => {
        const updatedItems = items.filter((item) => {
          if (item._id === data._id) {
            Object.assign(item, response.data);
          }
          return item;
        });
        setItems(updatedItems);
      })
      .catch((err) => {});
    };
    return <><SimpleHeader name="" parentName="Api Tokens" />
      <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                          {!loading ? (<>
                            <ModalAdd
                              buttonType="add"
                              buttonLabel="Add Token"
                              item={{}}
                              headerTitle="Add Token"
                              addItemToState = {addItemToState}
                            />
                            <TableApiToken 
                            items = {items}
                            addItemToState = {addItemToState}
                            changeStatusActive = {changeStatusActive}
                            updateItemToState = {updateItemToState}
                            deleteItemFromState = {deleteItemFromState}
                            />
                          </>):<Loader />}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
      </Container>
      </>;
}
ApiToken.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ApiToken);