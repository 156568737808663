import React from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import ModalEdit from "../Numbers/ModalEdit";

function TablePurchase(props) {
  const getMembers = (members) => {
    return members.map((member) => (
      <li key={member._id}>
        {member.first} {member.last}
      </li>
    ));
  };

  const items = props.items.map((item) => {
    return (
      <tr key={item._id}>
        <th scope="row">{item.name}</th>
        <th scope="row">{item.national}</th>
        <th scope="row">
          {item.routingType === false ? (
            <ol style={{ paddingInlineStart: "10px" }}>
              {item.members ? getMembers(item.members) : ""}
            </ol>
          ) : (
            <ol style={{ paddingInlineStart: "10px" }}>{item.team.name}</ol>
          )}
        </th>

        <td>
          <div style={{ float: "right" }}>
            <ModalEdit
              buttonType="actions"
              buttonLabel="Edit"
              item={item}
              updatePurchasedState={props.updatePurchasedState}
              deletePurchasedFromState={props.deletePurchasedFromState}
              headerTitle="Edit Phone"
              agents={props.agents}
              teams={props.teams}
              recordings={props.recordings}
            />{" "}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Table responsive hover style={{ marginTop: "10px" }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Number</th>
          <th>Calls</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

TablePurchase.propTypes = {
};

const mapStateToProps = (state) => ({
  teams: state.teams.teamDSDropdown,
  agents: state.agents.agentDropdown,
  recordings: state.recordings.recordingByType
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePurchase);