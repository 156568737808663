import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Button, FormGroup, Label, Row, Col, ModalFooter } from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
//import io from "socket.io-client";
import Loader from "layouts/Loader.js";
function FormVerify(props) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const { register, setValue, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      _id: 0,
    },
  });
  //const socketUrl = process.env.BASE_URL;
  const [loaderTwilo, setLoaderTwilo] = useState(false);
  //const [socket, setSocket] = useState(null);
  const setSocketData = () => {
    /*const socket = io.connect(socketUrl, {
      path: "/websoccket",
    });*/
    const socket = props.auth.user.socket;
    socket.on("number_verified_" + props.item._id, (message) => {
      //setLoaderTwilo(false);
      socket.off("number_verified_" + props.item._id);
      //socket.close();
      //setSocket(null);
      props.toggle();
      props.updateOwnedState(message.data);
    });
    socket.on("number_verified_error_" + props.item._id, (error) => {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 2250,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Error",
        "Your Phone number has not been verified.",
        toastrOptions
      );
      setError("verify", "problem", "Your Phone number has not been verified.");
      //socket.close();
      socket.off("number_verified_error_" + props.item._id);
      setLoaderTwilo(false);
      //setSocket(null);
    });
    //setSocket(socket);
  };
  const submitSendToken = (form) => {
    setLoading(true);
    axios({
      method: "post",
      responseType: "json",
      url: `/api/number/verifyoutgoing`,
      data: form,
    })
      .then((item) => {
        setLoading(false);
        // Set code for user to see
        if (item.status === 200) {
          setCode(item.data.validationCode);
          setLoaderTwilo(true);
          setSocketData();
          getField();
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.error) {
          return setError(
            err.response.data.error.field,
            "problem",
            err.response.data.error.message
          );
        } else {
        }
      });
  };

  const submitVerifyToken = (form) => {
    setLoading(true);
    axios({
      method: "get",
      responseType: "json",
      url: "/api/number/" + form._id,
    })
      .then((item) => {
        setLoading(false);
        // Set code for user to see
        if (item.status === 200) {
          setCode("");

          props.updateOwnedState(item.data);
          props.toggle();

          if (item.data.status === "Verified") {
            Swal.fire(
              "Verified!",
              "Your phone number has been verified. It can now be associated with a Team and used as a Caller ID.",
              "success"
            );

            const toastrType = "success";
            const toastrOptions = {
              timeOut: 2250,
              icon: toastrType,
              status: toastrType,
              progressBar: false,
              closeOnToastrClick: true,
              transitionOut: "bounceOut",
            };
            toastr.light(
              "Success",
              "Phone Number Verified sucessfully",
              toastrOptions
            );
          } else {
            const toastrType = "error";
            const toastrOptions = {
              timeOut: 2250,
              icon: toastrType,
              status: toastrType,
              progressBar: false,
              closeOnToastrClick: true,
              transitionOut: "bounceOut",
            };
            toastr.light(
              "Error",
              "Phone not verified yet. Try again.",
              toastrOptions
            );
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const instructions = () => {
    if (code !== "") {
      return (
        <>
          <Label>
            {" "}
            <br></br>
            <strong>A call is on the way...</strong>
            <br></br>
            Enter the Verification Code into your phone.
          </Label>
        </>
      );
    } else {
      return <Label></Label>;
    }
  };

  const actionButton = () => {
    if (code !== "") {
      return (
        <>
          {loading ? (
            <Button color="success" type="button" disabled={true}>
              Checking...
            </Button>
          ) : (
            <>
              {/*   <Button type="submit" color="success" style={{}}>
              Verify Number
          </Button> */}
            </>
          )}
        </>
      );
    } else {
      return loading ? (
        <Button color="primary" type="button" disabled={true}>
          Calling You...
        </Button>
      ) : (
        <Button type="submit" color="primary" style={{}}>
          Call Me Now
        </Button>
      );
    }
  };

  const getField = () => {
    if (code === "") {
      return (
        <>
          <FormGroup>
            {/*             <Label
              for="extension"
              style={{
                marginTop: "0px",
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
                Extension
            </Label>{" "}
           <FormText>Optional</FormText>
               <Input
              type="text"
              name="extension"
              id="extension"
              style={{ width: "150px" }}
              innerRef={register({ pattern: /^[0-9]+$/ })}
            />{" "}
      */}
          </FormGroup>
          {errors.verify && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {errors.verify.message}
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <Label
            for="verify"
            style={{
              marginTop: "20px",
              display: "block",
              marginBottom: "0rem",
              fontWeight: "600",
            }}
          >
            Verification Code
          </Label>

          <div style={{ fontSize: "40px" }}> {code}</div>

          {errors.verify && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {errors.verify.message}
            </div>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    register({ name: "_id" });
    setValue("_id", props.item._id);
  }, [register, setValue, props.item._id]);
  return (
    <>
      <form
        onSubmit={
          code ? handleSubmit(submitVerifyToken) : handleSubmit(submitSendToken)
        }
      >
        <Row>
          <Col sm="12">
            {" "}
            To verify your Caller ID, Call Assist will give you a Verification
            code and then call the number above. You must answer the call and
            enter the Verification code.<br></br>
            <br></br>
            The Verification Code will appear once you click "Call Me Now".
            <br></br>
            {instructions()}
            {getField()}
            {loaderTwilo ? <Loader /> : <></>}
            <FormGroup>
              <Label style={{ fontSize: "40px", marginBottom: "0px" }}></Label>
            </FormGroup>
          </Col>
        </Row>
        <ModalFooter>{actionButton()}</ModalFooter>
      </form>
    </>
  );
}
FormVerify.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormVerify);
