import axios from "axios";
import { LOAD_TAG_LIST, TAG_LIST, UPDATE_TAG_LIST } from "./types";
import { toastr } from "react-redux-toastr";
export const getTags = () => (dispatch) => {
  dispatch({
    type: LOAD_TAG_LIST,
  });

  return axios
    .get(`/api/tag/dropdown`)
    .then((res) => {
      dispatch({
        type: TAG_LIST,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 2250,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light("Error", "Something went wrong", toastrOptions);
    });
};
export const addTag = (data) => (dispatch) => {
  return axios({
    method: "post",
    responseType: "json",
    url: `/api/tag/`,
    data: data,
  })
    .then((item) => {
      dispatch({
        type: UPDATE_TAG_LIST,
        payload: item.data,
      });
      const toastrType = "success";
      const toastrOptions = {
        timeOut: 2250,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light(
        "Success",
        "Tag has been created sucessfully",
        toastrOptions
      );
      return item.data;
    })
    .catch((err) => {
      const toastrType = "error";
      const toastrOptions = {
        timeOut: 2250,
        icon: toastrType,
        status: toastrType,
        progressBar: false,
        closeOnToastrClick: true,
        transitionOut: "bounceOut",
      };
      toastr.light("Error", "Something went wrong", toastrOptions);
    });
};
