import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import startsWith from "lodash.startswith";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  ModalFooter,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";

function FormAdd(props) {
  const [loading, setLoading] = useState(false);
  const { register, setValue, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      _id: 0,
      name: "",
      country: "",
      local: "",
      national: "",
    },
  });

  useEffect(() => {
    register({ name: "_id" });
    register({ name: "country" });
    register({ name: "national" });
    register({ name: "local" });
  }, [register]);

  const submitFormAdd = (form) => {
    if (form.national === "" || form.local === "" || form.country === "") {
      return setError("national", "required", "Phone number is required");
    }
    setLoading(true);
    axios({
      method: "post",
      responseType: "json",
      url: `/api/number/addphone`,
      data: form,
    })
      .then((item) => {
        setLoading(false);
        if (!item.data.error) {
          props.addOwnedToState(item.data);
          props.toggle();

          const toastrType = "success";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Success",
            "Phone Number Added sucessfully",
            toastrOptions
          );
        } else {
          const toastrType = "error";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Error",
            "Phone number has already been added.",
            toastrOptions
          );

          //  if (err.data.error.field === "duplicate") {
          return setError(
            item.data.error.field,
            "duplicate",
            item.data.error.message
          );
          //    }
        }
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong adding your number",
          toastrOptions
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitFormAdd)}>
        <Row>
          <Col sm="12">
            {" "}
            <h3 style={{ paddingBottom: "25px", paddingTop: "0px" }}>
              Verify a phone number you already own so it can be used as the
              caller ID when calling Leads.
            </h3>
            <FormGroup>
              <Label
                for="name"
                style={{
                  marginTop: "20px",
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Name
              </Label>
              <FormText>Just a way to identify this number.</FormText>
              <Input
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />

              {errors.name && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </FormGroup>
            <Label
              for="national"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Phone
            </Label>
            <PhoneInput
              enableSearch={true}
              disableInitialCountryGuess={false}
              jumpCursorToEnd={false}
              autoFormat={false}
              country={"us"}
              preferredCountries={["us", "ca", "uk"]}
              regions={["north-america", "europe"]}
              name="national"
              id="national"
              placeholder="Enter phone number"
              onChange={(phone, country, e, formattedValue) => {
                setValue("national", phone);
                setValue("country", country);
                setValue("local", phone.slice(country.dialCode.length));
              }}
              isValid={(value, country, countries) => {
                return countries.some((country) => {
                  return (
                    startsWith(value, country.dialCode) ||
                    startsWith(country.dialCode, value)
                  );
                });
              }}
              defaultErrorMessage={"Invalid Phone"}
              innerRef={register({ validate: (value) => value !== "" })}
            />
            {errors.national && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Phone number is required
              </div>
            )}
            {errors.duplicate && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors.duplicate.message}
              </div>
            )}
          </Col>
        </Row>
        <ModalFooter>
          {loading ? (
            <Button color="primary" type="button" disabled={true}>
              Saving...
            </Button>
          ) : (
            <Button color="primary">Add Phone</Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}
FormAdd.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormAdd);
