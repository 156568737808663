import {
    TEAM_USER_DROPDOWN,
    LOAD_TEAM_USER_DROPDOWN,
    TEAM_USER_DROPDOWN_SETTINGS,
    LOAD_TEAM_USER_DROPDOWN_SETTINGS,
    TEAM_USER_LIST,
    LOAD_TEAM_USER_LIST
  } from "../actions/types";

  const initialState = {
    teamDLoading: false,
    teamDSLoading: false,
    loading: false,
    teamDropdown: [],
    teamDSDropdown: {'list':[
        {"options":[]},
        {"options":[]}
    ], 'seeallteams': true},
    team:[]
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_TEAM_USER_DROPDOWN:
            return {
                ...state,
                teamDLoading: true,
            };
        case TEAM_USER_DROPDOWN:
            return {
                ...state,
                teamDLoading: false,
                teamDropdown: action.payload
            };
        case LOAD_TEAM_USER_DROPDOWN_SETTINGS:
            return {
                ...state,
                teamDSLoading: true,
            };
        case TEAM_USER_DROPDOWN_SETTINGS:
            return {
                ...state,
                teamDSLoading: false,
                teamDSDropdown: action.payload
            };
        case LOAD_TEAM_USER_LIST:
            return {
                ...state,
                loading: true,
            };
        case TEAM_USER_LIST:
            return {
                ...state,
                loading: false,
                team: action.payload
            };
        default:
            return state;
    }
  }