import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../../actions/authActions";
import classnames from "classnames";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardBody,
  Container,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import AuthorizeHeader from "../../../components/Headers/AuthorizeHeader.js";
import axios from "axios";

class Oauth extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      data: {},
      errorUrl: null,
      loading: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }
  componentDidMount() {
    const hashData = this.props.location.hash;
    const bufferData = new Buffer.from(hashData, "base64").toString("utf8");
    const bufferDataJson = JSON.parse(bufferData);
    if (bufferDataJson.error) {
      this.setState({ errorUrl: bufferDataJson.error });
    } else if (!bufferDataJson.application) {
      this.setState({ errorUrl: "Invalid authorization request." });
    } else {
      this.setState({ data: bufferDataJson });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData, true, false);
  };
  authenticated = () => {
    this.setState({ loading: true });
    if (this.state.data.redirect_uri) {
      const form = {};
      form.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      form.oauthData = this.state.data;
      axios({
        method: "post",
        responseType: "json",
        url: `/api/api_token/oauth_token`,
        data: form,
      })
        .then((item) => {
          if (item.data.token) {
            /*"&token_type=Bearer&expires_in=36000&scope=zap&state="*/
            window.location.href =
              this.state.data.redirect_uri +
              "?code=" +
              item.data.token +
              "&token_type=Bearer&state=" +
              this.state.data.state +
              "&account=" +
              item.data.user.email;
          } else {
            Swal.fire({
              text: "Not able to generate token",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          Swal.fire({
            text: "Server error",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };
  cancle = () => {
    if (this.state.data.redirect_uri) {
      window.location.href =
        this.state.data.redirect_uri + "?error=access_denied";
    }
  };
  render() {
    const { errors } = this.state;
    return (
      <>
        <AuthorizeHeader title="Authorize Request" />
        <div className="mt--8 pb-7">
          <Container>
            <div className="header-body text-center">
              <div className="justify-content-center">
                <Col lg="12" md="12">
                  <Col lg="8" md="10" className="zapier_div_col">
                    <div className="zapier_div_col_div">
                      <h1 className="text-black">Authorize Request</h1>
                    </div>
                    {this.state.errorUrl ? (
                      <Card
                        className="bg-secondary border-0 mb-0"
                        style={{ marginTop: "-9px" }}
                      >
                        <CardBody className="px-lg-5 py-lg-5">
                          <b style={{ color: "red" }}>{this.state.errorUrl}</b>
                        </CardBody>
                      </Card>
                    ) : (
                      <Card
                        className="bg-secondary border-0 mb-0"
                        style={{ marginTop: "-9px" }}
                      >
                        <CardBody className="px-lg-5 py-lg-5">
                          <p>
                            <b>
                              <strong>{this.state.data.application}</strong> is
                              requesting permission to access your account.
                            </b>
                          </p>
                          {this.props.auth.isAuthenticated ? (
                            <>
                              {this.state.loading ? (
                                <Button
                                  color="success"
                                  onClick={this.authenticated}
                                  disabled={true}
                                >
                                  Loading...
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  onClick={this.authenticated}
                                >
                                  Authorize
                                </Button>
                              )}
                              <Button color="danger" onClick={this.cancle}>
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <>
                              <Form noValidate onSubmit={this.onSubmit}>
                                <FormGroup
                                  className={classnames("mb-3", {
                                    focused: this.state.focusedEmail,
                                  })}
                                >
                                  <InputGroup className="input-group-merge input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-email-83" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      onChange={this.onChange}
                                      value={this.state.email}
                                      error={errors.email}
                                      id="email"
                                      placeholder="Email"
                                      type="email"
                                      className={classnames("", {
                                        invalid:
                                          errors.email || errors.emailnotfound,
                                      })}
                                      onFocus={() =>
                                        this.setState({ focusedEmail: true })
                                      }
                                      onBlur={() =>
                                        this.setState({ focusedEmail: false })
                                      }
                                    />
                                  </InputGroup>
                                  <span
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.email}
                                    {errors.emailnotfound}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className={classnames({
                                    focused: this.state.focusedPassword,
                                  })}
                                >
                                  <InputGroup className="input-group-merge input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-lock-circle-open" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      onChange={this.onChange}
                                      value={this.state.password}
                                      error={errors.password}
                                      id="password"
                                      placeholder="Password"
                                      type="password"
                                      className={classnames("", {
                                        invalid:
                                          errors.password ||
                                          errors.passwordincorrect,
                                      })}
                                      onFocus={() =>
                                        this.setState({ focusedEmail: true })
                                      }
                                      onBlur={() =>
                                        this.setState({ focusedEmail: false })
                                      }
                                    />
                                  </InputGroup>
                                  <span
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.password}
                                    {errors.passwordincorrect}
                                  </span>
                                </FormGroup>

                                <span
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.message}
                                </span>

                                <div className="text-center">
                                  <Button color="info" type="submit">
                                    Sign in
                                  </Button>
                                </div>

                                <div className="text-center text-muted mb-4">
                                  <small>Or sign in with</small>
                                </div>

                                <div className="btn-wrapper text-center">
                                  <Col className="facebookgooglebutton_col">
                                    <div className="facebookgooglebutton facebook">
                                      <Button
                                        className="btn-neutral btn-icon"
                                        color="default"
                                        href={
                                          process.env.REACT_APP_BACKEND +
                                          "/api/auth/facebook"
                                        }
                                        onClick={this.facebookSignin}
                                      >
                                        <span className="btn-inner--icon mr-1">
                                          <img
                                            alt="..."
                                            src={require("assets/img/icons/common/facebook.png")}
                                          />
                                        </span>
                                        <span className="btn-inner--text">
                                          {" "}
                                          Sign in with Facebook
                                        </span>
                                      </Button>
                                    </div>
                                  </Col>
                                  <Col className="facebookgooglebutton_col">
                                    <div className="facebookgooglebutton google">
                                      <Button
                                        className="btn-neutral btn-icon "
                                        color="default"
                                        href={
                                          process.env.REACT_APP_BACKEND +
                                          "/api/auth/google"
                                        }
                                        onClick={this.googleSignin}
                                      >
                                        <span className="btn-inner--icon mr-1">
                                          <img
                                            alt="..."
                                            src={require("assets/img/icons/common/google.svg")}
                                          />
                                        </span>
                                        <span className="btn-inner--text">
                                          {" "}
                                          Sign in with Google
                                        </span>
                                      </Button>
                                    </div>
                                  </Col>
                                </div>
                              </Form>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Col>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

/*
<a class="btn btn-block btn-facebook btn-social" href="/auth/facebook"><i
class="fab fa-facebook-f fa-sm"></i>Sign in with Facebook</a>
<a class="btn btn-block btn-google btn-social" href="/auth/google"><i
class="fab fa-google-plus-g fa-xs"></i>Sign in with Google</a>
*/

Oauth.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Oauth);
