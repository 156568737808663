import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Row, Col, Container, ModalFooter } from "reactstrap";
// core components
import ConditionalWrapper from "../components/ConditionalWrapper.js";
import CardsHeader from "components/Headers/CardsHeader.js";
import ModalTeam from "./ModalTeam";
import TableTeam from "./TableTeam";
import Loader from "layouts/Loader.js";
import { teamDropdownSettings, teamDropdown }  from "./../../../actions/teamAction";
function Teams(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getItems = (token, abortController) => {
    setLoading(true);
    // Fetch Teams
    fetch("/api/team/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const addItemToState = (item) => {
    setItems([...items, item]);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["team"] += 1;
      props.setStepList(stepList);
    }
    props.teamDropdown();
    props.teamDropdownSettings();
  };

  const updateState = (item) => {
    const itemIndex = items.findIndex((data) => data._id === item._id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
    props.teamDropdown();
    props.teamDropdownSettings();
  };

  const deleteItemFromState = (_id) => {
    const updatedItems = items.filter((item) => item._id !== _id);
    setItems(updatedItems);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["recording"] -= 1;
      props.setStepList(stepList);
    }
    props.teamDropdown();
    props.teamDropdownSettings();
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token]);

  return (
    <>
      {props.handleSkipButton ? "" : <CardsHeader name="Teams" />}

      <ConditionalWrapper
        condition={!props.handleSkipButton}
        wrapper={(children) => (
          <>
            {" "}
            <Container className="mt--6" fluid>
              <Row>
                <div className="col">
                  <div className="card-wrapper">{children}</div>
                </div>
              </Row>
            </Container>
          </>
        )}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg="12">
                {!loading ? (
                  <>
                    {props.auth.user.role !== "agent" ? (
                      <ModalTeam
                        buttonType="add"
                        buttonLabel="Add Team"
                        addItemToState={addItemToState}
                        item={{}}
                        headerTitle="Add Team"
                      />
                    ) : null}
                    <TableTeam
                      items={items}
                      updateState={updateState}
                      deleteItemFromState={deleteItemFromState}
                      hideColumn={props.handleSkipButton}
                      auth={props.auth}
                      settings={[
                        { width: 600, column: "all" },
                        { width: 500, column: 3 },
                        { width: 400, column: 2 },
                        { width: 0, column: 2 },
                      ]}
                    />{" "}
                  </>
                ) : (
                  <>
                    <Loader />
                  </>
                )}
              </Col>
            </Row>
            <hr />
          </CardBody>
        </Card>
        <ModalFooter>
          {props.handleSkipButton ? props.handleSkipButton() : <></>}
        </ModalFooter>
      </ConditionalWrapper>
    </>
  );
}
Teams.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  teamDropdownSettings,
  teamDropdown
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
