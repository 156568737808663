import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  FormText,
  ModalFooter,
  Container,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import Loader from "layouts/Loader";
var classNames = require("classnames");

function Settings(props) {
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [items, setItems] = useState([]);

  const { register, setValue, handleSubmit, errors, setError } = useForm({
    defaultValues: {},
    mode: "all",
  });

  useEffect(() => {
    setLoading(true);
    const token = props.auth.user.token;
    async function fetchData(abortController) {
      await fetch("/api/setup/", {
        signal: abortController.signal,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((items) => {
          if (items) {
            setItems(items);
            setLoading(false);
            setValue("_id", items._id);
            //    setValue("amdEnabled", items.amdEnabled);
            setValue("rescheduleTime", items.rescheduleTime);
            setValue("autoOwner", items.autoOwner);
            setValue("autoTeam", items.autoTeam);
            setValue("seeAllteams", items.seeAllteams);
            setValue("leadRouting", items.leadRouting);
            setValue("agentPermission", items.agentPermission);
            setValue("playUnknown", items.playUnknown);
            setLoading(false);
          }
        })
        .catch((err) => {});
    }
    const abortController = new window.AbortController();
    fetchData(abortController);

    register({ name: "_id" }); // custom register
    register({ name: "timeout" }); // custom register
    return () => {
      abortController.abort();
    };
  }, [register, setValue, props.auth.user.token]);

  const [times] = React.useState([
    { value: "00:00:00", label: "12:00 am" },
    { value: "00:15:00", label: "12:15 am" },
    { value: "00:30:00", label: "12:30 am" },
    { value: "00:45:00", label: "12:45 am" },
    { value: "01:00:00", label: "1:00 am" },
    { value: "01:15:00", label: "1:15 am" },
    { value: "01:30:00", label: "1:30 am" },
    { value: "01:45:00", label: "1:45 am" },
    { value: "02:00:00", label: "2:00 am" },
    { value: "02:15:00", label: "2:15 am" },
    { value: "02:30:00", label: "2:30 am" },
    { value: "02:45:00", label: "2:45 am" },
    { value: "03:00:00", label: "3:00 am" },
    { value: "03:15:00", label: "3:15 am" },
    { value: "03:30:00", label: "3:30 am" },
    { value: "03:45:00", label: "3:45 am" },
    { value: "04:00:00", label: "4:00 am" },
    { value: "04:15:00", label: "4:15 am" },
    { value: "04:30:00", label: "4:30 am" },
    { value: "04:45:00", label: "4:45 am" },
    { value: "05:00:00", label: "5:00 am" },
    { value: "05:15:00", label: "5:15 am" },
    { value: "05:30:00", label: "5:30 am" },
    { value: "05:45:00", label: "5:45 am" },
    { value: "06:00:00", label: "6:00 am" },
    { value: "06:15:00", label: "6:15 am" },
    { value: "06:30:00", label: "6:30 am" },
    { value: "06:45:00", label: "6:45 am" },
    { value: "07:00:00", label: "7:00 am" },
    { value: "07:15:00", label: "7:15 am" },
    { value: "07:30:00", label: "7:30 am" },
    { value: "07:45:00", label: "7:45 am" },
    { value: "08:00:00", label: "8:00 am" },
    { value: "08:15:00", label: "8:15 am" },
    { value: "08:30:00", label: "8:30 am" },
    { value: "08:45:00", label: "8:45 am" },
    { value: "09:00:00", label: "9:00 am" },
    { value: "09:15:00", label: "9:15 am" },
    { value: "09:30:00", label: "9:30 am" },
    { value: "09:45:00", label: "9:45 am" },
    { value: "10:00:00", label: "10:00 am" },
    { value: "10:15:00", label: "10:15 am" },
    { value: "10:30:00", label: "10:30 am" },
    { value: "10:45:00", label: "10:45 am" },
    { value: "11:00:00", label: "11:00 am" },
    { value: "11:15:00", label: "11:15 am" },
    { value: "11:30:00", label: "11:30 am" },
    { value: "11:45:00", label: "11:45 am" },
    { value: "12:00:00", label: "12:00 pm" },
    { value: "12:15:00", label: "12:15 pm" },
    { value: "12:30:00", label: "12:30 pm" },
    { value: "12:45:00", label: "12:45 pm" },
    { value: "13:00:00", label: "1:00 pm" },
    { value: "13:15:00", label: "1:15 pm" },
    { value: "13:30:00", label: "1:30 pm" },
    { value: "13:45:00", label: "1:45 pm" },
    { value: "14:00:00", label: "2:00 pm" },
    { value: "14:15:00", label: "2:15 pm" },
    { value: "14:30:00", label: "2:30 pm" },
    { value: "14:45:00", label: "2:45 pm" },
    { value: "15:00:00", label: "3:00 pm" },
    { value: "15:15:00", label: "3:15 pm" },
    { value: "15:30:00", label: "3:30 pm" },
    { value: "15:45:00", label: "3:45 pm" },
    { value: "16:00:00", label: "4:00 pm" },
    { value: "16:15:00", label: "4:15 pm" },
    { value: "16:30:00", label: "4:30 pm" },
    { value: "16:45:00", label: "4:45 pm" },
    { value: "17:00:00", label: "5:00 pm" },
    { value: "17:15:00", label: "5:15 pm" },
    { value: "17:30:00", label: "5:30 pm" },
    { value: "17:45:00", label: "5:45 pm" },
    { value: "18:00:00", label: "6:00 pm" },
    { value: "18:15:00", label: "6:15 pm" },
    { value: "18:30:00", label: "6:30 pm" },
    { value: "18:45:00", label: "6:45 pm" },
    { value: "19:00:00", label: "7:00 pm" },
    { value: "19:15:00", label: "7:15 pm" },
    { value: "19:30:00", label: "7:30 pm" },
    { value: "19:45:00", label: "7:45 pm" },
    { value: "20:00:00", label: "8:00 pm" },
    { value: "20:15:00", label: "8:15 pm" },
    { value: "20:30:00", label: "8:30 pm" },
    { value: "20:45:00", label: "8:45 pm" },
    { value: "21:00:00", label: "9:00 pm" },
    { value: "21:15:00", label: "9:15 pm" },
    { value: "21:30:00", label: "9:30 pm" },
    { value: "21:45:00", label: "9:45 pm" },
    { value: "22:00:00", label: "10:00 pm" },
    { value: "22:15:00", label: "10:15 pm" },
    { value: "22:30:00", label: "10:30 pm" },
    { value: "22:45:00", label: "10:45 pm" },
    { value: "23:00:00", label: "11:00 pm" },
    { value: "23:15:00", label: "11:15 pm" },
    { value: "23:30:00", label: "11:30 pm" },
    { value: "23:45:00", label: "11:45 pm" },
    { value: "23:59:00", label: "11:59 pm" },
  ]);

  const submitEdit = (form) => {
    setBtnLoading(true);
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/setup/" + form._id + "/update",
      data: form,
    })
      .then((item) => {
        setBtnLoading(false);
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Advanced Settings have been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setBtnLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Team",
          toastrOptions
        );
        if (
          err.response.data &&
          !Array.isArray(err.response.data) &&
          Object.keys(err.response.data).length > 0
        ) {
          Object.keys(err.response.data).map((error, key) => {
            setError(error, "server", err.response.data[error]);
            return null;
          });
        } else {
        }
      });
  };

  // const enableAMD = watch("amdEnabled", true);

  return (
    <>
      <CardsHeader name="Advanced Configuration" />
      {!loading ? (
        <>
          <form onSubmit={handleSubmit(submitEdit)}>
            <Container className="mt--6" fluid>
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Rescheduled Time</h3>
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      {" "}
                      <Row>
                        <Col sm="12">
                          <FormText>
                            The time to scheduled a new call attempt when a call
                            fails because no agents are on call.
                          </FormText>
                          <select
                            name="rescheduleTime"
                            defaultValue={items.rescheduleTime}
                            className={classNames({
                              error: errors.rescheduleTime,
                            })}
                            ref={register()}
                          >
                            {times.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>

            <Container fluid>
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Lead Settings</h3>
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      {" "}
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              display: "block",
                              marginBottom: "0rem",
                              fontWeight: "600",
                            }}
                          >
                            Auto Lead Owner
                          </Label>
                          <FormText>
                            The first Agent to call a Lead will be assigned as
                            the Lead's Owner.
                          </FormText>
                          <FormGroup>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              Off
                            </span>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                placeholder="autoOwner"
                                name="autoOwner"
                                ref={register()}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "35px",
                              }}
                            >
                              On
                            </span>
                          </FormGroup>

                          <Label
                            style={{
                              display: "block",
                              marginBottom: "0rem",
                              fontWeight: "600",
                            }}
                          >
                            Auto Team Owner
                          </Label>
                          <FormText>
                            The first Team to call a Lead will be assigned as
                            the Lead's Team.
                          </FormText>
                          <FormGroup>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              Off
                            </span>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                placeholder="autoTeam"
                                name="autoTeam"
                                ref={register()}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "35px",
                              }}
                            >
                              On
                            </span>
                          </FormGroup>

                          <Label
                            style={{
                              display: "block",
                              marginBottom: "0rem",
                              fontWeight: "600",
                            }}
                          >
                            Lead Owner Routing
                          </Label>
                          <FormGroup>
                            <>
                              <div
                                className="input-group"
                                style={{ background: "#eee" }}
                              >
                                <span
                                  className="input-group-addon"
                                  style={{ border: "none", paddingTop: "17px" }}
                                >
                                  If a Lead has a Lead Owner
                                </span>
                                <select
                                  name="leadRouting"
                                  defaultValue={items.leadRouting}
                                  className={classNames("form-control", {
                                    error: errors.leadRouting,
                                  })}
                                  ref={register()}
                                >
                                  <option value="team">
                                    call the Team in priority order
                                  </option>
                                  <option value="ownerteam">
                                    call the Lead Owner first, then the rest of
                                    the Team
                                  </option>
                                  <option value="owner">
                                    only call the Lead Owner
                                  </option>
                                </select>
                              </div>
                            </>
                          </FormGroup>

                          <Label
                            style={{
                              display: "block",
                              marginBottom: "0rem",
                              fontWeight: "600",
                            }}
                          >
                            Lead & Call Permissions
                          </Label>
                          <FormText>
                            Control which leads & calls users with an "Agent"
                            role can view. Admins can view all leads & calls.
                          </FormText>
                          <FormGroup>
                            <>
                              <div
                                className="input-group"
                                style={{ background: "#eee" }}
                              >
                                <span
                                  className="input-group-addon"
                                  style={{ border: "none", paddingTop: "17px" }}
                                >
                                  Agents can access
                                </span>
                                <select
                                  name="agentPermission"
                                  defaultValue={items.agentPermission}
                                  className={classNames("form-control", {
                                    error: errors.agentPermission,
                                  })}
                                  ref={register()}
                                >
                                  <option value="all">ALL Calls & Leads</option>
                                  <option value="team">
                                    THEIR TEAMS Calls & Leads
                                  </option>
                                  <option value="own">
                                    ONLY THEIR Calls & Leads
                                  </option>
                                </select>
                              </div>
                            </>
                          </FormGroup>

                          <Label
                            style={{
                              display: "block",
                              marginBottom: "0rem",
                              fontWeight: "600",
                            }}
                          >
                            Agents Can See All Teams
                          </Label>
                          <FormText>
                            <b>Yes</b>: Users with an Agent role can assign
                            leads and create calls for all teams.
                            <br></br>
                            <b>No</b>: Users with an Agent role can assign leads
                            and create calls only for teams they are a member.
                          </FormText>
                          <FormGroup>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              No
                            </span>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                placeholder="seeAllteams"
                                name="seeAllteams"
                                ref={register()}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "35px",
                              }}
                            >
                              Yes
                            </span>
                          </FormGroup>
                          {!["basic"].indexOf(
                            props.billing.payload.stripePlanId
                          ) ? (
                            <>
                              <Label
                                style={{
                                  display: "block",
                                  marginBottom: "0rem",
                                  fontWeight: "600",
                                }}
                              >
                                Play for Unknown
                              </Label>
                              <FormText>
                                Whether you want the recorded sales message to
                                play if Answering Machine Detection fails to
                                determine if a Human or a Machine answered.
                                <br></br>
                                <br></br>
                                <b>Default Value</b>: Checked / True
                              </FormText>
                              <FormGroup>
                                <span
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  No
                                </span>
                                <label className="custom-toggle">
                                  <input
                                    type="checkbox"
                                    name="playUnknown"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                    ref={register()}
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <span
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "35px",
                                  }}
                                >
                                  Yes
                                </span>
                              </FormGroup>
                            </>
                          ) : (
                            <input
                              type="hidden"
                              name="playUnknown"
                              ref={register()}
                            />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <ModalFooter>
                      {btnloading ? (
                        <Button color="default" type="button" disabled={true}>
                          Saving...
                        </Button>
                      ) : (
                        <Button color="default" type="submit">
                          Save
                        </Button>
                      )}
                    </ModalFooter>
                  </Card>
                </Col>
              </Row>
            </Container>

            {/*

            <Container fluid>
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Answering Machine Detection</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              display: "block",
                              marginBottom: "0rem",
                              fontWeight: "600",
                            }}
                          >
                            Enable Answering Machine Detection (Default On)
                          </Label>
                          <FormText>
                            This will ensure you do not leave unintended voice
                            messages. Your message will only play when a human
                            answer is detected. Enabling AMD usually results in
                            a short delay before your message will play as the
                            system detects who answered. Disabling AMD will play
                            your message about 1 second after phone is answered.
                            This can result in a smoother transition from human
                            answering to message playing but you will leave
                            messages on occasion when your phone call goes to
                            voicemail.
                          </FormText>
                          <FormGroup>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              Off
                            </span>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                placeholder="amdEnabled"
                                name="amdEnabled"
                                ref={register()}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "35px",
                              }}
                            >
                              On
                            </span>
                          </FormGroup>

                          {enableAMD && (
                            <>
                              <hr></hr>
                              <h3
                                style={{
                                  paddingBottom: "0px",
                                  marginTop: "0px",
                                  paddingTop: "0px",
                                }}
                              >
                                Answering Machine Detection Instructions
                              </h3>
                              <FormText>
                                The following settings control Answering Machine
                                Detection. Typically, it's best to leave these
                                alone.
                                <br></br>
                                <br></br>In general, lower numbers mean faster
                                detection and less waiting before the message
                                plays. At the same time lower settings may cause
                                more false detection of human vs answering
                                machines and may cause unintended voicemails to
                                be left.<br></br>
                                <br></br> Increasing the number will create more
                                accurate detection but will create a delay after
                                a human answers the phone and says "hello". This
                                type of delay can cause humans to hang up early.
                              </FormText>
                              <hr></hr>
                              <FormGroup>
                                <h3
                                  style={{
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                    paddingTop: "0px",
                                  }}
                                >
                                  Timeout
                                </h3>

                                <FormText>
                                  The number of seconds to attempt to perform
                                  answering machine detection before timing out
                                  and returning and returning AnsweredBy as
                                  unknown.
                                  <br></br>
                                  <br></br>
                                  Increasing this value will provide the engine
                                  more time to make a determination.
                                  <br></br>
                                  <br></br>
                                  Decreasing this value will reduce the amount
                                  of time the engine has to make a
                                  determination. This can be useful particularly
                                  if you want to limit the time for initial
                                  detection.
                                  <br></br>
                                  <br></br>
                                  <b>Between</b> 3 and 59
                                  <br></br>
                                  <br></br>
                                  <b>Default Value</b>: 30
                                </FormText>
                                <Input
                                  type="text"
                                  name="timeout"
                                  id="timeout"
                                  className={classNames({
                                    error: errors.timeout,
                                  })}
                                  defaultValue={items.timeout}
                                  innerRef={register({
                                    required: "Timeout is required",
                                    pattern: {
                                      value: /^([3-9]|[1234][0-9]|5[0-9])$/,
                                      message:
                                        "Must provice a value between 3 and 59.",
                                    },
                                  })}
                                />

                                {errors.timeout && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.timeout.message}
                                  </div>
                                )}
                              </FormGroup>

                              <FormGroup>
                                <h3
                                  style={{
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                    paddingTop: "0px",
                                  }}
                                >
                                  Speech Threshold
                                </h3>

                                <FormText>
                                  The number of milliseconds that is used as the
                                  measuring stick for the length of the speech
                                  activity. Durations lower than this value will
                                  be interpreted as a human, and longer than
                                  this value as a machine.
                                  <br></br>
                                  <br></br>
                                  Increasing this value will reduce the chance
                                  of a False Machine (detected machine, actually
                                  human) for a long human greeting (e.g. a
                                  business greeting) but increase the time it
                                  takes to detect a machine.
                                  <br></br>
                                  <br></br>
                                  Decreasing this value will reduce the chances
                                  of a False Human (detected human, actually
                                  machine) for short voicemail greetings. (Note
                                  that the value of this parameter may need to
                                  be reduced by more than 1000ms to detect very
                                  short voicemail greetings. A reduction of that
                                  significance can result in increased False
                                  Machine detections. Adjusting the
                                  <b>Speech Threshold</b> is likely the better
                                  approach for short voicemails.) Decreasing{" "}
                                  <b>Speech End Threshold</b> will also reduce
                                  the time it takes to detect a machine.
                                  <br></br>
                                  <br></br>
                                  <b>Between</b> 1000 and 6000
                                  <br></br>
                                  <br></br>
                                  <b>Default Value</b>: 2500
                                </FormText>
                                <Input
                                  type="text"
                                  name="speechThreshold"
                                  id="speechThreshold"
                                  className={classNames({
                                    error: errors.speechThreshold,
                                  })}
                                  defaultValue={items.speechThreshold}
                                  innerRef={register({
                                    required: "Speech Threshold is required",
                                    pattern: {
                                      value: /^([1-5][0-9]{3}|6000)$/,
                                      message:
                                        "Must provice a value between 1000 and 6000.",
                                    },
                                  })}
                                />

                                {errors.speechThreshold && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.speechThreshold.message}
                                  </div>
                                )}
                              </FormGroup>

                              <FormGroup>
                                <h3
                                  style={{
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                    paddingTop: "0px",
                                  }}
                                >
                                  Speech End Threshold
                                </h3>

                                <FormText>
                                  The number of milliseconds of silence after
                                  speech activity at which point the speech
                                  activity is considered complete.
                                  <br></br>
                                  <br></br>
                                  <b>Increasing</b> this value will typically be
                                  used to better address the short voicemail
                                  greeting scenarios. For short voicemails,
                                  there is typically 1000ms-2000ms of audio
                                  following by 1200ms-2400ms of silence and then
                                  additional audio before the beep. Increasing
                                  the
                                  <b>Speech End Threshold</b> to ~2500ms will
                                  treat the 1200ms-2400ms of silence as a gap in
                                  the greeting but not the end of the greeting
                                  and will result in a machine detection. The
                                  downsides of such a change include:
                                  <br></br>
                                  <br></br>
                                  <li>
                                    Increasing the delay for human detection by
                                    the amount you increase this parameter (e.g.
                                    change of 1200ms to 2500ms increases human
                                    detection delay by 1300ms).
                                  </li>
                                  <li>
                                    In cases where a human has two utterances
                                    separated by a period of silence (e.g. A
                                    “Hello”, then 2000ms seconds of silence and
                                    another “Hello”), it may be interpreted as a
                                    machine.
                                  </li>
                                  <br></br>
                                  <br></br>
                                  <b>Decreasing</b> this value will result in
                                  faster human detection. The consequence is
                                  that it can lead to increased False Human
                                  (detected human, actually machine) detections
                                  because a silence gap in a voicemail greeting
                                  (not necessarily just in short voicemail
                                  scenarios) can be incorrectly interpreted as
                                  the end of speech.
                                  <br></br>
                                  <br></br>
                                  <b>Between</b> 500 and 5000
                                  <br></br>
                                  <br></br>
                                  <b>Default Value</b>: 1500
                                </FormText>
                                <Input
                                  type="text"
                                  name="speechEndthreshold"
                                  id="speechEndthreshold"
                                  className={classNames({
                                    error: errors.speechEndthreshold,
                                  })}
                                  defaultValue={items.speechEndthreshold}
                                  innerRef={register({
                                    required:
                                      "Speech End Threshold is required",
                                    pattern: {
                                      value: /^([5-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-4][0-9]{3}|5000)$/,
                                      message:
                                        "Must provice a value between 500 and 5000.",
                                    },
                                  })}
                                />

                                {errors.speechEndthreshold && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.speechEndthreshold.message}
                                  </div>
                                )}
                              </FormGroup>

                              <FormGroup>
                                <h3
                                  style={{
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                    paddingTop: "0px",
                                  }}
                                >
                                  Silence Timeout
                                </h3>

                                <FormText>
                                  The number of milliseconds of initial silence
                                  after which an unknown AnsweredBy result will
                                  be returned
                                  <br></br>
                                  <br></br>
                                  Increasing this value will result in waiting
                                  for a longer period of initial silence before
                                  returning an ‘unknown’ AMD result.
                                  <br></br>
                                  <br></br>
                                  Decreasing this value will result in waiting
                                  for a shorter period of initial silence before
                                  returning an ‘unknown’ AMD result.
                                  <br></br>
                                  <br></br>
                                  <b>Between</b> 2000 and 10000
                                  <br></br>
                                  <br></br>
                                  <b>Default Value</b>: 4000
                                </FormText>
                                <Input
                                  type="text"
                                  name="silenceTimeout"
                                  id="silenceTimeout"
                                  className={classNames({
                                    error: errors.silenceTimeout,
                                  })}
                                  defaultValue={items.silenceTimeout}
                                  innerRef={register({
                                    required: "Silence Timeout is required",
                                    pattern: {
                                      value: /([2-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|10000)$/,
                                      message:
                                        "Must provice a value between 2000 and 10000.",
                                    },
                                  })}
                                />

                                {errors.silenceTimeout && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.silenceTimeout.message}
                                  </div>
                                )}
                              </FormGroup>

                              <Label
                                style={{
                                  display: "block",
                                  marginBottom: "0rem",
                                  fontWeight: "600",
                                }}
                              >
                                Play for Unknown
                              </Label>
                              <FormText>
                                Whether you want the Voice notification to play
                                when Answering Machine Detection fails to
                                determine if a Human or a Machine answered the
                                phone.
                                <br></br>
                                <br></br>
                                <b>Default Value</b>: Checked / True
                              </FormText>
                              <FormGroup>
                                <span
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  No
                                </span>
                                <label className="custom-toggle">
                                  <input
                                    type="checkbox"
                                    name="playUnknown"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                    ref={register()}
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <span
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "35px",
                                  }}
                                >
                                  Yes
                                </span>
                              </FormGroup>
                            </>
                          )}
                        </Col>
                      </Row>

                      <ModalFooter>
                        <Button color="default">Save</Button>
                      </ModalFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>

            */}
          </form>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
Settings.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  billing: state.billing,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
