import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  FormText,
  ModalFooter,
  Container,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
var classNames = require("classnames");

function Settings(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const { register, setValue, handleSubmit, errors, setError } = useForm(
    {
      defaultValues: {},
      mode: "all",
    }
  );

  useEffect(() => {
    setLoading(true);
    const token = props.auth.user.token;
    async function fetchData() {
      await fetch("/api/agency/", {
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((items) => {
          if (items) {
            setItems(items);
            setValue("_id", items._id);
            setValue("name", items.name);
            setValue("email", items.email);
            setValue("logo", items.logo);
            setValue("domain", items.domain);
            setValue("ns01", items.ns01);
            setValue("ns02", items.ns02);
            setLoading(false);
          }
        })
        .catch((err) => {});
    }

    fetchData();
  }, [register, setValue, props.auth.user.token]);

  const submitEdit = (form) => {
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/agency/" + form._id + "/update",
      data: form,
    })
      .then((item) => {
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Agency Settings have been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Team",
          toastrOptions
        );
        if (
          err.response.data &&
          !Array.isArray(err.response.data) &&
          Object.keys(err.response.data).length > 0
        ) {
          Object.keys(err.response.data).map((error, key) => {
            setError(error, "server", err.response.data[error]);
            return null;
          });
        } else {
        }
      });
  };

  // const enableAMD = watch("amdEnabled", true);

  return (
    <>
      <CardsHeader name="Agency Setup" />
      {!loading ? (
        <>
          <form onSubmit={handleSubmit(submitEdit)}>
            <Container className="mt--6" fluid>
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            {" "}
                            Agency Settings allow you to whitelable Call Assit
                            for your clients.<br></br> This will make Call
                            Assist look like an application that you own /
                            control.
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <h3
                          style={{
                            paddingBottom: "0px",
                            marginTop: "0px",
                            paddingTop: "0px",
                          }}
                        >
                          Agency Name
                        </h3>

                        <FormText>
                          This name will replace "Call Assist" accross the
                          application.
                        </FormText>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          className={classNames({
                            error: errors.name,
                          })}
                          defaultValue={items.name}
                          innerRef={register({
                            required: "Agency Name is required",
                          })}
                        />

                        {errors.name && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.name.message}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <h3
                          style={{
                            paddingBottom: "0px",
                            marginTop: "0px",
                            paddingTop: "0px",
                          }}
                        >
                          Agency Email
                        </h3>

                        <FormText>
                          The email address you would like customer to reach you
                          at.
                        </FormText>
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          className={classNames({
                            error: errors.email,
                          })}
                          defaultValue={items.email}
                          innerRef={register({
                            required: "Agency Email is required",
                          })}
                        />

                        {errors.email && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.email.message}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <h3
                          style={{
                            paddingBottom: "0px",
                            marginTop: "0px",
                            paddingTop: "0px",
                          }}
                        >
                          Agency Logo URL
                        </h3>

                        <FormText>
                          This logo will appear in the top left inplace of the
                          Call Assist logo. To avoid SSL errors it must be
                          served on an https:// server.
                        </FormText>
                        <Input
                          type="text"
                          name="logo"
                          id="logo"
                          className={classNames({
                            error: errors.logo,
                          })}
                          defaultValue={items.logo}
                          innerRef={register({
                            required: "Agency Logo is required",
                            pattern: {
                              value: /^(https):\/\/.*$/,
                              message: "Must start with https://",
                            },
                          })}
                        />

                        {errors.name && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.name.message}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <h3
                          style={{
                            paddingBottom: "0px",
                            marginTop: "0px",
                            paddingTop: "0px",
                          }}
                        >
                          Agency SubDomain
                        </h3>

                        <FormText>
                          The subdomain you would like your clients to be able
                          to access the white labeled version of Call Assist
                          from. <br></br>
                          <br></br>
                          <strong>Format: </strong>
                          https://xxx.yourdomain.com <br></br>{" "}
                          <strong> where:</strong> <br></br>
                          <em>"xxx"</em> is a subdomain name of your choice (any
                          length)
                          <br></br> <em>"yourdomain.com"</em> is your domain{" "}
                          <br></br>
                          <br></br>You will need to configure DNS records to
                          point to Call Assist. <br></br> <br></br> Instructions
                          provided after saving. This value must match the
                          domain you plan to forward exactly.
                        </FormText>
                        <Input
                          type="text"
                          name="domain"
                          id="domain"
                          className={classNames({
                            error: errors.domain,
                          })}
                          defaultValue={items.domain}
                          innerRef={register({
                            pattern: {
                              value: /^(https):\/\/.*$/,
                              message: "Must start with https://",
                            },
                          })}
                        />

                        {errors.domain && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.domain.message}
                          </div>
                        )}
                      </FormGroup>
                    </CardBody>
                    <ModalFooter>
                      <Button color="default">Save</Button>
                    </ModalFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </form>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
Settings.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
