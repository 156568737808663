//import Components from "views/pages/forms/Components.js"; This has examples of how to include data but we need actual data
import Dashboard from "views/pages/Dashboards/Dashboard.js";
import Calls from "views/pages/Calls/Calls.js";
import Leads from "views/pages/Leads/Leads.js";
import ReportCalls from "views/pages/Reports/Calls.js";
import ReportLeads from "views/pages/Reports/Leads.js";
import ReportInbound from "views/pages/Reports/Inbound.js";
import Numbers from "views/pages/Numbers/Numbers.js";
import Agents from "views/pages/Agents/Agents.js";
import Teams from "views/pages/Teams/Teams.js";
import Recordings from "views/pages/Recordings/Recordings.js";
import Integration from "views/pages/Integration/Integration.js";
//import Locations from "views/pages/Settings/Locations.js";
import Billing from "views/pages/Settings/Billing.js";
import Register from "views/pages/Users/Register.js";
import Login from "views/pages/Users/Login.js";
import Forgot from "views/pages/Users/Forgot.js";
import Reset from "views/pages/Users/Reset.js";
import Profile from "views/pages/Users/Profile.js";
import Setup from "views/pages/Settings/Setup.js";
import Oauth from "views/pages/Authorize/Oauth.js";
import Settings from "views/pages/Settings/Settings.js";
import Agency from "views/pages/Settings/Agency.js";
import ApiToken from "views/pages/Settings/ApiToken/ApiToken.js";

import Admin from "views/pages/Admin/Admin.js";

import Index from "views/Index.js";
//ni ni-chart-pie-35
const routes = {
  sideMenu: [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "fa fas fa-chart-pie  text-primary",
      component: Dashboard,
      layout: "/account",
    },
    {
      path: "/calls",
      name: "Calls",
      icon: "fa fas fa-phone-volume  text-green",
      component: Calls,
      layout: "/account",
    },
    {
      path: "/leads",
      name: "Leads",
      icon: "fa fas fa-users text-info",
      component: Leads,
      layout: "/account",
    },
    {
      collapse: true,
      noAllow: ["agent"],
      name: "Reports",
      icon: "fa fas fa-chart-line text-pink",
      state: "reportsCollapse",
      views: [
        {
          path: "/report/calls",
          name: "Calls",
          miniName: "",
          component: ReportCalls,
          layout: "/account",
        },
        {
          path: "/report/leads",
          name: "Leads",
          miniName: "",
          component: ReportLeads,
          layout: "/account",
        },
        {
          path: "/report/inbound",
          name: "Inbound",
          miniName: "",
          component: ReportInbound,
          layout: "/account",
        },
      ],
    },
    {
      path: "",
      name: "",
      icon: "",
      component: "empty",
      layout: "",
    },
    {
      path: "/agents",
      name: "Agents",
      icon: "ni ni-badge",
      component: Agents,
      layout: "/account",
    },
    {
      path: "/teams",
      name: "Teams",
      icon: "fa fas fa-sitemap text-purple",
      component: Teams,
      layout: "/account",
    },
    {
      path: "/recordings",
      name: "Recordings",
      icon: "fa fas fa-microphone-alt text-orange",
      component: Recordings,
      layout: "/account",
      noAllow: ["agent"],
    },
    {
      path: "",
      name: "",
      icon: "",
      component: "empty",
      layout: "",
    },

    {
      path: "/integration",
      name: "Integration",
      icon: "fa fas fa-plug text-default",
      component: Integration,
      layout: "/account",
      noAllow: ["agent"],
    },
    {
      collapse: true,
      noAllow: ["agent"],
      name: "Settings",
      icon: "ni ni-settings text-danger",
      state: "settingsCollapse",
      views: [
        {
          path: "/numbers",
          name: "Phone Numbers",
          miniName: "",
          component: Numbers,
          layout: "/account",
        },
        {
          path: "/advanced",
          name: "Advanced Config",
          miniName: "",
          component: Settings,
          layout: "/account",
        },

        {
          path: "/api_token",
          name: "Api Tokens",
          miniName: "",
          component: ApiToken,
          layout: "/account",
        },
        {
          path: "/agency",
          name: "Agency",
          miniName: "",
          component: Agency,
          layout: "/account",
        },
        {
          path: "/billing",
          name: "Billing",
          miniName: "",
          component: Billing,
          layout: "/account",
        },
      ],
    },
    {
      path: "/admin",
      name: "Admin",
      noAllow: ["agent", "admin", "owner"],
      icon: "fas fa-user-shield text-primary",
      component: Admin,
      layout: "/account",
    },
  ],

  accountMenu: [
    {
      path: "/index",
      component: Index,
      layout: "/account",
    },
    {
      path: "/profile",
      component: Profile,
      layout: "/account",
    },
  ],

  authMenu: [
    {
      path: "/register",
      component: Register,
      layout: "/auth",
    },
    {
      path: "/login",
      component: Login,
      layout: "/auth",
    },
    {
      path: "/forgot",
      component: Forgot,
      layout: "/auth",
    },
    {
      path: "/reset/:token",
      component: Reset,
      layout: "/auth",
    },
  ],

  initialSetup: [
    {
      path: "/setup",
      component: Setup,
      layout: "/getstarted",
      noAllow: ["agent"],
    },
  ],

  authorizeSetup: [
    {
      path: "/oauth",
      component: Oauth,
      layout: "/authorize",
    },
  ],
};

export default routes;
