import { LOAD_TAG_LIST, ACTION_LOAD_TAG_LIST, UPDATE_TAG_LIST, TAG_LIST } from "../actions/types";
const initialState = {
    loading:false,
    actionloading: false,
    tags:[]
};
export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_TAG_LIST:
          return {
            ...state,
            loading: true
          };
        case TAG_LIST: {
            return {
                ...state,
                tags: action.payload,
                loading: false
              };
        }
        case ACTION_LOAD_TAG_LIST: {
            return {
                ...state,
                actionloading: true
              };
        }
        case UPDATE_TAG_LIST:
          return {
            ...state,
            tags: [...state.tags, action.payload],
            actionloading: false
          };       
        default:
          return state;
      }
}