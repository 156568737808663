import React, { useEffect, useState } from "react";
import Select from "react-select";
import TagDropdown from "./../components/TagDropdown";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Label,
  Input,
  Row,
  Col,
  ModalFooter,
  FormGroup,
  FormText,
} from "reactstrap";
import { addTag } from "../../../actions/tagActions";
import { useForm } from "react-hook-form";
import startsWith from "lodash.startswith";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";

//const MySwal = withReactContent(Swal);
function FormLeadEdit(props) {
  //const [owner, setOwner] = useState({});
  //const [team, setTeam] = useState({});
  const [status, setStatus] = useState({});
  //const [result, setResult] = useState({});
  const [tags, setTags] = useState({});
  const [ loading, setLoading ] = useState(false);
  const optionsResult = [
    { value: "Unknown", label: "Unknown" },
    { value: "Answered", label: "Answered" },
    { value: "Contacted", label: "Contacted" },
    { value: "Voicemail", label: "Voicemail" },
    { value: "Missed", label: "Missed" },
    { value: "Removed", label: "Removed" },
  ];

  const optionsStatus = [
    { value: "Waiting", label: "Waiting" },
    { value: "Called", label: "Called" },
    { value: "Not Called", label: "Not Called" },
    { value: "Skipped", label: "Skipped" },
    { value: "Error", label: "Error" },
  ];

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    errors,
    clearError,
    setError,
  } = useForm({
    defaultValues: {
      external_id: "",
      _id: 0,
      first: "",
      last: "",
      country: "",
      local: "",
      national: "",
      email: "",
      company: "",
      category: "",
      comments: "",
      tags: [],
    },
  });
  const handleCreate = (inputValue) => {
    props.addTag({ name: inputValue }).then((item) => {
      setValue("tags", [...getValues("tags"), item]);
      setTags([...tags, item]);
    });
  };

  // const options = [];
  useEffect(() => {
    register({ name: "_id" });
    register({ name: "country" }); // custom register
    register({ name: "national" });
    register({ name: "local" });
    register({ name: "status" });
    register({ name: "result" });
    register({ name: "owner" });
    register({ name: "team" });
    register({ name: "tags" });

    if (Object.keys(props.item).length > 0) {
      setValue("external_id", props.item.external_id);
      setValue("_id", props.item._id);
      setValue("first", props.item.first);
      setValue("last", props.item.last);
      setValue("country", props.item.country);
      setValue("local", props.item.local);
      setValue("national", props.item.national.toString());
      setValue("email", props.item.email);
      setValue("company", props.item.company);
      setValue("category", props.item.category);
      setValue("comments", props.item.comments);

      //setResult(props.item.result);
      setStatus(props.item.status);

      if (props.item.owner) {
        setValue("owner", props.item.owner);
        //setOwner(props.item.owner);
      }
      if (props.item.team) {
        setValue("team", props.item.team);
        //setTeam(props.item.team);
      }

      if (props.item.tags.length > 0) {
        var tagValueData = [];
        var tagData = props.item.tags.map((tag) => {
          tagValueData.push(tag);
          return { name: tag.name, _id: tag._id };
        });
        setValue("tags", tagValueData);
        setTags(tagData);
      } else {
        setValue("tags", []);
        setTags([]);
      }
    }
  }, [register, setValue, props]);

  const getPhone = (props) => {
    if (Object.keys(props.item).length > 0) {
      return props.item.national.toString();
    } else {
      return "";
    }
  };
  const getTeam = () => {
    const ownerValue = getValues("owner");
    var ownerR = props.auth.user.agent;
    if (ownerValue) {
      ownerR = ownerValue;
    }
    if (props.teams && !props.teams.seeallteams) {
      const yourTeam =
        (props.teams.list && props.teams.list[0].options.filter((item) => {
          return props.teams.seeallteams || item.members.includes(ownerR);
        })) || [];
      const otherTeam =
        (props.teams.list && props.teams.list[1].options.filter((item) => {
          return props.teams.seeallteams || item.members.includes(ownerR);
        })) || [];
      var returnData = [];
      if (yourTeam.length > 0) {
        returnData.push({
          label: props.teams.list[0].label,
          options: yourTeam,
        });
      }
      if (otherTeam.length > 0) {
        returnData.push({
          label: props.teams.list[1].label,
          options: otherTeam,
        });
      }
      return returnData;
    } else {
      return props.teams && props.teams.list;
    }
  };
  const submitFormEdit = (form) => {
    if (form.national === "") {
      return setError("national", "required", "Phone number is required");
    }
    setLoading(true);
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/lead/" + form._id + "/update/",
      data: form,
    })
      .then((item) => {
        setLoading(false);
        if (props.updateState) {
          const boolOwner =
            props.ownerRecord.agent &&
            (!item.data.owner ||
              item.data.owner._id !== props.ownerRecord.agent);
          const boolTeam =
            props.ownerRecord.members &&
            (props.ownerRecord.members.length === 0 ||
              !item.data.team ||
              !props.ownerRecord.members.includes(item.data.team._id));
          if (
            props.auth.user.role === "agent" &&
            props.ownerRecord &&
            ((props.ownerRecord.agentPermission === "own" && boolOwner) ||
              (props.ownerRecord.agentPermission === "team" && boolTeam))
          ) {
            props.deleteItemFromState(item.data._id);
          } else {
            props.updateState(item.data);
          }
        }

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Lead has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Lead",
          toastrOptions
        );

        if (err.response && err.response.data) {
          if (err.response.data.error) {
            return setError(
              err.response.data.error.field,
              "duplicate",
              err.response.data.error.message
            );
          } else {
          }
        }
      });
  };
  const _selectOwner = (selected) => {
    if (selected) {
      setValue("owner", selected);
      //setOwner(selected.first + " " + selected.last);
      clearError("owner");
    } else {
      setValue("owner", "");
      //setOwner("");
    }
  };
  const selectOwner = (selected) => {
    if (
      props.auth.user.role === "agent" &&
      props.ownerRecord &&
      props.ownerRecord.agentPermission === "own" &&
      (!selected || selected._id !== props.ownerRecord.agent)
    ) {
      Swal.fire(
        "",
        "Warning: You are about to reassign the lead to another agent. This will make the lead visible to this agent and you will lose access to the lead.",
        "warning"
      );
    }
    _selectOwner(selected);
  };
  const _selectTeam = (selected) => {
    if (selected) {
      setValue("team", selected);
      //setTeam(selected.name);
      clearError("team");
    } else {
      setValue("team", "");
      //setTeam("");
    }
  };
  const selectTeam = (selected) => {
    if (
      props.auth.user.role === "agent" &&
      props.ownerRecord &&
      props.ownerRecord.agentPermission === "team" &&
      (!selected ||
        props.ownerRecord.members.length === 0 ||
        !props.ownerRecord.members.includes(selected._id))
    ) {
      Swal.fire(
        "",
        "Warning: You are about to reassign the lead to a team that you are not part of. This will make the lead visible to members of that team and you will lose access to the lead.",
        "warning"
      );
    }
    _selectTeam(selected);
  };
 
  return (
    <>
      <form onSubmit={handleSubmit(submitFormEdit)}>
        <Row>
          <Col md="6">
            <Label
              style={{
                //marginBottom: "0.5rem",
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
              for="first"
            >
              First
            </Label>
            <Input
              type="text"
              name="first"
              id="first"
              style={{ marginBottom: "10px" }}
              innerRef={register({ required: true })}
            />

            {errors.first && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                First name is required
              </div>
            )}
          </Col>
          <Col md="6">
            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
              for="last"
            >
              Last
            </Label>
            <Input
              type="text"
              name="last"
              id="last"
              style={{ marginBottom: "10px" }}
              innerRef={register({ required: true })}
            />{" "}
            {errors.last && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Last name is required
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
              for="national"
            >
              Phone
            </Label>
            <PhoneInput
              /*style={{
            marginBottom: "25px",
          }}*/
              style={{ marginBottom: "10px" }}
              enableSearch={true}
              disableInitialCountryGuess={false}
              jumpCursorToEnd={false}
              autoFormat={false}
              country={props.item.country}
              preferredCountries={["us", "ca", "uk"]}
              regions={["north-america", "europe"]}
              name="national"
              id="national"
              placeholder="Enter phone number"
              onChange={(phone, country) => {
                setValue("national", phone);
                setValue("country", country);
                setValue("local", phone.slice(country.dialCode.length));
              }}
              isValid={(value, country, countries) => {
                return countries.some((country) => {
                  return (
                    startsWith(value, country.dialCode) ||
                    startsWith(country.dialCode, value)
                  );
                });
              }}
              defaultErrorMessage={"Invalid Phone"}
              value={getPhone(props)}
              innerRef={register({ validate: (value) => value !== "" })}
            />
            {errors.national && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Phone number is required
              </div>
            )}
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="external_id"
              >
                External Id
              </Label>
              <Input
                type="text"
                name="external_id"
                id="external_id"
                innerRef={register()}
              />

              {errors.external_id && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  A lead already exists with this External Id
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col md="6">
            <Label
              for="status"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Status
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={{
                label: status,
                value: status,
              }}
              isSearchable
              name="status"
              id="status"
              onChange={(selected) => {
                setValue("status", selected.value);
                setStatus(selected.value);
                clearError("status");
              }}
              options={optionsStatus}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => `${option.value}`}
              innerRef={register({ required: true })}
            />
          </Col>

          <Col md="6">
            <Label
              for="result"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Result
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              style={{ marginBottom: "10px" }}
              name="result"
              id="result"
              isSearchable
              isClearable
              defaultValue={
                props.item.result
                  ? {
                      label: props.item.result,
                      value: props.item.result,
                    }
                  : null
              }
              onChange={(selected) => {
                if (selected) {
                  setValue("result", selected.value);
                  //setResult(selected.value);
                  clearError("result");
                } else {
                  setValue("result", "");
                  //setResult("");
                }
              }}
              options={optionsResult}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => `${option.value}`}
              innerRef={register({ required: true })}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ marginBottom: "10px" }}>
            <Label
              for="tags"
              style={{
                display: "block",
                marginBottom: "-5px",
                fontWeight: "600",
              }}
            >
              Tags
            </Label>
            <FormText>Add Tags tp help organize your leads.</FormText>
            <TagDropdown
              style={{ marginBottom: "10px" }}
              isMulti
              isClearable
              value={tags}
              isSearchable
              name="tags"
              id="tags"
              onChange={(selected) => {
                if (selected) {
                  var tagValue = selected.map(function (data) {
                    return data;
                  });
                  setValue("tags", tagValue);
                  setTags(selected);
                } else {
                  // selected is null set to empty
                  setValue("tags", []);
                  setTags([]);
                }

                clearError("tags");
              }}
              onCreateOption={handleCreate}
              //        inputValue={inputValue}
              //  onInputChange={onInputTag}
              getOptionLabel={(option) => {
                if( option.__isNew__ ) {
                  return option.label
                } 
                return option.name
              }}
              getOptionValue={(option) => {
                return JSON.stringify(option)
              }}
              placeholder="Type something and press enter..."
              innerRef={register()}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col md="6">
            <Label
              for="owner"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Lead Owner
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              style={{ marginBottom: "10px" }}
              name="owner"
              id="owner"
              isSearchable
              isClearable
              onChange={(selected) => {
                selectOwner(selected);
              }}
              defaultValue={props.item.owner ? props.item.owner : ""}
              options={props.agents || ""}
              getOptionLabel={(option) => `${option.first} ${option.last}`}
              getOptionValue={(option) => JSON.stringify(option)}
              innerRef={register()}
            />
          </Col>

          <Col md="6">
            <Label
              for="team"
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Team
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              style={{ marginBottom: "10px" }}
              name="team"
              id="team"
              isSearchable
              isClearable
              onChange={(selected) => {
                selectTeam(selected);
              }}
              defaultValue={props.item.team ? props.item.team : ""}
              options={getTeam()}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => JSON.stringify(option)}
              innerRef={register()}
            />
          </Col>
        </Row>

        <Label
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
          for="email"
        >
          Email
        </Label>
        <Input
          type="text"
          name="email"
          id="email"
          style={{ marginBottom: "10px" }}
          innerRef={register()}
        />

        <Label
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
          for="company"
        >
          Company
        </Label>
        <Input
          type="text"
          name="company"
          id="company"
          style={{ marginBottom: "10px" }}
          innerRef={register()}
        />
        <Label
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
          for="category"
        >
          Category
        </Label>
        <Input
          type="text"
          name="category"
          id="category"
          style={{ marginBottom: "10px" }}
          innerRef={register()}
        />

        <Label
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
          for="comments"
        >
          Comments
        </Label>
        <Input
          type="textarea"
          name="comments"
          id="comments"
          innerRef={register()}
        />

        <ModalFooter>
          {loading?<Button 
            color="primary" 
            type="button" 
            disabled={true}>
              Saving...
          </Button>:<Button color="primary" type="submit">Save Lead</Button>}
        </ModalFooter>
      </form>
    </>
  );
}
FormLeadEdit.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  teams: state.teams.teamDSDropdown,
  agents: state.agents.agentDropdown,
  ownerRecord: state.agents.agentTODropdown,
});

const mapDispatchToProps = {
  addTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLeadEdit);
