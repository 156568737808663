import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import FormLeadEdit from "./FormLeadEdit";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormCall from "../Calls/FormCall";
const MySwal = withReactContent(Swal);

function MobileModalLead(props) {
  const [modalOpen, setModal] = useState(false);
  const [typeModal, setTypeModal] = useState("modal");
  //const [lead, setLead] = useState();
  const toggle = () => {
    setModal(!modalOpen);
    return true;
  };
  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/lead/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);
            Swal.fire("Deleted!", "Your lead is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const callToggle = (type) => {
    setTypeModal(type);
    toggle();
  };
  const modalToggle = () => {
    setTypeModal("modal");
    toggle();
  };
  var addEditButton = "";
  var addEditLeadForm = "";
  if (props.buttonType === "actions") {
    var identifier = props.item.first + " " + props.item.last;
    addEditButton = (
      <UncontrolledDropdown className="mobilescreen_button" direction="left">
        <DropdownToggle
          caret
          className={"remove_background action_act"}
        ></DropdownToggle>
        <DropdownMenu>
          <UncontrolledDropdown direction="left">
            <DropdownToggle
              className={"remove_background"}
              caret
              style={{ width: "100%", textAlign: "left" }}
            >
              <i
                className="fa fa-phone fa-rotate-90"
                style={{ marginRight: "2px" }}
              ></i>{" "}
              Call
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => callToggle("lead")}>
                Lead Call
              </DropdownItem>
              <DropdownItem onClick={() => callToggle("team")}>
                Team Call
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <DropdownItem
            onClick={modalToggle}
            className="dropdown-item remove_background"
          >
            <i className="fas fa-pen"></i> Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              confirmDelete(props.item._id, identifier);
            }}
            className="dropdown-item remove_background"
          >
            <i className="fa fa-trash"></i> Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    addEditLeadForm = (
      <FormLeadEdit
        addItemToState={props.addItemToState}
        updateState={props.updateState}
        toggle={toggle}
        item={props.item}
        deleteItemFromState={props.deleteItemFromState}
      />
    );
  }
  const getFormModal = () => {
    switch (typeModal) {
      case "lead":
      case "team":
        return (
          <FormCall
            addItemToState={props.addItemToState}
            toggle={callToggle}
            item={props.item}
            agents={props.agents}
            teams={props.teams}
            callerids={props.callerids}
            leadCall={typeModal}
            recordings={props.recordings}
            lead={props.lead}
            team={props.team}
          />
        );
      default:
        return addEditLeadForm;
    }
  };
  return (
    <>
      {addEditButton}
      <Modal isOpen={modalOpen} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.type[typeModal].headerTitle}
        </ModalHeader>
        <ModalBody>{getFormModal()}</ModalBody>
      </Modal>
    </>
  );
}
MobileModalLead.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MobileModalLead);
