import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";

function ApiTokenForm(props) {
  const [ loading, setLoading ] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      _id: 0,
      name: ""
    },
  });

  useEffect(() => {
    register({ name: "_id" });
  }, [register]);

  const submitFormAdd = (form) => {
    setLoading(true);
    axios({
      method: "post",
      responseType: "json",
      url: `/api/api_token/`,
      data: form,
    })
      .then((item) => {
          props.addItemToState(item.data);
          props.toggle();
          const toastrType = "success";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Success",
            "Token has been added sucessfully",
            toastrOptions
          );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong adding your number",
          toastrOptions
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitFormAdd)}>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label
                for="name"
                style={{
                  marginTop: "20px",
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Name
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true,  min: 2, max: 40 })}
              />

              {errors.name && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <ModalFooter>
          {loading?<Button 
          color="primary" 
          type="button" 
          disabled={true}>
            Saving...
          </Button>:
          <Button 
          color="primary" 
          type="submit">
            Submit
          </Button>}
        </ModalFooter>
      </form>
    </>
  );
}
ApiTokenForm.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenForm);
