import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateProfile, getProfile } from "../../../actions/profileActions";
import CardsHeader from "components/Headers/CardsHeader.js";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Loader from "layouts/Loader.js";
// core components

class Profile extends React.Component {
  // Set state initial values
  constructor(props, context) {
    super(props, context);
    this.state = {
      profileId: this.props.auth.user.id,
      loading: this.props.profile.payload.loading,
      first: "",
      last: "",
      email: "",
      currentPassword: "",
      password: "",
      password2: "",
      errors: {},
      loadingBtn: false
    };
  }

  componentDidMount() {
    this.props.getProfile(this.state.profileId);
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (
      Object.keys(nextProps.profile.payload).length > 0 &&
      Object.keys(nextProps.errors).length === 0
    ) {
      this.setState({
        first: nextProps.profile.payload.first,
        last: nextProps.profile.payload.last,
        email: nextProps.profile.payload.email,
        loading: false,
        currentPassword: "",
        password: "",
        password2: "",
        errors: {},
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({loadingBtn: true});
    try {
      const id = this.props.auth.user.id;
      const profile = {
        first: this.state.first,
        last: this.state.last,
        email: this.state.email,
        currentPassword: this.state.currentPassword,
        password: this.state.password,
        password2: this.state.password2,
      };
      this.props.updateProfile(id, profile)
      .then((res)=>this.setState({loadingBtn: false}));
    } catch (error) {
      this.setState({ error: "Sorry, something went wrong." });
    }
  };

  render() {
    const { loading } = this.props;
    if (loading) {
      return <><CardsHeader name={""} />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit profile</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                  <Loader />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container></>
    }

    const { errors, first, last } = this.state;
    return (
      <>
        <CardsHeader name={first + " " + last} />

        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit profile</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={this.handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="first"
                            >
                              First name
                            </label>
                            <Input
                              onChange={this.handleChange}
                              value={this.state.first}
                              error={errors.first}
                              id="first"
                              placeholder="First Name"
                              type="text"
                              autoComplete="new-password"
                            />
                            <span
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.first}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="last"
                            >
                              Last name
                            </label>
                            <Input
                              onChange={this.handleChange}
                              value={this.state.last}
                              error={errors.last}
                              onKeyDown={this.onKeyDown}
                              id="last"
                              placeholder="Last Name"
                              type="text"
                              autoComplete="new-password"
                            />
                            <span
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.last}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="email"
                            >
                              Email address
                            </label>
                            <Input
                              onChange={this.handleChange}
                              value={this.state.email}
                              onKeyDown={this.onKeyDown}
                              error={errors.email}
                              id="email"
                              placeholder="Email"
                              type="email"
                              autoComplete="new-password"
                            />
                            <span
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.email}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <h6 className="heading-small text-muted mb-4">
                      Security Information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="currentPassword"
                            >
                              Current Password
                            </label>
                            <Input
                              onChange={this.handleChange}
                              value={this.state.currentPassword}
                              error={errors.currentPassword}
                              onKeyDown={this.onKeyDown}
                              id="currentPassword"
                              placeholder="Current Password"
                              type="password"
                              autoComplete="new-password"
                            />
                            <span
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.currentPassword}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="passwor"
                            >
                              New Password
                            </label>
                            <Input
                              onChange={this.handleChange}
                              value={this.state.password}
                              error={errors.password}
                              id="password"
                              placeholder="New Password"
                              type="password"
                              autoComplete="new-password"
                            />
                            <span
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="password2"
                            >
                              Confirm Password
                            </label>
                            <Input
                              onChange={this.handleChange}
                              value={this.state.password2}
                              error={errors.password2}
                              onKeyDown={this.onKeyDown}
                              id="password2"
                              placeholder="Confirm Password"
                              type="password"
                              autoComplete="new-password"
                            />
                            <span
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password2}
                            </span>
                          </FormGroup>

                          <span
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.message}
                          </span>
                          <div className="btn-group">
                            {this.state.loadingBtn?<button type="button" className="btn btn-primary" disabled={true}>Loading...</button>:<button type="submit" className="btn btn-primary">
                              Update Profile
                            </button>}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Profile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  profile: state.profile,
  loading: state.profile.loading,
});

const mapDispatchToProps = { updateProfile, getProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
