import React from "react";
import { withRouter, Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../../actions/authActions";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      first: "",
      last: "",
      email: "",
      password: "",
      password2: "",
      agree: false,
      termsbt: false,
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("../account/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleCheck = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const newUser = {
      first: this.state.first,
      last: this.state.last,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      agree: this.state.agree,
      termsbt: this.state.termsbt,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    this.props
      .registerUser(newUser, this.props.history)
      .then((res) => this.setState({ loading: false }));
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        <AuthHeader title="Create an account" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <div className="text-muted text-center mt-2 mb-4">
                  <small>Sign up with</small>
                </div>
                <div className="text-center">
                  <Col className="facebookgooglebutton_col">
                    <div className="facebookgooglebutton facebook">
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href={
                          process.env.REACT_APP_BACKEND + "/api/auth/facebook"
                        }
                        onClick={this.facebookSignin}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={require("assets/img/icons/common/facebook.png")}
                          />
                        </span>
                        <span className="btn-inner--text">
                          {" "}
                          Sign up with Facebook
                        </span>
                      </Button>
                    </div>
                  </Col>
                  <Col className="facebookgooglebutton_col">
                    <div className="facebookgooglebutton google">
                      <Button
                        className="btn-neutral btn-icon "
                        color="default"
                        href={
                          process.env.REACT_APP_BACKEND + "/api/auth/google"
                        }
                        onClick={this.googleSignin}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={require("assets/img/icons/common/google.svg")}
                          />
                        </span>
                        <span className="btn-inner--text">
                          {" "}
                          Sign up with Google
                        </span>
                      </Button>
                    </div>
                  </Col>
                </div>

                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Or sign up with credentials</small>
                  </div>
                  <Form noValidate onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedLast,
                            "mb-3": 1,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <Input
                              onChange={this.onChange}
                              value={this.state.first}
                              error={errors.first}
                              id="first"
                              placeholder="First"
                              type="text"
                              className={classnames("", {
                                invalid: errors.first,
                              })}
                              onFocus={() =>
                                this.setState({ focusedFirst: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedFirst: false })
                              }
                            />
                          </InputGroup>
                          <span
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.first}
                          </span>
                        </FormGroup>
                      </div>
                      <div
                        className="col-sm-6"
                        style={{
                          paddingLeft: "5px !important",
                        }}
                      >
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedLast,
                            "mb-3": 1,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <Input
                              onChange={this.onChange}
                              value={this.state.last}
                              error={errors.last}
                              id="last"
                              placeholder="Last"
                              type="text"
                              className={classnames("", {
                                invalid: errors.last,
                              })}
                              onFocus={() =>
                                this.setState({ focusedLast: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedLast: false })
                              }
                            />
                          </InputGroup>
                          <span
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.last}
                          </span>
                        </FormGroup>
                      </div>
                    </div>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedEmail,
                        "mb-3": 1,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.email}
                          error={errors.email}
                          id="email"
                          placeholder="Email"
                          type="email"
                          className={classnames("", {
                            invalid: errors.email,
                          })}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.email}
                      </span>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                        "mb-3": 1,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          id="password"
                          placeholder="Password"
                          type="password"
                          className={classnames("", {
                            invalid: errors.password,
                          })}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.password}
                      </span>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword2,
                        "mb-3": 1,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.password2}
                          error={errors.password2}
                          id="password2"
                          placeholder="Confirm Password"
                          type="password"
                          className={classnames("", {
                            invalid: errors.password2,
                          })}
                          onFocus={() =>
                            this.setState({ focusedPassword2: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword2: false })
                          }
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.password2}
                      </span>
                    </FormGroup>

                    <FormGroup check>
                      <Label
                        check
                        style={{
                          marginRight: "15px",
                        }}
                      >
                        <Input
                          id="agree"
                          error={errors.agree}
                          type="checkbox"
                          onChange={this.handleCheck}
                          value={this.state.agree}
                        />{" "}
                        I accept Call Assist
                        <a href="https://mycallassist.com/terms-of-service/">
                          {" "}
                          Terms and Conditions{" "}
                        </a>
                        and acknowledge that my information will be used in
                        accordance with Call Assist{" "}
                        <a href="https://mycallassist.com/privacy-policy/">
                          {" "}
                          Privacy Policy{" "}
                        </a>
                        .
                      </Label>

                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.agree}
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label style={{ display: "none" }}>
                        <Input
                          id="termsbt"
                          type="checkbox"
                          onChange={this.handleCheck}
                          value={this.state.termsbt}
                        />{" "}
                        I accept Call Assist bot terms.
                      </Label>
                    </FormGroup>

                    <div className="text-center">
                      {this.state.loading ? (
                        <Button
                          className="mt-4"
                          color="info"
                          type="button"
                          disabled={true}
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button className="mt-4" color="info" type="submit">
                          Create account
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link to="/auth/Forgot" className="text-light">
                    <small>Forgot password?</small>
                  </Link>
                </Col>
                <Col className="text-right" xs="6">
                  <Link to="/auth/Login" className="text-light">
                    <small>Login</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
