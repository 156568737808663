import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormIntegration from "../FormIntegration";
import InstructionsIntegration from "../InstructionsIntegration";

function ModalIntegration(props) {
  const [modal, setModal] = useState(false);
  const [modalType, setModalTyp] = useState("default");
  const toggle = () => {
    setModal(!modal);
  };
  const setModalType = (type) => {
    toggle();
    setModalTyp(type);
  };

  const label = props.buttonLabel;

  var addEditButton = null;
  if (props.buttonType === "instructions") {
    addEditButton = [
      <Button
        key="0"
        color={"success"}
        className="offet-margin-left webhook_desktop"
        onClick={() => setModalType("instructions")}
        style={{ padding: "0.35rem 0.25rem" }}
      >
        {label}
      </Button>,
      <Button
        key="1"
        size="sm"
        color={"success"}
        className="offet-margin-left webhook_mobile"
        onClick={() => setModalType("instructions")}
      >
        <i className="fa fa-info-circle"></i>
      </Button>,
    ];
  } else if (props.buttonType === "create") {
    addEditButton = (
      <Button
        color={"primary"}
        onClick={(e) => {
          e.stopPropagation();
          setModalType("default");
          return false;
        }}
        style={{ float: "right", padding: "0.35rem 0.25rem" }}
      >
        {label}
      </Button>
    );
  } else {
    addEditButton = [
      <Button
        key="0"
        color={"primary"}
        className="webhook_desktop_sm"
        onClick={() => setModalType("default")}
        style={{ float: "right", padding: "0.35rem 0.25rem" }}
      >
        {label}
      </Button>,
    ];
  }
  const getModalForm = () => {
    var modalForm = null;
    switch (modalType) {
      case "instructions":
        modalForm = (
          <InstructionsIntegration
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            auth={props.auth}
            item={props.item}
            deleteItemFromState={props.deleteItemFromState}
            hasLogin={props.hasLogin}
          />
        );
        break;
      default:
        modalForm = (
          <FormIntegration
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            auth={props.auth}
            item={props.item}
            deleteItemFromState={props.deleteItemFromState}
            hasLogin={props.hasLogin}
          />
        );
    }
    return modalForm;
  };
  return (
    <>
      {addEditButton}
      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>{getModalForm()}</ModalBody>
      </Modal>
    </>
  );
}

export default ModalIntegration;
