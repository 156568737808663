import axios from "axios";
import {
    LOAD_AGENT_USER_DROPDOWN,
    AGENT_USER_DROPDOWN,
    LOAD_AGENT_TEAM_OWNER_LEAD_DROPDOWN,
    AGENT_TEAM_OWNER_LEAD_DROPDOWN,
    GET_ERRORS
  } from "./types";
export const agentDropdown = () => async ( dispatch ) => {
  dispatch({
    type: LOAD_AGENT_USER_DROPDOWN
  });
  axios
    .get(`/api/agent/dropdown`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      dispatch({
        type: AGENT_USER_DROPDOWN,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      });
    });
}
export const getAndTeamByOwnerLead= () => async ( dispatch ) => {
  dispatch({
    type: LOAD_AGENT_TEAM_OWNER_LEAD_DROPDOWN
  });
  axios
    .get(`/api/agent/getandteambyownerlead`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      dispatch({
        type: AGENT_TEAM_OWNER_LEAD_DROPDOWN,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      });
    });
}