import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

//import FormVerify from "./FormVerify";
import FormGetCode from "./FormGetCode";

function ModalVerify(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const label = props.buttonLabel;

  return (
    <>
      {props.auth.user.role !== "agent" && (
        <>
          {" "}
          <Button
            color={"primary"}
            onClick={toggle}
            style={{ marginLeft: "10px", padding: "4px" }}
          >
            {label}
          </Button>
          <Modal isOpen={modal} fade={false} toggle={toggle}>
            <ModalHeader
              toggle={toggle}
              close={
                <button className="close" onClick={toggle}>
                  &times;
                </button>
              }
            >
              {props.headerTitle} {props.item.national}
            </ModalHeader>
            <ModalBody>
              <FormGetCode
                addOwnedToState={props.addOwnedToState}
                updateOwnedState={props.updateOwnedState}
                toggle={toggle}
                item={props.item}
              />
            </ModalBody>
          </Modal>{" "}
        </>
      )}
    </>
  );
}

ModalVerify.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

//export default ModalVerify;
export default connect(mapStateToProps, mapDispatchToProps)(ModalVerify);
