import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormTexttospeech from "./FormTexttospeech";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function ModalTexttospeech(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text:
        "Removing this recording will also remove it from any teams. After removing, check that your teams still have a recording!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/recording/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);

            Swal.fire("Deleted!", "Your team is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const label = props.buttonLabel;
  var addEditButton = null;
  if (props.buttonType === "add") {
    addEditButton = (
      <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
        {label}
      </Button>
    );
  } else if (props.buttonType === "actions") {
    addEditButton = (
      <>
        <Button
          color={"primary"}
          onClick={toggle}
          style={{
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "7px",
            paddingRight: "7px",
          }}
        >
          <i className="fas fa-pen"></i>
        </Button>

        <Button
          color={"default"}
          onClick={() => {
            confirmDelete(props.item._id, props.item.name);
          }}
          style={{
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "7px",
            paddingRight: "7px",
          }}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  }

  return (
    <>
      {addEditButton}

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          className={"agentForm"}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
          {modal && props.item ? (
            <span className="float-right unique_id_class">
              id: {props.item.unique_id}
            </span>
          ) : null}
        </ModalHeader>
        <ModalBody>
          <FormTexttospeech
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            item={props.item}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalTexttospeech;
