import {
    USER_INTEGRATION_LIST,
    LOAD_USER_INTEGRATION_LIST
  } from "../actions/types";

  const initialState = {
    loading: false,
    userIntegration: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_USER_INTEGRATION_LIST:
            return {
                ...state,
                loading: true,
            };
        case USER_INTEGRATION_LIST:
            return {
                ...state,
                loading: false,
                userIntegration: action.payload
            };
        default:
            return state;
    }
  }