import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormText,
  ModalFooter,
} from "reactstrap";

import { useForm } from "react-hook-form";
import classnames from "classnames";
import { toastr } from "react-redux-toastr";
import axios from "axios";

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Select);

function FormTeam(props) {
  if (props.billing.payload.stripePlanId) {
    var boolBillingBasic = props.billing.payload.stripePlanId.includes("basic");
  }

  // Set up Tab layout
  var [activeTab, setActiveTab] = useState("0");
  const [recording, setRecording] = useState({});
  const [voicemail, setVoicemail] = useState({});
  const [loading, setLoading] = useState(false);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Set default options here. Use this to set initial values
  const [selected, setSelected] = React.useState(props.item.members);

  const onChange = (selectedOptions) => {
    if (selectedOptions !== undefined && selectedOptions !== "undefined") {
      setSelected(selectedOptions);
      setValue("members", selectedOptions);
      clearError("members");
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    setValue("members", newValue);
  };

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  const {
    register,
    setValue,
    handleSubmit,
    errors,
    clearError,
    setError,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      _id: 0,
      name: "",
      members: [],
      callMode: false,
      recordCall: true,
      callerid: "agent",
      whisperText:
        "Please press 1 to connect to {first} {last} from {company} {category}",
      postWhisper: true,
      whisperLanguage: "en-US",
      delay: 0,
      reschedule: true,
      hasSchedule: false,
      schedule: [],
      callback_url: "https://",
      recording: null,
      voicemail: null,
      optOut: false,
    },
  });

  // const options = [];
  useEffect(() => {
    register({ name: "_id" });
    register({ name: "members" });
    register({ name: "recording" });
    register({ name: "recordingQ" });
    register({ name: "voicemail" });
    if (Object.keys(props.item).length > 0) {
      // If editing team then show common tab and hide quick add tab
      setActiveTab("1");

      setValue("_id", props.item._id);
      setValue("name", props.item.name);
      setValue("callMode", props.item.callMode);
      setValue("recordCall", props.item.recordCall);

      if (props.item.callerid) {
        setValue("callerid", props.item.callerid);
      }

      setValue("whisperText", props.item.whisperText);
      setValue("postWhisperText", props.item.postWhisperText);
      setValue("whisperLanguage", props.item.whisperLanguage);
      setValue("delay", props.item.delay);
      setValue("hasSchedule", props.item.hasSchedule);
      setValue("schedule", props.item.schedule);
      setValue("reschedule", props.item.reschedule);
      setValue("callback_url", props.item.callback_url);

      setValue("optOut", props.item.optOut);

      if (props.item.members) {
        setValue("members", props.item.members);
      }
      if (props.item.recording) {
        setValue("recording", props.item.recording._id);
        setRecording(props.item.recording);
      }
      if (props.item.voicemail) {
        setValue("voicemail", props.item.voicemail._id);
        setVoicemail(props.item.voicemail);
      }
    }
  }, [register, setValue, props]);

  const hasSchedule = watch("hasSchedule", true);

  const submitFormAdd = (form) => {
    if (form.members === null || Object.keys(form.members).length < 1) {
      setError("members", "required");
    }

    if (!form.recording && !boolBillingBasic) {
      setError("recording", "required");
    }
    if (Object.keys(errors).length > 0) {
      return;
    }

    setLoading(true);
    //add time zone to team
    form.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios({
      method: "post",
      responseType: "json",
      url: `/api/team/`,
      data: form,
    })
      .then((item) => {
        setLoading(false);
        props.addItemToState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Team has been created sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Team",
          toastrOptions
        );
        if (err.response.data) {
          if (err.response.data.error) {
            // return setError("national", "required", "Phone number is required");
          } else {
          }
        }
      });
  };

  const submitFormEdit = (form) => {
    if (form.members === null || Object.keys(form.members).length < 1) {
      return setError("members", "required");
    }
    if (!form.recording && !boolBillingBasic) {
      return setError("recording", "required");
    }
    setLoading(true);
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/team/" + form._id + "/update/",
      data: form,
    })
      .then((item) => {
        setLoading(false);
        props.updateState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Team has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Team",
          toastrOptions
        );
      });
  };
  var recordingValue = "";
  if (recording._id) {
    recordingValue = recording;
  }
  /*if( props.item.recording ) {
    recordingValue = props.item.recording;
  }*/
  return (
    <>
      <Nav tabs>
        {!Object.keys(props.item).length > 0 && (
          <>
            <NavItem>
              <NavLink
                style={{
                  cursor: "pointer",
                  backgroundColor: "#afccfd",
                  fontWeight: "700",
                }}
                className={classnames({ active: activeTab === "0" })}
                onClick={() => {
                  toggle("0");
                }}
              >
                Quick Setup
              </NavLink>
            </NavItem>
          </>
        )}

        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Common
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Teams
          </NavLink>
        </NavItem>

        {!boolBillingBasic ? (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Leads
            </NavLink>
          </NavItem>
        ) : (
          ""
        )}
      </Nav>

      <form
        onSubmit={
          Object.keys(props.item).length > 0
            ? handleSubmit(submitFormEdit)
            : handleSubmit(submitFormAdd)
        }
      >
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <h3 style={{ paddingTop: "15px" }}>
              This is all you need to create a team. Advanced team settings can
              be found in other tabs.
            </h3>
            <FormGroup>
              <Label
                for="nameQ"
                style={{
                  paddingTop: "25px",
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Name
              </Label>
              <FormText>Name the team for easy reference later.</FormText>
              <Input
                type="text"
                name="nameQ"
                id="nameQ"
                onChange={() => {
                  setValue("name", getValues("nameQ"));
                }}
                innerRef={register()}
              />
              {errors.name && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Select Agents
              </label>
              <FormText>
                Agents will be called in the listed order. Drag agents to adjust
                the order.
              </FormText>
              <SortableSelect
                helperClass="sortableHelper"
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                isMulti
                name="members"
                id="members"
                value={selected}
                options={props.agents}
                closeMenuOnSelect={false}
                onChange={onChange}
                defaultValue={props.item.members ? props.item.members : ""}
                components={{
                  MultiValue: SortableMultiValue,
                }}
                getOptionLabel={(option) => `${option.first} ${option.last}`}
                getOptionValue={(option) => `${option._id}`}
                innerRef={register()}
              />
              {errors.members && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  An Agent is required
                </div>
              )}
            </FormGroup>
            {!boolBillingBasic && (
              <FormGroup>
                <Row>
                  <Col xs="12" lg="7">
                    <label
                      style={{
                        paddingTop: "25px",
                        display: "block",
                        marginBottom: "0rem",
                        fontWeight: "600",
                      }}
                    >
                      Recorded Sales Message
                    </label>
                    <FormText>
                      The sales message you want to play when calling leads
                      directly.
                    </FormText>
                    <Select
                      name="recordingQ"
                      id="recordingQ"
                      isClearable
                      options={props.recordings.message}
                      closeMenuOnSelect={true}
                      onChange={(selected) => {
                        if (selected) {
                          setValue("recordingQ", selected._id);
                          setValue("recording", selected._id);
                          setRecording(selected);
                          clearError("recording");
                          clearError("recordingQ");
                        } else {
                          setValue("recordingQ", "");
                          setValue("recording", "");
                          setRecording("");
                        }
                      }}
                      defaultValue={
                        props.item.recording ? props.item.recording : ""
                      }
                      value={recordingValue}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option._id}`}
                      innerRef={register(
                        !boolBillingBasic
                          ? { required: true }
                          : { required: false }
                      )}
                    />
                    {errors.recording && !boolBillingBasic && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        An Recorded Sales Message is required
                      </div>
                    )}
                  </Col>
                  <Col xs="12" lg="5" className="audio_col">
                    {recording._id ? (
                      <audio
                        src={recording.fileName}
                        controls="controls"
                        style={{ width: "300px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            )}
          </TabPane>

          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <h3 style={{ paddingTop: "15px" }}>Settings for All Calls.</h3>
                {(Object.keys(errors).length > 0 && errors.delay) ||
                  (errors.recording && !boolBillingBasic && (
                    <div
                      style={{
                        display: "block",
                        color: "#fb6340",
                        marginTop: "1px !important",
                      }}
                    >
                      <Alert color="danger">
                        Required fields may be missing on another tab.
                      </Alert>
                    </div>
                  ))}
                <FormGroup>
                  <Label
                    for="name"
                    style={{
                      paddingTop: "25px",
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Name
                  </Label>
                  <FormText>Name the team for easy reference later.</FormText>
                  <Input
                    type="text"
                    name="name"
                    //id="name"
                    id="nameQ"
                    onChange={() => {
                      setValue("nameQ", getValues("name"));
                    }}
                    innerRef={register({ required: true })}
                  />
                  {errors.name && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Name is required
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Select Agents
                  </label>
                  <FormText>
                    Agents will be called in the listed order. Drag agents to
                    adjust the order
                  </FormText>
                  <SortableSelect
                    helperClass="sortableHelper"
                    axis="xy"
                    onSortEnd={onSortEnd}
                    distance={4}
                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                    getHelperDimensions={({ node }) =>
                      node.getBoundingClientRect()
                    }
                    // react-select props:
                    isMulti
                    name="members"
                    id="members"
                    value={selected}
                    options={props.agents}
                    closeMenuOnSelect={false}
                    onChange={onChange}
                    defaultValue={props.item.members ? props.item.members : ""}
                    components={{
                      MultiValue: SortableMultiValue,
                    }}
                    getOptionLabel={(option) =>
                      `${option.first} ${option.last}`
                    }
                    getOptionValue={(option) => `${option._id}`}
                    innerRef={register({ required: true })}
                  />
                  {errors.members && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      An Agent is required
                    </div>
                  )}
                </FormGroup>

                <label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Call Routing
                </label>
                <FormText>
                  <b>Round Robin</b>: Call agents one at a time.
                </FormText>
                <FormText>
                  <b>Simultaneous</b>: Call agents at the same time.
                </FormText>
                <FormGroup>
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    Round Robin
                  </span>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      placeholder="callMode"
                      name="callMode"
                      ref={register()}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>{" "}
                  <span style={{ paddingLeft: "10px" }}>Simultaneous</span>
                </FormGroup>
                <label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Call Recording
                </label>
                <FormGroup>
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    Off
                  </span>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      placeholder="recordCall"
                      name="recordCall"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      ref={register()}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <span style={{ paddingLeft: "10px" }}>On</span>
                </FormGroup>

                <FormGroup style={{ marginTop: "20px" }}>
                  <Label
                    for="callerid"
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Lead-facing Caller ID
                  </Label>
                  <FormText>
                    The Caller ID a Lead will see when recieving a call.
                  </FormText>
                  <Input
                    type="select"
                    name="callerid"
                    id="callerid"
                    innerRef={register({ required: true })}
                  >
                    <option key="agent" value="agent">
                      The Agent's Phone Number
                    </option>
                    {props.callerids.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}: {item.national}
                      </option>
                    ))}
                  </Input>
                  {errors.callerid && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Caller ID is required
                    </div>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label
                    for="callback_url"
                    style={{
                      display: "inline-block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Callback URL
                  </Label>

                  <FormText>
                    A webhook URL to send call completed events after the call
                  </FormText>

                  <Input
                    type="text"
                    name="callback_url"
                    id="callback_url"
                    innerRef={register()}
                  />
                </FormGroup>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <h3 style={{ paddingTop: "15px" }}>
                  Settings for calls targeting a Team first.
                </h3>

                {(Object.keys(errors).length > 0 && !errors.delay) ||
                  (errors.recording && !boolBillingBasic && (
                    <div
                      style={{
                        display: "block",
                        color: "#fb6340",
                        marginTop: "1px !important",
                      }}
                    >
                      <Alert color="danger">
                        Required fields may be missing on another tab.
                      </Alert>
                    </div>
                  ))}

                <FormGroup>
                  <Label
                    for="whisperText"
                    style={{
                      paddingTop: "25px",
                      display: "inline-block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Whisper Text
                  </Label>
                  <FormText>
                    <b>Spoken to Agents before connecting to a lead.</b>
                    <br></br>
                    You can include the following values from the lead record:
                    <br></br> {"{first}"} {"{last}"} {"{company}"}{" "}
                    {"{category}"} {"{comments}"}
                  </FormText>
                  <Input
                    type="textarea"
                    name="whisperText"
                    id="whisperText"
                    innerRef={register({ required: true })}
                  />
                  {errors.whisperText && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Whisper Text is required
                    </div>
                  )}
                </FormGroup>

                {/*

                <label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Play After Call Whispertext
                </label>

                <FormText>
                 Whether system will play instructions to the Agent on how to set call result. Call result can always be set regardless of instructions are played
                </FormText>

                <FormGroup>
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    Off
                  </span>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      placeholder="reschedule"
                      name="reschedule"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      ref={register()}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <span style={{ paddingLeft: "10px", paddingRight: "35px" }}>
                    On
                  </span>
                </FormGroup>

      
                    <FormGroup>
                  <Label
                    for="whisperText"
                    style={{
                      display: "inline-block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Post Whisper Text
                  </Label>
                  <FormText>
                    Press * at anytime to end the call and access this menu
                  </FormText>
                  <Input
                    type="textarea"
                    name="postwhisperText"
                    id="postwhisperText"
                    innerRef={register({ required: true })}
                  />
                  {errors.postWhisper && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Post Whisper Text is required
                    </div>
                  )}
                </FormGroup>
*/}

                <FormGroup style={{ marginTop: "20px" }}>
                  <Label
                    for="whisperLanguage"
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Whispertext Language
                  </Label>

                  <Input
                    type="select"
                    name="whisperLanguage"
                    id="whisperLanguage"
                    innerRef={register({ required: true })}
                  >
                    <option value="en-US">English</option>
                    <option value="es-ES">Spanish</option>
                    <option value="fr-FR">French</option>
                    <option value="it-IT">Italian</option>
                    <option value="de-DE">German</option>
                    <option value="nl-NL">Dutch</option>
                    <option value="pt-BR">Portuguese</option>
                  </Input>
                  {errors.whisperLanguage && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Whispertext Language is required
                    </div>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label
                    for="delay"
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Call Delay
                  </Label>
                  <FormText>
                    Wait this long after the Lead comes in to start the call.
                  </FormText>

                  <input
                    className="form-control"
                    type="text"
                    name="delay"
                    id="delay"
                    style={{
                      width: "150px",
                      display: "inline-block",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    ref={register({
                      required: true,
                      minLength: 1,
                      maxLength: 3,
                      pattern: /^[0-9]+$/,
                    })}
                  />
                  <span className="input-group-addon">Minutes</span>
                  {errors.delay && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Delay is required and must be a valid number between 0 and
                      999.
                    </div>
                  )}
                </FormGroup>

                <label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Reschedule Call If Team Offline
                </label>

                <FormText>
                  Reschedule the call for the first available time slot
                </FormText>

                <FormGroup>
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    Off
                  </span>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      placeholder="reschedule"
                      name="reschedule"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      ref={register()}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <span style={{ paddingLeft: "10px", paddingRight: "35px" }}>
                    On
                  </span>
                </FormGroup>

                <label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Restrict Calling Schedule
                </label>

                <FormText>Only call the Team according to a scheduled</FormText>

                <FormGroup>
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    Off
                  </span>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      placeholder="hasSchedule"
                      name="hasSchedule"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      ref={register()}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <span style={{ paddingLeft: "10px", paddingRight: "35px" }}>
                    On
                  </span>
                  {hasSchedule && (
                    <Alert
                      color="info"
                      style={{ marginTop: "10px", color: "#0c5460" }}
                    >
                      You can change the Team schedule from the Teams list after
                      saving.
                    </Alert>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </TabPane>

          {!boolBillingBasic ? (
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <h3 style={{ paddingTop: "15px" }}>
                    Settings for calls targeting a Lead first.
                  </h3>
                  {Object.keys(errors).length > 0 && !errors.recording && (
                    <div
                      style={{
                        display: "block",
                        color: "#fb6340",
                        marginTop: "1px !important",
                      }}
                    >
                      <Alert color="danger">
                        Required fields may be missing on another tab.
                      </Alert>
                    </div>
                  )}
                  <FormGroup>
                    <Row>
                      <Col xs="12" lg="7">
                        <label
                          style={{
                            paddingTop: "25px",
                            display: "block",
                            marginBottom: "0rem",
                            fontWeight: "600",
                          }}
                        >
                          Recorded Sales Message
                        </label>
                        <FormText>
                          The sales message you want to play when calling leads
                          directly.
                        </FormText>
                        <Select
                          name="recording"
                          id="recording"
                          isClearable
                          options={props.recordings.message}
                          closeMenuOnSelect={true}
                          onChange={(selected) => {
                            if (selected) {
                              setValue("recordingQ", selected._id);
                              setValue("recording", selected._id);
                              setRecording(selected);
                              clearError("recording");
                            } else {
                              setValue("recordingQ", "");
                              setValue("recording", "");
                              setRecording("");
                            }
                          }}
                          defaultValue={
                            props.item.recording ? props.item.recording : ""
                          }
                          value={recordingValue}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(option) => `${option._id}`}
                          innerRef={register(
                            !boolBillingBasic
                              ? { required: true }
                              : { required: false }
                          )}
                        />
                        {errors.recording && !boolBillingBasic && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            An Recorded Sales Message is required
                          </div>
                        )}
                      </Col>
                      <Col xs="12" lg="5" className="audio_col">
                        {recording._id ? (
                          <audio
                            src={recording.fileName}
                            controls="controls"
                            style={{ width: "300px" }}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col xs="12" lg="7">
                        <label
                          style={{
                            display: "block",
                            marginBottom: "0rem",
                            fontWeight: "600",
                          }}
                        >
                          Voicemail
                        </label>
                        <FormText>
                          The voicemail message to leave if the lead doesn't
                          answer.
                        </FormText>
                        <Select
                          name="voicemail"
                          id="voicemail"
                          isClearable
                          options={props.recordings.voicemail}
                          closeMenuOnSelect={true}
                          onChange={(selected) => {
                            if (selected) {
                              setValue("recording", selected._id);
                              setValue("voicemail", selected._id);
                              setVoicemail(selected);
                            } else {
                              setValue("voicemail", "");
                              setVoicemail("");
                            }
                          }}
                          defaultValue={
                            props.item.voicemail ? props.item.voicemail : ""
                          }
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(option) => `${option._id}`}
                          innerRef={register(
                            !boolBillingBasic
                              ? { required: false }
                              : { required: false }
                          )}
                        />
                      </Col>
                      <Col xs="12" lg="5" className="audio_col">
                        {voicemail._id ? (
                          <audio
                            src={voicemail.fileName}
                            controls="controls"
                            style={{ width: "300px" }}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <div className="agent_filter">
                      <label className="container_checkbox">
                        Include an otp out message
                        <Input
                          type="checkbox"
                          name="optOut"
                          id="optOut"
                          innerRef={register(
                            !boolBillingBasic
                              ? { required: false }
                              : { required: false }
                          )}
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>
          ) : (
            ""
          )}
        </TabContent>
        <ModalFooter>
          {loading ? (
            <Button color="primary" disabled={true}>
              Saving...
            </Button>
          ) : (
            <Button color="primary">Save Team</Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}
FormTeam.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  recordings: state.recordings.recordingByType,
  callerids: state.numbers.numberDropdown,
  agents: state.agents.agentDropdown,
  billing: state.billing,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormTeam);
