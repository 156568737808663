import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import ApiTokenForm from "./ApiTokenForm";
import ApiTokenView from "./ApiTokenView";

function ModalAdd(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const label = props.buttonLabel;
  var addEditButton = null;
  if (props.buttonType === "add") {
    addEditButton = (
      <>
        <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
          {label}
        </Button>
      </>
    );
  } else if (props.buttonType === "actions") {
    addEditButton = <Button
        color={"info"}
        onClick={toggle}
        style={{
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px",
        }}
    >
        <i className="fas fa-eye"></i>
    </Button>
  }

  return (
    <>
      {addEditButton}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>
          {props.buttonType==="add"?<ApiTokenForm
            addItemToState={props.addItemToState}
            toggle={toggle}
            item={props.item}
          />:<ApiTokenView
            toggle={toggle}
            updateItemToState = {props.updateItemToState}
            deleteItemFromState = {props.deleteItemFromState}
            item={props.item}
          />}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalAdd;
