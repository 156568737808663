import React from "react";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
function TagDropdown(props) {
    return <CreatableSelect
    {...props}
    options={props.tags}
  />
}
TagDropdown.propTypes = {
};

const mapStateToProps = (state) => ({
  tags: state.tags.tags,
});

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(TagDropdown);