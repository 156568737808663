import {
  LOAD_CURRENT_USER,
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  GET_CURRENT_USER,
  SET_CSRF,
  NOTIFICATION_USER
} from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  csrf: null,
  notification: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_CURRENT_USER:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_USER: 
      return {
        ...state,
        notification: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case GET_CURRENT_USER:
      return {
        user: action.payload,
        //loading: false,
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        user: {...state.user , ...action.payload},
        //loading: false,
      };
    case SET_CSRF: 
      return {
        ...state,
        csrf: action.payload
      };
    default:
      return state;
  }
}
