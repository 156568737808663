import React, { useEffect } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import startsWith from "lodash.startswith";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function FormAgentView(props) {
  const { register, setValue, errors, watch } = useForm({
    defaultValues: {
      _id: 0,
      first: "",
      last: "",
      country: "",
      local: "",
      national: "",
      email: "",
      active: true,
      dnd: false,
      role: "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      schedule: [],
    },
  });

  useEffect(() => {
    register({ name: "country" }); // custom register
    register({ name: "national" });
    register({ name: "local" });
    register({ name: "_id" });
    if (props.item) {
      setValue("_id", props.item._id);
      setValue("first", props.item.first);
      setValue("last", props.item.last);

      if (props.item.phone) {
        setValue("country", props.item.phone.country);
        setValue("local", props.item.phone.local);
        setValue("national", props.item.phone.national.toString());
      }
      if (props.item.account === undefined) {
        setValue("hasLogin", false);
      } else {
        setValue("email", props.item.account.email);
        setValue("hasLogin", true);
        setValue("role", props.item.account.role);
      }

      setValue("active", props.item.active);
      setValue("dnd", props.item.dnd);
    }
    if (props.hasLogin) {
      setValue("hasLogin", true);
    }
  }, [register, setValue, props]);

  const getPhone = (props) => {
    if (props.item) {
      return props.item.phone ? props.item.phone.national.toString() : "";
    } else {
      return "";
    }
  };
  const canRoleEdit = () => {
    var bool = true;
    if (props.item) {
      if (props.item.account && props.item.account.role === "owner") {
        bool = false;
      } else {
        bool =
          props.auth.user.role !== "agent" &&
          (!props.item.account ||
            props.item.account.email !== props.auth.user.email);
      }
    } else {
      bool = true;
    }
    return bool;
  };
  const canRoleVisible = () => {
    return canRoleEdit() || props.auth.user.role === "admin";
  };

  const showLogin = watch("hasLogin", true);
  // This sets the roles and only allows selecting Owner if the account is already set to owner.
  function getRoles(props) {
    if (props.item !== undefined && props.item.account !== undefined) {
      if (props.item.account.role === "owner") {
        return <option value="owner">Owner</option>;
      } else {
        return (
          <>
            <option value="agent" defaultValue="selected">
              Agent
            </option>
            <option value="admin">Admin</option>
          </>
        );
      }
    } else {
      return (
        <>
          <option value="agent" defaultValue="selected">
            Agent
          </option>
          <option value="admin">Admin</option>
        </>
      );
    }
  }

  return (
    <>
      <form>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="first"
              >
                First
              </Label>
              <Input
                type="text"
                name="first"
                id="first"
                readOnly={true}
                innerRef={register({ required: true })}
              />

              {errors.first && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  First name is required
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="last"
              >
                Last
              </Label>
              <Input
                type="text"
                name="last"
                readOnly={true}
                id="last"
                innerRef={register({ required: true })}
              />{" "}
              {errors.last && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Last name is required
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>{" "}
        <Label
          style={{
            display: "block",
            marginBottom: "0rem",
            fontWeight: "600",
          }}
          for="national"
        >
          Phone
        </Label>
        <PhoneInput
          enableSearch={true}
          disableInitialCountryGuess={false}
          jumpCursorToEnd={false}
          autoFormat={false}
          country={"us"}
          preferredCountries={["us", "ca", "uk"]}
          regions={["north-america", "europe"]}
          name="national"
          id="national"
          placeholder="Enter phone number"
          disabled={true}
          disableDropdown={true}
          disableCountryCode={true}
          inputProps={{
            disabled: true,
          }}
          onChange={(phone, country) => {
            setValue("national", phone);
            setValue("country", country);
            setValue("local", phone.slice(country.dialCode.length));
          }}
          isValid={(value, country, countries) => {
            return countries.some((country) => {
              return (
                startsWith(value, country.dialCode) ||
                startsWith(country.dialCode, value)
              );
            });
          }}
          defaultErrorMessage={"Invalid Phone"}
          value={getPhone(props)}
          innerRef={register({ validate: (value) => value !== "" })}
        />
        {errors.national && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            Phone number is required
          </div>
        )}{" "}
        <FormGroup check style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Label check>
            <Input
              name="hasLogin"
              type="checkbox"
              innerRef={register()}
              disabled={true}
            />{" "}
            Create Login
          </Label>
        </FormGroup>
        {showLogin && (
          <div>
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                for="email"
              >
                Login Email
              </Label>
              <Input
                type="text"
                name="email"
                id="email"
                readOnly={true}
                innerRef={register({
                  required: "Email is required to create a login.",
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.email.message}
                </div>
              )}
            </FormGroup>
            {canRoleVisible() ? (
              <FormGroup style={{ marginTop: "20px" }}>
                <Label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                  for="role"
                >
                  Role
                </Label>
                <Input
                  type="select"
                  name="role"
                  id="role"
                  disabled={true}
                  innerRef={register({ required: true })}
                >
                  {getRoles(props)}
                </Input>
                {errors.role && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Role is required
                  </div>
                )}
              </FormGroup>
            ) : (
              <Input
                type="hidden"
                name="role"
                id="role"
                innerRef={register({ required: true })}
              />
            )}
          </div>
        )}
      </form>
    </>
  );
}

export default FormAgentView;
