import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Row, Col, Container, Badge } from "reactstrap";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import ModalLead from "./ModalLead";
import ModalCall from "../Calls/ModalCall";

import moment from "moment";
import MobileModalLead from "./MobileModalLead";
import {
  Table,
  DefaultColumnFilter,
  CustomColumnSelectFilter,
  DateFilter,
  CustomColumnMultiSelectFilter
} from "../components/Table";
import Loader from "layouts/Loader.js";

function Leads(props) {
  //const [curentAction, setCurentAction] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const getItems = React.useCallback((token, abortController) => {
    setLoading(true);
    fetch("/api/lead/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        if (items) {
          setItems(items);
          setLoading(false);
        }
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token, getItems]);
  const addItemToState = (item) => {
    setItems([item, ...items]);
  };
  const updateState = useCallback(
    (item) => {
      const itemIndex = items.findIndex((data) => data._id === item._id);
      const newArray = [
        ...items.slice(0, itemIndex),
        item,
        ...items.slice(itemIndex + 1),
      ];
      setItems(newArray);
    },
    [items]
  );

  const deleteItemFromState = useCallback(
    (_id) => {
      const updatedItems = items.filter((item) => item._id !== _id);
      setItems(updatedItems);
    },
    [items]
  );
  const teams = props.teams;
  const columns = React.useMemo(
    () => [
      {
        hide: true,
        accessor: (row) => row.external_id,
        Header: "external_id"
      },
      {
        hide: true,
        accessor: (row) => row.email,
        Header: "email"
      },
      {
        hide: true,
        accessor: (row) => row.company,
        Header: "company"
      },
      {
        hide: true,
        accessor: (row) => row.category,
        Header: "category"
      },
      {
        hide: true,
        accessor: (row) => row.comments,
        Header: "comments"
      },
      {
        hide: true,
        accessor: (row) => {
          return row.tags.map((tag)=> {
            return tag.name
          })
        },
        Header: "tags",
        Filter: CustomColumnMultiSelectFilter,
        filter: "includesSome"
      },
      {
       hide: true,
        accessor: (row) => {
          return row.owner ?[row.owner.first + " " + row.owner.last]:[]
        },
        Header: "owner",
        Filter: CustomColumnMultiSelectFilter,
        filter: "includesSome"
      },
      {
        hide: true,
         accessor: (row) => {
           return row.team ?[row.team.name]:[]
         },
         Header: "team",
         Filter: CustomColumnMultiSelectFilter,
         filter: "includesSome"
       },
      {
        Header: "Status",
        responsiveClone: false,
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          if (item.status === "Waiting") {
            return <Badge className="primary">Waiting</Badge>;
          } else if (item.status === "Called") {
            return <Badge color="success">Called</Badge>;
          } else if (item.status === "Not Called") {
            return <Badge color="warning">Not Called</Badge>;
          } else if (item.status === "Skipped") {
            return <Badge color="badge-default">Skipped</Badge>;
          } else if (item.status === "Error") {
            return <Badge color="danger">Error</Badge>;
          } else {
            return null;
          }
        },
        accessor: (row) => [row.status],
        Filter: CustomColumnSelectFilter,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else {
            return row[filter.id].filter.indexOf(filter.value);
          }
        },
        defaultCanFilter: false,
        sortType: "basic",
        minWidth: 20,
        maxWidth: 50,
      },
      {
        Header: "Result",
        responsiveClone: true,
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          if (item.result === "Unknown") {
            return <Badge className="primary">Unknown</Badge>;
          } else if (item.result === "Contacted") {
            return <Badge color="success">Contacted</Badge>;
          } else if (item.result === "Voicemail") {
            return <Badge color="badge-default">Voicemail</Badge>;
          } else if (item.result === "Missed") {
            return <Badge color="warning">Missed</Badge>;
          } else if (item.result === "Removed") {
            return <Badge color="danger">Removed</Badge>;
          } else {
            return null;
          }
        },
        accessor: (row) => [row.result],
        Filter: CustomColumnSelectFilter,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else {
            return row[filter.id].filter.indexOf(filter.value);
          }
        },
        defaultCanFilter: false,
        sortType: "basic",
        minWidth: 75,
        maxWidth: 125,
      },
      {
        Header: "Name",
        responsiveClone: true,
        accessor: (row) => row.first + " " + row.last,
        Filter: DefaultColumnFilter,
        //filter: "includes",
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id
          return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        },
        minWidth: 25,
        maxWidth: 50,
      },
      {
        Header: "Phone",
        responsiveClone: true,
        accessor: "national",
        Filter: DefaultColumnFilter,
        filter: "includes",
        minWidth: 25,
        maxWidth: 50,
      },
      {
        Header: "Created",
        responsiveClone: true,
        accessor: (r) => moment(r.createdDate).valueOf(),
        Cell: ({ cell: { value } }) =>
          moment(value).format("ddd, D-MMM-YYYY, h:mm a"),
        Filter: DateFilter,
        filter: "between",
        minWidth: 300,
      },
      {
        Header: " ",
        disableFilter: true,
        responsiveClone: false,
        actionCoumn: true,
        Cell: (props) => {
          var team = null;
          if (props.row.original.team && teams.list) {
            const itemIndex = teams.list[0].options.findIndex(
              (data) => data._id === props.row.original.team._id
            );
            if (itemIndex !== -1) {
              team = teams.list[0].options[itemIndex];
            } else {
              const itemIndex = teams.list[1].options.findIndex(
                (data) => data._id === props.row.original.team._id
              );
              if (itemIndex !== -1) {
                team = teams.list[1].options[itemIndex];
              }
            }
          }
          return (
            <div style={{ float: "right" }}>
              <MobileModalLead
                buttonType="actions"
                item={props.row.original}
                updateState={updateState}
                deleteItemFromState={deleteItemFromState}
                lead={{
                  value: props.row.original._id,
                  label:
                    props.row.original.first + " " + props.row.original.last,
                }}
                team={team}
                type={{
                  modal: {
                    headerTitle: "Edit Lead",
                    buttonLabel: "Edit",
                  },
                  lead: {
                    headerTitle: "Start a New Call",
                    buttonLabel: "Leadcall",
                  },
                  team: {
                    headerTitle: "Start a New Call",
                    buttonLabel: "Leadcall",
                  },
                }}
              />
              <div className="desktop_screen_button">
                <ModalCall
                  buttonType="leadcall"
                  buttonLabel="leadcall"
                  item={props.row.original}
                  headerTitle="Start a New Call"
                  updateState={updateState}
                  team = {props.row.original.team}
                  deleteItemFromState={deleteItemFromState}
                  lead={{
                    value: props.row.original._id,
                    label:
                      props.row.original.first + " " + props.row.original.last,
                  }}
                />{" "}
                <ModalLead
                  buttonType="actions"
                  buttonLabel="Edit"
                  item={props.row.original}
                  updateState={updateState}
                  deleteItemFromState={deleteItemFromState}
                  headerTitle="Edit Lead"
                />{" "}
              </div>
            </div>
          );
        },
      },
    ],
    [
      teams,
      deleteItemFromState,
      updateState
    ]
  );
  return (
    <>
      <CardsHeader name="Leads" />{" "}
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12" className="lead_container">
                      {!loading ? (
                        <>
                          <ModalLead
                            buttonType="add"
                            buttonLabel="Add Lead"
                            addItemToState={addItemToState}
                            item={{}}
                            headerTitle="Add Lead"
                          />
                          <Table
                            name="Leads"
                            columns={columns}
                            data={items}
                            globalFilter={true}
                            tagFilter = {true}
                            ownerFilter = {true}
                            teamFilter = {true}
                            filter={true}
                            paginate={true}
                            sort={true}
                            auth = {props.auth}
                            ownerRecord = {props.ownerRecord}
                            settings={[
                              { width: 882, column: "all" },
                              { width: 650, column: 5 },
                              { width: 580, column: 4 },
                              { width: 0, column: 3 },
                            ]}
                          />
                        </>
                      ) : (
                        <>
                          <Loader />
                        </>
                      )}
                    </Col>
                  </Row>
                  <hr />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
Leads.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  teams: state.teams.teamDSDropdown,
  ownerRecord: state.agents.agentTODropdown
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
