import React from "react";
import { Table, Button } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

function TableSearch(props) {
  //
  const confirmBuy = (item) => {
    Swal.fire({
      title: "Purchase this number?<br> " + item.friendlyName,

      text:
        "This phone number has a cost of $3/month and you will be billed immediately.",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Name this Phone Number",
        label: "Name",
      },
      showCancelButton: true,
      confirmButtonText: "Purchase",
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return "You must provide a name for this number.";
        }
      },
      preConfirm: (name) => {
        axios({
          method: "Post",
          responseType: "json",
          url: `/api/number/purchase/` + encodeURI(item.phoneNumber),
          data: { name: name, isoCountry: item.isoCountry },
        })
          .then((response) => {
            if (response.data.error) {
              throw new Error(response.data.error);
            } else {
              Swal.fire({
                icon: "success",
                title: "You're the proud new owner of " + item.phoneNumber,
              });

              props.updatePurchasedState(response.data);
              props.toggle();

              return;
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              confirmButtonText: "Ok",
            }).showValidationMessage(`${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const items = props.items.map((item) => {
    return (
      <tr key={item.phoneNumber}>
        <th scope="row">
          {item.phoneNumber}
          <br></br>
          {item.locality}, {item.region}
        </th>
        <th scope="row">
          {item.isoCountry}
          <br></br>
          {item.postalCode}
        </th>
        <th scope="row">
          <Button
            onClick={() => {
              confirmBuy(item);
            }}
          >
            BUY
          </Button>
        </th>
      </tr>
    );
  });

  return (
    <Table responsive hover style={{ marginTop: "10px" }}>
      <thead>
        <tr>
          <th>Number</th>
          <th>Country</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

export default TableSearch;
