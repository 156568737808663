import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loginUser } from "../../../actions/authActions";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.js";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email:
        process.env.REACT_APP_NODE_ENV === "DEVELOPMENT"
          ? "test@mycallassist.com"
          : "",
      password:
        process.env.REACT_APP_NODE_ENV === "DEVELOPMENT"
          ? "test@mycallassist.com"
          : "",
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("../account/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData).then((res) => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { errors } = this.state;
    const query = new URLSearchParams(this.props.history.location.search);
    const message = query.get("message");
    return (
      <>
        <AuthHeader title="Login to Call Assist!" />
        <Container className="mt--8 pb-5 container">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <Form noValidate onSubmit={this.onSubmit}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.email}
                          error={errors.email}
                          id="email"
                          placeholder="Email"
                          type="email"
                          className={classnames("", {
                            invalid: errors.email || errors.emailnotfound,
                          })}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          id="password"
                          placeholder="Password"
                          type="password"
                          className={classnames("", {
                            invalid:
                              errors.password || errors.passwordincorrect,
                          })}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.password}
                        {errors.passwordincorrect}
                      </span>
                    </FormGroup>

                    <span
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.message ? errors.message : message}
                    </span>

                    <div className="text-center">
                      {this.state.loading ? (
                        <Button color="info" type="button" disabled={true}>
                          Loading...
                        </Button>
                      ) : (
                        <Button color="info" type="submit">
                          Sign in
                        </Button>
                      )}
                    </div>

                    <div className="text-center text-muted mb-4">
                      <small>Or sign in with</small>
                    </div>

                    <div className="btn-wrapper text-center">
                      <Col className="facebookgooglebutton_col">
                        <div className="facebookgooglebutton facebook">
                          <Button
                            className="btn-neutral btn-icon"
                            color="default"
                            href={
                              process.env.REACT_APP_BACKEND +
                              "/api/auth/facebook"
                            }
                            onClick={this.facebookSignin}
                          >
                            <span className="btn-inner--icon mr-1">
                              <img
                                alt="..."
                                src={require("assets/img/icons/common/facebook.png")}
                              />
                            </span>
                            <span className="btn-inner--text">
                              {" "}
                              Sign in with Facebook
                            </span>
                          </Button>
                        </div>
                      </Col>
                      <Col className="facebookgooglebutton_col">
                        <div className="facebookgooglebutton google">
                          <Button
                            className="btn-neutral btn-icon "
                            color="default"
                            href={
                              process.env.REACT_APP_BACKEND + "/api/auth/google"
                            }
                            onClick={this.googleSignin}
                          >
                            <span className="btn-inner--icon mr-1">
                              <img
                                alt="..."
                                src={require("assets/img/icons/common/google.svg")}
                              />
                            </span>
                            <span className="btn-inner--text">
                              {" "}
                              Sign in with Google
                            </span>
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link to="/auth/Forgot" className="text-light">
                    <small>Forgot password?</small>
                  </Link>
                </Col>
                <Col className="text-right" xs="6">
                  <Link to="/auth/Register" className="text-light">
                    <small>Create new account</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

/*
<a class="btn btn-block btn-facebook btn-social" href="/auth/facebook"><i
class="fab fa-facebook-f fa-sm"></i>Sign in with Facebook</a>
<a class="btn btn-block btn-google btn-social" href="/auth/google"><i
class="fab fa-google-plus-g fa-xs"></i>Sign in with Google</a>
*/

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
