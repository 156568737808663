import React from "react";
import { Link } from "react-router-dom";
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
    };
  }
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 150;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }
  trial = () => {
    if (
      this.props.billing.payload.accountStatus !== "Canceled" &&
      this.props.billing.payload.planType === "Trial"
    ) {
      var trialEnd = new Date(this.props.billing.payload.trialEnd);
      var today = new Date();
      var endIn = Math.round(
        (trialEnd.getTime() - today.getTime()) / (1000 * 3600 * 24)
      );

      //var m = trialEnd.getMonth();
      //var d = trialEnd.getDate();
      //var y = trialEnd.getFullYear();

      /*var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
            ];*/
      // var mlong = months[m];
      //var fulldate = mlong + " " + d + ", " + y;
      if (endIn >= 0) {
        return (
          <div className={`banner_class ${this.state.isTop ? "top" : ""}`}>
            <div className="banner_class_div">
              {endIn} Days left in your Trail,{" "}
              <Link className="" to="/account/billing/">
                Upgrade Now
              </Link>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={`banner_class_suspended ${
              this.state.isTop ? "top" : ""
            }`}
          >
            <div className="banner_class_div_suspended">
              Your trial has expired but your leads are still waiting,{" "}
              <Link className="" to="/account/billing/">
                Upgrade Now
              </Link>{" "}
              to re-enable services.
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };
  render() {
    return this.trial();
  }
}

export default Banner;
