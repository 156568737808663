import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";

import { useForm } from "react-hook-form";

import "react-phone-input-2/lib/style.css";



function FormAdmin(props) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: {
      _id: 0,
    },
  });

  // const options = [];
  useEffect(() => {
    //register({ name: "country" });
  }, [register, setValue, props]);

  const submitFormEdit = async (form) => {
    setLoading(true);
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitFormEdit)}>
        <Row>
          <Col md="6">
            <FormGroup></FormGroup>
          </Col>
        </Row>

        <ModalFooter>
          {loading ? (
            <Button color="primary" type="button" disabled={true}>
              Saving...
            </Button>
          ) : (
            <Button type="submit" color="primary">
              Save Organization
            </Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}
FormAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormAdmin);
