import React from "react";

import { Table } from "reactstrap";

import ModalRecording from "./ModalRecording";
import ModalTexttospeech from "./ModalTexttospeech";
function TableRecording(props) {
  const items = props.items.map((item) => {
    return (
      <tr key={item._id}>
        <th scope="row">{item.name}</th>
        {props.hideColumn ? null : <th scope="row">{item.type}</th>}
        <th scope="row">
          {item.path && item.fileName ? (
            <audio
              src={item.fileName}
              controls="controls"
              style={{ width: "100%" }}
            />
          ) : (
            <></>
          )}
        </th>
        {props.hideColumn ? null : (
          <th scope="row" style={{ whiteSpace: "nowrap" }}>
            {item.description.slice(0, 35)}...
          </th>
        )}
        {props.hideColumn ? null : (
          <>
            <td>
              <div style={{ float: "right" }}>
                {item.recordingType === "text" ? (
                  <ModalTexttospeech
                    buttonType="actions"
                    buttonLabel="Edit"
                    item={item}
                    updateState={props.updateState}
                    deleteItemFromState={props.deleteItemFromState}
                    headerTitle="Edit Recording"
                  />
                ) : (
                  <ModalRecording
                    buttonType="actions"
                    buttonLabel="Edit"
                    item={item}
                    updateState={props.updateState}
                    deleteItemFromState={props.deleteItemFromState}
                    headerTitle="Edit Recording"
                  />
                )}{" "}
              </div>
            </td>
          </>
        )}
      </tr>
    );
  });

  return (
    <Table responsive hover style={{ marginTop: "10px" }}>
      <thead>
        <tr>
          <th>Name</th>
          {props.hideColumn ? null : <th>Type</th>}
          <th>Recording</th>
          {props.hideColumn ? null : <th>Description</th>}
          {props.hideColumn ? null : <th>Actions</th>}
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

export default TableRecording;
