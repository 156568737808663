import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import FormTeam from "./FormTeam";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormSchedule from "../Agents/FormSchedule";
const MySwal = withReactContent(Swal);

function MobileModalTeam(props) {
  const [modal, setModal] = useState(false);
  const [typeModal, setTypeModal] = useState("modal");
  const [screenChange, setScreenValue] = useState("desktop");

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/team/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);

            Swal.fire("Deleted!", "Your team is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  //const label = props.type[typeModal].buttonLabel;
  const scheduleToggle = () => {
    setTypeModal("schedule");
    toggle();
  };
  const modalToggle = () => {
    setTypeModal("modal");
    toggle();
  };
  if (props.buttonType === "actions") {
    var addEditButton = (
      <>
        <UncontrolledDropdown className="mobilescreen_button" direction="left">
          <DropdownToggle
            caret
            className={"remove_background action_act"}
          ></DropdownToggle>
          <DropdownMenu>
            {props.item.hasSchedule ? (
              <DropdownItem
                onClick={scheduleToggle}
                className="dropdown-item remove_background"
              >
                <i className="fas fa-calendar"></i>{" "}
                {props.type["schedule"].buttonLabel}
              </DropdownItem>
            ) : null}
            <DropdownItem
              onClick={modalToggle}
              className="dropdown-item remove_background"
            >
              <i className="fas fa-pen"></i> Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                confirmDelete(props.item._id, props.item.name);
              }}
              className="dropdown-item remove_background"
            >
              <i className="fa fa-trash"></i> Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
  const allowSchedule = (item) => {
    return true;
    /*if(props.auth.user.role === "owner" || props.auth.user.role === "admin") {
      return false;
    }
    const accounts = item.members;
    const index = accounts.findIndex(
      (data) => data._id === props.auth.user.agent
    );
    return index !== -1;*/
  };
  const getFormModal = () => {
    switch (typeModal) {
      case "schedule":
        return (
          <FormSchedule
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            updateWhat="team"
            allowEdit={!allowSchedule(props.item)}
            toggle={modalToggle}
            item={props.item}
            screenChange={screenChange}
            setScreenValue={setScreenValue}
          />
        );
      default:
        return (
          <FormTeam
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={scheduleToggle}
            item={props.item}
          />
        );
    }
  };
  return (
    <>
      {addEditButton}

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.type[typeModal].headerTitle}
        </ModalHeader>
        <ModalBody>{getFormModal()}</ModalBody>
      </Modal>
    </>
  );
}

export default MobileModalTeam;
