import {
    LOAD_CALL_LIST,
    CALL_LIST
  } from "../actions/types";

  const initialState = {
    loading: false,
    calls: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_CALL_LIST:
            return {
                ...state,
                loading: true,
            };
        case CALL_LIST:
            return {
                ...state,
                loading: false,
                calls: action.payload
            };
        default:
            return state;
    }
  }