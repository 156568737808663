import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormTeam from "./FormTeam";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function ModalTeam(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/team/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);

            Swal.fire("Deleted!", "Your team is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const label = props.buttonLabel;
  var addEditButton = null;
  if (props.buttonType === "add") {
    addEditButton = (
      <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
        {label}
      </Button>
    );
  } else if (props.buttonType === "actions") {
    if (props.mobile) {
      addEditButton = (
        <>
          <button onClick={toggle} className="dropdown-item remove_background">
            <i className="fas fa-pen"></i> Edit
          </button>
          <button
            onClick={() => {
              confirmDelete(props.item._id, props.item.name);
            }}
            className="dropdown-item remove_background"
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </>
      );
    } else {
      addEditButton = (
        <>
          <Button
            color={"info"}
            onClick={toggle}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>

          <Button
            color={"default"}
            onClick={() => {
              confirmDelete(props.item._id, props.item.name);
            }}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </>
      );
    }
  }

  return (
    <>
      {addEditButton}

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>
          <FormTeam
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            item={props.item}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalTeam;
