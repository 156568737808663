import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ModalIntegration from "./Modal/ModalIntegration";
import ZapierIntegration from "./Modal/ZapierIntegration";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  CardTitle,
  ModalFooter,
} from "reactstrap";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import ConditionalWrapper from "../components/ConditionalWrapper.js";
import axios from "axios";
import HookIntegration from "./HookIntegration";
import Loader from "layouts/Loader.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
function Integration(props) {
  const [integrations, setIntegrations] = useState([{ type: "webhook" }]);
  const [typeActive, setTypeActive] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);
  const [integrationDataLoading, setIntegrationDataLoading] = useState(false);
  const [userIntegrations, setUserIntegrations] = useState([]);
  const [userIntegrationsCount, setUserIntegrationsCount] = useState({});
  function getUserIntegration(token, typeActive, abortController) {
    setIntegrationDataLoading(true);
    fetch("/api/user_integration/type-" + typeActive.type, {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        setIntegrationDataLoading(false);
        if (items && items.list) {
          setUserIntegrations(items.list);
          setUserIntegrationsCount(items.usageCountArray);
        }
      })
      .catch((err) => {
        setIntegrationLoading(false);
      });
  }

  const getItems = (token, abortController) => {
    setIntegrationLoading(true);
    fetch("/api/integration/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((integrations) => {
        setIntegrationLoading(false);
        if (integrations) {
          //setTypeActive(integrations[0]);
          setTypeActive({ type: "all" });
          setIntegrations(integrations);
        }
      })
      .catch((err) => {
        setIntegrationLoading(false);
      });
  };
  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    setTypeActive({ type: "all" });
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token]);
  useEffect(() => {
    const abortController = new window.AbortController();
    if (typeActive && typeActive.type) {
      getUserIntegration(props.auth.user.token, typeActive, abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token, typeActive]);

  const addItemToState = (item) => {
    setUserIntegrations([...userIntegrations, item]);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["integration"] += 1;
      props.setStepList(stepList);
    }
  };

  const updateState = (item) => {
    const itemIndex = userIntegrations.findIndex((data) => {
      return data._id === item._id;
    });
    const newArray = [
      ...userIntegrations.slice(0, itemIndex),
      item,
      ...userIntegrations.slice(itemIndex + 1),
    ];
    setUserIntegrations(newArray);
  };

  const deleteItemFromState = (_id) => {
    const updatedItems = userIntegrations.filter((item) => item._id !== _id);
    setUserIntegrations(updatedItems);
    if (props.stepList) {
      const stepList = props.stepList;
      stepList["integration"] -= 1;
      props.setStepList(stepList);
    }
  };

  const changeActive = (integration) => {
    setTypeActive(integration);
  };

  const currentRecords = (userIntegration) => {
    const integrationData = integrations.filter((integration)=> integration.type === userIntegration.type)
    const type = userIntegration.type;
    var title = "Webhook";
    var actionButton = (
      <ModalIntegration
        buttonType={"settings"}
        buttonLabel={"Settings"}
        item={userIntegration}
        headerTitle={title}
        auth={props.auth}
        updateState={updateState}
        addItemToState={addItemToState}
        deleteItemFromState={deleteItemFromState}
      />
    );
    var Instruction = (
      <ModalIntegration
        buttonType={"instructions"}
        buttonLabel={"Instructions"}
        item={userIntegration}
        headerTitle={title + " Instructions"}
        auth={props.auth}
        updateState={updateState}
        addItemToState={addItemToState}
        deleteItemFromState={deleteItemFromState}
      />
    );
    switch (type) {
      case "zapier":
        title = "Zapier";
        actionButton = (
          <ZapierIntegration
            buttonType={"settings"}
            buttonLabel={"Settings"}
            item={userIntegration}
            headerTitle={title}
            auth={props.auth}
            updateState={updateState}
            addItemToState={addItemToState}
            deleteItemFromState={deleteItemFromState}
          />
        );
        Instruction = null;
        break;
      default:
        break;
    }
    return { 
      Action: actionButton, 
      title: title, 
      Instruction: Instruction, 
      icon: (integrationData.length > 0)?integrationData[0].icon:null
    };
  };

  const changeIntegrationStatus = (data) => {
    axios({
      method: "patch",
      responseType: "json",
      url: `/api/user_integration/` + data._id + "/status",
      data: { active: !data.active },
    })
      .then((response) => {
        const updatedItems = userIntegrations.filter((item) => {
          if (item._id === data._id) {
            Object.assign(item, response.data);
          }
          return item;
        });
        setUserIntegrations(updatedItems);
      })
      .catch((err) => {
        Swal.fire({
          text: "Something went wrong. Try again.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const integrationContainer = () => {
    //const { items, Action, title } = currentRecords();
    const items = userIntegrations;
    if (items.length > 0) {
      return items.map((userIntegration, index) => {
        if (
          userIntegration.type === typeActive.type ||
          typeActive.type === "all"
        ) {
          const { Action, Instruction, icon } = currentRecords(userIntegration);
          return (
            <HookIntegration
              key={index}
              userIntegrationsCount={userIntegrationsCount[userIntegration._id] || 0}
              data={{
                _type: "created",
                title: userIntegration.name,
                icon: icon ? (
                  <img
                    alt="..."
                    src={icon}
                    className="hook_card_integration_left_image"
                  />
                ) : (
                  ""
                ),
                event: {},
                action: (
                  <div
                    className="row_col"
                    style={{ float: "right", flexFlow: "row", display: "flex" }}
                  >
                    <div className="col_toggle_class">
                      <div className="toggle_button_div">
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            name="dnd"
                            checked={userIntegration.active}
                            onChange={() => {
                              MySwal.fire({
                                title:
                                  "Are you sure you want to " +
                                  (userIntegration.active
                                    ? "disable"
                                    : "enable") +
                                  " your integration",
                                text: "",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                              }).then((result) => {
                                if (result.value) {
                                  changeIntegrationStatus(
                                    userIntegration,
                                    index
                                  );
                                }
                              });
                            }}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Off"
                            data-label-on="On"
                          />
                        </label>
                      </div>
                    </div>
                    {Instruction}
                    {Action}
                  </div>
                ),
              }}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return <div className="no_record_div">No Record Found</div>;
    }
  };
  return (
    <>
      {props.handleSkipButton ? "" : <CardsHeader name="Integrations" />}
      <ConditionalWrapper
        condition={!props.handleSkipButton}
        wrapper={(children) => (
          <>
            {" "}
            <Container className="mt--6" fluid>
              <Row>
                <div className="col">
                  <div className="card-wrapper">{children}</div>
                </div>
              </Row>
            </Container>
          </>
        )}
      >
        {" "}
        <Row>
          <Col sm="12" xs="12" xl="5">
            <Card>
              <CardBody>
                <CardTitle className="text-uppercase text-muted mb-0">
                  INTEGRATIONS
                </CardTitle>
                <hr />
                <Container>
                  {!integrationLoading ? (
                    integrations &&
                    integrations.map((integration, index) => {
                      const type = integration.type;
                      var actionButton = null;
                      switch (type) {
                        case "zapier":
                          actionButton = (
                            <ZapierIntegration
                              buttonType={"create"}
                              buttonLabel={"Create"}
                              headerTitle={"Zapier"}
                              auth={props.auth}
                              addItemToState={addItemToState}
                            />
                          );
                          break;
                        default:
                          actionButton = (
                            <ModalIntegration
                              buttonType={"create"}
                              buttonLabel={"Create"}
                              headerTitle={"Webhook"}
                              auth={props.auth}
                              addItemToState={addItemToState}
                            />
                          );
                          break;
                      }
                      return (
                        <div
                          onClick={(e) => {
                            if( e.target.className === 'hook_card_integration_right_row row_col' ) {
                              e.stopPropagation()
                              changeActive(integration)
                            }
                          }}
                          key={index}
                        >
                          <HookIntegration
                            active={typeActive.type === integration.type}
                            data={{
                              _type: "integration",
                              title: integration.title,
                              icon: integration.icon ? (
                                <img
                                  alt="..."
                                  src={integration.icon}
                                  className="hook_card_integration_left_image"
                                />
                              ) : (
                                ""
                              ),
                              action: actionButton,
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <Col
                      xs="12"
                      sm="12"
                      xl="7"
                      style={{ paddingBottom: "30px" }}
                    >
                      <Loader />
                    </Col>
                  )}
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xs="12" xl="7">
            <Card>
              <CardBody>
                <CardTitle className="text-uppercase text-muted mb-0">
                  YOUR INTEGRATIONS
                </CardTitle>
                <hr />
                <Container>
                  {!integrationDataLoading ? (
                    <>
                    {typeActive.type !== 'all'?<span 
                      className="show_all" 
                      onClick={()=> setTypeActive({type: 'all'})}>
                        Show All Your Integrations
                    </span>:null}
                    { integrationContainer() }
                    </>
                  ) : (
                    <Col
                      xs="12"
                      sm="12"
                      md="7"
                      style={{ paddingBottom: "30px" }}
                    >
                      <Loader />
                    </Col>
                  )}
                </Container>
              </CardBody>
            </Card>
            <ModalFooter>
              {props.handleSkipButton ? props.handleSkipButton() : <></>}
            </ModalFooter>
          </Col>
        </Row>{" "}
      </ConditionalWrapper>
    </>
  );
}
Integration.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Integration);
