import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as toastrReducer } from "react-redux-toastr";
// List reducers below
import agentReducer from "./agentReducer";
import callReducer from "./callReducer";
import integrationReducer from "./integrationReducer";
//import leadReducer from "./leadReducer";
import numberReducer from "./numberReducer";
import recordingReducer from "./recordingReducer";
import teamReducer from "./teamReducer";
import userIntegrationReducer from "./userIntegrationReducer";

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";

import profileReducer from "./profileReducer";
import billingReducer from "./billingReducer";

import tagReducer from "./tagReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    errors: errorReducer,
    toastr: toastrReducer,
    profile: profileReducer,
    billing: billingReducer,
    tags: tagReducer,
    agents: agentReducer,
    calls: callReducer,
    integrations: integrationReducer,
    //leads: leadReducer,
    numbers: numberReducer,
    recordings: recordingReducer,
    teams: teamReducer,
    userIntegrations: userIntegrationReducer
  });
