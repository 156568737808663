import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormLead from "./FormLead";
import FormLeadEdit from "./FormLeadEdit";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function ModalLead(props) {
  const [modalOpen, setModal] = useState(false);
  const [lead, setLead] = useState();

  const toggle = () => {
    setModal(!modalOpen);
    return true;
  };

  const getLead = (_id) => {
    const token = props.auth.user.token;
    fetch("/api/lead/" + _id, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((lead) => {
        setLead(lead);
        toggle();
      })
      .catch((err) => {});
  };

  const confirmDelete = (_id, identifier) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + identifier + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/lead/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data._id);
            Swal.fire("Deleted!", "Your lead is gone for good.", "success");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const label = props.buttonLabel;
  var addEditButton = "";
  var addEditLeadForm = "";
  if (props.buttonType === "add") {
    addEditButton = (
      <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
        {label}
      </Button>
    );
    addEditLeadForm = (
      <FormLead
        addItemToState={props.addItemToState}
        updateState={props.updateState}
        toggle={toggle}
        item={props.item}
        agents={props.agents}
        deleteItemFromState={props.deleteItemFromState}
        teams={props.teams}
        ownerRecord={props.ownerRecord}
      />
    );
  } else if (props.buttonType === "actions") {
    var identifier = props.item.first + " " + props.item.last;
    if (props.mobile) {
      addEditButton = (
        <>
          <button onClick={toggle} className="dropdown-item remove_background">
            <i className="fas fa-pen"></i> Edit
          </button>
          <button
            onClick={() => {
              confirmDelete(props.item._id, identifier);
            }}
            className="dropdown-item remove_background"
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </>
      );
    } else {
      addEditButton = (
        <>
          <Button
            color={"info"}
            onClick={toggle}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>

          <Button
            color={"default"}
            onClick={() => {
              confirmDelete(props.item._id, identifier);
            }}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </>
      );
    }
    addEditLeadForm = (
      <FormLeadEdit
        addItemToState={props.addItemToState}
        updateState={props.updateState}
        toggle={toggle}
        item={props.item}
        deleteItemFromState={props.deleteItemFromState}
      />
    );
  } else if (props.buttonType === "view") {
    if (props.item._id) {
      addEditButton = (
        <>
          <Button
            color="link"
            onClick={() => {
              getLead(props.leadId);
            }}
            style={{ padding: "0px" }}
          >
            <i className="fa fa-users text-info" title="Lead"></i>{" "}
            {props.item.first
              ? props.item.first + " " + props.item.last + ": "
              : ""}
            {props.item.national}
          </Button>
        </>
      );
    } else {
      addEditButton = (
        <>
          <i className="fa fa-users text-info" title="Lead"></i>{" "}
          {props.item.first
            ? props.item.first + " " + props.item.last + ": "
            : ""}
          {props.item.national}
        </>
      );
    }

    addEditLeadForm = (
      <>
        <FormLeadEdit
          toggle={toggle}
          item={lead}
          agents={props.agents}
          teams={props.teams}
          deleteItemFromState={props.deleteItemFromState}
          ownerRecord={props.ownerRecord}
        />
      </>
    );
  }
  return (
    <>
      {addEditButton}
      <Modal isOpen={modalOpen} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>{addEditLeadForm}</ModalBody>
      </Modal>
    </>
  );
}
ModalLead.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalLead);
