import React from "react";
import { connect } from "react-redux";
import { cleartNotification } from "../actions/authActions";
//class Notification extends React.Component {
function Notification(props) {
  const { notification } = props;

  if (!notification) {
    return null;
  }

  /* 
TEAM CALLING FILTER
      (notification &&
      notification.segment01.target === "team" &&
      notification.segment01.segmentStatus === "Ringing" &&
      notification.lead) 

 LEAD CALLING FILTER
  (notification.segment01.target === "lead" &&
      notification.segment01.segmentStatus === "Queued for Agent" &&
      (notification.segment02.segmentStatus === "Initiated" ||
        notification.segment02.segmentStatus === "Ringing") &&
      notification.lead)
*/

  const leadBool =
    notification.segment02.target === "team" &&
    notification.segment02.currentTarget &&
    notification.segment02.currentTarget.id &&
    notification.segment02.currentTarget.id === props.user.agent;
  const teamBool =
    notification.segment01.target === "team" &&
    notification.segment01.currentTarget &&
    notification.segment01.currentTarget.id &&
    notification.segment01.currentTarget.id === props.user.agent;

  if (
    ((notification &&
      notification.segment01.target === "team" &&
      notification.segment01.segmentStatus === "Ringing" &&
      notification.lead) ||
      (notification.segment01.target === "lead" &&
        notification.segment01.segmentStatus === "Queued for Agent" &&
        (notification.segment02.segmentStatus === "Initiated" ||
          notification.segment02.segmentStatus === "Ringing") &&
        notification.lead)) &&
    (leadBool || teamBool)
  ) {
    var { lead } = notification;

    return (
      <div className="notificationDiv">
        <div className="notificationDiv_container">
          <div className="notificationDiv_container_header">Incoming Call</div>
          <div className="notificationDiv_container_content">
            <div>
              <span>
                {lead.first} {lead.last}
              </span>
            </div>
            <div>
              <span>{lead.national}</span>
            </div>
          </div>
          <div className="notificationDiv_container_footer">
            <button
              onClick={() => {
                props.cleartNotification();
                props.getLead(lead._id);
              }}
              className="btn btn-success"
            >
              View Lead
            </button>
            <button
              onClick={props.cleartNotification}
              className="btn btn-default btn-close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  notification: state.auth.notification,
});

export default connect(mapStateToProps, {
  cleartNotification,
})(Notification);
