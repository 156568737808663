import React from "react";
import { Table, Badge } from "reactstrap";
import { connect } from "react-redux";
import ModalVerify from "./ModalVerify";
import ModalAdd from "./ModalAdd";

function TableVerify(props) {
  const items = props.items.map((item) => {
    var getVerified = "";
    if (item.status !== "Pending") {
      getVerified = "";
    } else {
      getVerified = (
        <ModalVerify
          buttonLabel="Verify"
          item={item}
          updateOwnedState={props.updateOwnedState}
          headerTitle="Verify Phone"
        />
      );
    }

    var agentList = [];
    props.agents.find((a) => {
      if (a.phone === item._id) {
        agentList.push(
          <li key={a._id} style={{ listStyleType: "none" }}>
            <Badge color="danger" pill>
              {a.first} {a.last}
            </Badge>
          </li>
        );
      }
      return null;
    });

    return (
      <tr key={item._id}>
        <th scope="row">{item.name}</th>
        <th scope="row">{agentList} </th>
        <th scope="row">{item.national}</th>
        <th scope="row">
          {item.status}
          {getVerified}
        </th>
        <td>
          <div style={{ float: "right" }}>
            <ModalAdd
              buttonType="actions"
              item={item}
              deleteOwnedFromState={props.deleteOwnedFromState}
            />
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Table responsive hover style={{ marginTop: "10px" }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Agents</th>
          <th>Number</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}
const mapStateToProps = (state) => ({
  agents: state.agents.agentDropdown,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TableVerify);