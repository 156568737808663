import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import FormCall from "./FormCall";
import ViewCall from "./ViewCall";
import ReSchedule from "./ReSchedule";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
const MySwal = withReactContent(Swal);

function ModalCall(props) {
  const [modal, setModal] = useState(false);
  const [typeModal, setTypeModal] = useState("modal");

  const toggle = () => {
    setModal(!modal);
  };
  const callToggle = (type) => {
    setTypeModal(type);
    toggle();
  };
  const confirmDelete = (_id) => {
    MySwal.fire({
      title: "Are you sure you want to delete Call Schedule",
      text: "This will also change call status to Cancelled",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "patch",
          responseType: "json",
          url: `/api/call/` + _id + "/cancel",
        })
          .then((response) => {
            if (props.type === "Scheduled") {
              props.deleteOwnedFromState(_id);
            } else {
              props.updateState(response.data);
            }
            Swal.fire(
              "Deleted!",
              "Your call schedule has been cancelled.",
              "success"
            );
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };
  const label = props.buttonLabel;
  var addEditButton = null;

  if (props.billing.payload.stripePlanId) {
    var boolBillingBasic = props.billing.payload.stripePlanId.includes("basic");
  }

  if (props.buttonType === "add") {
    addEditButton = (
      <Button color={"success"} onClick={toggle} style={{ float: "right" }}>
        {label}
      </Button>
    );
  } else if (props.buttonType === "leadcall") {
    addEditButton = (
      <>
        <ButtonGroup>
          <UncontrolledDropdown
            direction="left"
            style={{
              float: "right",
            }}
          >
            <DropdownToggle color="success" style={{ padding: "4px" }} caret>
              <i
                className="fa fa-phone fa-rotate-90"
                style={{ marginRight: "2px" }}
              ></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => callToggle("team")}>
                Team Call
              </DropdownItem>
              {boolBillingBasic ? (
                <button
                  type="button"
                  tabIndex="0"
                  role="menuitem"
                  className="dropdown-item disable_btn"
                >
                  <div className="calltooltip lead_warn">
                    <i
                      className="fa fa-info-circle"
                      id={"UncontrolledTooltip_" + props.item._id}
                    ></i>
                    <div className="tooltiptext tooltip-left">
                      <span style={{ whiteSpace: "normal" }}>
                        Upgrade to Assist Max to activate Lead Calling
                      </span>
                    </div>
                  </div>
                  <span className="title">Lead Call</span>
                </button>
              ) : (
                <DropdownItem onClick={() => callToggle("lead")}>
                  Lead Call
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </ButtonGroup>
      </>
    );
    if (props.mobile) {
      addEditButton = (
        <UncontrolledDropdown direction="left">
          <DropdownToggle
            className={"remove_background"}
            caret
            style={{ width: "100%", textAlign: "left" }}
          >
            <i
              className="fa fa-phone fa-rotate-90"
              style={{ marginRight: "2px" }}
            ></i>{" "}
            Call
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => !boolBillingBasic && callToggle("team")}
            >
              Team Call
            </DropdownItem>

            {boolBillingBasic ? (
              <button
                type="button"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item disable_btn"
              >
                <div className="calltooltip lead_warn">
                  <i
                    className="fa fa-info-circle"
                    id={"UncontrolledTooltip_" + props.item._id}
                  ></i>
                  <div className="tooltiptext tooltip-left">
                    <span style={{ whiteSpace: "normal" }}>
                      Upgrade to Assist Max to activate Lead Calling
                    </span>
                  </div>
                </div>
                <span className="title">Lead Call</span>
              </button>
            ) : (
              <DropdownItem onClick={() => callToggle("lead")}>
                Lead Call
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  } else if (props.buttonType === "view") {
    addEditButton = (
      <>
        <UncontrolledDropdown className="mobilescreen_button" direction="left">
          <DropdownToggle
            caret
            className={"remove_background action_act"}
          ></DropdownToggle>
          <DropdownMenu>
            <button
              onClick={toggle}
              className="dropdown-item remove_background"
            >
              <i className="fas fa-eye"></i> View
            </button>
            {props.item.status === "Scheduled" ? (
              <>
                <button
                  onClick={() => callToggle("reschedule")}
                  className="dropdown-item remove_background"
                >
                  <i className="fas fa-pen"></i> Edit
                </button>
                <button
                  onClick={() => {
                    confirmDelete(props.item._id);
                  }}
                  className="dropdown-item remove_background"
                >
                  <i className="fas fa-trash"></i> Delete
                </button>
              </>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div className="desktop_screen_button" style={{ marginTop: "20px" }}>
          <Button
            color={"info"}
            onClick={toggle}
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
          {props.item.status === "Scheduled" ? (
            <>
              <button
                onClick={() => callToggle("reschedule")}
                className="btn btn-info"
                style={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingLeft: "7px",
                  paddingRight: "7px",
                }}
              >
                <i className="fas fa-pen"></i>
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  confirmDelete(props.item._id);
                }}
                style={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingLeft: "7px",
                  paddingRight: "7px",
                }}
              >
                <i className="fa fa-trash"></i>
              </button>
            </>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <>
      {addEditButton}

      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {typeModal === "reschedule" ? "Reschedule Call" : props.headerTitle}
        </ModalHeader>
        <ModalBody>
          {props.buttonType === "view" ? (
            typeModal === "reschedule" ? (
              <ReSchedule
                toggle={toggle}
                item={props.item}
                updateState={props.updateState}
                type={props.type}
                deleteOwnedFromState={props.deleteOwnedFromState}
              />
            ) : (
              <ViewCall
                toggle={toggle}
                item={props.item}
                agents={props.agents}
                teams={props.teams}
                callerids={props.callerids}
                recordings={props.recordings}
              />
            )
          ) : (
            <FormCall
              addItemToState={props.addItemToState}
              toggle={toggle}
              item={props.item}
              agents={props.agents}
              teams={props.teams}
              lead={props.lead}
              team={props.team}
              ownerRecord={props.ownerRecord}
              leadCall={typeModal}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  billing: state.billing,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ModalCall);
