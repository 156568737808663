import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Badge } from "reactstrap";
class CreditCard extends React.Component {
  setcardType(type) {
    var image;
    switch (type) {
      case "visa":
        image = require("assets/img/icons/cards/visa.png");
        return image;
      case "mastercard":
        image = require("assets/img/icons/cards/mastercard.png");
        return image;
      case "amex":
        image = require("assets/img/icons/cards/amex.png");
        return image;
      default:
        image = require("assets/img/icons/cards/card.png");
        return image;
    }
  }

  cardStatus(status) {
    if (status === "succeeded") {
      return (
        <Badge className="badge-lg" color="success">
          ACTIVE
        </Badge>
      );
    } else {
      return (
        <Badge className="badge-lg" color="warning">
          DECLINED
        </Badge>
      );
    }
  }
  render() {
    var {
      cardType,
      methodStatus,
      last4,
      cardName,
      expMonth,
      expyear,
    } = this.props;
    return (
      <>
        <Row>
          <Col lg="6" xs="12" sm="12" md="8" style={{ maxWidth: "450px" }}>
            <Card className="bg-gradient-primary">
              <CardBody>
                <Row className="justify-content-between align-items-center">
                  <div className="col">
                    <img alt="..." src={this.setcardType(cardType)} />
                  </div>
                  <Col className="col-auto">
                    {this.cardStatus(methodStatus)}
                  </Col>
                </Row>
                <div className="my-4">
                  <span className="h6 surtitle text-light">Card number</span>
                  <div className="card-serial-number h1 text-white">
                    <div>xxxx</div>
                    <div>xxxx</div>
                    <div>xxxx</div>
                    <div>{last4}</div>
                  </div>
                </div>
                <Row>
                  <div className="col">
                    <span className="h6 surtitle text-light">Name</span>
                    <span className="d-block h3 text-white">{cardName}</span>
                  </div>
                  <div className="col">
                    <span className="h6 surtitle text-light">Expiry date</span>
                    <span className="d-block h3 text-white">
                      {expMonth} / {expyear}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

CreditCard.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CreditCard;
