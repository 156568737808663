import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { attemptResetPassword } from "../../../actions/authActions";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

class Reset extends React.Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("account/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    // Get token from url
    const token = this.props.match.params.token;
    const resetPassword = {
      password: this.state.password,
      password2: this.state.password2,
    };

    try {
      var res = await this.props.attemptResetPassword(
        resetPassword,
        token,
        this.props.history
      );

      if (res.status === 200) {
        // display toast
        // redirect to login
      }
    } catch (error) {
      this.setState({ error: "Sorry, something went wrong." });
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        <AuthHeader
          title="Change your Password"
          lead="Update your password below so you can log in."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <Form noValidate onSubmit={this.onSubmit}>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          id="password"
                          placeholder="Password"
                          type="password"
                          className={classnames("", {
                            invalid: errors.password,
                          })}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.password}
                      </span>
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword2,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChange}
                          value={this.state.password2}
                          error={errors.password2}
                          id="password2"
                          placeholder="Confirm Password"
                          type="password"
                          className={classnames("", {
                            invalid: errors.password2,
                          })}
                          onFocus={() =>
                            this.setState({ focusedPassword2: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword2: false })
                          }
                        />
                      </InputGroup>
                      <span
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.password2}
                      </span>
                    </FormGroup>

                    <span
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.message}
                    </span>

                    <div className="text-center">
                      <Button className="mt-4" color="info" type="submit">
                        Change Password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Reset.propTypes = {
  attemptResetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

/*

function mapDispatchToProps(dispatch) {
  return {
    attemptResetPassword: (password, token) =>
      dispatch(attemptResetPassword(password, token)),
  };
}
attemptResetPassword
*/
export default connect(mapStateToProps, { attemptResetPassword })(Reset);
