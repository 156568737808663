import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import ModalAdd from "./ModalAdd";
import TableAdd from "./TableAdd";
import ModalPurchase from "./ModalPurchase";
import TablePurchase from "./TablePurchase";
import Loader from "layouts/Loader.js";
import { numberDropdown  } from "./../../../actions/numberAction";


function Numbers(props) {
  const [loading, setLoading] = useState(false);
  const [owned, setOwned] = useState([]);
  const [purchased, setPurchased] = useState([]);
  const [searched, setSearched] = useState([]);

  const getItems = (token, abortController) => {
    setLoading(true);
    // Get phone numbers
    fetch("/api/number/", {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((items) => {
        if (items.owned) {
          setOwned(items.owned);
        }
        if (items.purchased) {
          setPurchased(items.purchased);
        }
      })
      .catch((err) => {});
    setLoading(false);
    // Get agents for edit purchased numbers modal
    /*fetch("/api/agent/dropdown", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((agents) => {
        setAgents(agents);
      })
      .catch((err) => {});

    fetch("/api/recording/recording_by_type", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((recordings) => {
        setRecordings(recordings);
        setLoading(false);
      })
      .catch((err) => {});

    fetch("/api/team/dropdown", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((teams) => {
        if (teams) {
          setTeams(teams);
        }
      })
      .catch((err) => {});
    */
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(props.auth.user.token, abortController);
    return () => {
      abortController.abort();
    };
  }, [props.auth.user.token]);

  const addOwnedToState = (item) => {
    setOwned([...owned, item]);
    if(item.status === 'Verified') {
      props.numberDropdown();
    }
  };
  const addPurchasedToState = (item) => {
    setPurchased([...purchased, item]);
  };
  const updateOwnedState = (item) => {
    const itemIndex = owned.findIndex((data) => data._id === item._id);
    const newArray = [
      ...owned.slice(0, itemIndex),
      item,
      ...owned.slice(itemIndex + 1),
    ];
    setOwned(newArray);
    if(item.status === 'Verified') {
      props.numberDropdown();
    }
  };
  const updatePurchasedState = (item) => {
    const itemIndex = purchased.findIndex((data) => data._id === item._id);
    const newArray = [
      ...purchased.slice(0, itemIndex),
      item,
      ...purchased.slice(itemIndex + 1),
    ];
    setPurchased(newArray);
  };

  const updateSearchState = (item) => {
    setSearched(item);
  };

  const deleteOwnedFromState = (_id) => {
    const updatedItems = owned.filter((item) => item._id !== _id);
    setOwned(updatedItems);
    props.numberDropdown();
  };
  const deletePurchasedFromState = (_id) => {
    const updatedItems = purchased.filter((item) => item._id !== _id);
    setPurchased(updatedItems);
  };

  return (
    <>
      <SimpleHeader name="" parentName="Phone Numbers" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      {" "}
                      {!loading ? (
                        <>
                          <ModalAdd
                            buttonType="add"
                            buttonLabel="Add Phone"
                            addOwnedToState={addOwnedToState}
                            item={{}}
                            headerTitle="Add Phone"
                          />
                          <TableAdd
                            items={owned}
                            updateOwnedState={updateOwnedState}
                            deleteOwnedFromState={deleteOwnedFromState}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <Loader />
                        </>
                      )}
                    </Col>
                  </Row>
                  <hr />
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      {!loading ? (
                        <>
                          <ModalPurchase
                            addPurchasedToState={addPurchasedToState}
                            updatePurchasedState={updatePurchasedState}
                            updateSearchState={updateSearchState}
                            searched={searched}
                            item={{}}
                            headerTitle="Purchase Phone"
                          />
                          <TablePurchase
                            items={purchased}
                            updatePurchasedState={updatePurchasedState}
                            deletePurchasedFromState={deletePurchasedFromState}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <Loader />
                        </>
                      )}
                    </Col>
                  </Row>
                  <hr />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

Numbers.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { 
  numberDropdown
 };

export default connect(mapStateToProps, mapDispatchToProps)(Numbers);
