import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table } from "reactstrap";
//import { getAutomaticTypeDirectiveNames } from "typescript";
import moment from "moment";
import ModalCall from "../Calls/ModalCall";
//import io from "socket.io-client";

function TableCall(props) {
  //ahow/hide colum on resize
  const itemsRef = useRef([]);
  const tableRef = useRef([]);
  const trHeadRef = useRef([]);
  const tbodyRef = useRef([]);
  //const socketUrl = process.env.BASE_URL;
  const colCount = 6;
  const visibleCoumnCount = useRef(colCount);
  const defaultHeader = useRef(colCount);
  const oldVisibleCoumnCount = useRef(colCount);
  const widowWidth = useRef(window.innerWidth);
  const socketData = useRef();
  const setItems = props.setItems;
  const appendData = props.appendData;
  const getInitialColum = useCallback(
    (_init_) => {
      if (tableRef.current && trHeadRef.current) {
        if (
          !_init_ &&
          ((window.innerWidth < widowWidth.current &&
            widowWidth.current - window.innerWidth < 10) ||
            (window.innerWidth > widowWidth.current &&
              window.innerWidth - widowWidth.current < 100))
        ) {
          return false;
        }
        if (props.settings) {
          for (var i = 0; i < props.settings.length; i++) {
            if (window.innerWidth >= props.settings[i].width) {
              if (props.settings[i].column !== "all") {
                visibleCoumnCount.current = props.settings[i].column;
              } else {
                visibleCoumnCount.current = defaultHeader.current;
              }
              break;
            }
          }
          const tr = tbodyRef.current.querySelectorAll("tr[role='row']");
          if (
            oldVisibleCoumnCount.current !== visibleCoumnCount.current &&
            tr.length > 0
          ) {
            trHeadRef.current.setAttribute(
              "datacolum",
              visibleCoumnCount.current
            );
            if (tbodyRef.current.querySelectorAll("tr").length > 0) {
              tbodyRef.current
                .querySelectorAll("tr")
                .forEach((x) =>
                  x.setAttribute("datacolum", visibleCoumnCount.current)
                );
            } else {
              for (var ii = 0; i < itemsRef.length; i++) {
                itemsRef.current[ii].setAttribute(
                  "datacolum",
                  visibleCoumnCount.current
                );
              }
            }
            oldVisibleCoumnCount.current = visibleCoumnCount.current;
            widowWidth.current = window.innerWidth;
          }
        }
      }
    },
    [
      tableRef,
      trHeadRef,
      widowWidth,
      visibleCoumnCount,
      oldVisibleCoumnCount,
      props.settings,
    ]
  );
  const setSocketData = useCallback(() => {
    /*const socket = io.connect(socketUrl, {
      path: "/websoccket",
    });*/
    let socket = props.auth.user.socket;
    socketData.current = socket;
    socket.on(
      "call_status_update_" + props.auth.user.organization,
      (message) => {
        const itemIndex = props.items.findIndex(
          (data) => data._id === message.data._id
        );
        if (itemIndex !== -1) {
          const item = appendData(message.data);
          const newArray = [
            ...props.items.slice(0, itemIndex),
            item,
            ...props.items.slice(itemIndex + 1),
          ];
          setItems(newArray);
          getInitialColum();
        }
      },
      [props.items]
    );
    socket.on(
      "call_status_new_" + props.auth.user.organization,
      (message) => {
        const item = appendData(message.data);
        setItems([item, ...props.items.slice(0, props.items.length - 1)]);
        getInitialColum();
      },
      [props.items]
    );
    socketData.current = socket;
  }, [
    props.items,
    socketData,
    //socketUrl,
    getInitialColum,
    //props.auth.user.organization,
    props.auth,
    setItems,
    appendData,
  ]);

  useEffect(() => {
    if (!props.loading) {
      setSocketData();
      getInitialColum(true);
      window.addEventListener("resize", (e) => {
        e.preventDefault();
        getInitialColum();
      });
    }
    //itemsRef.current = itemsRef.current.slice(0, (paginate ? page : rows).length);
    return () => {
      if (socketData && socketData.current) {
        socketData.current.off(
          "call_status_new_" + props.auth.user.organization
        );
        socketData.current.off(
          "call_status_update_" + props.auth.user.organization
        );
        //socketData.current.close();
      }
    };
  }, [
    socketData,
    setSocketData,
    getInitialColum,
    props.loading,
    props.auth.user.organization,
  ]);

  //ahow/hide colum on resize
  const itemRow =
    props.items &&
    props.items.map((item, index) => {
      const first = item.leadDetails.first ? item.leadDetails.first : "";
      const last = item.leadDetails.last ? item.leadDetails.last : "";
      const phone = item.leadDetails.national ? item.leadDetails.national : "";

      var header = phone + ": " + first + " " + last;

      return [
        <tr
          key={index}
          role="row"
          datacolum={defaultHeader.current}
          maindatacolum={defaultHeader.current}
          ref={(el) => (itemsRef.current[index] = el)}
        >
          <td
            onClick={() => {
              const current = itemsRef.current[index];
              if (
                current.getAttribute("datacolum") ===
                current.getAttribute("maindatacolum")
              ) {
                current.classList.remove("parent_tr");
              } else {
                current.classList.toggle("parent_tr");
              }
              return true;
            }}
          >
            {item.dir}
          </td>
          <td>
            {moment(item.startTime).format("ddd, D-MMM-YYYY")}
            <br></br>
            {moment(item.startTime).format("h:mm a")}
          </td>
          <td>{item.StatusHtml}</td>
          <td>
            <i className="fa fa-map-marker" title="Source"></i> {item.source}
            <br></br>
            {item.targetColor}
          </td>
          <td>{item.leadTeamAgent}</td>
          <td className="action_coumn">
            <div style={{ float: "right" }}>
              <ModalCall
                buttonType="view"
                buttonLabel="View"
                item={item}
                headerTitle={header}
              />{" "}
            </div>
          </td>
        </tr>,
        <tr key={"key_" + index} className="child">
          <td colSpan={5}>
            <ul className="clone_ul">
              <li className="clone_li">
                <b>Date</b>:<br />
                <div style={{ marginLeft: 20 }}>
                  {moment(item.startTime).format("ddd, D-MMM-YYYY")}
                  <br></br>
                  {moment(item.startTime).format("h:mm a")}
                </div>
              </li>
              <li className="clone_li">
                <b>Status</b>:<br />
                <div style={{ marginLeft: 20 }}>{item.StatusHtml}</div>
              </li>
              <li className="clone_li">
                <b>Source/Target</b>:<br />
                <div style={{ marginLeft: 20 }}>
                  <i className="fa fa-map-marker" title="Source"></i>{" "}
                  {item.source}
                  <br></br>
                  {item.targetColor}
                </div>
              </li>
              <li className="clone_li">
                <b>Lead/Team/Agent</b>:<br />
                <div style={{ marginLeft: 20 }}>{item.leadTeamAgent}</div>
              </li>
            </ul>
          </td>
        </tr>,
      ];
    });

  return (
    <div
      ref={(el) => (tableRef.current = el)}
      className="table_data custom_table"
    >
      <Table
        className="table-striped"
        responsive
        hover
        style={{ marginTop: "10px" }}
      >
        <thead>
          <tr ref={(el) => (trHeadRef.current = el)}>
            <th></th>
            <th>Date</th>
            <th>Status</th>
            <th>Source/Target</th>
            <th>Lead/Team/Agent</th>
            <th className="action_coumn">
              <label>Actions</label>
            </th>
          </tr>
        </thead>
        <tbody ref={(el) => (tbodyRef.current = el)}>
          {itemRow}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
}
TableCall.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableCall);
