import axios from "axios";
import {
    LOAD_TEAM_USER_DROPDOWN,
    TEAM_USER_DROPDOWN,
    LOAD_TEAM_USER_DROPDOWN_SETTINGS,
    TEAM_USER_DROPDOWN_SETTINGS,
    LOAD_TEAM_USER_LIST,
    TEAM_USER_LIST,
    GET_ERRORS
  } from "./types";
export const teamDropdown = () => async ( dispatch ) => {
  dispatch({
    type: LOAD_TEAM_USER_DROPDOWN
  });
  axios
    .get(`/api/team/dropdown`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      dispatch({
        type: TEAM_USER_DROPDOWN,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      });
  });
}

export const teamDropdownSettings = () => async ( dispatch ) => {
  dispatch({
    type: LOAD_TEAM_USER_DROPDOWN_SETTINGS
  });
  axios
    .get(`/api/team/dropdownsettings`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      dispatch({
        type: TEAM_USER_DROPDOWN_SETTINGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      });
  });
}

export const teamList = () => async ( dispatch ) => {
  dispatch({
    type: LOAD_TEAM_USER_LIST
  });
  axios
    .get(`/api/team`) // Send get request to get CSRF token once site is visited.
    .then((res) => {
      dispatch({
        type: TEAM_USER_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      });
  });
}