import {
    NUMBER_USER_DROPDOWN,
    LOAD_NUMBER_USER_DROPDOWN
  } from "../actions/types";

  const initialState = {
    numberDLoading: false,
    numberDropdown: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_NUMBER_USER_DROPDOWN:
            return {
                ...state,
                numberDLoading: true,
            };
        case NUMBER_USER_DROPDOWN:
            return {
                ...state,
                numberDLoading: false,
                numberDropdown: action.payload
            };
        default:
            return state;
    }
  }