import {
    RECORDING_BY_TYPE_DATA,
    LOAD_RECORDING_BY_TYPE_DATA
  } from "../actions/types";

  const initialState = {
    recordingByloading: false,
    recordingByType: {
        message: [],
        voicemail: []
    }
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_RECORDING_BY_TYPE_DATA:
            return {
                ...state,
                recordingByloading: true,
            };
        case RECORDING_BY_TYPE_DATA:
            return {
                ...state,
                loading: false,
                recordingByType: action.payload
            };
        default:
            return state;
    }
  }