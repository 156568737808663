import React, { useRef, useEffect, useCallback } from "react";
import { Table } from "reactstrap";

import ModalAgent from "./ModalAgent";
import ModalSchedule from "./ModalSchedule";
import ModalVerify from "../Numbers/ModalVerify";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MobileModalAgent from "./MobileModalAgent";
const MySwal = withReactContent(Swal);

function TableAgent(props) {
  //ahow/hide colum on resize
  const itemsRef = useRef([]);
  const tableRef = useRef([]);
  const trHeadRef = useRef([]);
  const tbodyRef = useRef([]);
  const colCount = props.hideColumn ? 4 : 6;
  const visibleCoumnCount = useRef(colCount);
  const defaultHeader = useRef(colCount);
  const oldVisibleCoumnCount = useRef(colCount);
  const widowWidth = useRef(window.innerWidth);
  const getInitialColum = useCallback(
    (_init_, tableRef, trHeadRef) => {
      if (tableRef.current && trHeadRef.current) {
        if (
          !_init_ &&
          ((window.innerWidth < widowWidth.current &&
            widowWidth - window.innerWidth < 10) ||
            (window.innerWidth > widowWidth.current &&
              window.innerWidth - widowWidth.current < 100))
        ) {
          return false;
        }
        const settings = props.settings;
        if (settings) {
          for (var i = 0; i < settings.length; i++) {
            if (window.innerWidth >= settings[i].width) {
              if (props.settings[i].column !== "all") {
                visibleCoumnCount.current = props.settings[i].column;
              } else {
                visibleCoumnCount.current = defaultHeader.current;
              }
              break;
            }
          }
          if (oldVisibleCoumnCount.current !== visibleCoumnCount.current) {
            trHeadRef.current.setAttribute(
              "datacolum",
              visibleCoumnCount.current
            );
            if (tbodyRef.current.querySelectorAll("tr").length > 0) {
              tbodyRef.current
                .querySelectorAll("tr")
                .forEach((x) =>
                  x.setAttribute("datacolum", visibleCoumnCount.current)
                );
            } else {
              for (var ii = 0; ii < itemsRef.length; i++) {
                itemsRef.current[ii].setAttribute(
                  "datacolum",
                  visibleCoumnCount.current
                );
              }
            }
            oldVisibleCoumnCount.current = visibleCoumnCount.current;
            widowWidth.current = window.innerWidth;
          }
        }
      }
    },
    [visibleCoumnCount, oldVisibleCoumnCount, widowWidth, props.settings]
  );
  const allowEdit = (account) => {
    if (props.auth.user.role === "agent") {
      return !(account && props.auth.user.email === account.email);
    } else {
      return false;
    }
  };
  useEffect(() => {
    //itemsRef.current = itemsRef.current.slice(0, (paginate ? page : rows).length);
    if (tableRef.current && trHeadRef.current) {
      getInitialColum(true, tableRef, trHeadRef);
      window.addEventListener("resize", (e) => {
        e.preventDefault();
        getInitialColum(false, tableRef, trHeadRef);
      });
      // returned function will be called on component unmount
      return () => {
        window.removeEventListener("resize", (e) => {
          e.preventDefault();
          getInitialColum(false, tableRef, trHeadRef);
        });
      };
    }
  }, [tableRef, trHeadRef, getInitialColum]);
  //ahow/hide colum on resize
  const items = props.items.map((item, index) => {
    var account = "";
    if (typeof item.account !== "undefined") {
      account = item.account.email;
    } else {
      account = (
        <ModalAgent
          buttonType="createAccount"
          buttonLabel="Create Account"
          item={item}
          updateState={props.updateState}
          headerTitle="Create Account"
          hasLogin="true"
        />
      );
    }

    function setActive(active) {
      if (active === true) {
        return (
          <>
            <i
              className="fas fa-smile"
              style={{ fontSize: "28px", color: "#2dce89" }}
            ></i>
          </>
        );
      } else {
        return (
          <>
            <i
              className="far fa-frown"
              style={{ fontSize: "28px", color: "#f5365c" }}
            ></i>
          </>
        );
      }
    }

    return [
      <tr
        key={item._id}
        role="row"
        datacolum={defaultHeader.current}
        maindatacolum={defaultHeader.current}
        ref={(el) => (itemsRef.current[index] = el)}
      >
        <td
          className="font-weight-bold"
          onClick={() => {
            const current = itemsRef.current[index];
            if (
              current.getAttribute("datacolum") ===
              current.getAttribute("maindatacolum")
            ) {
              current.classList.remove("parent_tr");
            } else {
              current.classList.toggle("parent_tr");
            }
            return true;
          }}
        >
          {item.first} {item.last}
        </td>
        <td className="font-weight-bold">
          {item.phone ? item.phone.national : ""}
          <br></br>
          {item.phone ? item.phone.status : ""}
          {item.phone && item.phone.status === "Pending" ? (
            <>
              <ModalVerify
                buttonLabel="Verify"
                item={item.phone}
                updateOwnedState={(data)=> props.updateOwnedState(data, item )}
                headerTitle="Verify Phone"
              />
            </>
          ) : (
            ""
          )}
        </td>
        {props.hideColumn ? null : (
          <td className="font-weight-bold">{account}</td>
        )}
        {props.hideColumn ? null : (
          <td className="font-weight-bold">{setActive(item.active)}</td>
        )}
        {props.hideColumn ? null : (
          <>
            <td className="font-weight-bold">
              {" "}
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  disabled={allowEdit(item.account)}
                  placeholder="DND"
                  name="dnd"
                  checked={item.dnd}
                  onChange={() => {
                    MySwal.fire({
                      title:
                        "Are you sure you want to " +
                        (item.dnd ? "disable" : "enable") +
                        " do not distrub!",
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.value) {
                        props.changeStatusDnd(item, index);
                      }
                    });
                  }}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </td>
          </>
        )}
        {props.hideColumn ? null : (
          <>
            <td className="action_coumn">
              <MobileModalAgent
                buttonType="actions"
                item={item}
                updateState={props.updateState}
                deleteItemFromState={props.deleteItemFromState}
                type={{
                  modal: {
                    headerTitle: "Edit Agent",
                    headerTitleView: "View Agent",
                    buttonLabel: "Edit",
                  },
                  schedule: {
                    headerTitle: "Set Schedule",
                    headerTitleView: "View Schedule",
                    buttonLabel: "Schedule",
                  },
                }}
              />
              <div className="desktop_screen_button">
                <ModalSchedule
                  buttonLabel="Schedule"
                  item={item}
                  auth={props.auth}
                  updateState={props.updateState}
                  updateWhat="agent"
                  headerTitle="Set Schedule"
                  headerTitleView="View Schedule"
                />
                <ModalAgent
                  buttonType="actions"
                  buttonLabel="Edit"
                  item={item}
                  updateState={props.updateState}
                  deleteItemFromState={props.deleteItemFromState}
                  headerTitle="Edit Agent"
                  headerTitleView="View Agent"
                />
              </div>
            </td>
          </>
        )}
      </tr>, //child
      <tr key={"key_" + item._id} className="child">
        <td colSpan={6}>
          <ul className="clone_ul">
            <li className="clone_li">
              <b>Phone</b>:
              <br />
              <div style={{ marginLeft: 20 }}>{item.national}</div>{" "}
            </li>
            {props.hideColumn ? null : (
              <li className="clone_li">
                <b>Login Email</b>:<br />
                <div style={{ marginLeft: 20 }}>{account}</div>
              </li>
            )}
            {props.hideColumn ? null : (
              <li className="clone_li">
                <b>Avaliable</b>:<br />
                <div style={{ marginLeft: 20 }}>{setActive(item.active)}</div>
              </li>
            )}
            {props.hideColumn ? null : (
              <li className="clone_li">
                <b>Do Not Disturb</b>:<br />
                <div style={{ marginLeft: 20 }}>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      disabled={allowEdit(item.account)}
                      placeholder="DND"
                      name="dnd"
                      checked={item.dnd}
                      onChange={() => {
                        MySwal.fire({
                          title:
                            "Are you sure you want to " +
                            (item.dnd ? "disable" : "enable") +
                            " do not distrub!",
                          text: "",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          if (result.value) {
                            props.changeStatusDnd(item, index);
                          }
                        });
                      }}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </div>
              </li>
            )}
          </ul>
        </td>
      </tr>,
    ];
  });

  return (
    <div
      ref={(el) => (tableRef.current = el)}
      className="table_data custom_table"
    >
      <Table
        responsive
        className="table-striped"
        hover
        style={{ marginTop: "10px" }}
      >
        <thead>
          <tr ref={(el) => (trHeadRef.current = el)}>
            <th>Name</th>
            <th>Phone</th>
            {props.hideColumn ? null : <th>Login Email</th>}
            {props.hideColumn ? null : <th>Avaliable</th>}
            {props.hideColumn ? null : <th>Do Not Disturb</th>}
            {props.hideColumn ? null : (
              <th className="action_coumn">
                <label>Actions</label>
              </th>
            )}
          </tr>
        </thead>
        <tbody ref={(el) => (tbodyRef.current = el)}>
          {items}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
}

export default TableAgent;
