import axios from "axios";
import {
    LOAD_NUMBER_USER_DROPDOWN,
    NUMBER_USER_DROPDOWN,
    GET_ERRORS
  } from "./types";
export const numberDropdown = () => async ( dispatch ) => {
  dispatch({
    type: LOAD_NUMBER_USER_DROPDOWN
  });
  axios
    .get(`/api/number/dropdown`)
    .then((res) => {
      dispatch({
        type: NUMBER_USER_DROPDOWN,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
}