import {
    AGENT_USER_DROPDOWN,
    LOAD_AGENT_USER_DROPDOWN,
    LOAD_AGENT_TEAM_OWNER_LEAD_DROPDOWN,
    AGENT_TEAM_OWNER_LEAD_DROPDOWN
  } from "../actions/types";

  const initialState = {
    agentDLoading: false,
    agentDTOLoading: false,
    agentDropdown: [],
    agentTODropdown: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_AGENT_USER_DROPDOWN:
            return {
                ...state,
                agentDLoading: true,
            };
        case AGENT_USER_DROPDOWN:
            return {
                ...state,
                agentDLoading: false,
                agentDropdown: action.payload
            };
        case LOAD_AGENT_TEAM_OWNER_LEAD_DROPDOWN:
            return {
                ...state,
                agentDTOLoading: true,
            };
        case AGENT_TEAM_OWNER_LEAD_DROPDOWN:
            return {
                ...state,
                agentDTOLoading: false,
                agentTODropdown: action.payload
            };
        default:
            return state;
    }
  }