import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia ||
  navigator.oGetUserMedia;

function FormRecording(props) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    clearError,
    setError,
  } = useForm({
    defaultValues: {
      _id: 0,
      name: "",
      description: "",
      recording: "",
      duration: 0,
      recordSupport: "",
    },
  });
  const [isBlocked, setBlocked] = useState(false);
  const [audioList, setAudioList] = useState([]);
  const [isRecording, SetRecording] = useState(false);
  const [updateRecord, setUpdateRecording] = useState(false);
  const [recordSupport, setRecordSupport] = useState();
  const audioRef = useRef(null);
  // const options = [];
  useEffect(() => {
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { audio: true },
        () => {
          setBlocked(false);
        },
        () => {
          setBlocked(true);
        }
      );
    } else {
      setRecordSupport(
        "Audio Recording might not be supported by your web browser. If recordings fail, you should try Chrome, Firefox or Edge."
      );
    }

    register({ name: "_id" });
    register({ name: "recording" });
    register({ name: "duration" });
    if (Object.keys(props.item).length > 0) {
      setValue("_id", props.item._id);
      setValue("name", props.item.name);
      setValue("type", props.item.type);
      setValue("description", props.item.description);
      setValue("path", props.item.path);
    }
  }, [setValue, props.item, register]);

  const start = () => {
    if (isBlocked) {
    } else {
      Mp3Recorder.start()
        .then(() => {
          SetRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };
  const clearAll = () => {
    setAudioList([]);
    SetRecording(false);
    setUpdateRecording(false);
  };
  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        if (audioList.length === 0) {
          clearError("path");
          setUpdateRecording(blob);
        }
        setAudioList([...audioList, { blobURL: blobURL, blob: blob }]);
        SetRecording(false);
      })
      .catch((e) => {});
  };
  const submitFormAdd = (form) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.set("name", form.name);
    bodyFormData.set("type", form.type);
    bodyFormData.set("description", form.description);
    bodyFormData.set("duration", form.duration);
    bodyFormData.set("recordingType", "record");

    if (updateRecord) {
      bodyFormData.append("path", updateRecord);
    } else {
      return setError("path", "Please upload a recording");
    }
    axios({
      method: "post",
      //responseType: "json",
      url: `/api/recording/`,
      data: bodyFormData,
    })
      .then((item) => {
        setLoading(false);
        props.addItemToState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Recording has been created sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Recording",
          toastrOptions
        );
        if (err.response.data) {
          if (err.response.data.error) {
            // return setError("national", "required", "Phone number is required");
          } else {
          }
        }
      });
  };
  const setUpdateRecord = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      var audio = new Audio();
      audio.src = e.target.result;
      audio.addEventListener("loadedmetadata", () => {
        if (audio.duration !== Infinity) {
          const duration = (audio.duration / 100).toFixed(2) * 100;
          setValue("duration", duration);
          setUpdateRecording(file);
        }
      });
    };
  };
  const getUpdateRecordingDuration = (obj) => {
    const duration = (audioRef.current.duration / 100).toFixed(2) * 100;
    setValue("duration", duration);
  };
  const readURL = (event) => {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      const file = event.target.files[0];
      reader.onload = function (e) {
        setAudioList([...audioList, { blobURL: e.target.result, blob: file }]);
      };
      if (audioList.length === 0) {
        clearError("path");
        setUpdateRecording(file);
      }
      reader.readAsDataURL(event.target.files[0]); // convert to base64 string
    }
  };

  const submitFormEdit = async (form) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.set("name", form.name);
    bodyFormData.set("type", form.type);
    bodyFormData.set("description", form.description);
    bodyFormData.set("duration", form.duration);
    if (updateRecord) {
      bodyFormData.append("path", updateRecord);
    }
    axios({
      method: "patch",
      //responseType: "json",
      url: "/api/recording/" + form._id + "/update/",
      data: bodyFormData,
    })
      .then((item) => {
        setLoading(false);
        props.updateState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Lead has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Lead",
          toastrOptions
        );
      });
  };

  return (
    <>
      <div style={{ color: "red" }}> {recordSupport}</div>
      <br></br>
      <form
        encType="multipart/form-data"
        onSubmit={
          Object.keys(props.item).length > 0
            ? handleSubmit(submitFormEdit)
            : handleSubmit(submitFormAdd)
        }
      >
        <Row>
          <Col md="6">
            <FormGroup>
              <Label
                for="name"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Name
              </Label>
              <FormText>Identify this recording with a name.</FormText>
              <Input
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
              {errors.name && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                for="recording"
                style={{
                  paddingTop: "25px",
                  display: "inline-block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Recording
              </Label>
              <br></br>
              <Col xs="12">
                {props.item && props.item.path && props.item.fileName ? (
                  <audio
                    src={props.item.fileName}
                    controls="controls"
                    style={{ width: "300px" }}
                  />
                ) : (
                  <></>
                )}
              </Col>
              <FormText>Record an awesome sales message. </FormText>
              <Row>
                <Col xs="12">
                  <Button
                    color={"primary"}
                    onClick={start}
                    disabled={isRecording}
                    style={{ marginBottom: "10px" }}
                    type="button"
                  >
                    Record
                  </Button>
                  <Button
                    color={"danger"}
                    onClick={stop}
                    disabled={!isRecording}
                    style={{
                      marginBottom: "10px",
                      marginRight: "5px !important",
                    }}
                    type="button"
                  >
                    Stop
                  </Button>
                  {audioList.length > 0 ? (
                    <Button
                      color={"danger"}
                      onClick={clearAll}
                      disabled={isRecording}
                      style={{
                        marginBottom: "10px",
                        marginLeft: "-5px !important",
                      }}
                      type="button"
                    >
                      Clear All
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              {errors.path && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Recording is required
                </div>
              )}
              {isRecording ? (
                <Row>
                  <Col>Recording.....</Col>
                </Row>
              ) : (
                <></>
              )}
              <div
                style={{
                  marginTop: "15px",
                  width: "100%",
                  height: "20px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    padding: "0 10px",
                  }}
                >
                  Or
                </span>
              </div>
              <br></br>
              <Label
                for="recording"
                style={{
                  paddingTop: "10px",
                  display: "inline-block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Upload an mp3
              </Label>
              <FormGroup style={{ paddingTop: "10px" }}>
                <Input
                  type="file"
                  accept="audio/*"
                  capture="microphone"
                  onChange={(value) => {
                    readURL(value);
                  }}
                />
              </FormGroup>
              <hr style={{ marginBottom: "5px" }}></hr>

              <ListGroup>
                {audioList.map((audio, index) => {
                  var upField = {};
                  if (updateRecord === audio.blob) {
                    upField = {
                      onLoadedMetadata: getUpdateRecordingDuration,
                      ref: audioRef,
                    };
                  }
                  return (
                    <div key={index}>
                      <Label>Select the Recording you want to Save.</Label>
                      <ListGroupItem className="justify-content-between audiolist">
                        <Row>
                          <div className="audio_lable">
                            <label>{index + 1}</label>
                          </div>
                          <Col xs="11" className="audio_div">
                            <audio
                              {...upField}
                              src={audio.blobURL}
                              controls="controls"
                              style={{ width: "150px" }}
                            />
                          </Col>
                          <div>
                            <Input
                              type="checkbox"
                              name="file"
                              value={audio.blob}
                              style={{ width: "25px", height: "25px" }}
                              checked={updateRecord === audio.blob}
                              onChange={() => {
                                clearError("path");
                                setUpdateRecord(audio.blob);
                              }}
                            />
                          </div>
                        </Row>
                      </ListGroupItem>
                    </div>
                  );
                })}
              </ListGroup>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                for="type"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Type
              </Label>
              <FormText>Select the recording type.</FormText>
              <Input
                type="select"
                name="type"
                id="type"
                innerRef={register({ required: true })}
              >
                <option value="message">Message</option>
                <option value="voicemail">Voicemail</option>
                <option value="both">Both</option>
              </Input>
              {errors.type && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Type is required
                </div>
              )}
            </FormGroup>

            <FormGroup>
              <Label
                for="description"
                style={{
                  paddingTop: "25px",
                  display: "inline-block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Description
              </Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                innerRef={register({ required: false })}
              />
            </FormGroup>
          </Col>
        </Row>

        <ModalFooter>
          {loading ? (
            <Button color="primary" type="button" disabled={true}>
              Saving...
            </Button>
          ) : (
            <Button type="submit" color="primary">
              Save Recording
            </Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}
FormRecording.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormRecording);
