import React from "react";
import DateFilter from "./DateFilter";
import moment from "moment";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "layouts/Loader";
class ReportCalls extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const startValue = new Date(date).setMonth(date.getMonth() - 1).valueOf();
    date.setHours(23, 59, 59, 999);
    const endValue = new Date(date).valueOf();
    this.state = {
      defautValue: [startValue, endValue],
      byday: false,
      byAvgR: false,
      byAvg: false,
      byAgentP: false,
      byAgent: false,
      mainCount: false,
    };
  }
  async _callByAgent(_data) {
    const _label = [];
    const _avr = [];
    await _data.map((_byday) => {
      _label.push(_byday.name);
      _avr.push(_byday.agentR);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Agents",
          backgroundColor: "rgba(83, 51, 237, 0.2)",
          borderColor: "rgba(83, 51, 237, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(83, 51, 237, 0.4)",
          hoverBorderColor: "rgba(83, 51, 237, 1)",
          data: _avr,
        },
      ],
    };
    this.setState({ byAgent: chart });
  }
  async _callByAgentP(_data) {
    const _label = [];
    const _avr = [];
    await _data.map((_byday) => {
      _label.push(_byday.name);
      _avr.push(_byday.agentP);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Agents",
          backgroundColor: "rgba(83, 51, 237, 0.2)",
          borderColor: "rgba(83, 51, 237, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(83, 51, 237, 0.4)",
          hoverBorderColor: "rgba(83, 51, 237, 1)",
          data: _avr,
        },
      ],
    };
    this.setState({ byAgentP: chart });
  }
  async _callByAvgR(_data) {
    const _label = [];
    const _avr = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _avr.push(_byday.CallAvgR);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Calls",
          backgroundColor: "rgba(83, 51, 237, 0.2)",
          borderColor: "rgba(83, 51, 237, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(83, 51, 237, 0.4)",
          hoverBorderColor: "rgba(83, 51, 237, 1)",
          data: _avr,
        },
      ],
    };
    this.setState({ byAvgR: chart });
  }
  async _callByAv(_data) {
    const _label = [];
    const _avr = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _avr.push(_byday.callDurationAvg);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Calls",
          backgroundColor: "rgba(83, 51, 237, 0.2)",
          borderColor: "rgba(83, 51, 237, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(83, 51, 237, 0.4)",
          hoverBorderColor: "rgba(83, 51, 237, 1)",
          data: _avr,
        },
      ],
    };
    this.setState({ byAvg: chart });
  }
  async _callByDay(_data) {
    const _label = [];
    const _opt_out = [];
    const _missing_lead = [];
    const _missing_agent = [];
    const _completed = [];
    await _data.map((_byday) => {
      _label.push(_byday._id);
      _opt_out.push(_byday.opt_out);
      _missing_lead.push(_byday.missing_lead);
      _missing_agent.push(_byday.missing_agent);
      _completed.push(_byday.completed);
      return null;
    });
    const chart = {
      labels: _label,
      datasets: [
        {
          label: "Missed Agent",
          backgroundColor: "rgba(230, 126, 34, 0.2)",
          borderColor: "rgba(230, 126, 34, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(230, 126, 34, 0.4)",
          hoverBorderColor: "rgba(230, 126, 34, 1)",
          data: _missing_agent,
        },
        {
          label: "Missed Lead",
          backgroundColor: "rgba(240, 255, 0, 0.2)",
          borderColor: "rgba(240, 255, 0, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(240, 255, 0, 0.4)",
          hoverBorderColor: "rgba(240, 255, 0, 1)",
          data: _missing_lead,
        },
        {
          label: "Opt Out",
          backgroundColor: "rgba(246, 36, 89, 0.2)",
          borderColor: "rgba(246, 36, 89, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(246, 36, 89, 0.4)",
          hoverBorderColor: "rgba(246, 36, 89, 1)",
          data: _opt_out,
        },
        {
          label: "Completed",
          backgroundColor: "rgba(77, 175, 124, 0.2)",
          borderColor: "rgba(77, 175, 124, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(77, 175, 124, 0.4)",
          hoverBorderColor: "rgba(77, 175, 124, 1)",
          data: _completed,
        },
      ],
    };
    this.setState({ byday: chart });
  }
  getByDay(value) {
    this.setState({
      byday: false,
      byAvgR: false,
      byAvg: false,
      byAgentP: false,
      byAgent: false,
    });
    var startVlaue =
      value && value[0] ? moment(value[0]).format("YYYY-MM-DD") : "";
    var endVlaue =
      value && value[1] ? moment(value[1]).format("YYYY-MM-DD") : "";
    var queryString = `start=` + startVlaue + `&end=` + endVlaue;
    if (startVlaue && endVlaue) {
      if (startVlaue > endVlaue) {
        queryString = `start=` + endVlaue + `&end=` + startVlaue;
      }
    }
    this.axiosCancelSource = axios.CancelToken.source();
    axios({
      method: "get",
      responseType: "json",
      url: `/api/call/report/byday?` + queryString,
      cancelToken: this.axiosCancelSource.token
    })
      .then(async (response) => {
        if (response.data) {
          this._callByDay(response.data[0]["callByDay"]);
          this._callByAv(response.data[0]["callAvL"]);
          this._callByAvgR(response.data[0]["callByDay"]);
          this._callByAgentP(response.data[0]["callAgent"]);
          this._callByAgent(response.data[0]["callAgent"]);
          this.setState({ mainCount: response.data[0]["mainCount"][0] });
        }
      })
      .catch((err) => {
        if( err.message !== 'AXIOS_CANCEL') {
          Swal.fire({
            text: "Something went wrong. Try again.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  }
  componentWillUnmount() {
    this.axiosCancelSource.cancel('AXIOS_CANCEL')
  }

  componentDidMount() {
    this.getByDay(this.state.defautValue);
  }
  setFilterValue(value) {
    this.getByDay(value);
  }
  render() {
    return (
      <>
        <SimpleHeader name="" parentName="Reports" />
        <Container className="mt--6" fluid>
          <Row>
            <span className="col">
              <span className="card-wrapper">
                <Card>
                  <CardHeader>
                    <span className="date_filter_header">
                      <h3 className="mb-0">Calls</h3>
                      <span className="date_filter_div">
                        <DateFilter
                          defautValue={this.state.defautValue}
                          setFilterValue={this.setFilterValue.bind(this)}
                        />
                      </span>
                    </span>
                  </CardHeader>
                  <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <Col sm="6" md="4" xl="3" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Total Calls</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_calls
                              : "loading..."}{" "}
                            <i
                              className="fa fas fa-phone-volume  text-green"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="3" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">Total Leads</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.total_leads
                              : "loading..."}{" "}
                            <i
                              className="fa fas fa-users text-info"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="3" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">AVG Response Time</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.avr_response_time + " min"
                              : "loading..."}{" "}
                            <i
                              className="fas fa-clock text-pink"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="6" md="4" xl="3" className="class_custom">
                      <Card>
                        <CardHeader tag="h3">AVG Call Length</CardHeader>
                        <CardBody>
                          <CardTitle tag="h1">
                            {this.state.mainCount
                              ? this.state.mainCount.avr_call_length + " min"
                              : "loading..."}{" "}
                            <i
                              className="fas fa-hourglass-start text-purple"
                              style={{
                                fontSize: "50px",
                                position: "absolute",
                                right: "50px",
                              }}
                            ></i>
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">
                          Call Performance by Day
                        </CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byday ? (
                            <Bar
                              data={this.state.byday}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Number of Calls",
                                      },
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">
                          Average response Time by Day
                        </CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byAvgR ? (
                            <Bar
                              data={this.state.byAvgR}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Response Time (minutes)",
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">
                          Average Call length by Day
                        </CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byAvg ? (
                            <Bar
                              data={this.state.byAvg}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Date",
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Call Length (minutes)",
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">
                          Call Performance by Agent
                        </CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byAgentP ? (
                            <Bar
                              data={this.state.byAgentP}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Agent",
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Number of Calls",
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>

                  <Col>
                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card className="card_full_width">
                        <CardHeader tag="h3">Call Minutes by Agent</CardHeader>
                        <CardBody className="card_full_width">
                          {this.state.byAgent ? (
                            <Bar
                              data={this.state.byAgent}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Agent",
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Call Length (minutes)",
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>
                </Card>
              </span>
            </span>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReportCalls;
