import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  ModalFooter,
} from "reactstrap";
import { addTag } from "../../../actions/tagActions";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import TagDropdown from "./../components/TagDropdown";

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Select);

function FormEdit(props) {
  const [ loading, setLoading ] = useState(false);
  // Set default options here. Use this to set initial values
  const [selected, setSelected] = React.useState(props.item.members);
  //const [voicemail, setVoicemail] = useState({});
  //const [team, setTeam] = useState({});
  //const [agent, setAgent] = useState({});
  const [tags, setTags] = useState({});

  const onChange = (selectedOptions) => {
    if (selectedOptions !== undefined && selectedOptions !== "undefined") {
      setSelected(selectedOptions);
      setValue("members", selectedOptions);
      clearError("members");
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    setValue("members", newValue);
  };

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    errors,
    clearError,
    setError,
  } = useForm({
    defaultValues: {},
  });
  const handleCreate = (inputValue) => {
    props.addTag({ name: inputValue }).then((item) => {
      setValue("tags", [...getValues("tags"), item]);
      setTags([...tags, item]);
    });
  };

  useEffect(() => {
    register({ name: "_id" });
    register({ name: "members" });
    register({ name: "voicemail" });
    register({ name: "owner" });
    register({ name: "team" });
    register({ name: "tags" });
    if (props.item) {
      setValue("_id", props.item._id);
      setValue("name", props.item.name);
      setValue("whisper", props.item.whisper);
      setValue("whisperText", props.item.whisperText);
      setValue("whisperLanguage", props.item.whisperLanguage);
      setValue("callMode", props.item.callMode);
      setValue("routingType", props.item.routingType);

      if (props.item.members) {
        setValue("members", props.item.members);
      }
      if (props.item.owner) {
        setValue("owner", props.item.owner._id);
        //setAgent(props.item.owner);
      }
      if (props.item.team) {
        setValue("team", props.item.team._id);
        //setTeam(props.item.team);
      }
      setValue("ownerRouting", props.item.ownerRouting);

      if (props.item.tags.length > 0) {
        var tagValueData = [];
        var tagData = props.item.tags.filter((tag) => {
          if( tag ) {
            tagValueData.push(tag._id);
            return { name: tag.name, _id: tag._id };
          }
          return false;
        });
        setValue("tags", tagValueData);
        setTags(tagData);
      } else {
        setValue("tags", []);
        setTags([]);
      }

      if (props.item.voicemail) {
        setValue("voicemail", props.item.voicemail._id);
        //setVoicemail(props.item.voicemail);
      }
    }
  }, [register, setValue, props]);

  const submitPurchaseEdit = (form) => {
    if (
      (form.routingType === false &&
        (form.members === undefined || form.members === null)) ||
      (form.routingType === false && Object.keys(form.members).length < 1)
    ) {
      return setError("members", "required");
    }
    if (
      (form.routingType === true && form.team === null) ||
      (form.routingType === true && form.team === "")
    ) {
      return setError("team", "required");
    }
    setLoading(true);
    axios({
      method: "patch",
      responseType: "json",
      url: "/api/number/" + form._id + "/update",
      data: form,
    })
      .then((item) => {
        setLoading(false);
        props.updatePurchasedState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Phone Number has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        setLoading(false);
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Number",
          toastrOptions
        );
      });
  };

  const showAgents = watch("routingType", false);
  const showWhisper = watch("whisper", true);
  return (
    <>
      <form onSubmit={handleSubmit(submitPurchaseEdit)}>
        {" "}
        <Row>
          <Col sm="12">
            {" "}
            <h3
              style={{
                paddingBottom: "0px",
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              These settings control what happens when a lead calls one of your
              purchased numbers.
            </h3>
            <FormGroup>
              <Label
                for="name"
                style={{
                  marginTop: "20px",
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Name
              </Label>
              <FormText>Just a way to identify this number</FormText>
              <Input
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />

              {errors.name && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </FormGroup>
            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Read Whisper
            </Label>
            <FormText>
              Do you want to read whisper text to sales agent before they are
              connected with the lead.
            </FormText>
            <FormGroup>
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                Off
              </span>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  placeholder="whisper"
                  name="whisper"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <span style={{ paddingLeft: "10px", paddingRight: "35px" }}>
                On
              </span>
            </FormGroup>
            {showWhisper === true ? (
              <>
                <FormGroup>
                  <Label
                    for="whisperText"
                    style={{
                      display: "inline-block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Whisper Text
                  </Label>
                  <FormText>
                    <b>Spoken to Agents before connecting to a lead.</b>
                    <br></br>
                    You can include the following values from the lead record:
                    <br></br> {"{first}"} {"{last}"} {"{company}"}{" "}
                    {"{category}"} {"{comments}"}
                  </FormText>
                  <Input
                    type="textarea"
                    name="whisperText"
                    id="whisperText"
                    innerRef={register({ required: true })}
                  />
                  {errors.whisperText && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Whisper Text is required
                    </div>
                  )}
                </FormGroup>
                <FormGroup style={{ marginTop: "20px" }}>
                  <Label
                    for="whisperLanguage"
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Whispertext Language
                  </Label>

                  <Input
                    type="select"
                    name="whisperLanguage"
                    id="whisperLanguage"
                    innerRef={register({ required: true })}
                  >
                    <option value="en-US">English</option>
                    <option value="es-ES">Spanish</option>
                    <option value="fr-FR">French</option>
                    <option value="it-IT">Italian</option>
                    <option value="de-DE">German</option>
                    <option value="nl-NL">Dutch</option>
                    <option value="pt-BR">Portuguese</option>
                  </Input>
                  {errors.whisperLanguage && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Whispertext Language is required
                    </div>
                  )}
                </FormGroup>
              </>
            ) : (
              <></>
            )}
            {/*         <FormGroup>
          <Row>
                <Col xs="12" lg="7">
                  <label
                    style={{
                      display: "block",
                      marginBottom: "0rem",
                      fontWeight: "600",
                    }}
                  >
                    Voicemail
                  </label>
                  <Select
                    name="voicemail"
                    id="voicemail"
                    isClearable
                    options={props.recordings.voicemail}
                    closeMenuOnSelect={true}
                    onChange={(selected) => {
                      if (selected) {
                        setValue("voicemail", selected._id);
                        setVoicemail(selected);
                      } else {
                        setValue("voicemail", "");
                        setVoicemail("");
                      }
                    }}
                    defaultValue={
                      props.item.voicemail ? props.item.voicemail : ""
                    }
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option._id}`}
                    innerRef={register({ required: false })}
                  />
                </Col>
                <Col xs="12" lg="5" className="audio_col">
                  {voicemail._id ? (
                    <audio
                      src={voicemail.path + voicemail.fileName}
                      controls="controls"
                      style={{ width: "300px" }}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </FormGroup>
            */}
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Call Routing
              </Label>
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                Round Robin
              </span>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  placeholder="callMode"
                  name="callMode"
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>{" "}
              <span style={{ paddingLeft: "10px" }}>Simultaneous</span>
            </FormGroup>
            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Routing Type
            </Label>
            <FormGroup>
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                Agents
              </span>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  placeholder="routingType"
                  name="routingType"
                  onChange={(selected) => {
                    if (selected) {
                      setValue("team", selected._id);
                      //setTeam(selected.name);
                    } else {
                      setValue("team", "");
                      //setTeam("");
                    }
                  }}
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>{" "}
              <span style={{ paddingLeft: "10px" }}>Team</span>
            </FormGroup>
            {showAgents === false ? (
              <FormGroup>
                <label
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Agents
                </label>
                <FormText>
                  Selected agent(s) phones will ring when a lead calls in.
                </FormText>
                <SortableSelect
                  helperClass="sortableHelper"
                  axis="xy"
                  onSortEnd={onSortEnd}
                  distance={4}
                  // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                  getHelperDimensions={({ node }) =>
                    node.getBoundingClientRect()
                  }
                  // react-select props:
                  isMulti
                  name="members"
                  id="members"
                  value={selected}
                  options={props.agents}
                  closeMenuOnSelect={false}
                  onChange={onChange}
                  defaultValue={props.item.members ? props.item.members : ""}
                  components={{
                    MultiValue: SortableMultiValue,
                  }}
                  getOptionLabel={(option) => `${option.first} ${option.last}`}
                  getOptionValue={(option) => `${option._id}`}
                  innerRef={register({ required: true })}
                />
                {errors.members && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    An Agent is required
                  </div>
                )}
              </FormGroup>
            ) : (
              <FormGroup>
                <Label
                  for="team"
                  style={{
                    display: "block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                  }}
                >
                  Team
                </Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  style={{ marginBottom: "10px" }}
                  name="team"
                  id="team"
                  isSearchable
                  isClearable
                  onChange={(selected) => {
                    if (selected) {
                      setValue("team", selected._id);
                      //setTeam(selected.name);
                      clearError("team");
                    } else {
                      setValue("team", "");
                      //                      setTeam("");
                    }
                  }}
                  defaultValue={props.item.team ? props.item.team : ""}
                  options={props.teams || ""}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option._id}`}
                  innerRef={register({ required: true })}
                />
                {errors.team && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    A Team is required
                  </div>
                )}
              </FormGroup>
            )}
            <FormGroup>
              <Label
                for="owner"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Lead Owner
              </Label>
              <FormText>
                Callers with no existing lead owner will be assigned to:
              </FormText>
              <Select
                className="basic-single"
                classNamePrefix="select"
                style={{ marginBottom: "10px" }}
                name="owner"
                id="owner"
                isSearchable
                isClearable
                onChange={(selected) => {
                  if (selected) {
                    setValue("owner", selected._id);
                    //setAgent(selected.first + " " + selected.last);
                    clearError("owner");
                  } else {
                    setValue("owner", "");
                    //setAgent("");
                  }
                }}
                defaultValue={props.item.owner ? props.item.owner : ""}
                options={props.agents || ""}
                getOptionLabel={(option) => `${option.first} ${option.last}`}
                getOptionValue={(option) => `${option._id}`}
                innerRef={register()}
              />
            </FormGroup>
            <Label
              style={{
                display: "block",
                marginBottom: "0rem",
                fontWeight: "600",
              }}
            >
              Lead Owner Routing
            </Label>
            <FormText>
              If the caller has a lead owner, skip routing set here and direct
              to lead owner instead.
            </FormText>
            <FormGroup>
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                Off
              </span>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  placeholder="ownerRouting"
                  name="ownerRouting"
                  ref={register()}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>{" "}
              <span style={{ paddingLeft: "10px" }}>On</span>
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Tags
              </Label>
              <FormText>The caller lead will be tagged with</FormText>
              <TagDropdown
                style={{ marginBottom: "10px" }}
                isMulti
                isClearable
                value={tags}
                name="tags"
                id="tags"
                onChange={(selected) => {
                  if (selected) {
                    var tagValue = selected.map(function (data) {
                      return data._id;
                    });
                    setValue("tags", tagValue);
                    setTags(selected);
                  } else {
                    // selected is null set to empty
                    setValue("tags", []);
                    setTags([]);
                  }

                  clearError("tags");
                }}
                onCreateOption={handleCreate}
                //        inputValue={inputValue}
                //  onInputChange={onInputTag}
                getOptionLabel={(option) => {
                  if( option.__isNew__ ) {
                    return option.label
                  } 
                  return option.name
                }}
                getOptionValue={(option) => {
                  return JSON.stringify(option)
                }}
                placeholder="Type something and press enter..."
                innerRef={register()}
              />

              {/*
                <option key="Called In" value="Called In">
                  Called In
                </option>
                <option key="Called Back" value="Called Back">
                  Called Back
                </option>
                <option key="Interested" value="Interested">
                  Interested
                </option>
                <option key="Not Interested" value="Not Interested">
                  Not Interested
                </option>
            
*/}
            </FormGroup>
          </Col>
        </Row>
        <ModalFooter>
          {loading?<Button 
          color="default" 
          type="button" 
          disabled={true}>
            Saving...
          </Button>:<Button color="default">Save</Button>}
        </ModalFooter>
      </form>
    </>
  );
}
FormEdit.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = { addTag };

export default connect(mapStateToProps, mapDispatchToProps)(FormEdit);
