import React, { useEffect, useRef, useCallback } from "react";

import matchSorter from "match-sorter";
import Select from "react-select";
import moment from "moment";
import { Col } from "reactstrap";
import { DateRangePicker } from "react-date-range";
import classnames from "classnames";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
function Table({
  columns,
  data,
  paginate,
  renderTable = true,
  settings,
  tagFilter,
  ownerFilter,
  teamFilter,
  ownerRecord,
  auth,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    filter,
    page,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      /*globalFilter: React.useCallback((rows, columnNames, filterValue) => {
        return rows.filter((row) => {
          const rawRowData = Object.values(row["values"]);
          const data = rawRowData.find((rowString) => {
            return rowString !== undefined && typeof rowString === "string"
              ? rowString.includes(filterValue)
              : undefined;
          });
          return !!data;
        });
      }, []),*/
      /*(rows, ids, filterValue) => {
        return ids.map(id => {
          return rows.filter(row => {
            const rowValue = row.values[id];
            rowValue !== undefined || console.log(row.values[id], id, "test=================");
            return rowValue !== undefined
              ? String(rowValue)
                  //.toLowerCase()
                  .startsWith(String(filterValue)/*.toLowerCase())
              : true;
          });
        });
      },*/
      filterTypes,
      initialState: { pageIndex: 0 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );
  const PageControls = () => {
    if (!paginate || !renderTable) return null;

    const FirstPage = () => {
      if (pageIndex !== 0) {
        return (
          <li className="page-item">
            <span
              style={{ cursor: "pointer" }}
              className="page-link"
              onClick={() => {
                resetItemCurrent();
                gotoPage(0);
              }}
            >
              1
            </span>
          </li>
        );
      } else return null;
    };
    const LastPage = () => {
      if (pageIndex !== pageOptions.length - 1 && pageOptions.length > 0) {
        return (
          <li className="page-item">
            <span
              style={{ cursor: "pointer" }}
              className="page-link"
              onClick={() => {
                resetItemCurrent();
                gotoPage(pageOptions.length - 1);
              }}
            >
              {pageOptions.length}
            </span>
          </li>
        );
      } else return null;
    };

    return (
      <>
        {" "}
        <div className="row">
          <div className="col">
            <ul className="pagination">
              <li className="page-item">
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {" "}
                  &lsaquo;
                </span>
              </li>

              <FirstPage />
              {[-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0]
                .slice(Math.min(pageOptions.length - 1 - pageIndex, 5))
                .filter((n) => n + pageIndex > 1)
                .map((n) => {
                  return (
                    <li key={"pageq-" + pageIndex + n} className="page-item">
                      <span
                        style={{ cursor: "pointer" }}
                        className="page-link"
                        key={"page-" + pageIndex + n}
                        onClick={() => {
                          resetItemCurrent();
                          gotoPage(pageIndex + n - 1);
                        }}
                      >
                        {pageIndex + n}
                      </span>
                    </li>
                  );
                })}
              <li className="page-item active">
                <span className="page-link">{pageIndex + 1}</span>
              </li>
              {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                .slice(0, Math.max(11 - pageIndex, 5))
                .filter((n) => n + pageIndex < pageOptions.length)
                .map((n) => {
                  return (
                    <li key={"pagen-" + pageIndex + n} className="page-item">
                      <span
                        style={{ cursor: "pointer" }}
                        className="page-link"
                        key={"page-" + pageIndex + n}
                        onClick={() => {
                          resetItemCurrent();
                          gotoPage(pageIndex + n - 1);
                        }}
                      >
                        {pageIndex + n}
                      </span>
                    </li>
                  );
                })}
              <LastPage />
              <li key="nextPage" className="page-item">
                <span
                  style={{ cursor: "pointer" }}
                  className="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  &rsaquo;
                </span>
              </li>
            </ul>
          </div>
          <div className="col-2">
            <select
              style={{ cursor: "pointer" }}
              className="form-control"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </>
    );
  };

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const itemsRef = useRef([]);
  const tableRef = useRef([]);
  const trHeadRef = useRef([]);
  const tbodyRef = useRef([]);
  const visibleCoumnCount = useRef(headerGroups[0].headers.length);
  var oldVisibleCoumnCount = useRef(headerGroups[0].headers.length);
  var widowWidth = useRef(window.innerWidth);
  var currentPage = useRef(-1);
  const resetItemCurrent = () => {
    itemsRef.current.forEach((x) => {
      if (x && x.classList) x.classList.remove("parent_tr");
    });
    itemsRef.current = [];
  };
  const getInitialColum = useCallback(
    (_init_) => {
      if (tableRef.current && trHeadRef.current && rows) {
        if (
          !_init_ &&
          ((window.innerWidth < widowWidth.current &&
            widowWidth.current - window.innerWidth < 10) ||
            (window.innerWidth > widowWidth.current &&
              window.innerWidth - widowWidth.current < 100))
        ) {
          return false;
        }
        if (settings) {
          for (var i = 0; i < settings.length; i++) {
            if (window.innerWidth >= settings[i].width) {
              if (settings[i].column !== "all") {
                visibleCoumnCount.current = settings[i].column;
              } else {
                visibleCoumnCount.current = headerGroups[0].headers.length;
              }
              break;
            }
          }
          const tr = tbodyRef.current.querySelectorAll("tr[role='row']");
          if (
            (oldVisibleCoumnCount.current !== visibleCoumnCount.current ||
              currentPage.current !== pageIndex ||
              page) &&
            tr.length > 1
          ) {
            trHeadRef.current.setAttribute(
              "datacolum",
              visibleCoumnCount.current
            );
            tr.forEach((x) =>
              x.setAttribute("datacolum", visibleCoumnCount.current)
            );
            oldVisibleCoumnCount.current = visibleCoumnCount.current;
            widowWidth.current = window.innerWidth;
            currentPage.current = pageIndex;
          }
        }
      }
    },
    [
      tableRef,
      trHeadRef,
      widowWidth,
      visibleCoumnCount,
      oldVisibleCoumnCount,
      headerGroups,
      rows,
      settings,
      currentPage,
      pageIndex,
      page,
    ]
  );

  useEffect(() => {
    //itemsRef.current = itemsRef.current.slice(0, (paginate ? page : rows).length);
    if (tableRef.current && trHeadRef.current && rows) {
      getInitialColum(true);
      window.addEventListener("resize", (e) => {
        e.preventDefault();
        getInitialColum();
      });
      // returned function will be called on component unmount
      return () => {
        window.removeEventListener("resize", (e) => {
          e.preventDefault();
          getInitialColum();
        });
      };
    }
  });
  var tagFilterC = null;
  var ownerFilterC = null;
  var teamFilterC = null;
  // Render the UI for your table
  const getFilterColum = (type) => {
    var column = false;
    headerGroups.map((headerGroup) => {
      column = headerGroup.headers.find((column, index) => {
        return column.Header === type;
      });
      return null;
    });
    return column && column.canFilter && !column.disableFilter && column.Filter
      ? column.render("Filter")
      : null;
  };
  const getOwnerPerm = () => {
    return (
      ["admin", "owner", "super"].includes(auth.user.role) ||
      (auth.user.role === "agent" &&
        ownerRecord &&
        ( ["team", "all"].includes(ownerRecord.agentPermission)))
    );
  };
  const getTeamPerm = () => {
    return (
      ["admin", "owner", "super"].includes(auth.user.role) ||
      (auth.user.role === "agent" &&
        ownerRecord &&
        ( ["all"].includes(ownerRecord.agentPermission)))
    );
  };
  return (
    <div ref={(el) => (tableRef.current = el)} className="table_data">
      {" "}
      <table {...getTableProps()} className="table table-striped">
        <thead>
          <tr className="row-table">
            <th colSpan={headerGroups[0].headers.length}>
              <div className="filter_div_container">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />{" "}
                <div className = "div_class_filter">
                  {tagFilter && (tagFilterC = getFilterColum("tags")) ? (
                    <div
                      className = "filer_div">
                      <label>Tags</label>
                      <div>{tagFilterC}</div>
                    </div>
                  ) : null}
                  {ownerFilter &&
                  ownerRecord &&
                  getOwnerPerm() &&
                  (ownerFilterC = getFilterColum("owner")) ? (
                    <div
                      className = "filer_div">
                      <label>Owner</label>
                      <div>{ownerFilterC}</div>
                    </div>
                  ) : null}
                  {teamFilter &&
                  ownerRecord &&
                  getTeamPerm() &&
                  (teamFilterC = getFilterColum("team")) ? (
                    <div
                      className = "filer_div">
                      <label>Team</label>
                      <div>{teamFilterC}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </th>
          </tr>

          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              ref={(el) => (trHeadRef.current = el)}
            >
              {headerGroup.headers.map((column, index) => {
                if (column.hide) {
                  return null;
                }
                return (
                  <th
                    key={index}
                    className={classnames({
                      action_coumn: column.actionCoumn,
                    })}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </div>
                    <span>
                      {column.canFilter &&
                      !column.disableFilter &&
                      column.Filter
                        ? column.render("Filter")
                        : null}
                    </span>
                    <span>
                      {filter && column.canFilter
                        ? column.render("Filter")
                        : null}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}

          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            ></th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()} ref={(el) => (tbodyRef.current = el)}>
          {(paginate ? page : rows).length === 0 ? (
            <tr>
              <td
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "center",
                }}
              >
                No Record Found
              </td>
            </tr>
          ) : (
            <></>
          )}
          {(paginate ? page : rows).map((row, i) => {
            prepareRow(row);
            const rowTd = [
              <tr
                {...row.getRowProps()}
                key={i}
                datacolum={row.cells.length}
                maindatacolum={row.cells.length}
                ref={(el) => (itemsRef.current[i] = el)}
              >
                {row.cells.map((cell, index) => {
                  var click = {};
                  if (index === 0) {
                    click = {
                      onClick: () => {
                        const current = itemsRef.current[i];
                        if (
                          current.getAttribute("datacolum") ===
                          current.getAttribute("maindatacolum")
                        ) {
                          current.classList.remove("parent_tr");
                        } else {
                          current.classList.toggle("parent_tr");
                        }
                      },
                    };
                  }
                  if (cell.column.hide) {
                    return null;
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={classnames({
                        responsive: cell.column.responsiveClone,
                        action_coumn: cell.column.actionCoumn,
                      })}
                      {...click}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>,
              <tr {...row.getRowProps()} key={"key_" + i} className="child">
                <td colSpan={row.cells.length}>
                  <ul className="clone_ul">
                    {row.cells.map((cell) => {
                      if (cell.column.responsiveClone) {
                        return (
                          <li {...cell.getCellProps()} className="clone_li">
                            <b>{cell.column.Header}</b>:<br />
                            <div style={{ marginLeft: 20 }}>
                              {cell.render("Cell")}
                            </div>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ul>
                </td>
              </tr>,
            ];
            return rowTd;
          })}
        </tbody>
      </table>
      <PageControls page={page} resetItemCurrent={resetItemCurrent} />
    </div>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, totalWidth },
}) {
  const style = { minWidth: totalWidth };
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={``}
      style={style}
      className={"form-control"}
    />
  );
}

//CustomColumnSelectFilter
function CustomColumnSelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      row.values[id].map((_row) => {
        if (_row) {
          options.add(_row);
        }
        return null;
      });
      //options.push({ label: row.values[id], value: row.values[id] });
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className={"form-control"}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

//CustomColumnMultiSelectFilter
function CustomColumnMultiSelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      row.values[id].map((_row) => {
        if (_row) {
          options.add(_row);
        }
        return null;
      });
      //options.push({ label: row.values[id], value: row.values[id] });
    });

    return [...options.values()];
  }, [id, preFilteredRows]);
  const customStyles = {};
  return (
    <Select
      value={filterValue || undefined}
      onChange={(selected) => {
        setFilter(selected || undefined);
      }}
      isMulti
      styles={customStyles}
      options={options}
      getOptionLabel={(option) => `${option}`}
      getOptionValue={(option) => `${option}`}
    />
  );
  // Render a multi-select box
  /*return (
    <select
      multiple = {true}
      value={filterValue || [""]}
      onChange={(e) => {
        if(e.target.value === "") {
          setFilter([""]);
        } else if(filterValue && filterValue[0]!="") {
          
          const itemIndex = filterValue.findIndex((data) => data === e.target.value);

          if( itemIndex === -1 ) {
            setFilter([...filterValue, e.target.value]);
          } else {
            const fil = [...filterValue.splice(itemIndex, -1)];
            setFilter(fil.length > 0 ?fil:[""]);
          }
        } else {
          setFilter([e.target.value]);
        }
        return true;
      }}
      className={"form-control"}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );*/
}
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col xs="12" lg="5" md="6" sm="8">
      <div
        style={{
          width: "100%",
          maxWidth: "280px",
          marginRight: "10px",
        }}
      >
        <label>Search All</label>
      </div>

      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "1pzx",
          display: "inline-block",
          height: "38px",
        }}
        className={"form-control"}
      />
    </Col>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = [];

    preFilteredRows.forEach((row) => {
      if (!options.some((o) => o.value === row.values[id])) {
        options.push({ label: row.values[id], value: row.values[id] });
      }

      //options.add(row.values[id]);
    });
    return options.sort(function (a, b) {
      var labelA = a.label; // ignore upper and lowercase
      var labelB = b.label; // ignore upper and lowercase
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }

      // labels must be equal
      return 0;
    });
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      options={options}
      isClearable={true}
      isMulti={true}
      onChange={(options) => {
        setFilter(options ? options.map((o) => o.value) : undefined);
      }}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
      //options.push({ label: row.values[id], value: row.values[id] });
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className={"form-control"}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function DateFilter({
  column: {
    filterValue = [],
    preFilteredRows,
    setFilter,
    id,
    minWidth,
    maxWidth,
  },
}) {
  const containerRef = React.createRef();
  const style = {};
  if (minWidth) style.minWidth = minWidth;
  if (maxWidth) style.maxWidth = maxWidth;

  const selectionRange = [
    {
      startDate: Number.isInteger(filterValue[0])
        ? new Date(filterValue[0])
        : filterValue[0],
      endDate: Number.isInteger(filterValue[1])
        ? new Date(filterValue[1])
        : filterValue[1],
      key: "selection",
    },
  ];

  var dateValue = "";
  if (filterValue[0] && filterValue[1]) {
    const date1 = moment(filterValue[0]).format("MM/DD/YYYY");
    const date2 = moment(filterValue[1]).format("MM/DD/YYYY");
    if (date1 === date2) {
      dateValue = date1;
    } else {
      dateValue = date1 + " - " + date2;
    }
  }

  const [open, setOpen] = React.useState(false);
  const onClickOnPage = (e) => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    let didClickInside = false;

    let parentElement = e.target;
    while (parentElement) {
      if (parentElement === containerRef.current) {
        didClickInside = true;
        break;
      }

      parentElement = parentElement.parentElement;
    }

    if (didClickInside) {
      return;
    }

    // Clicked outside of the date range picker - cancel selection
    setOpenCalander(false);
  };
  const setOpenCalander = (bool) => {
    if (!bool) {
      document.body.removeEventListener("click", onClickOnPage, true);
    }
    setOpen(bool);
  };
  document.body.addEventListener("click", onClickOnPage, true);
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <>
        <br />
        <input
          className={"form-control"}
          value={dateValue}
          onChange={(e) => {}}
          onFocus={() => {
            //setOpen(!open);
          }}
          onClick={() => {
            setOpenCalander(!open);
          }}
        />
        {dateValue ? (
          <div
            className="close_calander_div"
            onClick={() => setFilter((old = []) => [])}
          >
            <span className="close_calander_span">X</span>
          </div>
        ) : (
          <></>
        )}

        {open ? (
          <div
            ref={containerRef}
            style={{
              position: "absolute",
              zIndex: "2000",
              marginTop: "48px",
              marginLeft: "-349px",
              border: "1px solid grey",
              paddingRight: "48px",
              backgroundColor: "white",
              paddingBottom: "30px",
            }}
          >
            <DateRangePicker
              style={{ position: "absolute" }}
              onChange={(ranges) => {
                let endDate = ranges.selection.endDate.valueOf();
                if (ranges.selection.endDate.getSeconds() === 0) {
                  endDate = endDate + 86400000 - 1;
                }

                // This check deals with clicking Continuos and setting a value where startDate is not defined. We just set a date way in the past so it is defined.
                if (ranges.selection.startDate) {
                  setFilter((old = []) => [
                    ranges.selection.startDate.valueOf(),
                    endDate,
                  ]);
                } else {
                  ranges.selection.startDate = new Date(
                    "1980-01-01T07:00:00.000Z"
                  );

                  setFilter((old = []) => [
                    ranges.selection.startDate.valueOf(),
                    endDate,
                  ]);
                }
              }}
              ranges={selectionRange}
              direction="vertical"
            />
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export {
  SelectColumnFilter,
  SelectFilter,
  DateFilter,
  Table,
  DefaultColumnFilter,
  CustomColumnSelectFilter,
  CustomColumnMultiSelectFilter,
};
