import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Navbar } from "reactstrap";
import PrivateRoute from "./components/private-route/PrivateRoute";
import ModalLead from "./layouts/ModalLead";
// Redux
import { connect, Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
//Authentication
import setAuthToken from "./utils/setAuthToken";
import {
  setCurrentUser,
  logoutUser,
  handleCsrfToken,
} from "./actions/authActions";

// Routing

import AccountLayout from "./layouts/Account.js";
import AuthLayout from "./layouts/Auth.js";
import GetstartedLayout from "./layouts/Getstarted.js";
import AuthorizeLayout from "./layouts/Authorize";
//import IndexView from "./views/Index.js";
import ProfilePage from "./views/pages/Users/Profile.js";
import BillingPage from "./views/pages/Settings/Billing.js";
import AgentPage from "./views/pages/Agents/Agents.js";
import AgencyPage from "./views/pages/Settings/Agency.js";
import TeamPage from "./views/pages/Teams/Teams.js";
import NumbersPage from "./views/pages/Numbers/Numbers.js";
import IntegrationPage from "./views/pages/Integration/Integration.js";
import ReportsCallsPage from "./views/pages/Reports/Calls.js";
import ReportsLeadsPage from "./views/pages/Reports/Leads.js";
import ReportsInboundPage from "./views/pages/Reports/Inbound.js";

import AdminPage from "./views/pages/Admin/Admin.js";

class App extends Component {
  render() {
    const { store, history } = this.props;
    store.dispatch(handleCsrfToken());

    // Check for token to keep user logged in
    if (localStorage.jwtToken) {
      // Set auth token header auth
      const token = localStorage.jwtToken;
      setAuthToken(token);
      // Decode token and get user info and exp
      const decoded = jwt_decode(token);
      decoded.token = token;
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded, history.location.pathname !== "/authorize/oauth"));
      // Check for expired token
      const currentTime = Date.now() / 1000; // to get in milliseconds
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        history.push("/auth/login");
      } else if (
        history.location.pathname !== "/authorize/oauth" &&
        history.location.pathname !== "/account/billing/success"
      ) {
        if (decoded.role !== "agent") {
          fetch("/api/billing/account_status", {
            headers: {
              Authorization: token,
            },
          })
            .then((response) => response.json())
            .then((items) => {
              if (items && !items.setupComplete) {
                history.push("/getstarted/setup");
              }
            })
            .catch((err) => {});
        }
      }
    } else {
      const query = new URLSearchParams(history.location.search);
      const token = query.get("token");
      if (token) {
        setAuthToken("Bearer " + token);
        // Decode token and get user info and exp
        const decoded = jwt_decode(token);
        decoded.token = "Bearer " + token;
        // Set user and isAuthenticated
        store.dispatch(setCurrentUser(decoded, history.location.pathname === "/authorize/oauth"));
        localStorage.setItem("jwtToken", "Bearer " + token);
        if (history.location.pathname !== "/authorize/oauth") {
          if (decoded.role !== "agent") {
            fetch("/api/billing/account_status", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
              .then((response) => response.json())
              .then((items) => {
                if (items && !items.setupComplete) {
                  history.push("/getstarted/setup");
                } else {
                  history.push("/account/dashboard");
                }
              })
              .catch((err) => {});
          }
        }
      }
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Navbar />
            <Switch>
              <Route path="/authorize/" component={AuthorizeLayout} />
              <PrivateRoute path="/account/" component={AccountLayout} />
              <PrivateRoute path="/getstarted" component={GetstartedLayout} />
              <PrivateRoute path="/profile/" component={ProfilePage} />
              <PrivateRoute path="/billing/" component={BillingPage} />
              <PrivateRoute path="/agents/" component={AgentPage} />
              <PrivateRoute path="/teams/" component={TeamPage} />
              <PrivateRoute path="/numbers/" component={NumbersPage} />
              <PrivateRoute path="/integration/" component={IntegrationPage} />
              <PrivateRoute path="/agency/" component={AgencyPage} />

              <PrivateRoute
                path="/reports/Calls"
                component={ReportsCallsPage}
              />
              <PrivateRoute
                path="/reports/Leads"
                component={ReportsLeadsPage}
              />
              <PrivateRoute
                path="/reports/Inbound"
                component={ReportsInboundPage}
              />

              <PrivateRoute path="/admin/Admin" component={AdminPage} />

              <Route
                path="/auth"
                render={(props) => <AuthLayout {...props} />}
              />
              {/*<Route path="/" render={(props) => <IndexView {...props} />} />*/}
              <Route path="/" render={(props) => <AuthLayout {...props} />} />
            </Switch>
            <ModalLead />
            <ReduxToastr
              timeOut={8000}
              newestOnTop={true}
              preventDuplicates
              position="top-right"
              transitionIn="bounceIn"
              transitionOut="bounceOut"
              closeOnToastrClick
            />
          </div>
        </Router>
      </Provider>
    );
  }
}

const mapStateToProps = null;

export default connect(mapStateToProps)(App);
