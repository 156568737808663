import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardBody, Row, Col, Container, Button } from "reactstrap";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import ModalLead from "../Leads/ModalLead";
import ModalCall from "./ModalCall";
//import { agentDropdown, getAndTeamByOwnerLead } from "../../../actions/agentAction";
import moment from "moment";
import {
  Table,
  DefaultColumnFilter,
  DateFilter,
  CustomColumnSelectFilter,
  CustomColumnMultiSelectFilter,
} from "../components/Table";
import Loader from "layouts/Loader.js";
//import { Button } from "semantic-ui-react";

const formatString = "ddd, D-MMM h:mm a";

function Calls(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  //const [agents, setAgents] = useState([]);
  //const [teams, setTeams] = useState({'list':[], 'seeallteams': true});
  //const [leads, setLeads] = useState([]);
  //const [callerids, setCallerids] = React.useState([]);
  //const [recordings, setRecordings] = useState([]);
  //const [ownerRecord, setOwnerRecord] = useState({});
  const [type, setType] = useState("History");
  const signal = useRef();
  const getCalls = useCallback(
    (type, abortController) => {
      setLoading(true);
      const token = props.auth.user.token;
      fetch("/api/call/?type=" + type, {
        signal: abortController.signal,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((items) => {
          setLoading(false);
          if (items) {
            setItems(items);
          }
        })
        .catch((err) => {});
    },
    [props.auth.user.token]
  );
  const deleteOwnedFromState = useCallback(
    (_id) => {
      const updatedItems = items.filter((item) => item._id !== _id);
      setItems(updatedItems);
    },
    [items, setItems]
  );
  const getItems = useCallback(
    (abortController) => {
      getCalls("History", abortController);
      //props.getAndTeamByOwnerLead();
      /*fetch("/api/agent/getandteambyownerlead", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOwnerRecord(data);
      })
      .catch((err) => {});*/
      /*fetch("/api/team/dropdownsettings", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((teams) => {
        if (teams) {
          setTeams(teams);
        }
      })
      .catch((err) => {});*/
      //      props.agentDropdown();
      /*fetch("/api/agent/dropdown", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((agents) => {
        if (agents) {
          setAgents(agents);
        }
      })
      .catch((err) => {});*/
    },
    [getCalls]
  );

  const addItemToState = (item) => {
    if (!(item.status === "Initiated" && type === "Scheduled")) {
      setItems([item, ...items]);
    }
  };

  const updateState = useCallback(
    (item) => {
      const itemIndex = items.findIndex((data) => data._id === item._id);
      const newArray = [
        ...items.slice(0, itemIndex),
        item,
        ...items.slice(itemIndex + 1),
      ];
      setItems(newArray);
    },
    [items]
  );

  useEffect(() => {
    const abortController = new window.AbortController();
    getItems(abortController);
    signal.current = abortController;
    return () => {
      abortController.abort();
    };
  }, [getItems, signal]);

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        responsiveClone: false,
        disableFilter: true,
        accessor: (row) => {
          const dir =
            row.direction === "Outgoing" ? (
              <>
                <i
                  className="fas fa-sign-out-alt"
                  title="Outgoing Call"
                  style={{ color: "#2dce89" }}
                ></i>
              </>
            ) : (
              <>
                <i
                  className="fas fa-sign-in-alt"
                  title="Incoming Call"
                  style={{ color: "#5e72e4" }}
                ></i>
              </>
            );

          return <>{dir}</>;
        },
        sortType: "basic",
        minWidth: 25,
      },
      {
        Header: "Date",
        responsiveClone: true,
        accessor: (row) => moment(row.startTime).valueOf(),
        //   accessor: (d) => d.startTime,
        Cell: ({ cell: { value } }) => {
          return (
            <>
              {moment(value).format("ddd, D-MMM-YYYY")}
              <br></br>
              {moment(value).format("h:mm a")}
            </>
          );
        },
        Filter: DateFilter,
        filter: "between",
        sort: "desc",
        sortType: "basic",
        minWidth: 10,
      },

      {
        Header: "Status",
        responsiveClone: true,
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          return (
            <>
              {item.status}{" "}
              <div className="calltooltip">
                {" "}
                <i
                  className="fa fa-info-circle"
                  id={"UncontrolledTooltip_" + row.index}
                ></i>
                <div className="tooltiptext tooltip-bottom">
                  {item.segment02.logs.length > 0 ? (
                    <>
                      {" "}
                      <div>
                        <label className="font-weight-bold">
                          Calling {item.segment02.target}:{" "}
                          {item.segment02.segmentStatus}
                        </label>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {item.segment02.logs.length > 0 ? (
                    item.segment02.logs.reverse().map((log, index) => {
                      return (
                        <div className="subheading" key={index}>
                          {moment(log.date).format(formatString) +
                            ": " +
                            log.message}
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  <div>
                    <label className="font-weight-bold">
                      Calling {item.segment01.target}:{" "}
                      {item.segment01.segmentStatus}
                    </label>
                  </div>
                  {item.segment01.logs.reverse().map((log, index) => {
                    if (log) {
                      return (
                        <div className="subheading" key={index}>
                          {moment(log.date).format(formatString) +
                            ": " +
                            log.message}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <br></br>
              {/* check if currentTarget is an object to deal with old data structure */}
              {item.segment01.segmentStatus}{" "}
              {typeof item.segment01.currentTarget === "object"
                ? item.segment01.currentTarget.name
                : item.segment01.currentTarget}
              <br></br>
              {item.segment02.segmentStatus}{" "}
              {typeof item.segment02.currentTarget === "object"
                ? item.segment02.currentTarget.name
                : item.segment02.currentTarget}
              {item.callDuration ? <>: {item.callDuration}s</> : <></>}
              {item.recordingUrl ? (
                <>
                  {" "}
                  <a href={row.recordingUrl + ".mp3?Download=true"}>
                    <i
                      className="fa fa-microphone"
                      style={{ fontSize: "12pt", color: "#f5365c" }}
                    ></i>
                  </a>
                </>
              ) : (
                <></>
              )}{" "}
            </>
          );
        },
        accessor: (row) => [row.status],
        Filter: CustomColumnMultiSelectFilter,
        filter: "includesSome",
        /*filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else {
            //return row[filter.id].filter.includes(filter.value);
            //return rows.filter(r => filterValues.includes(r.values[id]));
            return true;
          }
        },*/
        //defaultCanFilter: false,
        sort: "desc",
        sortType: "basic",
        minWidth: 100,
      },
      {
        Header: "Source/Target",
        responsiveClone: true,
        accessor: (row) => [row.source + "/" + row.type],
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          const targetColor =
            item.type === "Lead" ? (
              <>
                <i className="fa fa-bullseye  text-info" title="Target"></i>{" "}
                {item.type}
              </>
            ) : (
              <>
                <i className="fa fa-bullseye  text-purple" title="Target"></i>{" "}
                {item.type}
              </>
            );

          return (
            <>
              <i className="fa fa-map-marker" title="Source"></i> {item.source}
              <br></br>
              {targetColor}
            </>
          );
        },
        Filter: CustomColumnSelectFilter,
        filter: "includes",
        minWidth: 50,
        maxWidth: 55,
      },

      {
        Header: "Lead/Team/Agent",
        responsiveClone: true,
        accessor: (row) => {
          const first = row.leadDetails.first ? row.leadDetails.first : "";
          const last = row.leadDetails.last ? row.leadDetails.last : "";
          const phone = row.leadDetails.national
            ? row.leadDetails.national
            : "";

          var lead = first + " " + last + ": " + phone;

          const team = row.team !== null ? row.team.name : "";
          const members =
            row.team !== null
              ? row.team.members.map((m) => m.first).join(",")
              : "";

          return [lead, team, members];
        },
        Cell: ({ column, cell, row }) => {
          const item = row.original;
          const member =
            item.team !== null
              ? item.team.members.map((m) => m.first).join(",")
              : item.segment02.outcome.map((m) => m.target).join(",");

          return (
            <>
              <ModalLead
                buttonType="view"
                buttonLabel="Edit"
                leadId={item.lead ? item.lead._id : ""}
                item={item.lead ? item.lead : item.leadDetails}
                //agents={agents}
                //teams={teams}
                headerTitle="Edit Lead"
                updateState={updateState}
              />
              {item.team !== null ? (
                <>
                  <br></br>
                  <i
                    className="fa fa-sitemap text-purple"
                    title="Team"
                  ></i>{" "}
                  {item.team.name}{" "}
                </>
              ) : (
                ""
              )}
              <br></br>
              <i
                className="ni ni-badge"
                title="Team Members"
                style={{ paddingLeft: "2px", paddingRight: "2px" }}
              ></i>{" "}
              {member}
            </>
          );
        },
        Filter: DefaultColumnFilter,
        disableSortBy: true,
        minWidth: 25,
        maxWidth: 75,
      },
      {
        Header: "  ",
        disableFilter: true,
        responsiveClone: false,
        actionCoumn: true,
        Cell: (props) => {
          const name = props.row.original.lead
            ? props.row.original.lead.first + " " + props.row.original.lead.last
            : props.row.original.leadDetails.first +
              " " +
              props.row.original.leadDetails.last +
              ": " +
              props.row.original.leadDetails.national;

          const header = props.row.original.lead
            ? props.row.original.lead.national + ": " + name
            : props.row.original.leadDetails.national +
              ": " +
              props.row.original.leadDetails.first +
              " " +
              props.row.original.leadDetails.last;
          return (
            <div style={{ float: "right" }}>
              <ModalCall
                buttonType="view"
                buttonLabel="View"
                item={props.row.original}
                updateState={updateState}
                headerTitle={header}
                type={type}
                deleteOwnedFromState={deleteOwnedFromState}
              />{" "}
            </div>
          );
        },
      },
    ],
    [/*agents, teams,*/ updateState, deleteOwnedFromState, type]
  );
  return (
    <>
      <CardsHeader name="Calls" />{" "}
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  {!loading ? (
                    <>
                      <Row>
                        <div className="margin-right-10">
                          <Button
                            outline={type !== "Scheduled"}
                            disabled={type === "Scheduled"}
                            color="primary"
                            onClick={() => {
                              if (type === "Scheduled") {
                                getCalls("default", signal.current);
                                setType("default");
                              } else {
                                setType("Scheduled");
                                getCalls("Scheduled", signal.current);
                              }
                            }}
                          >
                            Scheduled
                          </Button>
                        </div>
                        <div className="margin-left-10 margin-right-10">
                          <Button
                            outline={type !== "History"}
                            disabled={type === "History"}
                            color="primary"
                            onClick={() => {
                              if (type === "History") {
                                setType("default");
                                getCalls("default", signal.current);
                              } else {
                                setType("History");
                                getCalls("History", signal.current);
                              }
                            }}
                          >
                            History
                          </Button>
                        </div>
                        <div className="margin-left-10">
                          <Button
                            outline={type !== "All"}
                            disabled={type === "All"}
                            color="primary"
                            onClick={() => {
                              if (type === "All") {
                                setType("default");
                                getCalls("default", signal.current);
                              } else {
                                setType("All");
                                getCalls("All", signal.current);
                              }
                            }}
                          >
                            All
                          </Button>
                        </div>
                        <div className="float-right new_call">
                          <ModalCall
                            buttonType="add"
                            buttonLabel="New Call"
                            addItemToState={addItemToState}
                            item={{}}
                            headerTitle="Start a New Call"
                            //agents={agents}
                            //teams={teams}
                            //leads={leads}
                            //callerids={callerids}
                            //recordings={recordings}
                            //ownerRecord={ownerRecord}
                          />
                        </div>
                      </Row>
                      <Row>
                        <Col lg="12" className="call_listing">
                          <Table
                            name="Calls"
                            columns={columns}
                            data={items}
                            globalFilter={true}
                            filter={true}
                            paginate={true}
                            sort={true}
                            settings={[
                              { width: 990, column: "all" },
                              { width: 882, column: 5 },
                              { width: 570, column: 4 },
                              { width: 448, column: 3 },
                              { width: 0, column: 3 },
                            ]}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Loader />
                  )}
                  <hr />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
Calls.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  //agentDropdown,
  //getAndTeamByOwnerLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calls);
