import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import InstructionsIntegration from "../InstructionsIntegration";
import Loader from "layouts/Loader.js";

function ModalIntegration(props) {
  const [modal, setModal] = useState(false);
  const [modalType, setModalTyp] = useState("default");
  const toggle = () => {
    setModal(!modal);
  };
  const setModalType = (type) => {
    toggle();
    setModalTyp(type);
  };
  const scriptId = "zapier_widget";
  React.useEffect(() => {
    var existingScript = null;
    if (props.item) {
      existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }
      if (!existingScript && modal && props.buttonType === "settings") {
        const script = document.createElement("script");
        script.src =
          process.env.REACT_APP_ZAPIER_ZAP_TEMPLATES + props.item._id;
        script.id = scriptId;
        document.body.appendChild(script);
      }
    }

    return () => {
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [scriptId, modal, props.buttonType, props.item]);
  const label = props.buttonLabel;

  var addEditButton = null;
  if (props.buttonType === "instructions") {
    addEditButton = [
      <Button
        key="0"
        color={"success"}
        className="offet-margin-left webhook_desktop"
        onClick={() => setModalType("instructions")}
      >
        {label}
      </Button>,
      <Button
        key="1"
        size="sm"
        color={"success"}
        className="offet-margin-left webhook_mobile"
        onClick={() => setModalType("instructions")}
      >
        <i className="fa fa-info-circle"></i>
      </Button>,
    ];
  } else if (props.buttonType === "create") {
    addEditButton = (
      <a
        className={"btn btn-primary"}
        /*onClick={() => setModalType("default")}*/
        href={process.env.REACT_APP_ZAPIER_ADD_ZAP}
        style={{ float: "right", padding: "0.35rem 0.25rem" }}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        {label}
      </a>
    );
  } else {
    addEditButton = [
      <Button
        key="0"
        color={"primary"}
        className="offet-margin-left webhook_desktop_sm"
        onClick={() => setModalType("default")}
        style={{ float: "right", padding: "0.35rem 0.25rem" }}
      >
        {label}
      </Button>,
    ];
  }
  const getModalForm = () => {
    var modalForm = null;
    switch (modalType) {
      case "instructions":
        modalForm = (
          <InstructionsIntegration
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            auth={props.auth}
            item={props.item}
            deleteItemFromState={props.deleteItemFromState}
            hasLogin={props.hasLogin}
          />
        );
        break;
      default:
        modalForm = props.item ? (
          <div id={"zapier" + props.item._id}>
            <Loader />
          </div>
        ) : null;
    }
    return modalForm;
  };

  return (
    <>
      {addEditButton}
      <Modal isOpen={modal} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>{getModalForm()}</ModalBody>
      </Modal>
    </>
  );
}

export default ModalIntegration;
