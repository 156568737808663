import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notification from "./Notification";
import {Modal, ModalHeader, ModalBody } from "reactstrap";

import FormLeadEdit from "../views/pages/Leads/FormLeadEdit";

function ModalLead(props) {
  const [modalOpen, setModal] = useState(false);
  const [lead, setLead] = useState();
  const toggle = () => {
    setModal(!modalOpen);
    return true;
  };
  const getLead = (_id) => {
    const token = props.auth.user.token;
    fetch("/api/lead/" + _id, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((lead) => {
        setLead(lead);
        toggle();
      })
      .catch((err) => {});
  };
  /*const getDropDowns = useCallback(async () => {
    if(props.auth && props.auth.user && props.auth.user.token) {
      const token = props.auth.user.token;
      await fetch("/api/team/dropdownsettings", {
      headers: {
          Authorization: token,
      },
      })
      .then((response) => response.json())
      .then((teams) => {
          if (teams) {
          setTeams(teams);
          }
      })
      .catch((err) => {});

      await fetch("/api/agent/dropdown", {
      headers: {
          Authorization: token,
      },
      })
      .then((response) => response.json())
      .then((agents) => {
          if (agents) {
          setAgents(agents);
          }
      })
      .catch((err) => {});
    }
  }, [props.auth.user.token]);
  useEffect(() => {
      getDropDowns();
  }, [getDropDowns]);*/
  var addEditLeadForm = (
        <>
          <FormLeadEdit
            toggle={toggle}
            item={lead}
            //agents={agents}
            //teams={teams}
            deleteItemFromState={()=>{}}
          />
        </>
      );
  return (
    <>
      <Notification getLead = {getLead}/>
      <Modal isOpen={modalOpen} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          Edit Lead
        </ModalHeader>
        <ModalBody>{addEditLeadForm}</ModalBody>
      </Modal>
    </>
  );
}
ModalLead.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalLead);
