import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthFooter from "../components/Footers/AuthFooter.js";

import routes from "routes.js";
import { connect } from "react-redux";
const initialSetup = routes.initialSetup;

class Getstarted extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (initialSetup) => {
    return initialSetup.map((prop, key) => {
      if (
        prop.redirect ||
        (prop.noAllow && prop.noAllow.includes(this.props.auth.user.role))
      ) {
        return <Redirect from="*" to="/account/dashboard" />
      }
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <Switch>
            {this.getRoutes(initialSetup)}
            <Redirect from="*" to="/getstarted/setup" />
          </Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Getstarted);
