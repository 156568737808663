import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";

// core components
import AuthFooter from "../components/Footers/AuthFooter.js";

import routes from "routes.js";

const authorizeSetup = routes.authorizeSetup;

class Authorize extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (authorizeSetup) => {
    return authorizeSetup.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <Switch>
            {this.getRoutes(authorizeSetup)}
          </Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Authorize;
