import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import FormAdmin from "./FormAdmin";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function ModalAdmin(props) {
  const [modalOpen, setModal] = useState(false);
  const toggle = () => {
    setModal(!modalOpen);
    return true;
  };

  const confirmDelete = (_id, name) => {
    MySwal.fire({
      title: "Are you sure you want to delete " + name + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios({
          method: "delete",
          responseType: "json",
          url: `/api/admin/` + _id,
        })
          .then((response) => {
            props.deleteItemFromState(response.data.deleteStatus._id);
            Swal.fire(
              "Deleted!",
              "Your Organization is gone for good." +
                JSON.stringify(response.data.deleteStatus),
              "success"
            );
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data) {
                Swal.fire({
                  text: "Something went wrong. Try again.",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                text: "Something went wrong. Try again.",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  const addEditButton = (
    <>
      <Button
        color={"info"}
        onClick={toggle}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <i className="fas fa-pen"></i>
      </Button>

      <Button
        color={"default"}
        onClick={() => {
          confirmDelete(props.item._id, props.item.name);
        }}
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </>
  );

  return (
    <>
      {addEditButton}
      <Modal isOpen={modalOpen} fade={false} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              &times;
            </button>
          }
        >
          {props.headerTitle}
        </ModalHeader>
        <ModalBody>
          <FormAdmin
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            item={props.item}
            deleteItemFromState={props.deleteItemFromState}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
ModalAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalAdmin);
