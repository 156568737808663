import React, { Component } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";

class AccountNavbar extends Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    //const { user } = this.props.auth;

    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown style={{ cursor: "pointer" }} nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <div>
                      <Media className="align-items-center">
                        <i
                          className="ni ni-circle-08 text-white"
                          style={{ fontSize: "27pt" }}
                        ></i>

                        <Media className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold"></span>
                        </Media>
                      </Media>
                    </div>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <DropdownItem>
                      <Link to="./profile" className="dropdown-item">
                        <i className="ni ni-single-02" />
                        <span>My profile</span>
                      </Link>
                    </DropdownItem>
                    {!(['agent'].includes(this.props.auth.user.role))?<DropdownItem>
                      <Link to="./billing" className="dropdown-item">
                        <i className="ni ni-credit-card" />
                        <span>Billing</span>
                      </Link>
                    </DropdownItem>:null}
                    <DropdownItem>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link
                        to="./logout"
                        className="dropdown-item"
                        onClick={this.onLogoutClick}
                      >
                        <i className="ni ni-user-run" />
                        <span>Logout</span>
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AccountNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AccountNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // profile: state.profile,
});

export default connect(mapStateToProps, { logoutUser })(AccountNavbar);
