import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
//import MicRecorder from "mic-recorder-to-mp3";

navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia ||
  navigator.oGetUserMedia;

function FormTexttospeech(props) {
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    clearError,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      _id: 0,
      name: "",
      description: "",
      recording: "",
      duration: 0,
      recordSupport: "",
      language: "en-US-Wavenet-J",
      speed: 1.05,
      pitch: 0,
      ssml: false,
    },
  });
  const [audioList, setAudioList] = useState([]);
  const [updateRecord, setUpdateRecording] = useState(false);
  //const [recordSupport, setRecordSupport] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSpeech, setLoadingSpeech] = useState(false);
  const audioRef = useRef(null);
  // const options = [];
  useEffect(() => {
    register({ name: "_id" });
    register({ name: "recording" });
    register({ name: "duration" });

    if (Object.keys(props.item).length > 0) {
      setValue("_id", props.item._id);
      setValue("name", props.item.name);
      setValue("type", props.item.type);
      setValue("description", props.item.description);
      setValue("path", props.item.path);
      setValue("language", props.item.language);
      setValue("speed", props.item.speed);
      setValue("pitch", props.item.pitch);
      setValue("ssml", props.item.ssml);
    }
  }, [setValue, props.item, register]);

  const start = (form) => {
    const description = getValues("description");
    const language = getValues("language");
    const speed = getValues("speed");
    const pitch = getValues("pitch");
    const ssml = getValues("ssml");
    if (!description) {
      setError("description");
    }
    if (!language) {
      setError("language");
    }
    if (!speed) {
      setError("speed", "required", "Speed is required.");
    }
    if (speed < 0.8 || speed > 1.6) {
      setError("speed", "required", "Range invalid");
    }
    if (!pitch) {
      setError("pitch", "required", "Speed is required.");
    }
    if (pitch < -12 || pitch > 12) {
      setError("pitch", "required", "Range invalid");
    }

    if (description && language && speed && pitch) {
      setLoadingSpeech(true);
      axios({
        method: "post",
        responseType: "json",
        url: `/api/recording/textspeech/`,
        data: {
          description: description,
          language: language,
          speed: speed,
          pitch: pitch,
          ssml: ssml,
        },
      })
        .then((item) => {
          var blob = new Blob([new Uint8Array(item.data.data.data)], {
            type: "audio/mpeg",
          });
          setAudioList([
            ...audioList,
            { blobURL: URL.createObjectURL(blob), blob: blob },
          ]);

          setLoadingSpeech(false);
          const toastrType = "success";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Success",
            "Recording has been created sucessfully",
            toastrOptions
          );
        })
        .catch((err) => {
          const toastrType = "error";
          const toastrOptions = {
            timeOut: 2250,
            icon: toastrType,
            status: toastrType,
            progressBar: false,
            closeOnToastrClick: true,
            transitionOut: "bounceOut",
          };
          toastr.light(
            "Error",
            "Something went wrong creating Recording",
            toastrOptions
          );
        });
    }
  };

  const submitFormAdd = (form) => {
    var bodyFormData = new FormData();
    bodyFormData.set("name", form.name);
    bodyFormData.set("type", form.type);
    bodyFormData.set("description", form.description);
    bodyFormData.set("language", form.language);
    bodyFormData.set("duration", form.duration);
    bodyFormData.set("recordingType", "text");
    bodyFormData.set("speed", form.speed);
    bodyFormData.set("pitch", form.pitch);
    bodyFormData.set("ssm", form.ssml);

    if (updateRecord) {
      bodyFormData.append("path", updateRecord);
    } else {
      return setError("path", "Please upload a recording");
    }
    setLoading(true);
    axios({
      method: "post",
      //responseType: "json",
      url: `/api/recording/`,
      data: bodyFormData,
    })
      .then((item) => {
        setLoading(false);
        props.addItemToState(item.data);
        props.toggle();

        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Recording has been created sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong creating Recording",
          toastrOptions
        );
        if (err.response.data) {
          if (err.response.data.error) {
            // return setError("national", "required", "Phone number is required");
          } else {
          }
        }
      });
  };
  const setUpdateRecord = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      var audio = new Audio();
      audio.src = e.target.result;
      audio.addEventListener("loadedmetadata", () => {
        if (audio.duration !== Infinity) {
          const duration = (audio.duration / 100).toFixed(2) * 100;
          setValue("duration", duration);
          setUpdateRecording(file);
        }
      });
    };
  };
  const getUpdateRecordingDuration = (obj) => {
    const duration = (audioRef.current.duration / 100).toFixed(2) * 100;
    setValue("duration", duration);
  };

  const submitFormEdit = async (form) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.set("name", form.name);
    bodyFormData.set("type", form.type);
    bodyFormData.set("description", form.description);
    bodyFormData.set("language", form.language);
    bodyFormData.set("duration", form.duration);
    bodyFormData.set("speed", form.speed);
    bodyFormData.set("pitch", form.pitch);
    bodyFormData.set("ssml", form.ssml);

    if (updateRecord) {
      bodyFormData.append("path", updateRecord);
    }
    axios({
      method: "patch",
      //responseType: "json",
      url: "/api/recording/" + form._id + "/update/",
      data: bodyFormData,
    })
      .then((item) => {
        props.updateState(item.data);
        props.toggle();
        setLoading(false);
        const toastrType = "success";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Success",
          "Lead has been updated sucessfully",
          toastrOptions
        );
      })
      .catch((err) => {
        const toastrType = "error";
        const toastrOptions = {
          timeOut: 2250,
          icon: toastrType,
          status: toastrType,
          progressBar: false,
          closeOnToastrClick: true,
          transitionOut: "bounceOut",
        };
        toastr.light(
          "Error",
          "Something went wrong updating Lead",
          toastrOptions
        );
      });
  };

  return (
    <>
      <br></br>
      <form
        encType="multipart/form-data"
        onSubmit={
          Object.keys(props.item).length > 0
            ? handleSubmit(submitFormEdit)
            : handleSubmit(submitFormAdd)
        }
      >
        <Row>
          <Col md="6">
            <FormGroup>
              <Label
                for="name"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Name
              </Label>
              <FormText>Identify this recording with a name.</FormText>
              <Input
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
              {errors.name && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label
                for="type"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Type
              </Label>
              <FormText>Select the recording type.</FormText>
              <Input
                type="select"
                name="type"
                id="type"
                innerRef={register({ required: true })}
              >
                <option value="message">Message</option>
                <option value="voicemail">Voicemail</option>
                <option value="both">Both</option>
              </Input>
              {errors.type && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Type is required
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label
                for="textmessage"
                style={{
                  paddingTop: "0px",
                  display: "inline-block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Text to Speech Message
              </Label>
              <Input
                type="select"
                name="template"
                id="template"
                innerRef={register()}
                style={{
                  height: "22px",
                  width: "250px",
                  float: "right",
                  fontSize: "12px",
                  padding: "0px",
                }}
                onChange={() => {
                  if (getValues("template") === "t2") {
                    setValue(
                      "description",
                      "Hey it's {NAME} calling from {COMPANY}.  You requested a quote the other day. I wanted to reach out so we could review it over the phone. Purchasing {PRODUCT} may seem like a complicated process but it doesn't have to be. You prolly noticed the detail of our quote! That's why we want to help you pick the best options and show you the {COMPANY} difference.  Press 1 now to speak with our team."
                    );
                  } else if (getValues("template") === "t3") {
                    setValue(
                      "description",
                      "This is {NAME} calling from {COMPANY}. You requested a quote the other day, and I wanted to make sure you understood the details. Buying a {PRODUCT} can be confusing and there's a lot of complicated options.  Good news is our team is standing by to help you through them.  Press 1 to speak with the team now."
                    );
                  }
                }}
              >
                <option value="t1">-- Example Sales Messages --</option>
                <option value="t2">Requested a Quote...</option>
                <option value="t3">Confusing Options...</option>
              </Input>
              <Input
                type="textarea"
                name="description"
                id="description"
                style={{
                  height: "150px",
                }}
                onChange={() => {
                  clearError("description");
                }}
                innerRef={register({ required: true })}
              />
              {errors.description && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Text to Speech Message is required
                </div>
              )}
              Add pronunciation instructions (
              <a
                href="https://mycallassist.com/docs/text-to-speech-pronunciation-instructions/"
                target="blank"
              >
                Help
              </a>
              ): {"  "}
              <input type="checkbox" name="ssml" id="ssml" ref={register} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs="5">
            <FormGroup>
              <Label
                for="Language"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
              >
                Language
              </Label>

              <Input
                type="select"
                name="language"
                id="language"
                innerRef={register({ required: true })}
                onChange={() => {
                  clearError("language");
                }}
              >
                <option value="en-US-Wavenet-J">English US J (Man)</option>
                <option value="en-US-Wavenet-I">English US I (Man)</option>
                <option value="en-US-Wavenet-B">English US B (Man)</option>
                <option value="en-US-Wavenet-D">English US D (Man)</option>
                <option value="en-US-Wavenet-A">English US A (Man)</option>
                <option value="en-US-Wavenet-E">English US E (Woman)</option>
                <option value="en-US-Wavenet-C">English US C (Woman)</option>
                <option value="en-US-Wavenet-G">English US G (Woman)</option>
                <option value="en-US-Wavenet-F">English US F (Woman)</option>
                <option value="en-US-Wavenet-H">English US H (Woman)</option>

                <option value="en-GB-Wavenet-A">
                  English (Great Britain) A
                </option>
                <option value="en-GB-Wavenet-B">
                  English (Great Britain) B
                </option>
                <option value="en-GB-Wavenet-C">
                  English (Great Britain) C
                </option>
                <option value="en-GB-Wavenet-D">
                  English (Great Britain) D
                </option>
                <option value="en-GB-Wavenet-E">
                  English (Great Britain) E
                </option>
                <option value="en-GB-Wavenet-F">
                  English (Great Britain) F
                </option>
                <option value="en-AU-Wavenet-A">English (Austrailia) A</option>
                <option value="en-AU-Wavenet-B">English (Austrailia) B</option>
                <option value="en-AU-Wavenet-C">English (Austrailia) C</option>
                <option value="en-AU-Wavenet-D">English (Austrailia) D</option>
                <option value="es-ES-Wavenet-B">Spanish B</option>
                <option value="fr-CA-Wavenet-A">French (Canada) A</option>
                <option value="fr-CA-Wavenet-B">French (Canada) B</option>
                <option value="fr-CA-Wavenet-C">French (Canada) C</option>
                <option value="fr-CA-Wavenet-D">French (Canada) D</option>
                <option value="fr-FR-Wavenet-A">French (France) A</option>
                <option value="fr-FR-Wavenet-B">French (France) B</option>
                <option value="fr-FR-Wavenet-C">French (France) C</option>
                <option value="fr-FR-Wavenet-D">French (France) D</option>
                <option value="fr-FR-Wavenet-E">French (France) E</option>
                <option value="it-IT-Wavenet-A">Italian A</option>
                <option value="it-IT-Wavenet-B">Italian B</option>
                <option value="it-IT-Wavenet-C">Italian C</option>
                <option value="it-IT-Wavenet-D">Italian D</option>
                <option value="de-DE-Wavenet-A">German A</option>
                <option value="de-DE-Wavenet-B">German B</option>
                <option value="de-DE-Wavenet-C">German C</option>
                <option value="de-DE-Wavenet-D">German D</option>
                <option value="de-DE-Wavenet-E">German E</option>
                <option value="de-DE-Wavenet-F">German F</option>
                <option value="nl-NL-Wavenet-A">Dutch A</option>
                <option value="nl-NL-Wavenet-B">Dutch B</option>
                <option value="nl-NL-Wavenet-C">Dutch C</option>
                <option value="nl-NL-Wavenet-D">Dutch D</option>
                <option value="nl-NL-Wavenet-E">Dutch E</option>
                <option value="nl-NL-Wavenet-F">Dutch F</option>
                <option value="pt-BR-Wavenet-A">Portuguese (Brazil) A</option>
              </Input>
              {errors.language && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Language is required
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs="1"></Col>
          <Col xs="2">
            <FormGroup>
              <Label
                for="speed"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                onChange={() => {
                  clearError("speed");
                }}
              >
                Speed
              </Label>

              <Input
                type="number"
                name="speed"
                id="speed"
                innerRef={register({
                  required: true,
                })}
                style={{
                  width: "75px",
                }}
                step=".05"
                min="0.80"
                max="1.60"
              />
              <FormText>0.80 to 1.60</FormText>
              {errors.speed && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.speed.message}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs="2">
            <FormGroup>
              <Label
                for="pitch"
                style={{
                  display: "block",
                  marginBottom: "0rem",
                  fontWeight: "600",
                }}
                onChange={() => {
                  clearError("pitch");
                }}
              >
                Pitch
              </Label>

              <Input
                type="number"
                name="pitch"
                id="pitch"
                innerRef={register({ required: true })}
                style={{
                  width: "75px",
                }}
                step=".10"
                min="-12"
                max="12"
              />
              <FormText>-12 to 12</FormText>
              {errors.pitch && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.speed.pitch}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs="6">
            <FormGroup>
              {loadingSpeech ? (
                <Button
                  color="primary"
                  style={{ marginBottom: "10px" }}
                  type="button"
                  disabled={true}
                >
                  Processing...
                </Button>
              ) : (
                <Button
                  color={"primary"}
                  onClick={start}
                  style={{ marginBottom: "10px" }}
                  type="button"
                >
                  Synthesize Voice
                </Button>
              )}{" "}
            </FormGroup>
          </Col>
          <Col xs="6">
            <ListGroup>
              <FormGroup>
                <Label
                  for="recording"
                  style={{
                    paddingTop: "25px",
                    display: "inline-block",
                    marginBottom: "0rem",
                    fontWeight: "600",
                    lineHeight: "40px",
                  }}
                >
                  Voice Synthesis
                </Label>
                <br></br>
                <Col xs="12">
                  {props.item && props.item.path && props.item.fileName ? (
                    <audio
                      src={props.item.fileName}
                      controls="controls"
                      style={{ width: "300px" }}
                    />
                  ) : (
                    <></>
                  )}
                </Col>

                {errors.path && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Voice Synthesis is required
                  </div>
                )}
              </FormGroup>
              {audioList.map((audio, index) => {
                var upField = {};
                if (updateRecord === audio.blob) {
                  upField = {
                    onLoadedMetadata: getUpdateRecordingDuration,
                    ref: audioRef,
                  };
                }
                return (
                  <div key={index}>
                    {index === 0 && (
                      <Label>
                        Select the Voice Synthesis you want to Save.
                      </Label>
                    )}

                    <ListGroupItem className="justify-content-between audiolist">
                      <Row>
                        <div className="audio_lable">
                          <label>{index + 1}</label>
                        </div>
                        <Col xs="11" className="audio_div">
                          <audio
                            {...upField}
                            src={audio.blobURL}
                            controls="controls"
                            style={{ width: "240px" }}
                          />
                        </Col>
                        <div>
                          <Input
                            type="checkbox"
                            name="file"
                            value={audio.blob}
                            style={{ width: "25px", height: "25px" }}
                            checked={updateRecord === audio.blob}
                            onChange={() => {
                              clearError("path");
                              setUpdateRecord(audio.blob);
                            }}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  </div>
                );
              })}
            </ListGroup>
          </Col>
        </Row>

        <ModalFooter>
          {loading ? (
            <Button color="primary" type="button" disabled={true}>
              Saving...
            </Button>
          ) : (
            <Button type="submit" color="primary">
              Save Voice
            </Button>
          )}
        </ModalFooter>
      </form>
    </>
  );
}
FormTexttospeech.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormTexttospeech);
